/**
 * @module builder
 */
import type { OptionsDocument, PresentationOption } from 'paintscout'

export function deletePresentationOptions(args: {
  options: OptionsDocument
  presentationOptions: PresentationOption[]
}): OptionsDocument {
  const { options, presentationOptions } = args
  const keys = presentationOptions.map((presentationOption) => presentationOption.key)

  // Clean Order
  const order = (options.options?.presentationOptions?.order ?? []).filter((item) => !keys.includes(item))
  const values = { ...(options.options.presentationOptions?.values ?? {}) }

  // Clean PresentationOptionsValues
  keys.forEach((key) => {
    if (values[key]) {
      delete values[key]
    }
  })

  return {
    ...options,
    options: {
      ...options.options,
      presentationOptions: {
        ...options.options.presentationOptions,
        order,
        values
      }
    }
  }
}
