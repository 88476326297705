import 'prismjs/themes/prism.css'
import React from 'react'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-json'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid',
    borderRadius: 2,
    '& textarea': {
      outline: 'none'
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
    }
  }
}))

export default function CodeEditor(props: { value: string; onChange: (value: string) => any }) {
  const classes = useStyles()
  return (
    <Editor
      className={classes.root}
      value={props.value}
      onValueChange={props.onChange}
      highlight={(code) => highlight(code, languages.json)}
      padding={10}
      style={{
        fontSize: 16
      }}
    />
  )
}
