import React, { useState } from 'react'
import { makeStyles, Divider } from '@material-ui/core'
import type { OptionsDocument } from 'paintscout'
import type { StyleClasses } from '@ui/core/theme'
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Collapse,
  KeypadInputField,
  Select,
  MenuItem,
  FormSection,
  Checkbox
} from '@ui/paintscout'
import type { DialogProps } from '@ui/paintscout'
import { useDialogs } from '@ui/paintscout'
import classnames from 'classnames'

import type { ValueType } from '@ui/paintscout/src/TileList'
import { getObjectLabels, getProductOptions, updateProductOption } from '@paintscout/util/builder'
import type { ProductOption } from '@paintscout/util/builder'
import Done from '@material-ui/icons/Done'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import find from 'lodash/find'
import { useFormik } from 'formik'
import EditProductOptionDialog from '../EditProductOptionDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '50vh',
    overflowY: 'scroll'
  },
  paper: {
    minHeight: '80vh',
    height: 'auto'
  },
  acceptButton: {
    marginLeft: theme.spacing(1)
  },
  collapseDiv: {
    marginLeft: theme.spacing()
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  formSection: {
    paddingTop: theme.spacing(2)
  },
  collapse: {},
  collapseButton: {
    margin: `${theme.spacing(2)}px 0px`,
    marginBottom: theme.spacing()
  },
  itemContainer: {
    border: '0.5px solid grey'
  },
  changeText: {
    color: 'green'
  },
  errorText: {
    color: 'red'
  },
  titleGrid: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0
  },
  titleDiv: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0
  },
  changesDiv: {
    maxHeight: '550px',
    overflowY: 'scroll',
    border: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxHeight: '370px'
    }
  },
  selectButton: {
    padding: 0,
    height: 'auto'
  },
  checkboxDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    alignSelf: 'start'
  }
}))

export interface EditBulkProductOptionDialogProps extends DialogProps {
  classes?: StyleClasses<typeof useStyles>
  keys: ValueType[]
  options: OptionsDocument

  onConfirm: (updatedOptions: OptionsDocument) => void
  onCancel?: (ev: React.MouseEvent<HTMLElement>) => void
}

export default function EditBulkProductOptionDialog({
  keys,
  options,
  onCancel,
  onConfirm,
  ...props
}: EditBulkProductOptionDialogProps) {
  const classes = useStyles()
  const { openDialog, dismissDialog } = useDialogs()
  const { currency } = getObjectLabels({ options })
  const [currentOptions, setCurrentOptions] = useState<OptionsDocument>(options)
  const productUnitOptions = [
    { label: 'gal', value: 'gal' },
    { label: 'item', value: 'item' },
    { label: 'Custom...', value: 'custom' }
  ]
  const items = getProductOptions({ options: currentOptions, quoteType: 'all', inactive: true })
  const [adjustCoverage, setAdjustCoverage] = useState<boolean>(false)
  const [adjustMarkup, setAdjustMarkup] = useState<boolean>(false)
  const [showPriceChanges, setShowPriceChanges] = useState<boolean>(true)
  const [showCoverageChanges, setShowCoverageChanges] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const selectedItems = items
    .map((item) => {
      if (keys.includes(item.key)) {
        let selectedProductUnitOption = null

        selectedProductUnitOption = find(productUnitOptions, { value: item.unitLabel })
        if (!selectedProductUnitOption && typeof item.unitLabel === 'undefined') {
          selectedProductUnitOption = productUnitOptions[0]
        }

        const unitLabel = selectedProductUnitOption?.label ?? item.unitLabel

        return {
          ...item,
          unitLabel: unitLabel
        }
      } else {
        return null
      }
    })
    .filter((item) => Boolean(item))

  const formik = useFormik({
    initialValues: {
      markupPriceType: 'percentage',
      markupPriceAmount: 0,
      markupCoverageType: 'percentage',
      markupCoverageAmount: 0
    },
    onSubmit: (_values) => {
      // Handled by handleSubmit
    }
  })

  const leftButton = (
    <Button disabled={loading} onClick={onCancel} variant={'text'}>
      Cancel
    </Button>
  )

  return (
    <Dialog
      {...props}
      classes={{
        root: classes.root,
        paper: classes.paper
      }}
    >
      <DialogTitle>Multi Product Edit</DialogTitle>
      <DialogContent>
        <Grid className={classes.titleGrid} container xs={12} sm={12} spacing={2}>
          <Grid className={classes.titleDiv} item xs={4} spacing={2}>
            <Typography
              variant={'h4'}
              className={classnames({
                [classes.changeText]: adjustMarkup
              })}
            >
              Adjust Product Markup
            </Typography>
            <Typography variant="subtitle2">Enable to apply markup changes set below.</Typography>
          </Grid>
          <Checkbox
            className={classes.checkbox}
            checked={adjustMarkup}
            onChange={() => setAdjustMarkup(!adjustMarkup)}
          />
        </Grid>
        <FormSection className={classes.formSection} hideDivider>
          <Grid container xs={12} sm={12} spacing={2}>
            <Grid item xs={4}>
              <KeypadInputField
                allowNegative
                id="markupPriceAmount"
                name="markupPriceAmount"
                label="Amount:"
                value={formik.values.markupPriceAmount}
                onChange={formik.handleChange}
                error={formik.touched.markupPriceAmount && Boolean(formik.errors.markupPriceAmount)}
                helperText={formik.touched.markupPriceAmount && formik.errors.markupPriceAmount}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                }}
                id="markupPriceType"
                name="markupPriceType"
                label="type"
                value={formik.values.markupPriceType}
                onChange={formik.handleChange}
                renderValue={(value: string) => {
                  let displayValue
                  switch (value) {
                    case 'percentage':
                      displayValue = <span>{'%'}</span>
                      break
                    case 'value':
                      displayValue = <span>{`${currency.symbol}`}</span>
                      break
                    case 'absolute':
                      displayValue = <span>{'Abs'}</span>
                      break
                  }
                  return displayValue
                }}
              >
                <MenuItem value="percentage">%</MenuItem>
                <MenuItem value="value">{`${currency.symbol}`}</MenuItem>
                <MenuItem value="absolute">Abs.</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Button
            className={classes.collapseButton}
            variant="text"
            onClick={() => setShowPriceChanges(!showPriceChanges)}
          >
            {showPriceChanges ? 'Hide Changes' : 'Show Changes'}
          </Button>
          <div className={classes.collapseDiv}>
            <Collapse scroll show={showPriceChanges}>
              <Grid className={classes.changesDiv} container xs={12} spacing={2}>
                {selectedItems.map((item) => {
                  const newPrice = getNewPrice({
                    oldPrice: item.price,
                    markupPriceAmount: formik.values.markupPriceAmount,
                    markupPriceType: formik.values.markupPriceType
                  })
                  return (
                    <Grid
                      onClick={() => handleEditItem(item.key)}
                      className={classes.itemContainer}
                      container
                      justifyContent={'center'}
                      spacing={0}
                      item
                      sm={6}
                      xs={12}
                      key={item?.key}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6">{item?.label}</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1">{item?.unitLabel}</Typography>
                        <Typography className={!item?.price ? classes.errorText : null} variant="body1">{`(${
                          currency.symbol
                        }/${item?.unitLabel}): ${item?.price?.toFixed(2) ?? 'N/A'}`}</Typography>
                      </Grid>
                      <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={1}>
                        <ArrowForwardIos />
                      </Grid>
                      {/* updated item column */}
                      <Grid item xs={5}>
                        <Typography
                          className={newPrice !== '' ? classes.changeText : classes.errorText}
                          variant="body1"
                        >
                          {`(${currency.symbol}/${item?.unitLabel}): ${newPrice !== '' ? newPrice : 'N/A'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Collapse>
          </div>
        </FormSection>
        <Divider className={classes.divider} />
        <Grid className={classes.titleGrid} container xs={12} sm={12} spacing={2}>
          <Grid className={classes.titleDiv} item xs={4} spacing={2}>
            <Typography
              variant={'h4'}
              className={classnames({
                [classes.changeText]: adjustCoverage
              })}
            >
              Adjust Product Coverage
            </Typography>
            <Typography variant="subtitle2">Enable to apply coverage changes set below.</Typography>
          </Grid>
          <Checkbox
            className={classes.checkbox}
            checked={adjustCoverage}
            onChange={() => setAdjustCoverage(!adjustCoverage)}
          />
        </Grid>
        <FormSection className={classes.formSection} hideDivider>
          <Grid container xs={12} sm={12} spacing={2}>
            <Grid item xs={4}>
              <KeypadInputField
                allowNegative
                id="markupCoverageAmount"
                name="markupCoverageAmount"
                label="Amount:"
                value={formik.values.markupCoverageAmount}
                onChange={formik.handleChange}
                error={formik.touched.markupCoverageAmount && Boolean(formik.errors.markupCoverageAmount)}
                helperText={formik.touched.markupCoverageAmount && formik.errors.markupCoverageAmount}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                }}
                id="markupCoverageType"
                name="markupCoverageType"
                label="type"
                value={formik.values.markupCoverageType}
                onChange={formik.handleChange}
                renderValue={(value: string) => {
                  let displayValue
                  switch (value) {
                    case 'percentage':
                      displayValue = <span>{'%'}</span>
                      break
                    case 'value':
                      displayValue = <span>{'#'}</span>
                      break
                    case 'absolute':
                      displayValue = <span>{'Abs'}</span>
                      break
                  }
                  return displayValue
                }}
              >
                <MenuItem value="percentage">%</MenuItem>
                <MenuItem value="value">#</MenuItem>
                <MenuItem value="absolute">Abs.</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Button
            className={classes.collapseButton}
            variant="text"
            onClick={() => setShowCoverageChanges(!showCoverageChanges)}
          >
            {showCoverageChanges ? 'Hide Changes' : 'Show Changes'}
          </Button>
          <div className={classes.collapseDiv}>
            <Collapse scroll show={showCoverageChanges}>
              <Grid className={classes.changesDiv} container xs={12} spacing={2}>
                {selectedItems.map((item) => {
                  const newCoverage = getNewCoverage(
                    item,
                    formik.values.markupCoverageAmount,
                    formik.values.markupCoverageType
                  )
                  return (
                    <Grid
                      onClick={() => handleEditItem(item.key)}
                      className={classes.itemContainer}
                      container
                      justifyContent={'center'}
                      spacing={0}
                      item
                      sm={6}
                      xs={12}
                      key={item?.key}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6">{item?.label}</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1">x /{item?.unitLabel}</Typography>
                        {item?.price && (
                          <Typography variant="body1">{`(${currency.symbol}/${item?.unitLabel}): ${item?.price?.toFixed(
                            2
                          )}`}</Typography>
                        )}
                      </Grid>
                      <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={1}>
                        <ArrowForwardIos />
                      </Grid>
                      {/* updated item column */}
                      <Grid item xs={5}>
                        {newCoverage ? (
                          <Typography
                            className={newCoverage !== '' ? classes.changeText : classes.errorText}
                            variant="body1"
                          >
                            {newCoverage}
                          </Typography>
                        ) : (
                          <Typography
                            className={newCoverage !== '' ? classes.changeText : classes.errorText}
                            variant="body1"
                          >
                            x / {item?.unitLabel}
                          </Typography>
                        )}
                        {item?.price && (
                          <Typography variant="body1">{`(${currency.symbol}/${item?.unitLabel}): ${item?.price?.toFixed(
                            2
                          )}`}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Collapse>
          </div>
        </FormSection>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button
          loading={loading}
          classes={{ root: classes.acceptButton }}
          onClick={handleConfirm}
          variant={'contained'}
          icon={Done}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleConfirm() {
    setLoading(true)
    // use current options to update the product options with new price values
    // and new coverages ONLY if they have been checked
    let updatedOptions = { ...currentOptions }
    if (adjustMarkup || adjustCoverage) {
      selectedItems.forEach((item) => {
        let newPrice = null
        let newCoverage = null
        const {
          coverage_rate: oldCoverage,
          lnft_coverage: oldLnftCoverage,
          quantity_coverage: oldQuantityCoverage
        } = item
        const previousCoverage = oldCoverage ?? oldQuantityCoverage ?? oldLnftCoverage

        // Get new price if enabled
        if (adjustMarkup) {
          newPrice = getNewPrice({
            oldPrice: item.price,
            markupPriceAmount: formik.values.markupPriceAmount,
            markupPriceType: formik.values.markupPriceType
          })
        }

        // Only adjust coverage if product has an existing coverage, we will leave empty coverages alone(for now)
        if (adjustCoverage && previousCoverage) {
          newCoverage = getNewCoverage(item, formik.values.markupCoverageAmount, formik.values.markupCoverageType)
        }
        // Need to find out the type of coverage we are updating on the item.
        const coverageTypes = ['coverage', 'lnftCoverage', 'quantityCoverage']
        let coverageType
        switch (previousCoverage) {
          case item['coverage']:
            coverageType = coverageTypes[0]
            break
          case item['lnftCoverage']:
            coverageType = coverageTypes[1]
            break
          case item['quantityCoverage']:
            coverageType = coverageTypes[2]
            break
          default:
            coverageType = coverageTypes[0]
            break
        }

        // Update options if item has update
        if (newPrice || newCoverage) {
          const newItem: ProductOption = {
            ...item,
            price: newPrice ? parseFloat(newPrice) : item?.price,
            [coverageType]: newCoverage ? newCoverage : previousCoverage
          }

          updatedOptions = updateProductOption({ options: updatedOptions, productOption: newItem })
        }
      })
    }

    setLoading(false)
    onConfirm(updatedOptions)
  }

  function handleEditItem(key: string) {
    const items = getProductOptions({ options: currentOptions, quoteType: 'all', inactive: true })
    const item = items.find((item) => item.key === key)

    openDialog(EditProductOptionDialog, {
      options: currentOptions,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, _isDirty?: boolean) => {
        dismissDialog()
        setCurrentOptions(updatedOptions)
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }
}

function getNewPrice({ oldPrice = 0, markupPriceAmount, markupPriceType }) {
  if (markupPriceAmount === '') return ''

  if (markupPriceType === 'absolute') {
    return parseFloat(markupPriceAmount).toFixed(2)
  } else if (markupPriceType === 'percentage') {
    return (oldPrice * (1 + (1 * markupPriceAmount) / 100))?.toFixed(2)
  } else {
    return (1 * oldPrice + 1 * markupPriceAmount)?.toFixed(2)
  }
}

function getNewCoverage(item: ProductOption, markupCoverageAmount: any, markupCoverageType: string) {
  const { coverage_rate: oldCoverage, lnft_coverage: oldLnftCoverage, quantity_coverage: oldQuantityCoverage } = item
  // Order important here, need to fallback to correct coverage value
  const coverage = oldCoverage ?? oldQuantityCoverage ?? oldLnftCoverage
  if (!coverage || markupCoverageAmount === '') return ''

  if (markupCoverageType === 'absolute') {
    return parseFloat(markupCoverageAmount).toFixed(2)
  } else if (markupCoverageType === 'percentage') {
    return (coverage * (1 + (1 * markupCoverageAmount) / 100))?.toFixed(2)
  } else {
    return (1 * coverage + 1 * markupCoverageAmount)?.toFixed(2)
  }
}
