/**
 * @module builder
 */

import type { PresentationSection, PresentationSectionOption } from 'paintscout'
import { uuid } from '../../../util'

export function createPresentationSection(args: {
  presentationSectionOption: PresentationSectionOption
}): PresentationSection {
  const { presentationSectionOption } = args
  return {
    ...presentationSectionOption,
    key: uuid()
  }
}
