import { Grid } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import Done from '@material-ui/icons/Done'
import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, EditableDialogTitle, InputField } from '@ui/paintscout'
import type { AreaLabelOption } from '@paintscout/util/builder'
import { getObjectLabels, updateAreaLabelOption } from '@paintscout/util/builder'
import clone from 'lodash/clone'
import type { OptionsDocument } from 'paintscout'
import React from 'react'
import QuoteTypeSelect from '../../QuoteTypeSelect'

export interface EditAreaOptionDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  item: AreaLabelOption
  options: OptionsDocument
  isNew?: boolean
  onConfirm?: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel?: () => void
}

export interface EditAreaOptionDialogState {
  item: AreaLabelOption
  isDirty?: boolean
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    input: {},
    divider: {
      marginBottom: theme.spacing(4)
    },
    overflowVisible: {
      overflow: 'visible'
    }
  })
}

class EditAreaOptionDialog extends React.PureComponent<EditAreaOptionDialogProps, EditAreaOptionDialogState> {
  private signatureData?: string

  constructor(props: EditAreaOptionDialogProps) {
    super(props)

    const { item } = this.props

    this.state = {
      item: clone(item),
      isDirty: false
    }
  }

  public render() {
    const { loading, classes, options, ...otherProps } = this.props
    const { item } = this.state
    const objectLabels = getObjectLabels({ options })

    const leftButton = (
      <Button disabled={loading} onClick={this.props.onCancel} variant={'text'}>
        Cancel
      </Button>
    )

    return (
      <Dialog
        classes={{ root: classes.root, paper: classes.overflowVisible }}
        fullWidth={true}
        maxWidth={'md'}
        {...otherProps}
      >
        <EditableDialogTitle
          title={`${this.props.isNew ? 'Add' : 'Edit'} Area`}
          loading={loading}
          onToggle={this.handleToggle}
          toggleValue={typeof item.active !== 'undefined' ? !!item.active : true}
          toggleLabel="Active"
        />

        <DialogContent className={classes.overflowVisible}>
          <form
            id="area-option"
            onSubmit={(ev) => {
              ev.preventDefault()
              this.handleConfirm()
            }}
          >
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} sm={12} md={6}>
                <InputField
                  classes={{ root: classes.input }}
                  label={'Label or Name'}
                  name={'label'}
                  value={item.label}
                  fullWidth={true}
                  onChange={this.handleChange}
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <QuoteTypeSelect
                  label={`${objectLabels.quote.value} Types`}
                  variant="multi"
                  onChange={this.handleQuoteTypeChange}
                  value={item.quoteTypes}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions showBorder={true} leftButton={leftButton}>
          <Button type="submit" form="area-option" disabled={loading} variant={'contained'} icon={Done}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target

    this.setState({
      item: {
        ...this.state.item,
        [name]: value
      },
      isDirty: true
    })
  }

  public handleCheckboxChange = (event: any, value: string) => {
    const { name } = event.target

    this.setState({
      item: {
        ...this.state.item,
        [name]: value
      },
      isDirty: true
    })
  }

  public handleConfirm = () => {
    if (this.props.onConfirm) {
      const { options } = this.props
      const updatedOptions = updateAreaLabelOption({ options, areaLabelOption: this.state.item })
      this.props.onConfirm(updatedOptions, this.state.isDirty)
    }
  }

  public handleQuoteTypeChange = (values: string[]) => {
    this.setState({
      item: {
        ...this.state.item,
        quoteTypes: values
      },
      isDirty: true
    })
  }

  public handleToggle = () => {
    this.setState({
      item: {
        ...this.state.item,
        active: !this.state.item.active
      },
      isDirty: true
    })
  }
}

export default withStyles(styles)(EditAreaOptionDialog)
