import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

import InputField from '../../InputField'
import Button from '../../Button'
import Switch from '../../Switch'
import FormControlLabel from '../../FormControlLabel'

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography

  return createStyles({
    root: {
      ...theme.typography.h3,
      minHeight: pxToRem(40),
      height: pxToRem(80),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px #DDDDDD solid',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing()
      }
    },
    buttons: {
      marginLeft: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: '0.5fr 0.5fr',
      gridGap: pxToRem(theme.spacing())
    },
    title: {
      display: 'flex',
      fiexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleLeft: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%'
    },
    titleRight: {},
    form: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    }
  })
}

export interface EditableDialogTitleProps extends WithStyles<typeof styles> {
  title?: string
  loading?: boolean
  disabled?: boolean
  toggleValue?: boolean
  toggleTip?: string
  toggleLabel?: string
  onChange?: (value: string) => void
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
  onToggle?: (event: React.ChangeEvent<HTMLElement>, checked: boolean) => void
}

export interface EditableDialogTitleState {
  editing?: boolean
  title?: string
}

class EditableDialogTitle extends React.Component<EditableDialogTitleProps, EditableDialogTitleState> {
  constructor(props: EditableDialogTitleProps) {
    super(props)

    this.state = {
      editing: false,
      title: this.props.title
    }
  }

  public render() {
    const { loading, classes, toggleValue, onToggle, toggleTip = '', toggleLabel } = this.props
    const { editing } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.titleLeft}>
          {!editing && this.renderTitle()}
          {editing && this.renderForm()}
        </div>
        {!editing && (
          <div className={classes.titleRight}>
            {onToggle && (
              <Tooltip title={toggleTip} placement="bottom" disableTouchListener={true}>
                <FormControlLabel
                  control={<Switch color={'primary'} checked={toggleValue} onChange={onToggle} />}
                  label={toggleLabel}
                  labelPlacement="start"
                />
              </Tooltip>
            )}
            {loading && <div>{loading}</div>}
          </div>
        )}
      </div>
    )
  }

  public renderTitle() {
    const { classes, disabled, onChange } = this.props
    const { title } = this.state
    return (
      <div className={classes.title}>
        <div>{title}</div>
        {!disabled && onChange && (
          <div className={classes.buttons}>
            <Button variant={'text'} onClick={this.handleEditStart}>
              Edit
            </Button>
          </div>
        )}
      </div>
    )
  }

  public renderForm() {
    const { classes } = this.props
    const { title } = this.state
    return (
      <div className={classes.form}>
        <InputField value={title} onChange={this.handleChange} autoFocus={true} />

        <div className={classes.buttons}>
          <Button variant={'contained'} onClick={this.handleEditDone}>
            Done
          </Button>
          <Button variant={'text'} onClick={this.handleEditCancel}>
            Cancel
          </Button>
        </div>
      </div>
    )
  }

  public handleEditStart = () => {
    this.setState({ editing: true })
  }

  public handleEditDone = () => {
    this.setState({ editing: false })
  }

  public handleEditCancel = () => {
    this.setState({
      editing: false,
      title: this.props.title
    })
  }

  public handleChange = (ev: any) => {
    const { value } = ev.target
    this.setState({ title: value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }
}

export default withStyles(styles)(EditableDialogTitle)
