import { makeStyles } from '@material-ui/core'
import {
  useDialogs,
  ConfirmationDialog,
  NewFormSection as FormSection,
  AlertDialog,
  Grid,
  Typography,
  Spinner,
  useClientOptions,
  useUser
} from '@ui/paintscout'
import {
  createRateSection,
  deleteRateSection,
  getObjectLabels,
  getRateSections,
  copyRateSection,
  reorderRateSections,
  deleteRateSections
} from '@paintscout/util/builder'
import type { RateSectionOption } from '@paintscout/util/builder'
import FileSaver from 'file-saver'
import kebabCase from 'lodash/kebabCase'
import moment from 'moment'
import { Form, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument, ClientMetaDocument } from 'paintscout'
import React, { useState } from 'react'
import EditRateSectionDialog from '../../dialogs/EditRateSectionDialog'
import OptionsTileList from '../../OptionsTileList'
import type { ValueType } from '@ui/paintscout/src/TileList'
import { gql } from 'apollo-boost'
import { useApolloClient } from '@apollo/react-hooks'
import * as Sentry from '@sentry/core'
import SettingsPage from '@ui/paintscout/src/SettingsPage'
import BulkTypeAssignDialog from '../../dialogs/BulkTypeAssignDialog'

const useStyles = makeStyles((_theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    padding: 0
  },
  buttonItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}))

export interface RateOptionsProps {
  onDialogConfirm?: () => void
}

function RateOptions({ onDialogConfirm }: RateOptionsProps) {
  const classes = useStyles({})
  const apolloClient = useApolloClient()
  const { isAdmin } = useClientOptions()
  const { isSuperadmin } = useUser()
  const { openDialog, dismissDialog, dismissAllDialogs } = useDialogs()
  const [selected, setSelected] = useState<ValueType[]>([])
  const [quoteType, setQuoteType] = useState('all')
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
    meta?: ClientMetaDocument // Only in admin
  }>()
  const { options, rates, meta } = values
  const objectLabels = getObjectLabels({ options })
  const items = getRateSections({ options, quoteType: quoteType, inactive: true })

  return (
    <SettingsPage
      title={`Rate Categories`}
      callout={{
        path: 'settings-rates',
        content: `A ${objectLabels.productionRate.value.toLowerCase()} is a calculation that helps you estimate the time required to complete a task. The items listed below represent your ${objectLabels.productionRate.value.toLowerCase()}  categories. Click on a category to edit the rates within it.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/3206998-production-rates-section-overview'
      }}
    >
      <Form className={classes.root}>
        <FormSection
          hidePadding
          selectHeader
          selectValue={quoteType}
          changeSelect={setQuoteType}
          buttonAction={handleExportDialog}
        >
          <OptionsTileList
            items={items}
            createTitle={'Add New Rate Category'}
            showCreate={true}
            selected={selected}
            setSelected={setSelected}
            onDeleteBulkItems={handleDeleteBulkItems}
            onAssignBulkItems={isAdmin || isSuperadmin ? handleAssignBulkItems : null}
            onReorder={handleReorder}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            onCreateClick={handleCreateItem}
            onCopyItem={handleCopyItem}
            noItemTitle={'No Rate Categories'}
            noItemHelpText={'Rate Categories are used to group your rates together. Add a new one to get started.'}
          />
        </FormSection>
      </Form>
    </SettingsPage>
  )

  function handleReorder(rateSections: RateSectionOption[]) {
    const updatedOptions = reorderRateSections({ rateSections, options, quoteType })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getRateSections({ options, quoteType: quoteType, inactive: true })
    const item = items.find((item) => item.key === key)

    openDialog(EditRateSectionDialog, {
      options,
      rates,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, updatedRates: RatesDocument, isDirty?: boolean) => {
        setFieldValue('options', updatedOptions)
        setFieldValue('rates', updatedRates)
        dismissDialog()
        if (onDialogConfirm && isDirty) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const { options: updatedOptions, rates: updatedRates } = copyRateSection({ options, rates, key })

    setFieldValue('options', updatedOptions)
    setFieldValue('rates', updatedRates)
  }

  function handleCreateItem(_event: React.MouseEvent) {
    const item = createRateSection()

    openDialog(EditRateSectionDialog, {
      options,
      rates,
      item,
      isNew: true,
      onConfirm: async (updatedOptions: OptionsDocument, updatedRates: RatesDocument, isDirty?: boolean) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        setFieldValue('rates', updatedRates)
        if (onDialogConfirm && isDirty) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>
            This {objectLabels.productionRate.value.toLowerCase()} will no longer be available to add to new{' '}
            {objectLabels.quote.plural}.
          </p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getRateSections({
          options,
          quoteType,
          inactive: true
        })
        const { updatedOptions, updatedRates } = deleteRateSection({
          rateSection: items.find((item) => item.key === key),
          options,
          rates
        })

        setFieldValue('options', updatedOptions)
        setFieldValue('rates', updatedRates)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleAssignBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(BulkTypeAssignDialog, {
      keys,
      field: 'rateSections',
      options,
      onConfirm: (updatedOptions: OptionsDocument) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => dismissDialog()
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>
            These {objectLabels.productionRate.plural.toLowerCase()} will no longer be available to add to new{' '}
            {objectLabels.quote.plural}.
          </p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getRateSections({ options })
        const { updatedOptions, updatedRates } = deleteRateSections({
          rateSections: items.filter((item) => keys.includes(item.key)),
          options,
          rates
        })
        setFieldValue('options', updatedOptions)
        setFieldValue('rates', updatedRates)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  async function handleExportDialog() {
    openDialog(ConfirmationDialog, {
      title: `Select Export Type`,
      message: 'Select detail level of export you would like.',
      yesLabel: 'Detailed',
      noLabel: 'Basic',
      onConfirm: async (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
        await handleExport({
          detailed: true,
          basic: false
        })
      },
      onCancel: async (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
        await handleExport({
          detailed: false,
          basic: true
        })
      }
    })
  }

  async function handleExport({ detailed, basic }: { detailed: boolean; basic: boolean }) {
    openDialog(AlertDialog, {
      title: `Export ${objectLabels.productionRate.plural}`,
      actions: [
        {
          label: 'Cancel',
          onClick: dismissDialog,
          left: true,
          variant: 'text'
        }
      ],
      message: (
        <Grid container spacing={2} alignItems={'center'} direction={'column'}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom={true}>
              Please wait while we export your {objectLabels.productionRate.plural.toLowerCase()}.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Spinner />
          </Grid>
        </Grid>
      )
    })
    const dateFormat = options.options.dateFormat.momentValue
    const companyName = options.options?.companyName?.value ?? ''
    const fileName = `${kebabCase(companyName as string)}-${moment(Date.now()).format(dateFormat)}.csv`

    try {
      const { data } = await apolloClient.query({
        query: gql`
          query exportProductionRates($id: String, $detailed: Boolean, $basic: Boolean) {
            exportProductionRates(id: $id, detailed: $detailed, basic: $basic) {
              csv
            }
          }
        `,
        variables: {
          id: meta?._id ?? null,
          detailed,
          basic
        },
        fetchPolicy: 'no-cache'
      })
      if (data.exportProductionRates.csv) {
        FileSaver.saveAs(new Blob([data.exportProductionRates.csv]), fileName)
      }
      dismissAllDialogs()
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

export default RateOptions
