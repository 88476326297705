import type { OptionsDocument } from 'paintscout'
import type { QuoteTag } from '../..'

export function deleteQuoteTag(args: { options: OptionsDocument; quoteTag: QuoteTag }): OptionsDocument {
  const { options, quoteTag } = args
  const key = quoteTag.name

  const order = (options.options.quoteTags?.order ?? []).filter((item) => key !== item)
  const values = { ...options.options.quoteTags?.values }
  if (values[key]) {
    delete values[key]
  }

  return {
    ...options,
    options: {
      ...options.options,
      quoteTags: {
        ...options.options.quoteTags,
        order,
        values
      }
    }
  }
}

export function deleteQuoteTags(args: { options: OptionsDocument; quoteTags: QuoteTag[] }): OptionsDocument {
  const { options, quoteTags } = args
  return quoteTags.reduce((acc, quoteTag) => deleteQuoteTag({ options: acc, quoteTag }), options)
}
