import { FormikInputField, CheckboxField, Grid, Button, Switch, Typography } from '@ui/paintscout'
import type { LabelsOption } from '@paintscout/util/builder'
import { getObjectLabels, uuid } from '@paintscout/util/builder'
import { FastField, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import TaxDiscountLayout from './TaxDiscountLayout'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  defaultCheckbox: {
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      marginLeft: -theme.spacing(1)
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: -theme.spacing(1.5)
    }
  }
}))

export default function TaxOptions({
  showAdvanced,
  objectLabels
}: {
  objectLabels: LabelsOption
  showAdvanced?: boolean
}) {
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {(options.options.taxRates ?? []).map((taxRate, taxRateIndex) => {
        return (
          <TaxRateRow
            rateName={`options.options.taxRates[${taxRateIndex}].rate`}
            descriptionName={`options.options.taxRates[${taxRateIndex}].description`}
            index={taxRateIndex}
            key={taxRateIndex}
            isDefault={options.options.taxRates[taxRateIndex]?.isDefault}
          />
        )
      })}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            name="addTaxRate"
            color="primary"
            onClick={() => {
              setFieldValue('options.options.taxRates', [
                ...(options.options.taxRates ?? []),
                { key: uuid(), rate: 0, description: '', isDefault: false }
              ])
            }}
          >
            + Add Tax Rate
          </Button>
        </Grid>
        {showAdvanced && (
          <Grid item xs={12} className={classes.visibleRadioDiv}>
            <Switch
              name="options.options.taxRate.allow_custom"
              checked={options.options.taxRate?.allow_custom}
              onChange={(_ev) => {
                setFieldValue('options.options.taxRate.allow_custom', !options.options.taxRate?.allow_custom)
              }}
            />
            <Typography variant={'body1'}>
              Allow Tax Rates to be customized on individual {objectLabels.quote.plural.toLowerCase()}.
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

function TaxRateRow({
  rateName,
  descriptionName,
  isDefault,
  index
}: {
  rateName: string
  descriptionName: string
  isDefault?: boolean
  index?: number
}) {
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const classes = useStyles()

  const { quote: quoteLabel } = getObjectLabels({ options })

  const handleRemove = () => {
    const updatedTaxRates = [...options.options.taxRates]
    updatedTaxRates.splice(index, 1)
    setFieldValue('options.options.taxRates', updatedTaxRates)
  }

  return (
    <TaxDiscountLayout
      Description={
        <FastField
          component={FormikInputField}
          name={descriptionName}
          type="text"
          label={'Tax Description'}
          fullWidth
        />
      }
      Value={
        <FastField
          required
          component={FormikInputField}
          name={rateName}
          format={'taxRate'}
          label={'Tax Rate (%)'}
          placeholder={'Tax Rate'}
          error={!values.options.options.taxRates[index]?.rate}
          fullWidth
        />
      }
      Checkbox={
        <CheckboxField
          className={classes.defaultCheckbox}
          name={`options.options.taxRates[${index}].isDefault`}
          checked={isDefault}
          label={`Include on New ${quoteLabel.plural}`}
          onClick={() => {
            setFieldValue(`options.options.taxRates[${index}].isDefault`, !isDefault)
          }}
        />
      }
      handleRemove={handleRemove}
    />
  )
}
