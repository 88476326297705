import { Button, useDialogs } from '@ui/paintscout'
import React from 'react'
import history from 'shared/util/routing/history'
import NewClientDialog from './NewClientDialog'

export default function NewClientButton() {
  const { openDialog, dismissDialog } = useDialogs()

  return (
    <Button
      color="primary"
      variant="text"
      onClick={() => {
        openDialog(NewClientDialog, {
          onConfirm: (clientId: string) => {
            history.push({
              pathname: `/clients/${clientId}`
            })
            dismissDialog()
          },
          onClose: () => dismissDialog()
        })
      }}
    >
      + New Client
    </Button>
  )
}
