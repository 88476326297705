/**
 * @module builder
 */
import type { OptionsDocument, PresentationPageOption, PresentationSection } from 'paintscout'
import { createPresentationPage } from '../create-presentation-page'
import { uuid } from '../../../util'

export function getPresentationPageOptions(args: {
  options?: OptionsDocument
  type?: string
}): PresentationPageOption[] {
  const { type, options } = args
  const hasSavedPages = options?.options?.presentationPageLibrary?.order?.length >= 1
  const defaultPageOptions: PresentationPageOption[] = [
    {
      type: 'empty',
      label: 'Empty Page',
      title: 'New Page',
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: true
      },
      sections: [] as PresentationSection[]
    },
    {
      type: 'quote',
      label: 'Quote',
      title: 'Quote',
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: true
      },
      sections: [
        {
          key: uuid(),
          type: 'quote',
          label: 'Quote',
          title: 'Quote',
          active: true,
          advanced: {
            useForNav: true,
            allowCustom: false
          },
          background: {
            style: 'contained',
            color: '#fff'
          }
        },
        {
          key: uuid(),
          type: 'pictures',
          label: 'Pictures',
          title: 'Pictures',
          active: true,
          advanced: {
            useForNav: true,
            allowCustom: false
          },
          background: {
            style: 'contained',
            color: '#fff'
          }
        },
        {
          key: uuid(),
          type: 'terms',
          label: 'Terms',
          title: 'Terms',
          active: true,
          advanced: {
            useForNav: true,
            allowCustom: false
          },
          background: {
            style: 'contained',
            color: '#fff'
          }
        }
      ]
    }
  ]

  if (hasSavedPages) {
    const savedPageOptions = options.options.presentationPageLibrary.order.map((page) => {
      return options.options.presentationPageLibrary.values[page]
    })

    const savedPages = savedPageOptions.map((page) => {
      return createPresentationPage({ presentationPageOption: page })
    })

    const response = [...defaultPageOptions, ...savedPages]

    if (type) {
      return response.filter((page) => page.type === type)
    }
    return response
  }
  if (type) {
    return defaultPageOptions.filter((option) => option.type === type)
  }

  return defaultPageOptions
}
