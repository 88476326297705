/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { LineItemOption } from '../../index'

export function deleteLineItemOption(args: {
  options: OptionsDocument
  lineItemOption: LineItemOption
}): OptionsDocument {
  const { options, lineItemOption } = args

  const key = lineItemOption.name

  const order = (options.options.lineItems?.order ?? []).filter((item) => key !== item)
  const quoteTypeOrder = options.options.lineItems?.quoteTypeOrder ?? {}

  Object.keys(quoteTypeOrder).forEach((type) => {
    quoteTypeOrder[type] = quoteTypeOrder[type].filter((item) => key !== item)
  })

  const values = { ...options.options.lineItems?.values }
  if (values[key]) {
    delete values[key]
  }

  return {
    ...options,
    options: {
      ...options.options,
      lineItems: {
        ...options.options.lineItems,
        order,
        quoteTypeOrder,
        values
      }
    }
  }
}

export function deleteLineItemOptions(args: {
  options: OptionsDocument
  lineItemOptions: LineItemOption[]
}): OptionsDocument {
  const { options, lineItemOptions } = args
  return lineItemOptions.reduce((options, lineItemOption) => deleteLineItemOption({ options, lineItemOption }), options)
}
