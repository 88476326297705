import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { Collapse, Grid, Button, FormSectionTitle, Switch } from '../../'
import { NewFormSection as FormSection } from '../../FormSection'
import type { ProviderConfig } from 'paintscout'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { useIsMobile } from '@ui/core'

const useStyles = makeStyles<Theme>(
  (_theme) => ({
    root: {}
  }),
  { name: 'BaseAdminProvider' }
)

interface BaseAdminProviderProps {
  title: string
  providerConfig: ProviderConfig
  children?: React.ReactNode
  onConnect?: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  onDisconnect?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function BaseAdminProvider({
  title,
  providerConfig,
  children,
  onConnect,
  onDisconnect
}: BaseAdminProviderProps) {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const [showSettings, setShowSettings] = useState<boolean>(false)

  return (
    <FormSection>
      <Grid item md={6} sm={5} xs={3}>
        <FormSectionTitle classes={{ root: classes.providerTitle }} title={title} variant={'h3'} />
      </Grid>
      <Grid>
        <Grid container item justifyContent="flex-end">
          {!providerConfig.enabled ? (
            <Switch
              name={`${title}.enabled`}
              checked={providerConfig.enabled}
              // disabled={isSubmitting || disabled}
              onChange={() => {
                if (onConnect) {
                  onConnect(title, { ...providerConfig, enabled: true }, true)
                }
              }}
            />
          ) : (
            <Switch
              name={`${title}.enabled`}
              checked={providerConfig.enabled}
              // disabled={isSubmitting || disabled}
              onChange={(e) => {
                if (onDisconnect) {
                  onDisconnect(e)
                }
              }}
            />
          )}
        </Grid>
        {providerConfig.enabled && children && (
          <Grid container item justifyContent="flex-end">
            <Button
              endIcon={showSettings ? <ExpandLess /> : <ExpandMore />}
              size={isMobile ? 'large' : 'small'}
              onClick={() => setShowSettings(!showSettings)}
              variant="text"
            >
              {isMobile ? '' : 'Settings'}
            </Button>
          </Grid>
        )}
      </Grid>
      {providerConfig.enabled && children && (
        <Collapse show={providerConfig.enabled && showSettings}>
          <div className={classes.formWrapper}>{children}</div>
        </Collapse>
      )}
    </FormSection>
  )
}
