import type { EventType, EventTypeConfig, ProviderConfig } from 'paintscout'

interface HandleIntegrationEventChangeProps {
  providerName: string
  providerConfig: ProviderConfig
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
}

interface IntegrationEventHandlers {
  handleStageSelection: (name: string, value: any) => void
  handleEventChange: (name: EventType, event: EventTypeConfig) => void
  handleConfigChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  handleConfigToggleChange: (name: string) => React.MouseEventHandler<HTMLDivElement>
  handleUpdateConfigCheckbox: (name: string) => (_: React.ChangeEvent) => void
}

export const integrationEventHandlers = ({
  providerConfig,
  providerName,
  onChange
}: HandleIntegrationEventChangeProps): IntegrationEventHandlers => {
  const handleStageSelection = (name: string, value: any) => {
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: value
      }
    })
  }

  const handleEventChange = (name: EventType, event: EventTypeConfig) => {
    onChange(providerName, {
      ...providerConfig,
      events: {
        ...providerConfig.events,
        [name]: event
      }
    })
  }

  const handleConfigChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target

    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: value
      }
    })
  }

  function handleConfigToggleChange(name: string) {
    return function (_e: React.MouseEvent) {
      onChange(providerName, {
        ...providerConfig,
        config: {
          ...providerConfig.config,
          [name]: !providerConfig.config[name]
        }
      })
    }
  }

  const handleUpdateConfigCheckbox = (name: string) => (_: React.ChangeEvent) => {
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: !providerConfig.config[name]
      }
    })
  }

  return {
    handleStageSelection,
    handleEventChange,
    handleConfigChange,
    handleConfigToggleChange,
    handleUpdateConfigCheckbox
  }
}
