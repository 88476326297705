import type { BillingPlan } from '../index'
import { getFeatures } from '../get-features'
import type { OptionsDocument } from 'paintscout'
const deepmerge = require('deepmerge')

export function getPlans(args?: { deprecated?: boolean; options?: OptionsDocument }): BillingPlan[] {
  const trialFeatures = getFeatures({ options: args?.options })

  const premiumFeatures = deepmerge(trialFeatures, {
    reports: {
      enabled: false
    },
    presentation: {
      enabled: false
    },
    quotes: {
      conversations: { enabled: false },
      followUps: { enabled: false }
    },
    team: {
      enabled: true
    }
  })

  const businessFeatures = deepmerge(premiumFeatures, {
    presentation: {
      enabled: true
    },
    quotes: {
      conversations: { enabled: true },
      followUps: { enabled: true }
    },
    team: {
      enabled: true
    }
  })

  const plans = [
    { value: 'paintscout-202009-addon-user-monthly', price: '1900', label: 'Additional User' } as any as BillingPlan,
    { value: 'trial', label: 'Trial', features: trialFeatures } as any as BillingPlan,
    {
      value: 'paintscout-202103-premium-annual',
      label: 'Premium - Annual',
      sublabel: 'per user, per month',
      price: '$59',
      disclaimer: 'Billed annually',
      features: premiumFeatures,
      addons: {
        crewUser: 'paintscout-202009-addon-user-annual'
      },
      deprecated: true
    },
    {
      value: 'paintscout-202103-premium-monthly',
      label: 'Premium - Monthly',
      sublabel: 'per user, per month',
      disclaimer: 'Billed monthly',
      price: '$79',
      features: premiumFeatures,
      addons: {
        crewUser: 'paintscout-202009-addon-user-monthly'
      },
      deprecated: true
    },
    {
      value: 'paintscout-202103-business-annual',
      sublabel: 'per user, per month',
      label: 'Business - Annual',
      disclaimer: 'Billed annually',
      price: '$79',
      features: businessFeatures,
      addons: {
        crewUser: 'paintscout-202009-addon-user-annual'
      },
      deprecated: true
    },
    {
      value: 'paintscout-202103-business-monthly',
      sublabel: 'per user, per month',
      label: 'Business - Monthly',
      disclaimer: 'Billed monthly',
      price: '$99',
      features: businessFeatures,
      addons: {
        crewUser: 'paintscout-202009-addon-user-monthly'
      },
      deprecated: true
    },
    {
      value: 'paintscout_202404_annual',
      sublabel: 'per user, per month',
      label: 'Business - Annual',
      disclaimer: 'Billed annually',
      price: '$99',
      features: businessFeatures,
      addons: {
        crewUser: 'paintscout-202009-addon-user-annual'
      }
    },
    {
      value: 'paintscout_202404_monthly',
      sublabel: 'per user, per month',
      label: 'Business - Monthly',
      disclaimer: 'Billed monthly',
      price: '$119',
      features: businessFeatures,
      addons: {
        crewUser: 'paintscout-202009-addon-user-monthly'
      }
    }
  ]

  return plans.filter((plan) => {
    if (!(args?.deprecated ?? true) && plan.deprecated) {
      return false
    }

    return true
  })
}
