/**
 * @module builder
 */

import type { PresentationOption } from 'paintscout'
import { uuid } from '../../../util'

export function copyPresentationPage(args: {
  pageIndex: number
  presentation: PresentationOption
}): PresentationOption {
  const { pageIndex, presentation } = args

  const page = presentation?.pages?.[pageIndex]

  if (!page) {
    return null
  }

  const newSections = page.sections.map((section) => {
    return {
      ...section,
      key: uuid()
    }
  })

  const newPage = { ...page, sections: newSections, key: uuid() }

  const updatedPages = [...presentation.pages]
  updatedPages.splice(pageIndex, 0, newPage)

  return {
    ...presentation,
    pages: updatedPages
  }
}
