import type { OptionsDocument } from 'paintscout'
import type { LeadSourceResult, LeadSourceType } from '..'

/**
 * Reorder lead sources based on type
 * @returns updated options
 */
export function reorderLeadSources(args: {
  options: OptionsDocument
  type: LeadSourceType
  sources: LeadSourceResult<LeadSourceType>[]
}): OptionsDocument {
  const { options, type, sources } = args
  return {
    ...options,
    options: {
      ...options.options,
      [type]: {
        ...options.options[type],
        order: sources.map((item: LeadSourceResult<LeadSourceType>) => item.name)
      }
    }
  }
}
