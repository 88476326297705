import type { OptionsDocument } from 'paintscout'
import type { LeadSourceResult, LeadSourceType } from '../..'

export function deleteLeadSource(args: {
  options: OptionsDocument
  type: LeadSourceType
  source: LeadSourceResult<LeadSourceType>
}): OptionsDocument {
  const { options, type, source } = args
  const key = source.name

  const order = (options.options[type]?.order ?? []).filter((item) => key !== item)
  const values = { ...(options.options[type]?.values ?? {}) }
  if (values[key]) {
    delete values[key]
  }

  return {
    ...options,
    options: {
      ...options.options,
      [type]: {
        ...options.options[type],
        order,
        values
      }
    }
  }
}

export function deleteLeadSources(args: {
  options: OptionsDocument
  type: LeadSourceType
  sources: LeadSourceResult<LeadSourceType>[]
}): OptionsDocument {
  const { options, type, sources } = args
  return sources.reduce((options, source) => deleteLeadSource({ options, type, source }), options)
}
