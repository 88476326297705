import React from 'react'
import type { FieldProps } from 'formik'
import type { Moment } from 'moment'
import moment from 'moment'
import type { SingleDatePickerProps } from '../DatePicker'
import { SingleDatePicker } from '../DatePicker'

export interface FormikDatePickerProps extends FieldProps<any>, Omit<SingleDatePickerProps, 'form' | 'classes'> {
  InputProps?: any
}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikDatePicker = ({ field, form, ...props }: FormikDatePickerProps) => {
  const onChange = (date: Moment) => {
    form.handleChange({ target: { name: field.name, value: date ? date.unix() * 1000 : null } })
  }

  return (
    <SingleDatePicker
      {...field}
      {...props}
      label={props.InputProps?.label}
      sublabel={props.InputProps?.sublabel}
      onChange={onChange}
      date={getMomentValue(field.value)}
      PopperProps={{ disablePortal: false }}
    />
  )
}

function getMomentValue(value) {
  if (!value) {
    return null
  }

  const r = moment(value)
  if (!r.isValid()) {
    return null
  }

  return r
}

export default FormikDatePicker
