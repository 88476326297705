import React, { useState } from 'react'

import { Button, Grid, InputField } from '@ui/paintscout'

import type { FormikPresentationSectionConnectProps, FormikPresentationSectionValues } from '../../../'
import { connect } from 'formik'

import CopyToClipboard from 'react-copy-to-clipboard'
import { useSnackbar } from 'notistack'
import { Alert } from '@ui/paintscout'

export interface ImportExportOptionsProps {}

const ImportExportOptions = ({
  formik,
  ..._props
}: ImportExportOptionsProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values
  const [json, setJson] = useState(JSON.stringify(section))
  const [error, setError] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Grid container alignItems={'flex-end'} spacing={1}>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error" content={error} />
        </Grid>
      )}
      <Grid item xs={12}>
        <InputField value={json} fullWidth={true} multiline={true} onChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-around">
              <Grid item>
                <CopyToClipboard
                  text={json}
                  onCopy={() => enqueueSnackbar(`Section has been copied to clipboard.`, { variant: 'success' })}
                >
                  <Button>Copy</Button>
                </CopyToClipboard>
              </Grid>
              <Grid item>
                <Button onClick={handleImportClick}>Import</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  function handleImportClick(_ev: React.MouseEvent) {
    try {
      const newSection = JSON.parse(json)
      if (!newSection.type) {
        throw 'Missing section type'
      }
      formik.setFieldValue('section', { ...newSection, key: section.key })
    } catch (err) {
      setError('Invalid Json')
      enqueueSnackbar('Unable to copy', { variant: 'error' })
    }
  }

  function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const { value } = ev.target
    setJson(value)
  }
}

export default connect<ImportExportOptionsProps, FormikPresentationSectionValues>(ImportExportOptions)
