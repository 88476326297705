import ColorPicker from './ColorPicker'
export default ColorPicker
export * from './ColorPicker'

import InlineColorPicker from './InlineColorPicker'
export { InlineColorPicker }
export * from './InlineColorPicker'

export interface BaseColorPickerProps {
  label?: string
  value: string
  onChange?: (hex: string) => void
}
