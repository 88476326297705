import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import Typography from '../Typography'
import { Chip } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: theme.spacing(),
    height: 'auto',
    borderRadius: theme.borderRadius.lg
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      '&$content': {
        fontSize: '0.8rem'
      }
    }
  }
}))

export interface FilterChipProps {
  classes?: StyleClasses<typeof useStyles>
  title: React.ReactNode
  label: React.ReactNode
  onDelete: () => any
}

function FilterChip({ title, label, onDelete, ...props }: FilterChipProps) {
  const classes = useStyles(props)

  return (
    <Chip
      className={classes.root}
      label={
        <div className={classes.label}>
          <Typography variant="overline" align="center">
            {title}
          </Typography>
          <Typography className={classes.content} variant="body1" align="center">
            {label}
          </Typography>
        </div>
      }
      onDelete={onDelete}
    />
  )
}

export default FilterChip
