import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  copyPresentationOption,
  createPresentationOption,
  deletePresentationOption,
  deletePresentationOptions,
  getObjectLabels,
  getPresentationOptions,
  reorderPresentationOptions,
  updatePresentationOption
} from '@paintscout/util/builder'
import { Button, ClientOptionsProvider, FormSectionTitle } from '@ui/paintscout'
import { useFormikContext } from 'formik'
import type { OptionsDocument, PresentationOption, RatesDocument } from 'paintscout'
import React, { useEffect, useRef, useState } from 'react'
import PresentationDetails from './PresentationDetails'
import PresentationIndex from './PresentationIndex'

import SettingsPage from '@ui/paintscout/src/SettingsPage'
import find from 'lodash/find'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: `0 0 ${theme.spacing()}px`
  },
  title: {
    padding: 0,
    marginBottom: 0,
    marginLeft: theme.spacing(1)
  }
}))

export interface PresentationOptionsProps {
  presentation?: string
  isAdmin?: boolean
  onPresentationChange?: (presentationKey: string) => void
  onDialogConfirm?: () => void
}

function PresentationOptions(props: PresentationOptionsProps) {
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const classes = useStyles(props)

  const { options, rates } = values
  const quoteLabel = getObjectLabels({ options }).quote.value.toLowerCase()
  const { isAdmin, onPresentationChange, onDialogConfirm } = props

  const presentationOptions = getPresentationOptions({ options, inactive: true })
  const initialPresentation = useRef(props.presentation ? find(presentationOptions, { key: props.presentation }) : null)
  const [currentPresentation, setCurrentPresentation] = useState<PresentationOption>(initialPresentation.current)
  const title = currentPresentation ? 'Edit Presentation' : 'Presentations'

  useEffect(() => {
    if (props.presentation) {
      setCurrentPresentation(find(presentationOptions, { key: props.presentation }))
    }
  }, [initialPresentation.current, props.presentation])

  return (
    <SettingsPage
      title={currentPresentation ? null : 'Presentations'}
      callout={
        currentPresentation
          ? null
          : {
              path: 'settings-presentations',
              content: `Impress your customers and blow away the competition with a presentation. These are designed to help elevate your brand and showcase your company's unique strengths and differentiators with every ${quoteLabel} you send.`,
              learnMoreLink: 'http://help.paintscout.com/en/collections/3384558-presentations'
            }
      }
      hideMenuButton={!!currentPresentation}
    >
      <div className={currentPresentation ? classes.titleRow : null}>
        {currentPresentation && (
          <>
            <Button variant={'text'} icon={ArrowBackIosIcon} onClick={handleBackClick}>
              Back
            </Button>
            <FormSectionTitle title={title} variant="h2" classes={{ root: classes.title }} />
          </>
        )}
      </div>
      {currentPresentation ? (
        // to support any components inside PresentationDetails that use ClientOptions context, we'll wrap it
        // with a provider using the current formik values
        <ClientOptionsProvider options={options} rates={rates}>
          <PresentationDetails
            onChange={handleChange}
            onDialogConfirm={onDialogConfirm}
            presentation={currentPresentation}
          />
        </ClientOptionsProvider>
      ) : (
        <PresentationIndex
          options={options}
          onSelectItem={handleSelectItem}
          onNewItem={handleNewItem}
          onDeleteItem={handleDeleteItem}
          onDeleteBulkItems={handleDeleteBulkItems}
          onCopyItem={handleCopyItem}
          onReorderItems={handleReorderItems}
          onImportItem={handleImportItem}
          isAdminPresentation={isAdmin}
        />
      )}
    </SettingsPage>
  )

  function handleSelectItem(item: PresentationOption) {
    setCurrentPresentation(item)
    onPresentationChange(item.key)
  }

  function handleCopyItem(item: PresentationOption) {
    setFieldValue(
      'options',
      copyPresentationOption({
        options,
        presentation: item
      })
    )
  }

  function handleImportItem(presentationOption: PresentationOption) {
    setCurrentPresentation(presentationOption)
    onPresentationChange(presentationOption.key)
    setFieldValue(
      'options',
      updatePresentationOption({
        options,
        presentationOption
      })
    )
  }

  function handleBackClick() {
    setCurrentPresentation(null)
    onPresentationChange(null)
  }

  function handleNewItem() {
    const presentationOption = createPresentationOption({ options })
    setCurrentPresentation(presentationOption)
    onPresentationChange(presentationOption.key)
    setFieldValue(
      'options',
      updatePresentationOption({
        options,
        presentationOption
      })
    )
  }

  function handleChange(presentationOption: PresentationOption) {
    setCurrentPresentation(presentationOption)
    onPresentationChange(presentationOption.key)
    setFieldValue(
      'options',
      updatePresentationOption({
        options,
        presentationOption
      })
    )
  }

  function handleDeleteItem(presentationOption: PresentationOption) {
    setFieldValue(
      'options',
      deletePresentationOption({
        presentationOption,
        options
      })
    )
  }

  function handleDeleteBulkItems(presentationOptions: PresentationOption[]) {
    setFieldValue(
      'options',
      deletePresentationOptions({
        presentationOptions,
        options
      })
    )
  }

  function handleReorderItems(presentationOptions: PresentationOption[], quoteType: string) {
    setFieldValue(
      'options',
      reorderPresentationOptions({
        presentationOptions,
        options,
        quoteType
      })
    )
  }
}

export default PresentationOptions
