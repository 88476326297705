import { useUserQuery } from '@paintscout/api'
import type { DialogProps } from '@ui/paintscout'
import { Button, Checkbox, FormControlLabel, FormSection, FormSectionTitle, Grid, DropdownSelect } from '@ui/paintscout'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@ui/paintscout'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import type { UserImportExportProps } from '../UserAdministrationForm/UserImportExport'
import type { DropdownSelectOption } from '@paintscout/ui/src/DropdownSelect'

const defaultUserImportOptions = {
  quoteEmails: false,
  thankYouAcceptEmails: false,
  thankYouDeclineEmails: false,
  followUps: false
} as UserImportExportProps

const userOptions: DropdownSelectOption[] = [
  { value: 'auth0|608c7b6c42ec0c00710a1bea', label: 'Onboarding Account - Main User' }
  // NEED TO UPDATE IDS for these other users
  // { value: 'iqxvskejjohghvzh', label: 'Consulting 4 Contractors' },
  // { value: 'oxjbyvmmctvnheho', label: 'PBP TEMPLATE' },
  // { value: 'imgnzzxxwlujqesx', label: 'PCA TEMPLATE' },
  // { value: 'bcqjokvasggitxfx', label: 'SUMMIT TEMPLATE' },
  // { value: 'sqlwadwqemrpelag', label: 'Unforgettable Coatings' }
]

export interface ImportAccountDialogProps extends DialogProps {
  queueDocUpdates: (json: any, selectedOptions?: UserImportExportProps) => void
  onClose?: () => void
}

const ImportUserDialog = ({ onClose, queueDocUpdates, ...props }: ImportAccountDialogProps) => {
  const match = useRouteMatch<{ id: string; tab: string; section: string }>()
  const { id } = match.params
  const { enqueueSnackbar } = useSnackbar()
  const [importOptions, setImportOptions] = useState(defaultUserImportOptions)
  const [selectedUser, setSelectedUser] = useState(id)

  const { data, error } = useUserQuery({
    variables: {
      id: selectedUser
    }
  })
  const details = data?.user

  if (error) {
    enqueueSnackbar('Error retrieving user details', { variant: 'error' })
    return null
  }
  const handleImportCheckboxChange = (target: string) => {
    return function () {
      setImportOptions({ ...importOptions, [target]: !importOptions[target] })
    }
  }

  const handleImport = () => {
    if (details) {
      queueDocUpdates({ user: details }, importOptions)
      enqueueSnackbar('Documents ready to save!', { variant: 'success' })
      onClose()
    } else {
      enqueueSnackbar('Error retrieving account details', { variant: 'error' })
    }
  }

  return (
    <Dialog {...props}>
      <DialogTitle>Import From User</DialogTitle>
      <DialogContent>
        <DropdownSelect
          variant="single"
          label="Template User"
          options={userOptions}
          value={userOptions.find((option) => option.value === selectedUser)}
          onChange={(option: DropdownSelectOption) => setSelectedUser(option.value)}
        />
        <br />
        <br />
        <FormSectionTitle title="Import" />
        <FormSection>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12}>
              <FormControlLabel
                label={'Quote Emails'}
                control={
                  <Checkbox checked={importOptions.quoteEmails} onChange={handleImportCheckboxChange('quoteEmails')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Thank You Accept Email'}
                control={
                  <Checkbox
                    checked={importOptions.thankYouAcceptEmails}
                    onChange={handleImportCheckboxChange('.thankYouAcceptEmails')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Thank You Decline Email'}
                control={
                  <Checkbox
                    checked={importOptions.thankYouDeclineEmails}
                    onChange={handleImportCheckboxChange('thankYouDeclineEmails')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={"Follow Up's"}
                control={
                  <Checkbox checked={importOptions.followUps} onChange={handleImportCheckboxChange('followUps')} />
                }
              />
            </Grid>
          </Grid>
        </FormSection>
      </DialogContent>
      <DialogActions leftButton={<Button onClick={onClose}>Cancel</Button>}>
        <Button onClick={handleImport} disabled={selectedUser === id}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportUserDialog
