/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { LineItemOption } from '../../index'
import { getQuoteType } from '../../quote/util'

export function reorderLineItemOptions(args: {
  options: OptionsDocument
  lineItemOptions: LineItemOption[]
  quoteType: string
}): OptionsDocument {
  const { options, lineItemOptions } = args

  const quoteType = getQuoteType(args)

  let order = [...options.options.lineItems.order]
  const quoteTypeOrder = { ...options.options.lineItems.quoteTypeOrder }

  if (quoteType && quoteType !== 'all') {
    quoteTypeOrder[quoteType] = lineItemOptions.map((item: LineItemOption) => {
      return item.name
    })
  } else {
    order = lineItemOptions.map((item: LineItemOption) => {
      return item.name
    })
  }

  return {
    ...options,
    options: {
      ...options.options,
      lineItems: {
        ...options.options.lineItems,
        order,
        quoteTypeOrder
      }
    }
  }
}
