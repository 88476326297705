/**
 * @module builder
 */

import type { PresentationOption } from 'paintscout'
import { uuid } from '../../../util'

export function copyPresentationSection(args: {
  pageIndex: number
  sectionIndex: number
  presentation: PresentationOption
}): PresentationOption {
  const { pageIndex, sectionIndex, presentation } = args

  const section = presentation?.pages?.[pageIndex]?.sections?.[sectionIndex]

  if (!section) {
    return null
  }

  const updatedPages = [...presentation.pages]
  const newSection = { ...section, key: uuid() }
  updatedPages[pageIndex].sections.splice(sectionIndex, 0, newSection)

  const updatedPresentation = {
    ...presentation,
    pages: updatedPages
  }

  return updatedPresentation
}
