import type { EventType, EventParams } from 'paintscout'
import { useMutation } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'

import { gql } from 'apollo-boost'

export const ADMIN_INTEGRATIONS = gql`
  mutation ($type: String!, $provider: String, $params: JSON) {
    adminIntegrationsConfig(type: $type, provider: $provider, params: $params) {
      provider
      result
      error
    }
  }
`

export interface AdminIntegrationsConfigResponse {
  adminIntegrationsConfig: {
    provider: string
    error: string
    result: any
  }
}

export function useAdminIntegrationsConfig() {
  const [adminIntegrationsConfigMutation] = useMutation<AdminIntegrationsConfigResponse>(ADMIN_INTEGRATIONS, {
    fetchPolicy: 'no-cache'
  })

  return {
    adminIntegrationsConfig: async ({
      type,
      provider,
      params
    }: {
      type: EventType
      provider: string
      params: EventParams
    }): Promise<AdminIntegrationsConfigResponse['adminIntegrationsConfig']> => {
      const { data, errors } = await adminIntegrationsConfigMutation({
        variables: {
          type,
          provider,
          params
        }
      })

      if (errors) {
        console.log(errors)
        Sentry.captureException(new Error('processEvent returned an error'))
      }

      const errorMessage =
        typeof data?.adminIntegrationsConfig?.error === 'string'
          ? data?.adminIntegrationsConfig?.error
          : (data?.adminIntegrationsConfig?.error as any)?.message

      return data?.adminIntegrationsConfig
        ? {
            ...data.adminIntegrationsConfig,
            error: errorMessage
          }
        : null
    }
  }
}
