import type { PresentationSection } from 'paintscout'
import type { FormikConfig, FormikErrors } from 'formik'
import { Formik } from 'formik'
import type { FormikPresentationSectionValues } from './'
import React from 'react'

export interface PresentationSectionFormikProps
  extends Omit<FormikConfig<FormikPresentationSectionValues>, 'initialValues'> {
  section?: PresentationSection
}

/**
 * A helper that renders a <Formik> component using PresentationSection
 */
export default function PresentationSectionFormik({ section, children, ...props }: PresentationSectionFormikProps) {
  return (
    <Formik
      initialValues={{ section }}
      validate={(_values) => {
        const errors: FormikErrors<PresentationSection> = {}

        // if (!get(values, 'user.user_metadata.firstName')) {
        //   set(errors, 'user.user_metadata.firstName', 'Required')
        // }
        // if (!get(values, 'user.user_metadata.lastName')) {
        //   set(errors, 'user.user_metadata.lastName', 'Required')
        // }

        return errors
      }}
      {...props}
    >
      {children}
    </Formik>
  )
}
