/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'

import cloneDeep from 'lodash/cloneDeep'
import { uuid } from '../../util'

export function copyProductOption(args: { options: OptionsDocument; key: string }): OptionsDocument {
  const { options, key } = args

  const newItem = cloneDeep(options.options.products.values[key])
  newItem.key = uuid()
  newItem.label = `${newItem.label} (copy)`

  const order = options.options.products.order
  const orderIndex = order.indexOf(key)
  if (orderIndex !== -1) {
    order.splice(orderIndex + 1, 0, newItem.key)
  } else {
    order.push(newItem.key)
  }

  return {
    ...options,
    options: {
      ...options.options,
      products: {
        ...options.options.products,
        order,
        values: {
          ...options.options.products.values,
          [newItem.key]: newItem
        }
      }
    }
  }
}
