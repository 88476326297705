/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { RateSectionOption } from '../../index'

export function updateRateSection(args: { rateSection: RateSectionOption; options: OptionsDocument }): OptionsDocument {
  const { options, rateSection } = args

  const { quoteTypes } = rateSection

  const order = [...options.options.rateSections.order]
  const orderIndex = order.indexOf(rateSection.key)
  if (orderIndex === -1) {
    order.push(rateSection.key)
  }

  const quoteTypeOrder = { ...options.options.rateSections.quoteTypeOrder }
  quoteTypes.forEach((quoteType: string) => {
    if (quoteTypeOrder[quoteType]) {
      const quoteTypeOrderIndex = quoteTypeOrder[quoteType].indexOf(rateSection.key)
      if (quoteTypeOrderIndex === -1) {
        quoteTypeOrder[quoteType].push(rateSection.key)
      }
    }
  })

  return {
    ...options,
    options: {
      ...options.options,
      rateSections: {
        ...options.options.rateSections,
        values: {
          ...options.options.rateSections.values,
          [rateSection.key]: {
            active: true,
            ...options.options.rateSections.values[rateSection.key],
            ...rateSection
          }
        },
        order,
        quoteTypeOrder
      }
    }
  }
}
