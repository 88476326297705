/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { ProductOption } from '../../index'

export function updateProductOption(args: { productOption: ProductOption; options: OptionsDocument }): OptionsDocument {
  const { options, productOption } = args

  const { quoteTypes } = productOption

  const order = [...options.options.products.order]
  const orderIndex = order.indexOf(productOption.key)
  if (orderIndex === -1) {
    order.push(productOption.key)
  }

  const quoteTypeOrder = { ...options.options.products.quoteTypeOrder }
  quoteTypes.forEach((quoteType: string) => {
    if (quoteTypeOrder[quoteType]) {
      const quoteTypeOrderIndex = quoteTypeOrder[quoteType].indexOf(productOption.key)
      if (quoteTypeOrderIndex === -1) {
        quoteTypeOrder[quoteType].push(productOption.key)
      }
    }
  })

  const convertedProduct: ProductOption = {
    key: productOption.key,
    label: productOption.label,
    coverage_rate:
      typeof productOption.coverage_rate === 'string'
        ? parseFloat(productOption.coverage_rate)
        : productOption.coverage_rate,
    lnft_coverage:
      typeof productOption.lnft_coverage === 'string'
        ? parseFloat(productOption.lnft_coverage)
        : productOption.lnft_coverage,
    quantity_coverage:
      typeof productOption.quantity_coverage === 'string'
        ? parseFloat(productOption.quantity_coverage)
        : productOption.quantity_coverage,
    price: typeof productOption.price === 'string' ? parseFloat(productOption.price) : productOption.price,
    quoteTypes: productOption.quoteTypes ? productOption.quoteTypes : [],
    active: productOption.active,
    unitLabel: productOption.unitLabel,
    rateTypes: productOption.rateTypes ? productOption.rateTypes : []
  }

  return {
    ...options,
    options: {
      ...options.options,
      products: {
        ...options.options.products,
        values: {
          ...options.options.products.values,
          [productOption.key]: {
            active: true,
            ...options.options.products.values[productOption.key],
            ...convertedProduct
          }
        },
        order,
        quoteTypeOrder
      }
    }
  }
}
