/**
 * @module builder
 */
import type { RatesDocument } from 'paintscout'
import type { RateSectionOption } from '../../index'
import cloneDeep from 'lodash/cloneDeep'

export function deleteRatesBySection(args: { rates: RatesDocument; rateSection: RateSectionOption }): RatesDocument {
  const { rates, rateSection } = args
  const ratesClone: RatesDocument = cloneDeep(rates)

  const cleanRates = Object.keys(ratesClone.rates).reduce((acc, key) => {
    const rate = ratesClone.rates[key]
    if (rate.section !== rateSection.key) {
      acc[key] = rate
    }
    return acc
  }, {} as RatesDocument['rates'])
  ratesClone.rates = cleanRates

  return ratesClone
}
