/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { TermsOption } from '../../index'

export function updateTermsOption(args: { termsOption: TermsOption; options: OptionsDocument }): OptionsDocument {
  const { options, termsOption } = args

  const order = [...options.options.terms.order]
  const orderIndex = order.indexOf(termsOption.name)
  if (orderIndex === -1) {
    order.push(termsOption.name)
  }

  return {
    ...options,
    options: {
      ...options.options,
      terms: {
        ...options.options.terms,
        values: {
          ...options.options.terms.values,
          [termsOption.name]: {
            active: true,
            ...options.options.terms.values[termsOption.name],
            ...termsOption
          }
        },
        order
      }
    }
  }
}
