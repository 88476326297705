import React, { useState, useCallback, useMemo, useRef } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles, FormControlLabel } from '@material-ui/core'
import { InputField, Editor, FormSection, Checkbox, useClientOptions } from '@ui/paintscout'
import type { StyleClasses } from '@ui/paintscout'
import type { LineItemOption, UpdateableProduct } from '@paintscout/util/builder'
import { getObjectLabels } from '@paintscout/util/builder'
import MaterialsTable from '../../dialogs/EditAreaDialog/MaterialsTable'
import { makeHtml } from '@paintscout/util/util'
import { calculateLineItemOption } from './util'
import LineItemOptionPricing from './LineItemOptionPricing'

export interface LineItemOptionFormProps {
  classes?: StyleClasses<typeof useStyles>
  item: LineItemOption
  showSections: {
    showNotes: boolean
    showPricing: boolean
    showMaterials: boolean
  }
  onChange: (lineItem: LineItemOption) => void
  onConfirm: (options: LineItemOption) => void
}

const useStyles = makeStyles<Theme, LineItemOptionFormProps>(
  (theme: Theme) => ({
    root: {},
    noteSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    nameSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 0
    },
    nameInput: {
      '& input': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    descriptionSection: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 0
    },
    priceSection: {
      position: 'relative',
      marginBottom: theme.spacing(2)
    },
    contentInner: {}
  }),
  { name: 'LineItemOptionForm' }
)

export function LineItemOptionForm(props: LineItemOptionFormProps) {
  const { item, showSections, onConfirm, onChange } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const initialItem: LineItemOption = {
    price: 0,
    hours: '',
    calculate: false,
    customHourlyRate: false,
    hourlyRate: 0,
    ...item
  }
  const descriptionRef = useRef(null)
  const crewNoteRef = useRef(null)
  const clientNoteRef = useRef(null)
  const nameRef = useRef(null)

  const [currentItem, setCurrentItem] = useState(initialItem)

  const initialCrewNote = makeHtml(item.crewNote, { replaceBreaks: true })
  const initialClientNote = makeHtml(item.clientNote, { replaceBreaks: true })

  const handleCheckboxChange = useCallback(
    (event: any, value: any) => {
      const { name } = event.target

      const updatedItem = {
        ...item,
        [name]: value
      }

      if (name === 'customHourlyRate') {
        updatedItem.hourlyRate = null
      }

      const calculatedItem = calculateLineItemOption({ item: updatedItem, options })

      setCurrentItem(calculatedItem)
      onChange(calculatedItem)
    },
    [item, props]
  )

  const handleUpdateMaterials = useCallback(
    (materials: UpdateableProduct[]) => {
      setCurrentItem((item) => {
        const updatedItem = {
          ...item,
          materials
        }

        const calculatedItem = calculateLineItemOption({ item: updatedItem, options })

        onChange(calculatedItem)

        return calculatedItem
      })
    },
    [setCurrentItem, options, onChange, calculateLineItemOption]
  )

  const MemoizedLineItemPricing = useMemo(
    () => LineItemOptionPricing({ ...props, item: currentItem, setItem: setCurrentItem }),
    [item, currentItem, props, setCurrentItem]
  )

  function onComplete() {
    onConfirm(getUpdatedLineItem())
  }

  function getUpdatedLineItem(): LineItemOption {
    const name = nameRef.current?.value ?? currentItem.name
    const description = descriptionRef.current?.getHTML() ?? currentItem.description
    const crewNote = crewNoteRef.current?.getHTML() ?? currentItem.crewNote
    const clientNote = clientNoteRef.current?.getHTML() ?? currentItem.clientNote

    const lineItemOption: LineItemOption = {
      ...currentItem,
      label: name,
      description,
      crewNote,
      clientNote
    }
    return lineItemOption
  }

  return (
    <form
      id="line-item-form"
      className={classes.contentInner}
      onSubmit={(e) => {
        e.preventDefault()
        onComplete()
      }}
      onKeyDown={(keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13 && !(keyEvent.target as any).editor) {
          onComplete()
        }
      }}
    >
      <div className={classes.root}>
        <FormSection classes={{ root: classes.nameSection }} hideDivider>
          <InputField
            fullWidth={true}
            label={'Name'}
            defaultValue={item.label}
            name={'label'}
            autoFocus
            classes={{ root: classes.nameInput }}
            inputRef={nameRef}
          />
        </FormSection>
        <FormSection classes={{ root: classes.descriptionSection }} hideDivider>
          <Editor label={'Description'} content={item.description} ref={descriptionRef} fullWidth={true} />
          <FormControlLabel
            control={
              <Checkbox checked={item.hideOnWorkOrder} onChange={handleCheckboxChange} name={'hideOnWorkOrder'} />
            }
            label={`Hidden on ${objectLabels.workOrder.value}`}
          />
        </FormSection>
        <div className={'showPricing'}>
          {showSections.showPricing && (
            <FormSection
              classes={{ root: classes.priceSection }}
              style={{ paddingBottom: showSections.showMaterials ? 16 : 0 }}
            >
              {MemoizedLineItemPricing}
            </FormSection>
          )}
        </div>

        {showSections.showNotes && (
          <div className={'showNotes'}>
            <FormSection classes={{ root: classes.noteSection }}>
              <Editor label={'Crew Note'} fullWidth={true} content={initialCrewNote} ref={crewNoteRef} />
            </FormSection>
            <FormSection classes={{ root: classes.noteSection }} hideDivider>
              <Editor label={'Client Note'} fullWidth={true} content={initialClientNote} ref={clientNoteRef} />
            </FormSection>
          </div>
        )}

        <div className={'showMaterials'}>
          {showSections.showMaterials && (
            <MaterialsTable
              allowCustomProduct={false}
              materials={item.materials}
              onChange={handleUpdateMaterials}
              options={options}
              quoteTypes={item.quoteTypes}
            />
          )}
        </div>
      </div>
    </form>
  )
}
