import { getObjectDiff } from '../get-object-diff/get-object-diff'
/**
 * Recursively compares two objects and returns an array of all properties that are different between the objects.
 * @returns Array of keys that differ between obj1 and obj2
 */
export const getDeepObjectDiff = <T extends { [key: string]: any }>(obj1: T, obj2: T): Array<string> => {
  if (!obj1 || !obj2) {
    return []
  }

  const keys = getObjectDiff(obj1, obj2)

  return keys.reduce((result: Array<string>, key: string) => {
    const val1 = obj1[key]
    const val2 = obj2[key]
    const isObject = (val: any) => typeof val === 'object' && val !== null && !Array.isArray(val)

    if (isObject(val1) && isObject(val2)) {
      const subDiff = getDeepObjectDiff(val1, val2)
      const subDiffKeys = subDiff.map((subKey) => `${key}.${subKey}`)
      result.push(...subDiffKeys)
    } else {
      result.push(key as string)
    }

    return result
  }, [])
}
