/**
 * @module builder
 */
import type { RateSectionOption } from '../index'
import { uuid } from '../../util'

export function createRateSection(): RateSectionOption {
  return {
    key: uuid(),
    label: '',
    quoteTypes: ['all'],
    active: true
  }
}
