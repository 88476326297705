/**
 * @module builder
 */
import type { OptionsDocument, RatesDocument } from 'paintscout'
import cloneDeep from 'lodash/cloneDeep'
import { uuid } from '../../util'

export function copyRateSection(args: {
  options: OptionsDocument
  rates: RatesDocument
  key: string
}): {
  rates: RatesDocument
  options: OptionsDocument
} {
  const { options, rates, key } = args

  const originalRateSection = options.options.rateSections.values[key]

  const newRateSection = cloneDeep(originalRateSection)
  newRateSection.key = uuid()
  newRateSection.label = `${newRateSection.label} (copy)`

  const rateSectionOrder = options.options.rateSections.order
  const orderIndex = rateSectionOrder.indexOf(key)
  if (orderIndex !== -1) {
    rateSectionOrder.splice(orderIndex + 1, 0, newRateSection.key)
  } else {
    rateSectionOrder.push(newRateSection.key)
  }

  const newRates = Object.keys(rates.rates).reduce((acc, rateKey) => {
    if (rates.rates[rateKey].section !== originalRateSection.key) {
      return acc
    }

    const newRate = cloneDeep(rates.rates[rateKey])
    newRate.name = uuid()
    newRate.section = newRateSection.key
    acc[newRate.name] = newRate
    return acc
  }, {})

  return {
    rates: {
      ...rates,
      rates: {
        ...rates.rates,
        ...newRates
      }
    },
    options: {
      ...options,
      options: {
        ...options.options,
        rateSections: {
          ...options.options.rateSections,
          order: rateSectionOrder,
          values: {
            ...options.options.rateSections.values,
            [newRateSection.key]: newRateSection
          }
        }
      }
    }
  }
}
