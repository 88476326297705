/**
 * @module builder
 */

import { uuid } from '../../util'
import type { EditableRate } from '../index'
export function createRate(args: { quoteType?: string; custom?: boolean; rateSection?: string } = {}): EditableRate {
  const { quoteType, custom, rateSection = 'custom' } = args
  return {
    quoteType,
    name: custom ? 'custom' : uuid(),
    custom: !!custom,
    label: '',
    clientLabel: '',
    rateType: 'sqftWalls',
    calculationType: 'amountPerHour',
    rateSection,
    defaultApplications: 2,
    possibleApplications: 3,
    reportText: '<p></p>',
    areaReportText: '<p></p>',
    values: [0, 0, 0, 0],
    caption: '',
    useCoats: true,
    active: true
  }
}
