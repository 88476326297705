import React, { useState } from 'react'
import type { WithStyles, Theme } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import ResponsivePopover from '../ResponsivePopover'
import FilterMenuButton from './FilterMenuButton'
import FilterMenuFooter from './FilterMenuFooter'

export interface FilterMenuProps {
  className?: string
  filterContent: React.ReactNode
  filtersActive?: boolean
  filterLabels?: string[]
  showExport?: boolean
  isExporting?: boolean

  onChange?: (id: string, value: any) => any
  onSearch?: () => any
  onClear?: () => any
  onExport?: () => any
}

const styles = (theme: Theme) =>
  createStyles({
    popover: {
      marginTop: 60,
      maxWidth: 725,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    },
    filterButtons: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    filterButton: {
      margin: '0 10px'
    },
    exportButton: {
      position: 'relative'
    },
    exportSpinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })

type FilterMenuPropsWithStyles = FilterMenuProps & WithStyles<typeof styles>

function FilterMenu({
  classes,
  filtersActive,
  filterContent,

  showExport,
  isExporting,
  onExport,
  onSearch,
  onClear
}: FilterMenuPropsWithStyles) {
  const [anchorEl, setAnchorEl] = useState(null)
  return (
    <>
      <FilterMenuButton onClick={(ev) => setAnchorEl(ev.currentTarget)} iconActive={filtersActive} />
      <ResponsivePopover
        classes={{
          popover: classes.popover
        }}
        open={!!anchorEl}
        PopoverProps={{
          anchorEl
        }}
        modalBreakpoint="sm"
        onClose={() => setAnchorEl(null)}
        footer={
          <FilterMenuFooter
            padButtons
            onSearch={() => {
              onSearch()
              setAnchorEl(null)
            }}
            onClear={onClear}
            onExport={onExport}
            showExport={showExport}
            isExporting={isExporting}
          />
        }
      >
        {filterContent}
      </ResponsivePopover>
    </>
  )
}
export default withStyles(styles)(FilterMenu)
