import { ConfirmationDialog, NewFormSection as FormSection, useDialogs } from '@ui/paintscout'
import type { ValueType } from '@ui/paintscout/src/TileList'
import type { ContactLeadSource } from '@paintscout/util/builder'
import {
  copyLeadSource,
  createLeadSource,
  deleteLeadSource,
  deleteLeadSources,
  getLeadSources,
  reorderLeadSources
} from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useEffect, useState } from 'react'
import { OptionsTileList } from '../..'
import SettingsPage from '@ui/paintscout/src/SettingsPage'
import { EditLeadSourceDialog } from '../../dialogs'

export interface ContactSourcesProps {
  onDialogConfirm?: () => void
}

function ContactSources({ onDialogConfirm, ..._props }: ContactSourcesProps) {
  const { openDialog, dismissDialog } = useDialogs()
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const [selected, setSelected] = React.useState<ValueType[]>([])
  const { options } = values
  const [items, setItems] = useState<ContactLeadSource[]>(getLeadSources({ options, type: 'contactSources' }))

  useEffect(() => {
    setItems(getLeadSources({ options, type: 'contactSources' }))
  }, [options])

  function handleCreateItem(_: React.MouseEvent) {
    const item = createLeadSource<'contactSources'>()
    openDialog(EditLeadSourceDialog, {
      options,
      type: 'contactSources',
      item,
      isNew: true,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        setFieldValue('options', updatedOptions)
        dismissDialog()
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>This item will no longer be available to add to new contacts.</p>
          <p>Existing contacts will not be affected.</p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
        const updatedOptions = deleteLeadSource({
          source: items.find((item) => item.name === key),
          type: 'contactSources',
          options
        })
        setFieldValue('options', updatedOptions)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These items will no longer be available to add to new contacts.</p>
          <p>Existing contacts will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
        const selectedSources = items.filter((i) => keys.includes(i.name))
        const updatedOptions = deleteLeadSources({
          sources: selectedSources,
          type: 'contactSources',
          options
        })

        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const item = items.find((item) => item.name === key)

    openDialog(EditLeadSourceDialog, {
      options,
      type: 'contactSources',
      item,
      isNew: false,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        if (onDialogConfirm && isDirty) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleReorder(contactSources: ContactLeadSource[]) {
    const updatedOptions = reorderLeadSources({
      sources: contactSources,
      type: 'contactSources',
      options
    })
    setFieldValue('options', updatedOptions)
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyLeadSource({ options, type: 'contactSources', key })
    setFieldValue('options', updatedOptions)
  }

  return (
    <SettingsPage
      title={'Contact Sources'}
      callout={{
        path: 'settings-sources',
        content: 'Add your list of lead sources so you can keep track of how your customers heard about your services.',
        learnMoreLink: 'http://help.paintscout.com/en/articles/6063119-adding-contact-sources'
      }}
    >
      <Form>
        <FormSection topPadded>
          <OptionsTileList
            title={''}
            items={items.map((item) => ({ ...item, key: item.name }))}
            createTitle={'Add New Contact Source'}
            showCreate={true}
            selected={selected}
            setSelected={setSelected}
            onDeleteBulkItems={handleDeleteBulkItems}
            onReorder={handleReorder}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            onCreateClick={handleCreateItem}
            onCopyItem={handleCopyItem}
            noItemTitle={'No Contact Sources'}
            noItemHelpText={'Organize where your contacts are coming from.'}
          />
        </FormSection>
      </Form>
    </SettingsPage>
  )
}

export default ContactSources
