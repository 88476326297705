import cloneDeep from 'lodash/cloneDeep'
import type { OptionsDocument } from 'paintscout'
import { uuid } from '../../util'
import type { LeadSourceType } from '..'

/**
 * Copy a contact or quote lead source based on type
 * @returns updated options
 */
export function copyLeadSource(args: { options: OptionsDocument; type: LeadSourceType; key: string }): OptionsDocument {
  const { options, key, type } = args
  const sources = options.options[type]

  const newItem = cloneDeep(sources.values[key])
  newItem.key = uuid()
  newItem.label = `${newItem.label} (copy)`

  const order = sources.order
  const orderIndex = order.indexOf(key)
  if (orderIndex !== -1) {
    order.splice(orderIndex + 1, 0, newItem.key)
  } else {
    order.push(newItem.key)
  }

  return {
    ...options,
    options: {
      ...options.options,
      [type]: {
        ...sources,
        order,
        values: {
          ...sources.values,
          [newItem.key]: newItem
        }
      }
    }
  }
}
