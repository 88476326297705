import { NewFormSection as FormSection, ConfirmationDialog, useDialogs } from '@ui/paintscout'
import {
  createQuoteTypeOption,
  deleteQuoteTypeOption,
  copyQuoteTypeOption,
  getObjectLabels,
  getQuoteTypeOptions,
  reorderQuoteTypeOptions,
  deleteQuoteTypeOptions
} from '@paintscout/util/builder'
import type { QuoteTypeOption } from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useState } from 'react'
import EditQuoteTypeOptionDialog from '../../dialogs/EditQuoteTypeOptionDialog'
import OptionsTileList from '../../OptionsTileList'
import type { ValueType } from '@ui/paintscout/src/TileList'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import SettingsPage from '@ui/paintscout/src/SettingsPage'

export interface QuoteTypeOptionsProps {
  classes?: StyleClasses<typeof useStyles>
  onDialogConfirm?: () => void
}
const useStyles = makeStyles<Theme, QuoteTypeOptionsProps>((_theme) => ({
  root: {}
}))

function QuoteTypeOptions({ onDialogConfirm }: QuoteTypeOptionsProps) {
  const { openDialog, dismissDialog } = useDialogs()
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const [selected, setSelected] = useState<ValueType[]>([])
  const classes = useStyles({})

  const { options } = values

  const items = getQuoteTypeOptions({ options, inactive: true })
  const objectLabels = getObjectLabels({ options })

  function handleReorder(quoteTypeOptions: QuoteTypeOption[]) {
    const updatedOptions = reorderQuoteTypeOptions({ quoteTypeOptions, options: options })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getQuoteTypeOptions({ options, inactive: true })
    const item = items.find((i) => i.key === key)

    openDialog(EditQuoteTypeOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        if (onDialogConfirm && isDirty) {
          setFieldValue('options', updatedOptions)
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  async function handleCreateItem(_event: React.MouseEvent) {
    const item = createQuoteTypeOption()

    openDialog(EditQuoteTypeOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        setFieldValue('options', updatedOptions)
        dismissDialog()

        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyQuoteTypeOption({ options, key })
    setFieldValue('options', updatedOptions)
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>
            This {objectLabels.quote.value} Type will no longer be available to add to new {objectLabels.quote.plural}.
          </p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getQuoteTypeOptions({ options: options, inactive: true })
        const updatedOptions = deleteQuoteTypeOption({ quoteTypeOption: items.find((i) => i.key === key), options })

        setFieldValue('options', updatedOptions)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>
            These {objectLabels.quote.value} Types will no longer be available to add to new {objectLabels.quote.plural}
            .
          </p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getQuoteTypeOptions({ options: options, inactive: true })
        const updatedOptions = deleteQuoteTypeOptions({
          quoteTypeOptions: items.filter((item) => keys.includes(item.key)),
          options
        })

        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  return (
    <SettingsPage
      title={`${objectLabels.quote.value} Types`}
      callout={{
        path: 'settings-quote-types',
        content: `${
          objectLabels.quote.value
        } types are used to categorize the different services your company offers. Assigning different rates, products, terms, and other items to each ${objectLabels.quote.value.toLowerCase()} type helps you organize your account and easily filter for search and reporting purposes.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/3206757-what-are-estimate-types'
      }}
    >
      <Form className={classes.root}>
        <FormSection topPadded>
          <OptionsTileList
            title={''}
            permanent={['all']}
            items={items}
            createTitle={`Add New ${objectLabels.quote.value} Type`}
            showCreate={true}
            selected={selected}
            setSelected={setSelected}
            onDeleteBulkItems={handleDeleteBulkItems}
            onReorder={handleReorder}
            onEditItem={handleEditItem}
            onCopyItem={handleCopyItem}
            onDeleteItem={handleDeleteItem}
            onCreateClick={handleCreateItem}
            noItemTitle={`No ${objectLabels.quote.value} Types`}
            noItemHelpText={`Orgnanize projects by specific ${objectLabels.quote.value.toLowerCase()} types.`}
          />
        </FormSection>
      </Form>
    </SettingsPage>
  )
}
export default QuoteTypeOptions
