/**
 * @module activity
 */

import isEqual from 'lodash/isEqual'

/**
 * Returns an array of properties that are different between obj1 and obj2. obj1 will be referred to as the "base",
 * meaning any properties in obj2 and that are not in obj1 will be in the result.
 * Exclude omits any listed keys from the comparison for the full depth of the objects.
 *
 * @param obj1
 * @param obj2
 */
export const getObjectDiff = <T extends { [key: string]: any }>(
  obj1: T,
  obj2: T,
  exclude?: string[]
): Array<keyof T> => {
  if (!obj1 || !obj2) {
    return []
  }

  const removeKeys = (obj: any, keys: string[]) =>
    obj !== Object(obj)
      ? obj
      : Array.isArray(obj)
      ? obj.map((item) => removeKeys(item, keys))
      : Object.keys(obj)
          .filter((k) => !keys.includes(k))
          .reduce((acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }), {})

  const object1 = exclude?.length ? removeKeys(obj1, exclude) : obj1
  const object2 = exclude?.length ? removeKeys(obj2, exclude) : obj2

  return Object.keys(object2).reduce((result, key) => {
    // eslint-disable-next-line
    if (!object1.hasOwnProperty(key)) {
      result.push(key)
    } else if (isEqual(object1[key], object2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(object1))
}
