import { useApolloClient } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import type { ProductOption } from '@paintscout/util/builder'
import {
  copyProductOption,
  createProductOption,
  deleteProductOption,
  deleteProductOptions,
  getObjectLabels,
  getProductOptions,
  reorderProductOptions
} from '@paintscout/util/builder'
import * as Sentry from '@sentry/core'
import {
  AlertDialog,
  ConfirmationDialog,
  NewFormSection as FormSection,
  Grid,
  Spinner,
  Typography,
  useClientOptions,
  useDialogs,
  useUser
} from '@ui/paintscout'
import SettingsPage from '@ui/paintscout/src/SettingsPage'
import type { ValueType } from '@ui/paintscout/src/TileList'
import { gql } from 'apollo-boost'
import FileSaver from 'file-saver'
import { useFormikContext } from 'formik'
import kebabCase from 'lodash/kebabCase'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import type { ClientMetaDocument, OptionsDocument, RatesDocument } from 'paintscout'
import React, { useState } from 'react'
import OptionsTileList from '../../OptionsTileList'
import BulkTypeAssignDialog from '../../dialogs/BulkTypeAssignDialog'
import EditBulkProductOptionDialog from '../../dialogs/EditBulkProductOptionDialog'
import EditProductOptionDialog from '../../dialogs/EditProductOptionDialog'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(5)}px ${theme.spacing()}px 0`
  },
  title: {}
}))

export interface ProductOptionsProps {
  onDialogConfirm?: () => void
}

export interface ProductOptionsState {
  quoteType: string
}

function ProductOptions({ onDialogConfirm }: ProductOptionsProps) {
  const classes = useStyles({})
  const { openDialog, dismissDialog, dismissAllDialogs } = useDialogs()
  const { isAdmin } = useClientOptions()
  const { isSuperadmin } = useUser()
  const apolloClient = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const [quoteType, setQuoteType] = useState('all')
  const [selected, setSelected] = useState<ValueType[]>([])
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
    meta?: ClientMetaDocument // Only in admin
  }>()

  const { options, meta } = values

  const items = getProductOptions({ options, quoteType, inactive: true })
  const objectLabels = getObjectLabels({ options })

  return (
    <SettingsPage
      title={'Products'}
      callout={{
        path: 'settings-products',
        content: 'Add your list of products. Each product can have a coverage rate and a price per gallon.',
        learnMoreLink: 'http://help.paintscout.com/en/articles/3207004-adding-products'
      }}
    >
      <div className={classes.root}>
        <FormSection
          hidePadding
          selectHeader
          selectValue={quoteType}
          changeSelect={setQuoteType}
          buttonAction={handleExport}
        >
          <OptionsTileList
            title={''}
            items={items}
            createTitle={'Add New Product'}
            showCreate={true}
            selected={selected}
            setSelected={setSelected}
            onAssignBulkItems={isAdmin || isSuperadmin ? handleAssignBulkItems : null}
            onEditBulkItems={handleEditBulkItems}
            onDeleteBulkItems={handleDeleteBulkItems}
            onReorder={handleReorder}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            onCreateClick={handleCreateItem}
            onCopyItem={handleCopyItem}
            noItemTitle={`There are no products.`}
            noItemHelpText={`Add product to make your ${objectLabels.quote.value.toLowerCase()} process easier & faster.`}
          />
        </FormSection>
      </div>
    </SettingsPage>
  )

  async function handleExport() {
    openDialog(AlertDialog, {
      title: `Export Products`,
      actions: [
        {
          label: 'Cancel',
          onClick: dismissDialog,
          left: true,
          variant: 'text'
        }
      ],
      message: (
        <Grid container spacing={2} alignItems={'center'} direction={'column'}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom={true}>
              Please wait while we export your Products.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Spinner />
          </Grid>
        </Grid>
      )
    })

    const dateFormat = options.options.dateFormat.momentValue
    const companyName = options.options?.companyName?.value ?? ''
    const fileName = `${kebabCase(companyName as string)}-products-${moment(Date.now()).format(dateFormat)}.csv`

    try {
      const { data } = await apolloClient.query({
        query: gql`
          query exportProducts($id: String) {
            exportProducts(id: $id) {
              csv
            }
          }
        `,
        variables: {
          id: meta?._id ?? null
        },
        fetchPolicy: 'no-cache'
      })
      if (data.exportProducts.csv) {
        FileSaver.saveAs(new Blob([data.exportProducts.csv]), fileName)
      }
      dismissAllDialogs()
    } catch (error) {
      dismissAllDialogs()
      enqueueSnackbar('Error exporting products', { variant: 'error' })
      Sentry.captureException(error)
    }
  }

  function handleReorder(productOptions: ProductOption[]) {
    const updatedOptions = reorderProductOptions({
      productOptions,
      options,
      quoteType
    })
    setFieldValue('options', updatedOptions)
  }

  function handleEditBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(EditBulkProductOptionDialog, {
      keys,
      options,
      onConfirm: (updatedOptions: OptionsDocument) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => dismissDialog()
    })
  }

  function handleAssignBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(BulkTypeAssignDialog, {
      keys,
      field: 'products',
      options,
      onConfirm: (updatedOptions: OptionsDocument) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => dismissDialog()
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These products will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const updatedOptions = deleteProductOptions({
          productOptionKeys: keys.map((key) => `${key}`),
          options
        })

        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getProductOptions({ options, quoteType, inactive: true })
    const item = items.find((item) => item.key === key)

    openDialog(EditProductOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        if (onDialogConfirm && isDirty) {
          setFieldValue('options', updatedOptions)
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCreateItem(_event: React.MouseEvent) {
    const item = createProductOption({ quoteTypes: [quoteType] })

    openDialog(EditProductOptionDialog, {
      options,
      item,
      isNew: true,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>This product will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const updatedOptions = deleteProductOption({ key, options })

        setFieldValue('options', updatedOptions)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyProductOption({ options, key })
    setFieldValue('options', updatedOptions)
  }
}

export default ProductOptions
