import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '../theme'
import { Divider as MuiDivider } from '@material-ui/core'

const useStyles = makeStyles<Theme, DividerProps>(
  (theme) => ({
    root: {},
    divider: {
      borderBottom: `2px solid ${theme.palette.grey[200]}`,
      margin: theme.spacing(1, 0)
    }
  }),
  { name: 'Divider' }
)

export interface DividerProps {
  classes?: StyleClasses<typeof useStyles>
}

function Divider(props: DividerProps) {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <MuiDivider className={classes.divider} />
    </div>
  )
}

export default Divider
