/**
 * @module builder
 */
import type { RatesDocument } from 'paintscout'
import clone from 'lodash/clone'

export function deleteRate(args: { rates: RatesDocument; key: string }): RatesDocument {
  const { key } = args
  const rates = clone(args.rates)
  delete rates.rates[key]
  return rates
}
