import type { OptionsDocument } from 'paintscout'
import type { QuoteTag } from '..'

export function reorderQuoteTags(args: { options: OptionsDocument; quoteTags: QuoteTag[] }): OptionsDocument {
  const { options, quoteTags } = args
  return {
    ...options,
    options: {
      ...options.options,
      quoteTags: {
        ...options.options.quoteTags,
        order: quoteTags.map((item: QuoteTag) => item.name)
      }
    }
  }
}
