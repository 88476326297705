/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'

import cloneDeep from 'lodash/cloneDeep'
import { uuid } from '../../util'

export function copyLineItemOption(args: { options: OptionsDocument; key: string }): OptionsDocument {
  const { options, key } = args

  const newItem = cloneDeep(options.options.lineItems.values[key])
  newItem.name = uuid()
  newItem.label = `${newItem.label} (copy)`

  const order = options.options.lineItems.order
  const orderIndex = order.indexOf(key)
  if (orderIndex !== -1) {
    order.splice(orderIndex + 1, 0, newItem.name)
  } else {
    order.push(newItem.name)
  }

  return {
    ...options,
    options: {
      ...options.options,
      lineItems: {
        ...options.options.lineItems,
        order,
        values: {
          ...options.options.lineItems.values,
          [newItem.name]: newItem
        }
      }
    }
  }
}
