import type { OptionsDocument } from 'paintscout'
import type { QuoteTag } from '../..'

export function updateQuoteTag(args: { quoteTag: QuoteTag; options: OptionsDocument }): OptionsDocument {
  const { options, quoteTag } = args
  const { quoteTags } = options.options

  const order = quoteTags ? [...quoteTags.order] : [quoteTag.name]
  const orderIndex = order.indexOf(quoteTag.name)
  if (orderIndex === -1) {
    order.push(quoteTag.name)
  }

  return {
    ...options,
    options: {
      ...options.options,
      quoteTags: {
        ...quoteTags,
        values: quoteTags
          ? {
              ...quoteTags.values,
              [quoteTag.name]: {
                ...quoteTags.values[quoteTag.name],
                ...quoteTag
              }
            }
          : {
              [quoteTag.name]: {
                ...quoteTag
              }
            },
        order
      }
    }
  }
}
