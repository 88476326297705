/**
 * @module builder
 */
import type { PresentationOption, OptionsDocument } from 'paintscout'
import { uuid } from '../../../util'
import { getDefaultPresentation } from '../get-default-presentation'

const generalOptions = {
  letterSpacingOptions: ['0', '1px', '2px', '3px'],
  lineHeightOptions: ['1.4', '1.7', '2.0'],
  textTransformOptions: ['none', 'uppercase', 'lowercase'],
  fontWeightOptions: [300, 400, 500, 600]
}

export const presentationTypographyOptions = {
  heading1: {
    ...generalOptions,
    title: 'Heading 1',
    shortTitle: 'h1',
    fontSizeOptions: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem']
  },
  heading2: {
    ...generalOptions,
    title: 'Heading 2',
    shortTitle: 'h2',
    fontSizeOptions: ['1rem', '1.25rem', '1.5rem', '1.75rem', '2rem']
  },
  heading3: {
    ...generalOptions,
    title: 'Heading 3',
    shortTitle: 'h3',
    fontSizeOptions: ['0.9rem', '1rem', '1.1rem', '1.2rem', '1.3rem']
  },
  body: {
    ...generalOptions,
    title: 'Body',
    shortTitle: 'body',
    fontSizeOptions: ['0.9rem', '0.95rem', '1rem', '1.05rem', '1.1rem']
  }
}

export const defaultPresentationTypographyPicks = {
  heading1: {
    fontWeight: 0,
    lineHeight: 1,
    letterSpacing: 0,
    textTransform: 0,
    fontSize: 2
  },
  heading2: {
    fontWeight: 2,
    lineHeight: 1,
    letterSpacing: 0,
    textTransform: 0,
    fontSize: 2
  },
  heading3: {
    fontWeight: 2,
    lineHeight: 1,
    letterSpacing: 1,
    textTransform: 1,
    fontSize: 1
  },
  body: {
    lineHeight: 1,
    letterSpacing: 0,
    textTransform: 0,
    fontSize: 2
  }
}

export function createPresentationOption(args?: { options: OptionsDocument }): PresentationOption {
  const { options } = args
  const newPresentation = { ...getDefaultPresentation({ options }), key: uuid(), label: 'New Presentation' }

  newPresentation.advanced.autoOpenNav = true

  return newPresentation
}
