/**
 * @module builder
 */
import type { RatesDocument } from 'paintscout'
import type { EditableRate } from '../../index'
import differenceBy from 'lodash/differenceBy'
import clone from 'lodash/clone'

export function reorderRates(args: { rates: RatesDocument; rateOptions: EditableRate[] }): RatesDocument {
  const { rateOptions } = args

  const rates = clone(args.rates)

  const keys = rateOptions.map((rateOption) => rateOption.name)
  const otherKeys = differenceBy(Object.keys(rates.rates), keys, 'name')
  const order = [...keys, ...otherKeys]

  order.forEach((key, index) => {
    if (rates.rates[key]) {
      rates.rates[key].order = index
    }
  })

  return rates
}
