/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { ProductOption } from '../../index'
import { getQuoteType } from '../../quote/util'

export function reorderProductOptions(args: {
  options: OptionsDocument
  productOptions: ProductOption[]
  quoteType: string
}): OptionsDocument {
  const { options, productOptions } = args

  const quoteType = getQuoteType(args)

  let order = [...options.options.products.order]
  const quoteTypeOrder = { ...options.options.products.quoteTypeOrder }

  if (quoteType && quoteType !== 'all') {
    quoteTypeOrder[quoteType] = productOptions.map((item: ProductOption) => {
      return item.key
    })
  } else {
    order = productOptions.map((item: ProductOption) => {
      return item.key
    })
  }

  return {
    ...options,
    options: {
      ...options.options,
      products: {
        ...options.options.products,
        order,
        quoteTypeOrder
      }
    }
  }
}
