import IconButton from '@material-ui/core/IconButton'
import type { WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import React from 'react'
import Typography from '../Typography'

const styles = createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  pageLabel: {
    margin: '0 8px'
  }
})

interface TablePaginationProps extends WithStyles<typeof styles> {
  currentPage: number
  pages?: number
  disablePrevPage?: boolean
  disableNextPage?: boolean
  disableFirstPage?: boolean
  disableLastPage?: boolean

  showFirstLastPagination?: boolean

  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, page: number) => any
}

function TablePagination(props: TablePaginationProps) {
  const {
    classes,
    disableFirstPage,
    disableLastPage,
    showFirstLastPagination,
    currentPage,
    pages,
    disablePrevPage,
    disableNextPage,
    onChangePage
  } = props

  function handlePrevButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onChangePage(event, currentPage - 1)
  }

  function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onChangePage(event, currentPage + 1)
  }

  function handleFirstButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onChangePage(event, 1)
  }

  function handleLastButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    onChangePage(event, pages)
  }

  return (
    <div className={classes.root}>
      {showFirstLastPagination && (
        <IconButton disabled={disableFirstPage} aria-label="Previous Page" onClick={handleFirstButtonClick}>
          <FirstPageIcon />
        </IconButton>
      )}
      <IconButton disabled={disablePrevPage} aria-label="Previous Page" onClick={handlePrevButtonClick}>
        <KeyboardArrowLeft />
      </IconButton>
      <Typography className={classes.pageLabel} variant="subtitle2">
        Page {currentPage} {pages ? `of ${pages}` : ''}
      </Typography>
      <IconButton disabled={disableNextPage} aria-label="Next Page" onClick={handleNextButtonClick}>
        <KeyboardArrowRight />
      </IconButton>
      {showFirstLastPagination && (
        <IconButton disabled={disableLastPage} aria-label="Next Page" onClick={handleLastButtonClick}>
          <LastPageIcon />
        </IconButton>
      )}
    </div>
  )
}

export default withStyles(styles)(TablePagination)
