import cloneDeep from 'lodash/cloneDeep'
import type { OptionsDocument } from 'paintscout'
import { uuid } from '../../util'

export function copyQuoteTag(args: { options: OptionsDocument; key: string }): OptionsDocument {
  const { options, key } = args
  const { quoteTags } = options.options

  const newItem = cloneDeep(quoteTags.values[key])
  newItem.name = uuid()
  newItem.label = `${newItem.label} (copy)`

  const order = quoteTags.order
  const orderIndex = order.indexOf(key)
  if (orderIndex !== -1) {
    order.splice(orderIndex + 1, 0, newItem.name)
  } else {
    order.push(newItem.name)
  }

  return {
    ...options,
    options: {
      ...options.options,
      quoteTags: {
        ...quoteTags,
        order,
        values: {
          ...quoteTags.values,
          [newItem.name]: newItem
        }
      }
    }
  }
}
