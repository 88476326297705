import React, { useMemo } from 'react'

import { Card, CardContent, Fab, Grid, Grow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { useSnackbar } from 'notistack'

import { NavHeader } from '../../components/NavDrawer'
import { Field, Formik, Form } from 'formik'
import {
  FormSection,
  FormikCheckbox,
  FormikDatePicker,
  FormikDropdownSelect,
  FormikInputField,
  Collapse,
  EmergencyBanner,
  Button,
  Hidden,
  Flex,
  Spinner,
  useDialogs,
  ConfirmationDialog
} from '@ui/paintscout'
import RoutePrompt from '../../components/RoutePrompt'

import type { EmergencyMessageInput } from '@paintscout/api'
import { EmergencyMessageSeverity, useEmergencyMessageQuery, useSaveEmergencyMessageMutation } from '@paintscout/api'

const useStyles = makeStyles({
  root: {},
  mobileSaveButton: {
    position: 'fixed',
    bottom: 16,
    right: 16
  }
})

const severityOptions = [
  { value: EmergencyMessageSeverity.Low, label: 'Minor impact - Most of the system is functioning properly' },
  { value: EmergencyMessageSeverity.Medium, label: 'Significant Impact - Severe downgrade of services' }
  // { value: 'high', label: 'Critical Impact - System Down. Complete system outage.' }
]

export default function Emergency() {
  const classes = useStyles({})
  const { data, loading } = useEmergencyMessageQuery({
    fetchPolicy: 'cache-and-network',
    onError(e) {
      console.error(e)
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  })

  const [saveEmergencyMessage] = useSaveEmergencyMessageMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissDialog } = useDialogs()

  const initialValues = useMemo<EmergencyMessageInput>(() => {
    return {
      enabled: false,
      message: '',
      severity: EmergencyMessageSeverity.Low,
      ...data?.emergencyMessage
    }
  }, [data])

  if (!data && loading) {
    return <Spinner fullWidth fullHeight />
  }

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          openDialog(ConfirmationDialog, {
            title: 'Are you sure?',
            message: values.enabled
              ? `This will immediately publish an emergency banner to all users`
              : `This will immediately remove the emergency banner for all users`,
            onConfirm: async () => {
              try {
                const { enabled, message, severity, expires = 0 } = values
                const input = enabled
                  ? {
                      enabled,
                      message,
                      severity,
                      expires
                    }
                  : {
                      enabled: false,
                      message: '',
                      severity: EmergencyMessageSeverity.Low
                    }

                await saveEmergencyMessage({
                  variables: { input }
                })

                actions.resetForm({
                  values: input
                })
                dismissDialog()
                enqueueSnackbar('Saved', { variant: 'success' })
              } catch (e) {
                console.error(e)
                enqueueSnackbar(e.message, { variant: 'error' })
              }
            },
            onCancel: dismissDialog
          })
        }}
      >
        {({ values, dirty, isSubmitting }) => (
          <Form>
            <RoutePrompt when={dirty} message={'You have unsaved changes, are you sure?'} />
            <NavHeader title="Emergency Banner" />

            <Hidden mdDown>
              <Flex justify="flex-end" mb={2}>
                <Grow in={dirty}>
                  <Button type="submit" disabled={!dirty || isSubmitting} loading={isSubmitting} icon={SaveIcon}>
                    Save
                  </Button>
                </Grow>
              </Flex>
            </Hidden>
            <Hidden lgUp>
              <div className={classes.mobileSaveButton}>
                <Grow in={dirty}>
                  <Fab disabled={!dirty || isSubmitting} color="primary" type="submit">
                    <SaveIcon />
                  </Fab>
                </Grow>
              </div>
            </Hidden>
            <Card>
              <CardContent>
                <FormSection>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field label="Show Banner" name="enabled" component={FormikCheckbox} />
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse show={values.enabled}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <EmergencyBanner severity={values.severity} message={values.message!} />
                          </Grid>
                          <Grid item xs={9}>
                            <Field
                              label="Severity"
                              name="severity"
                              component={FormikDropdownSelect}
                              options={severityOptions}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Field
                              name="expires"
                              format="MMMM DD, YYYY"
                              component={FormikDatePicker}
                              clearable
                              InputProps={{
                                required: true,
                                fullWidth: true,
                                label: 'Until'
                              }}
                              PickerProps={{
                                disabledDays: (date: Date) => date.getTime() < Date.now()
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="message"
                              label="Message"
                              component={FormikInputField}
                              required
                              multiline
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                </FormSection>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  )
}
// function ConfirmDialog(ConfirmDialog: any, arg1: { title: string }) {
//   throw new Error('Function not implemented.')
// }
