import type { Theme } from '@material-ui/core'
import { Typography, makeStyles } from '@material-ui/core'
import { Form } from 'formik'
import React from 'react'
import FormSectionTitle from '../FormSectionTitle'
import FormSection from '../FormSection'
import { TitleCallout } from '../TitleCallout'
import { LearnMoreLink } from '../PageHelpMessage'
import { useMenu } from '../Menu'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import Button from '../Button'
import Hidden from '../Hidden'

const useStyles = makeStyles<Theme, SettingsPageProps>((theme) => ({
  title: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(8)
    }
  },
  form: {
    padding: 0
  },
  dropdownButton: {
    padding: 2,
    color: theme.palette.common.black,
    position: 'absolute',
    zIndex: 2
  },
  menuIcon: {
    color: '#707070',
    fontSize: '2.3rem',
    alignSelf: 'center'
  }
}))

export interface SettingsPageProps {
  title: string
  subTitle?: string
  learnMoreLink?: string
  callout?: {
    path: string
    content: string | React.ReactNode
    learnMoreLink?: string
  }
  children?: React.ReactNode
  hideMenuButton?: boolean
}

export default function SettingsPage(props: SettingsPageProps) {
  const classes = useStyles(props)
  const { title, subTitle, children, callout, learnMoreLink, hideMenuButton } = props

  const { menuOpen, setMenuOpen } = useMenu()
  return (
    <Form>
      {!hideMenuButton && (
        <Hidden lgUp>
          <Button
            className={classes.dropdownButton}
            variant="text"
            size="large"
            onClick={() => setMenuOpen(!menuOpen)}
            flipIconSide
            aria-haspopup
          >
            <MenuRoundedIcon className={classes.menuIcon} />
          </Button>
        </Hidden>
      )}

      {callout ? (
        <TitleCallout
          path={callout?.path}
          openContent={callout?.content}
          learnMoreLink={callout?.learnMoreLink}
          title={title}
          subTitle={subTitle}
        />
      ) : (
        <>
          <FormSectionTitle
            classes={{ root: classes.title }}
            variant="h2"
            title={title}
            subTitle={
              learnMoreLink ? (
                <Typography variant="subtitle1">
                  {subTitle} <LearnMoreLink link={learnMoreLink} />
                </Typography>
              ) : (
                subTitle
              )
            }
          />
        </>
      )}

      <FormSection classes={{ root: classes.form }} hideDivider>
        {children}
      </FormSection>
    </Form>
  )
}
