import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { EventType, ProviderConfig } from 'paintscout'
import {
  useClientOptions,
  FormSection,
  FormSectionTitle,
  Checkbox,
  Typography,
  EventSubscriptionType,
  EventSubscriptionDetails,
  DropdownSelect
} from '../../'
import { useProcessEvent, useAdminIntegrationsConfig } from '@ui/core'
import type { DropdownSelectOption } from '../../'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'

const useStyles = makeStyles<Theme, ZenDeskProviderFormProps>(
  (theme) => ({
    root: {},
    stageSelection: {
      marginLeft: theme.typography.pxToRem(5),
      marginRight: theme.typography.pxToRem(5)
    },
    optionRow: {
      display: 'flex',
      alignItems: 'center'
    }
  }),
  { name: 'ZenDeskProviderForm' }
)

export interface ZenDeskProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
  companyId?: string
  userId?: string
}

export function ZenDeskProviderForm(props: ZenDeskProviderFormProps) {
  const {
    providerConfig,
    providerName,
    isSettingsIntegration = false,
    isAdminIntegrations = false,
    companyId = '',
    userId = '',
    onChange
  } = props
  const classes = useStyles(props)
  const { enabled, config } = providerConfig
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const { handleEventChange, handleStageSelection, handleUpdateConfigCheckbox } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  const [dealStages, setDealStages] = useState([])
  const [loadingStages, setLoadingStages] = useState(false)

  const { adminIntegrationsConfig } = useAdminIntegrationsConfig()
  const { processEvent } = useProcessEvent()

  useEffect(() => {
    async function getDealStages() {
      if (enabled && processEvent) {
        console.log('process events check', { companyId, userId })
        setLoadingStages(true)
        const res = isAdminIntegrations
          ? await adminIntegrationsConfig({
              provider: providerName,
              type: 'get-config-items',
              params: {
                companyId,
                userId
              }
            })
          : await processEvent({
              provider: providerName,
              type: 'get-config-items',
              params: {}
            })

        setDealStages(res?.result?.dealStages ?? [])
        setLoadingStages(false)
      }
    }
    getDealStages()
  }, [enabled])

  const StageSelection = (props: { name: string }) => {
    const { name } = props
    const dropdownOptions = dealStages as DropdownSelectOption[]
    const value = dropdownOptions.find((option) => option.value === config[name]?.value)

    return (
      <div className={classes.stageSelection}>
        <DropdownSelect
          fullWidth
          name={name}
          disabled={loadingStages}
          variant="single"
          placeholder={{
            selectPlaceholder: 'Select Deal Stage...'
          }}
          value={value}
          options={dropdownOptions}
          onChange={(option) => handleStageSelection(name, option)}
        />
      </div>
    )
  }

  return (
    <div>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle title={'Update Deals'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-sent' as EventType}
              event={providerConfig.events['quote-sent']}
              onChange={handleEventChange}
              label={
                <>
                  When {objectLabels.quote.plural} are <strong>sent:</strong>
                </>
              }
            />
            <EventSubscriptionDetails
              disabled={!providerConfig.events['quote-sent'] || !providerConfig.events['quote-sent'].enabled}
              rows={[
                <>
                  <Checkbox
                    checked={providerConfig.config.sentUpdateValue}
                    name={'sentUpdateValue'}
                    onChange={handleUpdateConfigCheckbox('sentUpdateValue')}
                  />
                  <Typography>Update deal value to {objectLabels.quote.value} total</Typography>
                </>,
                <>
                  <Checkbox
                    checked={providerConfig.config.sentUpdateStage}
                    name={'sentUpdateStage'}
                    onChange={handleUpdateConfigCheckbox('sentUpdateStage')}
                  />
                  <Typography>Update deal stage to:</Typography>
                  <StageSelection name={'sentDealStage'} />
                </>
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-accepted' as EventType}
              event={providerConfig.events['quote-accepted']}
              onChange={handleEventChange}
              label={
                <>
                  When {objectLabels.quote.plural} are <strong>accepted:</strong>
                </>
              }
            />
            <EventSubscriptionDetails
              disabled={!providerConfig.events['quote-accepted'] || !providerConfig.events['quote-accepted'].enabled}
              rows={[
                <>
                  <Checkbox
                    checked={providerConfig.config.acceptedUpdateValue}
                    name={'acceptedUpdateValue'}
                    onChange={handleUpdateConfigCheckbox('acceptedUpdateValue')}
                  />
                  <Typography>Update deal value to {objectLabels.quote.value} total</Typography>
                </>,
                <>
                  <Checkbox
                    checked={providerConfig.config.acceptedUpdateStage}
                    name={'acceptedUpdateStage'}
                    onChange={handleUpdateConfigCheckbox('acceptedUpdateStage')}
                  />
                  <Typography>Update deal stage to:</Typography>
                  <StageSelection name={'acceptedDealStage'} />
                </>
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-declined' as EventType}
              event={providerConfig.events['quote-declined']}
              onChange={handleEventChange}
              label={
                <>
                  When {objectLabels.quote.plural} are <strong>declined:</strong>
                </>
              }
            />
            <EventSubscriptionDetails
              disabled={!providerConfig.events['quote-declined'] || !providerConfig.events['quote-declined'].enabled}
              rows={[
                <>
                  <Checkbox
                    checked={providerConfig.config.declinedUpdateValue}
                    name={'declinedUpdateValue'}
                    onChange={handleUpdateConfigCheckbox('declinedUpdateValue')}
                  />
                  <Typography>Update deal value to {objectLabels.quote.value} total</Typography>
                </>,
                <>
                  <Checkbox
                    checked={providerConfig.config.declinedUpdateStage}
                    name={'declinedUpdateStage'}
                    onChange={handleUpdateConfigCheckbox('declinedUpdateStage')}
                  />
                  <Typography>Update deal stage to:</Typography>
                  <StageSelection name={'declinedDealStage'} />
                </>
              ]}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection hideDivider={true}>
        <FormSectionTitle title={'Create & Edit Quotes from ZenDesk Sell'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'get-new-quote' as EventType}
              selfLabel={'Just Me'}
              allLabel={'All Users'}
              event={providerConfig.events['get-new-quote']}
              onChange={handleEventChange}
              label={`Create new ${objectLabels.quote.plural} from ZenDesk Sell Smart Links`}
            />
          </Grid>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'get-existing-quotes' as EventType}
              selfLabel={'Just Me'}
              allLabel={'All Users'}
              event={providerConfig.events['get-existing-quotes']}
              onChange={handleEventChange}
              label={`Edit existing ${objectLabels.quote.plural} from ZenDesk Sell Smart Links`}
            />
          </Grid>
        </Grid>
      </FormSection>
    </div>
  )
}
