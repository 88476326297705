/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { AreaLabelOption } from '../../index'

export function updateAreaLabelOption(args: {
  areaLabelOption: AreaLabelOption
  options: OptionsDocument
}): OptionsDocument {
  const { options, areaLabelOption } = args

  const { quoteTypes } = areaLabelOption

  const order = [...options.options.areaTemplates.order]
  const orderIndex = order.indexOf(areaLabelOption.name)
  if (orderIndex === -1) {
    order.push(areaLabelOption.name)
  }

  const quoteTypeOrder = { ...options.options.areaTemplates.quoteTypeOrder }
  quoteTypes.forEach((quoteType: string) => {
    if (quoteTypeOrder[quoteType]) {
      const quoteTypeOrderIndex = quoteTypeOrder[quoteType].indexOf(areaLabelOption.name)
      if (quoteTypeOrderIndex === -1) {
        quoteTypeOrder[quoteType].push(areaLabelOption.name)
      }
    }
  })

  return {
    ...options,
    options: {
      ...options.options,
      areaTemplates: {
        ...options.options.areaTemplates,
        values: {
          ...options.options.areaTemplates.values,
          [areaLabelOption.name]: {
            active: true,
            ...options.options.areaTemplates.values[areaLabelOption.name],
            ...areaLabelOption
          }
        },
        order,
        quoteTypeOrder
      }
    }
  }
}
