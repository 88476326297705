import type { HTMLProps } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import type { WithStyles, Theme } from '@material-ui/core'
import { Paper, createStyles, withStyles, Input, Chip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FilterMenu from './FilterMenu'
import classnames from 'classnames'
import Typography from '../Typography'
import FilterMenuButton from './FilterMenuButton'
import Collapse from '../Collapse'
import Grid from '../Grid'
import FilterMenuFooter from './FilterMenuFooter'
import { useIsMobile } from '@ui/core'

export interface SearchBarProps {
  value?: string
  defaultValue?: string
  filtersActive?: boolean
  filterContent?: React.ReactNode
  filterLabels?: Array<{
    title: string
    label: string
  }>
  showExport?: boolean
  isExporting?: boolean
  placeholder?: string
  inputProps?: Partial<HTMLProps<HTMLInputElement>>
  style?: React.CSSProperties
  className?: string
  filterMenuCollapse?: boolean

  disabled?: boolean

  endAdornment?: JSX.Element

  showFilters?: boolean
  setShowFilters?: (show: boolean) => void

  onChange?: (query: string) => any
  onExport?: (query: string) => any
  onSearch?: (query: string) => any
  onClear?: () => any
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    },
    paper: {
      padding: `1em ${theme.spacing(2)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(1)}px`
      }
    },
    filtersActive: {},
    input: {},
    icon: {
      marginRight: theme.spacing(),
      color: theme.palette.grey[400]
    },
    filterLabels: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 10
    },
    filterLabel: {
      display: 'flex',
      flexDirection: 'column',
      margin: '4px 8px'
    },
    filterChip: {
      marginRight: 8,
      marginTop: 4
    },
    invalid: {
      '& $paper': {
        backgroundColor: theme.palette.error.light
      },
      '& $icon': {
        color: theme.palette.error.dark
      },
      '& $input': {
        color: theme.palette.error.dark
      }
    },
    collapse: {
      padding: theme.spacing(3, 4, 2, 4)
    }
  })

type SearchBarPropsWithStyles = SearchBarProps & WithStyles<typeof styles>

function SearchBar({
  classes,
  value,
  defaultValue,
  filterMenuCollapse,
  onChange,
  style,
  filterContent,
  filtersActive,
  className,
  showExport,
  disabled,
  isExporting,
  placeholder,
  filterLabels,
  inputProps,
  endAdornment,
  showFilters: propsShowFilters,
  setShowFilters: propsSetShowFilters,
  onExport,
  onClear,
  onSearch
}: SearchBarPropsWithStyles) {
  const inputRef = useRef(null)
  const [localShowFilters, setlocalShowFilters] = useState(false)

  const showFilters = propsShowFilters ?? localShowFilters
  const setShowFilters = propsSetShowFilters ?? setlocalShowFilters

  const handleOnChange = (ev) => {
    onChange(ev.target.value)
  }

  const mobile = useIsMobile()

  useEffect(() => {
    if (mobile) {
      setShowFilters(false)
    }
  }, [mobile])

  return (
    <div
      className={classnames({
        [className]: !!className,
        [classes.root]: true,
        [classes.filtersActive]: filtersActive,
        [classes.invalid]: encodeURI(inputRef?.current?.value ?? '').split(/%..|./).length - 1 >= 4096
      })}
      style={style}
    >
      <Paper elevation={1} className={classes.paper}>
        <Input
          classes={{
            input: classes.input
          }}
          inputRef={inputRef}
          startAdornment={<SearchIcon className={classes.icon} />}
          disabled={disabled}
          disableUnderline={true}
          fullWidth={true}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleOnChange}
          spellCheck={false}
          autoComplete="off"
          endAdornment={
            <>
              {filterContent &&
                (filterMenuCollapse ? (
                  <FilterMenuButton onClick={() => setShowFilters(!showFilters)} iconActive={filtersActive} />
                ) : (
                  <FilterMenu
                    showExport={showExport}
                    isExporting={isExporting}
                    filterContent={filterContent}
                    filtersActive={filtersActive}
                    onClear={onClear}
                    onSearch={() => onSearch(inputRef.current.value)}
                    onExport={() => onExport(inputRef.current.value)}
                  />
                ))}
              {endAdornment}
            </>
          }
          inputProps={inputProps}
        />
        <Collapse show={showFilters}>
          <Grid container spacing={3} className={classes.collapse}>
            <Grid item xs={12}>
              {filterContent}
            </Grid>
            <Grid item xs={12}>
              <FilterMenuFooter
                showExport={showExport}
                isExporting={isExporting}
                onClear={onClear}
                onSearch={() => {
                  setShowFilters(false)
                  onSearch(inputRef.current.value)
                }}
                onExport={() => onExport(inputRef.current.value)}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Paper>
      {filtersActive && filterLabels && filterLabels.length > 0 && (
        <div className={classes.filterLabels}>
          {filterLabels.map((filter, index) => (
            <div key={index} className={classes.filterLabel}>
              <Typography variant="overline">{filter.title}</Typography>
              <Chip label={filter.label} className={classes.filterChip} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default withStyles(styles)(SearchBar)
