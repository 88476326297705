import { Grid, makeStyles } from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import MoreIcon from '@material-ui/icons/MoreVert'
import { Button, Hidden, IconButton, PopupMenu, Typography } from '@ui/paintscout'
import React from 'react'
import { useHistory } from 'react-router-dom'
import NavHeaderPortal from './NavHeaderPortal'

export interface NavHeaderProps {
  title: React.ReactElement | string
  showBackButton?: boolean
  menuItems?: React.ReactElement
}

const useStyles = makeStyles(() => ({
  backButton: {
    minWidth: 20,
    fontSize: 16
  }
}))

export default function NavHeader({ title, showBackButton, menuItems }: NavHeaderProps) {
  const classes = useStyles({})
  const history = useHistory()

  return (
    <NavHeaderPortal>
      <Grid container alignItems="center" spacing={1}>
        {showBackButton && (
          <Hidden mdDown>
            <Grid item>
              <Button
                className={classes.backButton}
                onClick={() => {
                  history.goBack()
                }}
                variant="text"
                size="small"
                color="primary"
              >
                <BackIcon fontSize="inherit" />
              </Button>
            </Grid>
          </Hidden>
        )}
        <Grid item zeroMinWidth>
          {typeof title === 'string' ? <Typography variant="h1">{title}</Typography> : title}
        </Grid>
        {menuItems && (
          <Grid item>
            <PopupMenu
              component={
                <IconButton color="default" style={{ marginTop: 0 }}>
                  <MoreIcon />
                </IconButton>
              }
            >
              {getMenuItems(menuItems)}
            </PopupMenu>
          </Grid>
        )}
      </Grid>
    </NavHeaderPortal>
  )
}

/**
 * If menuItems is fragment, return fragment's children
 */
function getMenuItems(el: React.ReactElement) {
  if (el.type === React.Fragment) {
    return React.Children.map(el.props.children, (child, index) => {
      return React.cloneElement(child, { key: index })
    }) as JSX.Element[]
  }
  return el
}
