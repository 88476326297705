/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'

import cloneDeep from 'lodash/cloneDeep'
import { uuid } from '../../util'

export function copyQuoteTypeOption(args: { options: OptionsDocument; key: string }): OptionsDocument {
  const { options, key } = args
  const originalItem = options.options.intExtOptions.values[key]

  const newItem = cloneDeep(originalItem)
  newItem.key = uuid()
  newItem.label = `${newItem.label} (copy)`

  const order = options.options.intExtOptions.order
  const orderIndex = order.indexOf(key)
  if (orderIndex !== -1) {
    order.splice(orderIndex + 1, 0, newItem.key)
  } else {
    order.push(newItem.key)
  }

  return {
    ...options,
    options: {
      ...options.options,
      intExtOptions: {
        ...options.options.intExtOptions,
        order,
        values: {
          ...options.options.intExtOptions.values,
          [newItem.key]: newItem
        }
      }
    }
  }
}
