import type { User } from '@paintscout/api'
import { AppDocsDocument, SearchCombinedUsersDocument, useDeactivateUserMutation } from '@paintscout/api'
import type { DialogProps, DropdownSelectOption } from '@ui/paintscout'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Highlight,
  Typography,
  RadioButtons,
  useClientOptions,
  useDialogs
} from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { getOperationName } from 'apollo-link'
import * as Sentry from '@sentry/react'
import EstimatorSelect from '../../EstimatorSelect'

export interface DeactivateUserDialogProps extends DialogProps {
  user: User
  onConfirm: () => void
}

export function DeactivateUserDialog({ user, onClose, onConfirm, ...props }: DeactivateUserDialogProps) {
  const { options, clientId } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const [transferTo, setTransferTo] = useState<string>('')
  const [transferType, setTransferType] = useState<string>('')
  const { dismissDialog } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()
  const [deactivateUser, { loading }] = useDeactivateUserMutation()

  return (
    <Dialog {...props}>
      <DialogTitle loading={loading}>
        <Typography variant="h3">
          Deactivate{' '}
          <Highlight>
            {user.user_metadata.firstName} {user.user_metadata.lastName}
          </Highlight>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              This will deactivate the account for{' '}
              <Highlight>
                {user.user_metadata.firstName} {user.user_metadata.lastName}
              </Highlight>
              . They will no longer be able to log in and will not count towards your total number of users.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              What would you like to do with this user's existing {objectLabels.quote.plural}?
            </Typography>
            <RadioButtons
              value={transferType}
              options={[
                {
                  label: 'Do Nothing',
                  name: 'none'
                },
                {
                  label: `Remove Estimator`,
                  sublabel: `Don't show estimator on ${objectLabels.quote.plural.toLowerCase()}`,
                  name: 'remove'
                },
                { label: `Transfer ${objectLabels.quote.plural.toLowerCase()} to another user`, name: 'transfer' }
              ]}
              onChange={setTransferType}
            />
          </Grid>
          {transferType === 'transfer' && (
            <Grid item xs={12}>
              <EstimatorSelect
                variant="single"
                name="estimator"
                label={`Transfer ${objectLabels.quote.plural} to`}
                omit={[user.user_id]}
                value={transferTo}
                onChange={(option: DropdownSelectOption) => setTransferTo(option.value)}
                fullWidth
              />
              <Typography variant="subtitle2" style={{ marginTop: 4 }}>
                Transferring may take a few minutes, depending on the amount of {objectLabels.quote.plural}
              </Typography>
            </Grid>
          )}

          {transferTo === 'none' && (
            <Grid item xs={12}>
              <Alert severity="info">
                Transferring to No Estimator will allow customers to continue accessing {objectLabels.quote.plural}, but
                not show any of the deactivated user's information. These estimates can be assigned to another estimate
                at any time.
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button onClick={() => onClose(null, null)} variant={'text'}>
            Cancel
          </Button>
        }
      >
        <Button
          color="primary"
          variant="contained"
          disabled={(!transferTo && !transferType) || loading}
          onClick={async () => {
            try {
              await deactivateUser({
                variables: {
                  id: user.user_id,
                  companyId: clientId,
                  transferType,
                  transferQuotesTo: transferTo
                },
                refetchQueries: [getOperationName(SearchCombinedUsersDocument), getOperationName(AppDocsDocument)]
              })
              enqueueSnackbar('User deactivated', { variant: 'success' })
              onConfirm()
            } catch (error) {
              enqueueSnackbar('Unable to deactivate user', { variant: 'error' })
              Sentry.captureException(error, { tags: { userId: user.user_id } })
              dismissDialog()
            }
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
