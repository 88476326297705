/**
 * @module builder
 */
import type { ProductOption } from '../index'
import { uuid } from '../../util'

export function createProductOption(args?: { quoteTypes?: string[]; key?: string; label?: string }): ProductOption {
  const { quoteTypes = ['all'] } = args ?? {}
  const rateTypes = ['all']
  const label = args?.label ?? ''
  const key = args?.key ?? uuid()

  return {
    key,
    label,
    coverage_rate: 0,
    lnft_coverage: 0,
    quantity_coverage: 0,
    quoteTypes,
    rateTypes,
    price: 0,
    active: true
  }
}
