import type { OptionsDocument } from 'paintscout'
import { generateTaxRateDetails } from '../generate-tax-rate-details'

// Generate taxRate values for options document
export function calculateTaxRate(options: OptionsDocument) {
  const { taxRate: taxValue, taxDescription, taxDetails } = generateTaxRateDetails({ options })

  const taxRate = {
    value: taxValue,
    description: taxDescription,
    details: taxDetails,
    allow_custom: options.options.taxRate.allow_custom
  }

  const updatedOptions = {
    ...options,
    options: {
      ...options.options,
      taxRate: {
        ...taxRate
      }
    }
  }

  return updatedOptions
}
