import { makeStyles } from '@material-ui/core'
import type { FollowUp } from '@paintscout/api'
import { getObjectLabels, uuid } from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React from 'react'
import { ConfirmationDialog, Grid, useClientOptions } from '../'
import Button from '../Button'
import FollowUpDialog from '../dialogs/FollowUpDialog'
import { useDialogs } from '../DialogStack'
import FollowUpTable from '../FollowUpTable'
import { NewFormSection as FormSection } from '../FormSection'
import ExportJsonDialog from '../ExportJsonDialog'
import ImportJsonDialog from '../ImportJsonDialog'
import SettingsPage from '../SettingsPage'

const useStyles = makeStyles((theme) => ({
  root: {},
  addressbookSection: {
    width: '100%',
    maxWidth: 600
  },
  templates: {
    marginTop: theme.typography.pxToRem(15)
  },
  importButton: {
    marginRight: -theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formSection: {
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing()}px ${theme.spacing()}px`
    }
  }
}))

export interface UserFollowUpsFormProps {
  onDialogConfirm?: () => void
}

export default function ClientFollowUpsForm({ onDialogConfirm }: UserFollowUpsFormProps) {
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { openDialog, dismissDialog } = useDialogs()
  const { isAdmin } = useClientOptions()
  const { options } = values
  const classes = useStyles()
  const quoteLabel = getObjectLabels({ options }).quote
  const invoiceLabel = getObjectLabels({ options, invoice: true }).quote
  const order = values.options.options.emails?.followUps?.order ?? []
  if (Object.keys(values.options.options.emails?.followUps?.values || {}).length > order.length) {
    // We are missing some follow-up keys in order, these can still get triggered without being able to see/edit them, lets add them in
    const newOrder = Object.keys(values.options.options?.emails?.followUps?.values || {}).filter(
      (key) => !order.includes(key)
    )
    setFieldValue(`options.options.emails.followUps.order`, [...order, ...newOrder])
  }
  const followUps = order.map((key) => values.options.options.emails?.followUps?.values[key])

  function handleEdit(followUp: FollowUp) {
    openDialog(FollowUpDialog, {
      followUp,
      onConfirm: (followUp) => {
        setFieldValue(`options.options.emails.followUps.values.${followUp.key}`, followUp)
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  function handleToggle(followUp: FollowUp) {
    setFieldValue(`options.options.emails.followUps.values.${followUp.key}.active`, !followUp.active)
    setFieldValue(`options.options.emails.followUps.values.${followUp.key}.toggled`, Date.now())
  }

  function handleToggleText(followUp: FollowUp) {
    setFieldValue(`options.options.emails.followUps.values.${followUp.key}.sendText`, !followUp.sendText)
  }

  function handleCopy(followUp: FollowUp) {
    const newKey = uuid()
    const index = order.findIndex((o) => o === followUp.key)

    setFieldValue(`options.options.emails.followUps.order`, [...order.slice(0, index), newKey, ...order.slice(index)])
    setFieldValue(`options.options.emails.followUps.values.${newKey}`, {
      ...followUp,
      key: newKey
    })
  }

  function handleAdd() {
    openDialog(FollowUpDialog, {
      onConfirm: (followUp) => {
        const followUpsValues = {
          ...(values.options.options?.emails?.followUps?.values ?? {}),
          [followUp.key]: followUp
        }

        setFieldValue(`options.options.emails.followUps.values`, followUpsValues)
        setFieldValue(`options.options.emails.followUps.order`, [
          ...(values.options.options?.emails?.followUps?.order ?? []),
          followUp.key
        ])

        onDialogConfirm()
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  function handleDelete(followUp: FollowUp) {
    openDialog(ConfirmationDialog, {
      title: 'Are you sure?',
      message: `This follow-up will no longer be available`,
      onConfirm: () => {
        const followUpsValues = {
          ...values.options.options.emails.followUps.values
        }

        delete followUpsValues[followUp.key]
        setFieldValue(`options.options.emails.followUps.values`, followUpsValues)
        setFieldValue(
          `options.options.emails.followUps.order`,
          values.options.options.emails.followUps.order.filter((o) => o !== followUp.key)
        )
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  function handleExport(followUp: FollowUp) {
    openDialog(ExportJsonDialog, {
      item: followUp,
      onConfirm: dismissDialog
    })
  }

  function handleImportJson() {
    openDialog(ImportJsonDialog, {
      onImport: (json: JSON) => {
        const newFollowUp = json as unknown as FollowUp
        const followUpsValues = {
          ...(values.options.options?.emails?.followUps?.values ?? {}),
          [newFollowUp.key]: newFollowUp
        }

        setFieldValue(`options.options.emails.followUps.values`, followUpsValues)
        setFieldValue(`options.options.emails.followUps.order`, [
          ...(values.options.options?.emails?.followUps?.order ?? []),
          newFollowUp.key
        ])
        dismissDialog()
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onClose: dismissDialog
    })
  }

  return (
    <SettingsPage
      title={'Auto Follow-Ups'}
      callout={{
        path: 'settings-follow-ups',
        content: `Initiate automated follow-up communications with your customers based on their interactions with ${quoteLabel.plural.toLowerCase()} or ${invoiceLabel.plural.toLowerCase()} and a designated timeframe.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/8289229-auto-follow-ups'
      }}
    >
      <Form className={classes.root}>
        <FormSection className={classes.formSection} topPadded={!isAdmin}>
          {isAdmin && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button className={classes.importButton} variant={'text'} onClick={handleImportJson}>
                  Import
                </Button>
              </Grid>
            </Grid>
          )}
          <FollowUpTable
            settingsView
            followUps={followUps}
            isSuperadmin={isAdmin}
            onAdd={handleAdd}
            onAction={(action, followUp) => {
              switch (action) {
                case 'edit':
                  handleEdit(followUp)
                  break
                case 'copy':
                  handleCopy(followUp)
                  break
                case 'delete':
                  handleDelete(followUp)
                  break
                case 'toggle':
                  handleToggle(followUp)
                  break
                case 'toggleSendText':
                  handleToggleText(followUp)
                  break
                case 'export':
                  handleExport(followUp)
                  break
              }
            }}
          />
        </FormSection>
      </Form>
    </SettingsPage>
  )
}
