/**
 * @module builder
 */
import type { OptionsDocument, RatesDocument } from 'paintscout'
import type { RateSectionOption } from '../../index'
import { deleteRatesBySection } from '../delete-rates-by-section'

export function deleteRateSection(args: {
  options: OptionsDocument
  rateSection: RateSectionOption
  rates: RatesDocument
}): { updatedOptions: OptionsDocument; updatedRates: RatesDocument } {
  const { options, rateSection, rates } = args

  const key = rateSection.key

  const order = (options.options.rateSections?.order ?? []).filter((item) => key !== item)
  const quoteTypeOrder = options.options.rateSections?.quoteTypeOrder ?? {}

  Object.keys(quoteTypeOrder).forEach((type) => {
    quoteTypeOrder[type] = quoteTypeOrder[type].filter((item) => key !== item)
  })

  const values = { ...options.options.rateSections?.values }
  if (values[key]) {
    delete values[key]
  }

  const updatedRates = deleteRatesBySection({ rates, rateSection })

  return {
    updatedOptions: {
      ...options,
      options: {
        ...options.options,
        rateSections: {
          ...options.options.rateSections,
          order,
          quoteTypeOrder,
          values
        }
      }
    },
    updatedRates
  }
}

export function deleteRateSections(args: {
  options: OptionsDocument
  rateSections: RateSectionOption[]
  rates: RatesDocument
}): { updatedOptions: OptionsDocument; updatedRates: RatesDocument } {
  const { options, rateSections, rates } = args
  return rateSections.reduce(
    ({ updatedOptions, updatedRates }, rateSection) =>
      deleteRateSection({ options: updatedOptions, rateSection, rates: updatedRates }),
    { updatedOptions: options, updatedRates: rates }
  )
}
