/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { QuoteTypeOption } from '../../index'
import { removeQuoteTypesFromOption, removeQuoteTypesFromTerms } from './remove-quote-types'

export function deleteQuoteTypeOption(args: {
  options: OptionsDocument
  quoteTypeOption: QuoteTypeOption
}): OptionsDocument {
  const { options, quoteTypeOption } = args

  const key = quoteTypeOption.key

  const intExtOptionsOrder = [...(options.options.intExtOptions?.order || [])]
  const orderIndex = intExtOptionsOrder.indexOf(key)
  if (orderIndex !== -1) {
    intExtOptionsOrder.splice(orderIndex, 1)
  }

  const intExtOptionsValues = { ...(options.options.intExtOptions?.values || {}) }
  if (intExtOptionsValues[key]) {
    delete intExtOptionsValues[key]
  }

  return {
    ...options,
    options: {
      ...options.options,
      intExtOptions: {
        ...options.options.intExtOptions,
        order: intExtOptionsOrder,
        values: intExtOptionsValues
      },
      areaTemplates: removeQuoteTypesFromOption(options.options.areaTemplates, [key]),
      lineItems: removeQuoteTypesFromOption(options.options.lineItems, [key]),
      products: removeQuoteTypesFromOption(options.options.products, [key]),
      quoteTemplates: removeQuoteTypesFromOption(options.options.quoteTemplates, [key]),
      rateSections: removeQuoteTypesFromOption(options.options.rateSections, [key]),
      terms: removeQuoteTypesFromTerms(options.options.terms, [key]),
      presentationOptions: removeQuoteTypesFromOption(options.options.presentationOptions, [key])
    }
  }
}

export function deleteQuoteTypeOptions(args: {
  options: OptionsDocument
  quoteTypeOptions: QuoteTypeOption[]
}): OptionsDocument {
  const { options, quoteTypeOptions } = args
  return quoteTypeOptions.reduce(
    (options, quoteTypeOption) => deleteQuoteTypeOption({ options, quoteTypeOption }),
    options
  )
}
