import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { OptionsDocument } from 'paintscout'
import { Button, InputField } from '@ui/paintscout'
import { ClientOptionsProvider } from '@ui/paintscout'
import type { WithDialogStackContext } from '@ui/paintscout'
import { withDialogStackContext } from '@ui/paintscout'
import type { DialogProps } from '@ui/paintscout'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormSection } from '@ui/paintscout'
import ProductSelector from '../../ProductSelector'
import { createProduct, convertProductOption } from '@paintscout/util/builder'
import type { ProductOption, Product } from '@paintscout/util/builder'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'
import EditProductDialog from '../EditProductDialog'

export interface ProductSelectDialogProps extends DialogProps, WithDialogStackContext {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  onConfirm?: (productOption: Product, custom?: boolean) => void
  onCancel?: () => void
  loading?: boolean
  quoteTypes: string[]
  allowCustom?: boolean
  options?: OptionsDocument
}

export interface ProductSelectState {
  query?: string
}

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography
  return createStyles({
    root: {},
    dialogContent: {
      paddingTop: 0
    },
    contentInner: {
      padding: pxToRem(30),
      paddingTop: '0'
    },
    searchAdornment: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    formSection: {},
    searchGrid: {
      marginBottom: '0.5em'
    }
  })
}

class ProductSelectDialog extends React.PureComponent<ProductSelectDialogProps, ProductSelectState> {
  constructor(props) {
    super(props)
    this.state = {
      query: ''
    }
  }
  public render() {
    const {
      classes,
      loading,

      options,
      quoteTypes,
      onCancel,
      allowCustom = true,
      ...otherProps
    } = this.props
    const { query } = this.state

    const leftButton = (
      <Button disabled={loading} onClick={onCancel} variant={'text'}>
        Cancel
      </Button>
    )

    return (
      <ClientOptionsProvider options={options}>
        <Dialog {...otherProps} className={classes.root} maxWidth={'md'} fullWidth={true}>
          <DialogTitle loading={loading}>Select Product</DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <FormSection classes={{ root: classes.formSection }} hideDivider={true}>
              <div className={classes.contentInner}>
                <Grid className={classes.searchGrid} container={true} spacing={2} alignItems={'center'}>
                  <Grid item={true} xs={12} sm={8}>
                    <InputField
                      id="query"
                      data-testid="product-search-input"
                      placeholder={`Search Products...`}
                      fullWidth={true}
                      value={query}
                      onChange={(ev) => this.setState({ query: ev.currentTarget.value })}
                      startAdornment={
                        <div className={classes.searchAdornment}>
                          <SearchIcon />
                        </div>
                      }
                    />
                  </Grid>
                  {allowCustom && (
                    <Grid item={true} xs={12} sm={4}>
                      <Button
                        variant={'text'}
                        onClick={(event: any) => {
                          this.handleChange(event, null, true)
                        }}
                      >
                        Custom Product
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <ProductSelector
                  options={options}
                  query={query}
                  quoteTypes={quoteTypes}
                  onChange={this.handleChange}
                  allowCustom={allowCustom}
                />
              </div>
            </FormSection>
          </DialogContent>
          <DialogActions leftButton={leftButton} />
        </Dialog>
      </ClientOptionsProvider>
    )
  }

  public handleChange = (_event: any, productOption: ProductOption, custom?: boolean) => {
    if (custom) {
      const { quoteTypes, dialogStackContext } = this.props
      const item = createProduct({ quoteTypes: quoteTypes })

      dialogStackContext.openDialog(EditProductDialog, {
        item,
        isNew: true,
        isCustom: true,
        onConfirm: async (product: Product) => {
          dialogStackContext.dismissDialog()
          this.props.onConfirm(product, false)
        },
        onCancel: () => {
          dialogStackContext.dismissDialog()
        }
      })
    } else if (this.props.onConfirm) {
      this.props.onConfirm(convertProductOption({ productOption }), custom)
    }
  }
}

export default withStyles(styles)(withDialogStackContext(ProductSelectDialog))
