/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { QuoteTypeOption } from '../../index'

export function updateQuoteTypeOption(args: {
  quoteTypeOption: QuoteTypeOption
  options: OptionsDocument
}): OptionsDocument {
  const { options, quoteTypeOption } = args

  const order = [...options.options.intExtOptions.order]
  const orderIndex = order.indexOf(quoteTypeOption.key)
  if (orderIndex === -1) {
    order.push(quoteTypeOption.key)
  }

  let hourlyRate = quoteTypeOption.hourlyRate
  if (typeof hourlyRate !== 'number') {
    hourlyRate = parseFloat(hourlyRate)
  }
  if (isNaN(hourlyRate)) {
    hourlyRate = 0
  }

  return {
    ...options,
    options: {
      ...options.options,
      intExtOptions: {
        ...options.options.intExtOptions,
        values: {
          ...options.options.intExtOptions.values,
          [quoteTypeOption.key]: {
            ...options.options.intExtOptions.values[quoteTypeOption.key],
            active: true,
            ...quoteTypeOption,
            value: quoteTypeOption.key,
            hourlyRate
          }
        },
        order
      }
    }
  }
}
