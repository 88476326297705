import { makeStyles } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import {
  AlertDialog,
  ConfirmationDialog,
  Checkbox,
  FormSection,
  FormControlLabel,
  Grid,
  InputField,
  NavTabs,
  Paper,
  Scaler,
  Tooltip,
  useClientOptions,
  useDialogs,
  useUser,
  Alert,
  InputLabel,
  Button
} from '@ui/paintscout'
import {
  copyPresentationPage,
  copyPresentationSection,
  getFeature,
  getObjectLabels,
  getPresentationPreviewQuote
} from '@paintscout/util/builder'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import type {
  PartnerContentFeatures,
  // PartnerPresentationOptions,
  PresentationOption,
  PresentationPage,
  PresentationSection
} from 'paintscout'
import React, { useState } from 'react'
import { QuoteContextProvider } from '../../../context'
import OptionsTileList from '../../../OptionsTileList'
import PresentationPreview from '../../../presentation/PresentationPreview'
import QuoteTypeSelect from '../../../QuoteTypeSelect'
import EditPresentationSectionDialog from '../../dialogs/EditPresentationSectionDialog'
import PresentationPageSelectDialog from '../../dialogs/PresentationPageSelectDialog'
import PresentationSectionSelectDialog from '../../dialogs/PresentationSectionSelectDialog'
import PresentationPartnerDialog from '../../dialogs/PresentationPartnerDialog'
import PageOptionsMenu from './PageOptionsMenu'

export interface PresentationDetailsProps {
  onChange: (presentationOption: PresentationOption) => void
  onDialogConfirm?: () => void
  presentation: PresentationOption
}

const useStyles = makeStyles((theme) => ({
  root: {},

  tiles: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    paddingRight: 0,
    paddingBottom: 0
  },

  presentationWrapper: {
    borderTop: `1px solid ${theme.palette.grey[400]}`
  },
  quoteWrapper: {
    // overflowY: 'scroll',
    // maxHeight: 1000
  },
  leftSide: {
    marginTop: theme.spacing(2)
  },
  acceptButton: {
    marginLeft: theme.spacing(2)
  },
  logo: {
    height: 50
  },
  navTabsWrapper: {
    background: '#fff',
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    borderRadius: 0
  },
  labelDiv: {
    minWidth: '350px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '250px'
    }
  },
  navTabs: {
    marginLeft: theme.spacing(2),
    marginBottom: 0
  },
  tab: {
    background: 'red'
  },
  rightSide: {
    // backgroundColor: '#fff'
  }
}))

function PresentationDetails({ presentation, onChange, onDialogConfirm, ...props }: PresentationDetailsProps) {
  const classes = useStyles(props)

  const { options } = useClientOptions()
  const partnerContentFeatures: PartnerContentFeatures = getFeature({ options: options, path: 'partnerContent' })

  const [quote] = useState(getPresentationPreviewQuote())

  const objectLabels = getObjectLabels({ options })
  const { openDialog, dismissDialog, dismissAllDialogs } = useDialogs()
  const { isSuperadmin } = useUser()

  const [pageKey, setPageKey] = useState(null)
  const [pageIndex, setPageIndex] = useState(null)

  let page = find(presentation.pages, { key: pageKey }) as PresentationPage
  if (!page) {
    page = presentation.pages[0]
    setPageKey(page.key)
    setPageIndex(0)
  }

  const isSinglePage = presentation.pages.length <= 1

  const isEditable = presentation.key !== 'default'

  const sectionActions = []
  if (pageIndex !== 0) {
    sectionActions.push({ key: 'move-previous', label: 'Move to Previous Page', icon: ArrowLeft })
  }
  if (pageIndex < presentation.pages.length - 1) {
    sectionActions.push({ key: 'move-next', label: 'Move to Next Page', icon: ArrowRight })
  }

  return (
    <div className={classes.root}>
      <QuoteContextProvider quote={quote} tableView={'area'}>
        <FormSection>
          <Grid container spacing={3} justifyContent={'space-between'}>
            {presentation.key === 'default' && (
              <Grid item md={12}>
                <Alert
                  severity="info"
                  title="This is the default Presentation. "
                  content="It cannot be edited, and will be used when no other presentation is selected."
                />
              </Grid>
            )}
            {presentation.key !== 'default' && isSinglePage && (
              <Grid item md={12}>
                <Alert
                  severity="info"
                  title="This is a single-page presentation"
                  content="It will be shown all on one page using it's sections as navigation."
                />
              </Grid>
            )}
            <Grid item className={classes.labelDiv} md={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputField
                    data-testid={'presentation-label'}
                    disabled={!isEditable}
                    fullWidth={true}
                    name={'label'}
                    label={'Label'}
                    value={presentation.label}
                    onChange={handleLabelChange}
                  />
                </Grid>
                {isSuperadmin && (
                  <>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label={'Automatically Open Navigation'}
                        disabled={!isEditable}
                        control={
                          <Checkbox
                            checked={presentation.advanced?.autoOpenNav}
                            onChange={handleCheckboxChange('autoOpenNav')}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label={'Allow Visitors to Scroll between pages'}
                        disabled={!isEditable}
                        control={
                          <Checkbox
                            checked={presentation.advanced?.infiniteScroll}
                            onChange={handleCheckboxChange('infiniteScroll')}
                          />
                        }
                      />
                    </Grid>
                  </>
                )}
                {partnerContentFeatures?.enabled && (
                  <Grid item xs={12}>
                    <Button onClick={handlePartnerActivationDialog}>Partner Presentations</Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={3}>
              <QuoteTypeSelect
                label={`${objectLabels.quote.value} Types`}
                variant="multi"
                disabled={!isEditable}
                onChange={handleQuoteTypeChange}
                value={presentation.quoteTypes}
              />
            </Grid>
          </Grid>
        </FormSection>
        <div className={classes.presentationWrapper}>
          <Grid container spacing={3}>
            <Grid item md={3} lg={2}>
              <div className={classes.leftSide}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Tooltip
                      title={isSinglePage ? 'Single Page Mode' : 'Multi-Page Mode'}
                      content={
                        isSinglePage ? (
                          <>
                            <p>Each section of this page will appear as a tab to customers.</p>
                            <p>Clicking on a tab will scroll to that section.</p>
                          </>
                        ) : (
                          <>
                            <p>Each page will appear as a tab to customers.</p>
                            <p>Clicking on a tab will display that page's content.</p>
                          </>
                        )
                      }
                    >
                      <InputLabel> Page Title</InputLabel>
                    </Tooltip>
                    <InputField
                      style={{ marginTop: 2 }}
                      value={page.title}
                      fullWidth={true}
                      disabled={isSinglePage || !isEditable}
                      onChange={handlePageTitleChange}
                      endAdornment={
                        <PageOptionsMenu onRemove={handleRemovePage} onCopy={handleCopyPage} disabled={isSinglePage} />
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <OptionsTileList
                      title={''}
                      disabled={!isEditable}
                      classes={{ tiles: classes.tiles }}
                      items={page.sections.map((section) => {
                        let label = ''
                        if (section?.title) {
                          label = section.title
                        } else if (section?.label) {
                          label = section.label
                        }

                        return { ...section, label }
                      })}
                      createTitle={'Add Section'}
                      showCreate={isEditable}
                      onReorder={handleReorderSections}
                      onEditItem={handleEditItem}
                      onCopyItem={handleCopyItem}
                      onDeleteItem={handleDeleteItem}
                      onCreateClick={handleCreateSection}
                      actions={sectionActions}
                      onItemAction={handleItemAction}
                      axis={'y'}
                      hideReorderSwitch={true}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={9} lg={10}>
              <div className={classes.rightSide}>
                <Grid container>
                  <Grid item md={12}>
                    <Scaler fixedWidth={1002} noHeight={true}>
                      <Paper classes={{ root: classes.navTabsWrapper }}>
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                          <Grid item>
                            <NavTabs
                              allowAdd={isEditable}
                              presentation={presentation}
                              classes={{ root: classes.navTabs }}
                              logo={options?.options?.logo?.value}
                              value={page.key}
                              onAddClick={handleCreatePage}
                              onReorder={handleReorderPages}
                              onNavClick={handleNavClick}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <div className={classes.quoteWrapper}>
                        <PresentationPreview
                          presentation={presentation}
                          page={pageKey}
                          isSettings={true}
                          onPresentationClick={handlePresentationClick}
                        />
                      </div>
                    </Scaler>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </QuoteContextProvider>
    </div>
  )

  function handlePageTitleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const updatedPages = cloneDeep(presentation.pages)

    updatedPages[pageIndex].title = ev.target.value

    if (onChange) {
      onChange({
        ...presentation,
        pages: updatedPages
      })
    }
  }

  function handleQuoteTypeChange(values: string[]) {
    if (onChange) {
      onChange({
        ...presentation,
        quoteTypes: values
      })
    }
  }
  function handleLabelChange(ev: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange({
        ...presentation,
        label: ev.target.value
      })
    }
  }
  function handleCheckboxChange(name: string) {
    return function (_ev: React.ChangeEvent) {
      if (onChange) {
        onChange({
          ...presentation,
          advanced: {
            ...(presentation.advanced ?? {}),
            [name]: !presentation.advanced?.[name]
          }
        })
      }
    }
  }

  function handleNavClick(ev: React.MouseEvent, value: string) {
    if (!isSinglePage) {
      setPageKey(value)
      const i = findIndex(presentation.pages, { key: value })
      setPageIndex(i)
    }
  }

  function handleReorderPages(items) {
    let updatedPresentation
    if (isSinglePage) {
      const updatedPages = cloneDeep(presentation.pages)

      updatedPages[pageIndex].sections = [
        ...updatedPages[pageIndex].sections.filter((item) => {
          return !find(items, { value: item.key })
        }),
        ...items.map((item) => {
          return find(page.sections, { key: item.value })
        })
      ]
      updatedPresentation = {
        ...presentation,
        pages: updatedPages
      }
    } else {
      updatedPresentation = {
        ...presentation,
        pages: items.map((item) => {
          return find(presentation.pages, { key: item.value })
        })
      }

      const newPageIndex = findIndex(updatedPresentation.pages, { key: pageKey })
      setPageIndex(newPageIndex)
    }

    if (onChange) {
      onChange(updatedPresentation)
    }
  }

  function handleRemovePage() {
    if (presentation.pages.length === 1) {
      openDialog(AlertDialog, {
        message: 'Presentations must contain at least one page.',
        onConfirm: dismissAllDialogs
      })
      return
    }

    const updatedPresentation = cloneDeep(presentation)

    updatedPresentation.pages.splice(pageIndex, 1)

    const newIndex = pageIndex >= 1 ? pageIndex - 1 : 0

    setPageKey(updatedPresentation.pages[newIndex].key)
    setPageIndex(newIndex)

    if (onChange) {
      onChange(updatedPresentation)
    }
  }

  function handleCopyPage() {
    if (onChange) {
      onChange(copyPresentationPage({ pageIndex, presentation }))
    }
  }

  function handleReorderSections(sections: PresentationSection[]) {
    const updatedPages = cloneDeep(presentation.pages)

    updatedPages[pageIndex].sections = sections

    if (onChange) {
      onChange({
        ...presentation,
        pages: updatedPages
      })
    }
  }

  function handlePresentationClick(key: string) {
    if (!isEditable) {
      return
    }
    editSection(findIndex(page.sections, { key }))
  }

  function handleEditItem(_event: React.MouseEvent, key: string) {
    editSection(findIndex(page.sections, { key }))
  }

  function handleCopyItem(_event: React.MouseEvent, key: string) {
    copySection(findIndex(page.sections, { key }))
  }

  function editSection(index: number) {
    openDialog(EditPresentationSectionDialog, {
      section: page.sections[index],
      showAdvanced: true,
      isSettings: true,
      quote,
      onConfirm: (section: PresentationSection, isDirty?: boolean) => {
        const updatedPages = cloneDeep(presentation.pages)

        updatedPages[pageIndex].sections[index] = section

        if (onChange) {
          onChange({
            ...presentation,
            pages: updatedPages
          })

          if (onDialogConfirm && isDirty) {
            onDialogConfirm()
          }
        }
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  function copySection(sectionIndex: number) {
    onChange(copyPresentationSection({ pageIndex, sectionIndex, presentation }))
  }

  function handleDeleteItem(ev: React.MouseEvent, key: string) {
    const index = findIndex(page.sections, { key })
    const section = page.sections[index]
    if (section.type === 'quote') {
      openDialog(AlertDialog, {
        message: `The ${objectLabels.quote.value} section cannot be removed.`,
        onConfirm: dismissDialog
      })
      return
    }
    openDialog(ConfirmationDialog, {
      message: 'This section will be removed from the presentation.',
      onConfirm: () => {
        const updatedPages = cloneDeep(presentation.pages)

        updatedPages[pageIndex].sections.splice(index, 1)
        if (onChange) {
          onChange({
            ...presentation,
            pages: updatedPages
          })
        }
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  function handleItemAction(_ev: React.MouseEvent, key: string, action: string) {
    const updatedPages = cloneDeep(presentation.pages)

    switch (action) {
      case 'move-previous':
        if (pageIndex !== 0) {
          const sectionIndex = findIndex(updatedPages[pageIndex].sections, { key })
          const actionSection: PresentationSection[] = updatedPages[pageIndex].sections.splice(sectionIndex, 1)
          updatedPages[pageIndex - 1]?.sections.push(actionSection[0])
        }

        break
      case 'move-next':
        if (pageIndex !== presentation.pages.length - 1) {
          const sectionIndex = findIndex(updatedPages[pageIndex].sections, { key })
          const actionSection: PresentationSection[] = updatedPages[pageIndex].sections.splice(sectionIndex, 1)
          updatedPages[pageIndex + 1]?.sections.push(actionSection[0])
        }
        break
    }

    if (onChange) {
      onChange({
        ...presentation,
        pages: updatedPages
      })
    }
  }

  function handleCreatePage(_ev: React.MouseEvent) {
    openDialog(PresentationPageSelectDialog, {
      onConfirm: (presentationPage: PresentationPage) => {
        const updatedPages = cloneDeep(presentation.pages)

        if (onChange) {
          onChange({
            ...presentation,
            pages: [...updatedPages, presentationPage]
          })
          if (onDialogConfirm) {
            onDialogConfirm()
          }
          dismissDialog()
        }
        setPageKey(presentationPage.key)
        setPageIndex(updatedPages.length)
      },
      onCancel: dismissDialog
    })
  }

  function handleCreateSection(_ev: React.MouseEvent) {
    openDialog(PresentationSectionSelectDialog, {
      onConfirm: (presentationSection: PresentationSection) => {
        openDialog(EditPresentationSectionDialog, {
          section: presentationSection,
          quote,
          showAdvanced: true,
          isSettings: true,
          onConfirm: (newSection: PresentationSection) => {
            const updatedPages = cloneDeep(presentation.pages)

            updatedPages[pageIndex].sections = [...updatedPages[pageIndex].sections, newSection]

            if (onChange) {
              onChange({
                ...presentation,
                pages: updatedPages
              })
              if (onDialogConfirm) {
                onDialogConfirm()
              }
            }
            dismissAllDialogs()
          },
          onCancel: dismissAllDialogs
        })
      },
      onCancel: dismissDialog
    })
  }

  function handlePartnerActivationDialog(_ev: React.MouseEvent) {
    openDialog(PresentationPartnerDialog, {
      presentation,
      partnerContentFeatures,
      onCancel: dismissAllDialogs,
      onConfirm: (updatedPresentation: PresentationOption) => {
        onChange(updatedPresentation)
        dismissAllDialogs()
      }
    })
  }
}

export default PresentationDetails
