import React from 'react'

export interface MenuContextValue {
  menuOpen: boolean
  setMenuOpen: (open: boolean) => void
}

export const MenuContextInitialValue: MenuContextValue = {
  menuOpen: false,
  setMenuOpen: () => {}
}

export const MenuContext = React.createContext<MenuContextValue>(MenuContextInitialValue)
