/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'

/**
 * @description Deletes a product option from the options document
 */
export function deleteProductOption(args: { options: OptionsDocument; key: string }): OptionsDocument {
  const { options, key } = args

  const order = (options.options.products?.order ?? []).filter((item) => key !== item)
  const quoteTypeOrder = options.options.products?.quoteTypeOrder ?? {}

  Object.keys(quoteTypeOrder).forEach((type) => {
    quoteTypeOrder[type] = quoteTypeOrder[type].filter((item) => key !== item)
  })

  const values = { ...options.options.products?.values }
  if (values[key]) {
    delete values[key]
  }

  return {
    ...options,
    options: {
      ...options.options,
      products: {
        ...options.options.products,
        order,
        quoteTypeOrder,
        values
      }
    }
  }
}

export function deleteProductOptions(args: { options: OptionsDocument; productOptionKeys: string[] }): OptionsDocument {
  const { options, productOptionKeys } = args
  return productOptionKeys.reduce((options, key) => deleteProductOption({ options, key }), options)
}
