import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TileList, useClientOptions } from '@ui/paintscout'
import { createPresentationSection, getPresentationSectionOptions } from '@paintscout/util/builder'
import type { PresentationSection } from 'paintscout'
import React from 'react'
import sections from '../../sections'

export interface PresentationSectionSelectDialogProps extends Omit<DialogProps, 'message' | 'title' | 'loading'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  onConfirm?: (section: PresentationSection) => void
  onCancel?: () => void
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {},
    tileList: {}
  })

function PresentationSectionSelectDialog({
  open,
  classes,
  onConfirm,
  onCancel,
  ...props
}: PresentationSectionSelectDialogProps) {
  const { options } = useClientOptions()
  const sectionOptions = getPresentationSectionOptions({ options }).map((section) => ({
    ...section,
    Thumbnail: sections?.[section.type]?.Thumbnail
  }))

  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Add Section</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <TileList
            variant="select"
            items={sectionOptions.map((section, index) => ({ ...section, key: index }))}
            onSelect={onTileClick}
          />
        </div>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button variant={'text'} onClick={onCancel}>
            Cancel
          </Button>
        }
      ></DialogActions>
    </Dialog>
  )

  function onTileClick(event: any, index: number) {
    const presentationSectionOption = sectionOptions[index]
    delete presentationSectionOption.Thumbnail

    if (onConfirm) {
      onConfirm(createPresentationSection({ presentationSectionOption }))
    }
  }
}

export default withStyles(styles)(PresentationSectionSelectDialog)
