/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { TermsOption } from '../../index'

export function deleteTermsOption(args: { options: OptionsDocument; termsOption: TermsOption }): OptionsDocument {
  const { options, termsOption } = args

  const key = termsOption.name

  const order = (options.options.terms?.order ?? []).filter((item) => key !== item)
  const quoteTypeOrder = options.options.terms?.quoteTypeOrder ?? {}

  Object.keys(quoteTypeOrder).forEach((type) => {
    quoteTypeOrder[type] = quoteTypeOrder[type].filter((item) => key !== item)
  })

  const values = { ...options.options.terms?.values }
  if (values[key]) {
    delete values[key]
  }

  return {
    ...options,
    options: {
      ...options.options,
      terms: {
        ...options.options.terms,
        order,
        quoteTypeOrder,
        values
      }
    }
  }
}

export function deleteTermsOptions(args: { options: OptionsDocument; termsOptions: TermsOption[] }): OptionsDocument {
  const { options, termsOptions } = args
  return termsOptions.reduce((acc, termsOption) => deleteTermsOption({ options: acc, termsOption }), options)
}
