import type { Auth0User, OptionsDocument, UserPreferencesDocument } from 'paintscout'
import type { Auth0UserWithClaims } from '../UserProvider'
import type { FormikConfig, FormikErrors } from 'formik'
import { Formik } from 'formik'
import React from 'react'
import set from 'lodash/set'
import get from 'lodash/get'

export interface ProfileFormikValues {
  user: Auth0User | Auth0UserWithClaims
  preferences: UserPreferencesDocument
  options: OptionsDocument
}

export interface ProfileFormikProps extends Omit<FormikConfig<ProfileFormikValues>, 'initialValues'> {
  user: Auth0User | Auth0UserWithClaims
  options?: OptionsDocument
  preferences: UserPreferencesDocument
}

/**
 * A helper that renders a <Formik> component using ProifleFormikValues
 */
export default function ProfileFormik({ user, preferences, options, children, ...props }: ProfileFormikProps) {
  return (
    <Formik
      initialValues={{ user, preferences, options }}
      validate={(values) => {
        const errors: FormikErrors<ProfileFormikValues> = {}

        if (!get(values, 'user.user_metadata.firstName')) {
          set(errors, 'user.user_metadata.firstName', 'Required')
        }
        if (!get(values, 'user.user_metadata.lastName')) {
          set(errors, 'user.user_metadata.lastName', 'Required')
        }

        return errors
      }}
      {...props}
    >
      {children}
    </Formik>
  )
}
