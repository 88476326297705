/**
 * @module builder
 */

import type { PresentationOption, OptionsDocument } from 'paintscout'
import { uuid } from '../../../util'

export function copyPresentationOption(args: {
  options: OptionsDocument
  presentation: PresentationOption
}): OptionsDocument {
  const { options, presentation } = args

  const presentationOptions = options?.options?.presentationOptions
  const existingOption = presentationOptions?.values?.[presentation.key]
  if (!presentationOptions || !existingOption) {
    return null
  }

  const orderIndex = presentationOptions.order.findIndex((key) => key === presentation.key)
  const newKey = uuid()
  const newPresentation = {
    ...existingOption,
    label: `${existingOption.label} Copy`,
    key: newKey
  }
  const newOrder = presentationOptions.order
  newOrder.splice(orderIndex + 1, 0, newKey)

  return {
    ...options,
    options: {
      ...options.options,
      presentationOptions: {
        ...options.options.presentationOptions,
        order: newOrder,
        values: {
          ...options.options.presentationOptions.values,
          [newKey]: newPresentation
        }
      }
    }
  }
}
