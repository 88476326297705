/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { RateSectionOption } from '../../index'
import { getQuoteType } from '../../quote/util'

export function reorderRateSections(args: {
  options: OptionsDocument
  rateSections: RateSectionOption[]
  quoteType: string
}): OptionsDocument {
  const { options, rateSections } = args

  const quoteType = getQuoteType(args)

  let order = [...options.options.rateSections.order]
  const quoteTypeOrder = { ...options.options.rateSections.quoteTypeOrder }

  if (quoteType && quoteType !== 'all') {
    quoteTypeOrder[quoteType] = rateSections.map((item: RateSectionOption) => {
      return item.key
    })
  } else {
    order = rateSections.map((item: RateSectionOption) => {
      return item.key
    })
  }

  return {
    ...options,
    options: {
      ...options.options,
      rateSections: {
        ...options.options.rateSections,
        order,
        quoteTypeOrder
      }
    }
  }
}
