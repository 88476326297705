import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles, Radio, RadioGroup } from '@material-ui/core'
import type { EventType } from 'paintscout'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  InputField,
  Checkbox,
  Collapse,
  Typography,
  FormControlLabel,
  FormSection,
  MenuItem,
  Select,
  FormSectionTitle,
  Grid,
  useClientOptions,
  EventSubscriptionType,
  DropdownSelect
} from '../../'
import { useProcessEvent, useAdminIntegrationsConfig } from '@ui/core'
import type { ProviderConfig } from 'paintscout'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'
import { Divider } from '@material-ui/core'
import find from 'lodash/find'

const useStyles = makeStyles<Theme, QuickBooksProviderFormProps>(
  (theme) => ({
    root: {},
    configSection: {
      marginLeft: theme.spacing(4),
      cursor: 'pointer'
    },
    itemSelection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1)
    },
    itemSelectText: {
      minWidth: '110px'
    },
    select: {
      width: 225,
      [theme.breakpoints.down('md')]: {
        width: 200
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: -10
      }
    },
    radioWrapper: {
      marginLeft: theme.spacing(1.4)
    },
    divider: {
      margin: theme.spacing(3, 0),
      height: 3
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    },
    required: {
      color: theme.palette.error.main
    }
  }),
  { name: 'QuickBooksProviderForm' }
)

export interface QuickBooksProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
  companyId?: string
  userId?: string
}

export function QuickBooksProviderForm(props: QuickBooksProviderFormProps) {
  const {
    providerConfig,
    providerName,
    isSettingsIntegration = false,
    isAdminIntegrations = false,
    companyId = '',
    userId = '',
    onChange
  } = props
  const classes = useStyles(props)
  const { enabled, config } = providerConfig
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { handleEventChange } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  const [serviceItems, setServiceItems] = useState([])
  const [taxRates, setTaxRates] = useState([])
  const [loading, setLoading] = useState(false)

  const { adminIntegrationsConfig } = useAdminIntegrationsConfig()
  const { processEvent } = useProcessEvent()

  useEffect(() => {
    async function load() {
      if (enabled) {
        setLoading(true)
        const configItemsRes = isAdminIntegrations
          ? await adminIntegrationsConfig({
              provider: providerName,
              type: 'get-config-items',
              params: {
                companyId,
                userId
              }
            })
          : await processEvent({
              provider: providerName,
              type: 'get-config-items',
              params: {}
            })

        setServiceItems(
          (configItemsRes?.result?.serviceItems ?? []).map((item) => {
            return {
              ...item,
              key: item.value
            }
          })
        )
        setTaxRates(configItemsRes?.result?.taxItems ?? [])

        setLoading(false)
      }
    }
    setTimeout(() => {
      load()
    }, 3000)
  }, [enabled])

  const ItemSelection = (props: { name: string; items: Array<{ value: number | string; label: string }> }) => {
    const { name, items } = props
    return (
      <Select
        name={name}
        disabled={loading}
        value={config[name]?.value}
        onChange={handleItemSelection}
        className={classes.select}
        displayEmpty
        variant={'outlined'}
        renderValue={(selectedValue: string) => {
          const selected = find(items, { value: selectedValue }) as { value: number | string; label: string }
          return selected?.label ?? 'Select Item...'
        }}
      >
        {(items ?? []).map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    )
  }

  const DropdownItemSelection = (props: {
    name: string
    items: Array<{ value: number | string; label: string }>
    searchable?: boolean
  }) => {
    const { name, items, searchable } = props
    const selectedItem = items.filter((item) => item.value === config[name]?.value)[0] ?? null
    return (
      <DropdownSelect
        style={{ alignSelf: 'flex-end' }}
        name={name}
        searchable={searchable}
        disabled={loading}
        value={selectedItem}
        onChange={(option) => handleDropdownSelection(option, name)}
        options={items}
        className={classes.select}
        placeholder={{
          plural: 'Select Item...'
        }}
        variant={'single'}
      />
    )
  }
  const handleDropdownSelection = (option: any, name: string) => {
    let item = null
    if (name === 'totalServiceItem') {
      item = find(serviceItems, { value: option.value })
    }
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: item
      }
    })
  }

  const handleItemSelection = (ev: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = ev.target
    let item = null
    if (name === 'customTaxRate') {
      item = find(taxRates, { value })
    } else if (name === 'totalServiceItem') {
      item = find(serviceItems, { value })
    } else if (name === 'invoiceItemStyle' || name === 'invoiceAdditionalItemStyle') {
      item = { value }
    } else if (name === 'acceptedAutomaticQuoteStyle') {
      item = { value }
    }
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: item
      }
    })
  }

  const invoiceItemOptions = [
    { value: 'total', label: 'Total Item' },
    { value: 'individual', label: 'Areas/Line Items' }
  ]

  const QBFormSection = ({
    title,
    subTitle,
    required,
    rightContent
  }: {
    title: string
    subTitle?: string
    required?: boolean
    rightContent: JSX.Element
  }) => {
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={4}>
            <FormSectionTitle required={required} title={title} subTitle={subTitle} variant={'h4'} />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={12} md={7}>
            {rightContent}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </>
    )
  }

  return (
    <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
      <QBFormSection
        title={'Create Invoices'}
        rightContent={
          <>
            <Grid item xs={12} className={classes.marginBottom}>
              <EventSubscriptionType
                isAdminIntegrations={isAdminIntegrations}
                disableRadio={isSettingsIntegration}
                name={'quote-accepted' as EventType}
                event={providerConfig.events['quote-accepted']}
                onChange={handleEventChange}
                label={`When ${objectLabels.quote.plural} are accepted`}
              />
              <Collapse show={providerConfig.events['quote-accepted']?.enabled}>
                <Grid item classes={{ root: classes.radioWrapper }}>
                  <RadioGroup
                    className={classes.radioGroup}
                    name={'acceptedAutomaticQuoteStyle'}
                    value={providerConfig.config.acceptedAutomaticQuoteStyle?.value ?? null}
                    onChange={(ev) => {
                      if (handleItemSelection) {
                        handleItemSelection(ev)
                      }
                    }}
                  >
                    <FormControlLabel
                      label={`Full Invoice`}
                      value={'full'}
                      disabled={!enabled}
                      control={<Radio color={'primary'} />}
                    />
                    <FormControlLabel
                      label={`Deposit Invoice`}
                      value={'deposit'}
                      disabled={!enabled}
                      control={<Radio color={'primary'} />}
                    />
                  </RadioGroup>
                  {!providerConfig?.config?.acceptedAutomaticQuoteStyle?.value && (
                    <Typography style={{ color: 'red' }} variant={'subtitle2'}>
                      Must select option.
                    </Typography>
                  )}
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <EventSubscriptionType
                isAdminIntegrations={isAdminIntegrations}
                disableRadio={isSettingsIntegration}
                name={'invoice-created' as EventType}
                event={providerConfig.events['invoice-created']}
                onChange={handleEventChange}
                label={`When Invoices are created`}
              />
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <EventSubscriptionType
                isAdminIntegrations={isAdminIntegrations}
                disableRadio={isSettingsIntegration}
                name={'quote-status-changed' as EventType}
                event={providerConfig.events['quote-status-changed']}
                onChange={handleEventChange}
                label={`When Invoice is changed to paid status`}
              />
            </Grid>
          </>
        }
      />
      <QBFormSection
        title={'Canadian Quickbooks Online'}
        subTitle={'Toggle this on if using the canadian version of Quickbooks online.'}
        rightContent={
          <FormControlLabel
            label={'Canadian Quickbooks User'}
            control={
              <Checkbox
                checked={!!providerConfig?.config?.useCanadianConfig}
                onChange={() => {
                  onChange(providerName, {
                    ...providerConfig,
                    config: { ...config, useCanadianConfig: !providerConfig?.config?.useCanadianConfig }
                  })
                }}
              />
            }
          />
        }
      />
      <Collapse show={providerConfig?.config?.useCanadianConfig}>
        <QBFormSection
          title={'Tax Rate'}
          subTitle={'PaintScout must use a Tax Rate that has already been defined in Quickbooks.'}
          required={providerConfig?.config?.useCanadianConfig && !providerConfig?.config?.customTaxRate?.value}
          rightContent={
            <Typography className={classes.itemSelection}>
              Use tax rate: <ItemSelection name={'customTaxRate'} items={taxRates} />
            </Typography>
          }
        />
      </Collapse>
      <QBFormSection
        title={'Additional Work Invoices'}
        subTitle={`Paintscout can create an invoice in Quickbooks when additional work accepted. Can include all ${objectLabels.quote.value} items, or only the additionally accepted ${objectLabels.quote.value} items on it.`}
        rightContent={
          <>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-additional-work-accepted' as EventType}
              event={providerConfig.events['quote-additional-work-accepted']}
              onChange={handleEventChange}
              label={`Create new Quickbooks invoice when additional work is accepted.`}
            />
            <Collapse show={providerConfig.events['quote-additional-work-accepted']?.enabled}>
              <Grid item classes={{ root: classes.radioWrapper }}>
                <RadioGroup
                  className={classes.radioGroup}
                  name={'invoiceAdditionalItemStyle'}
                  value={providerConfig.config.invoiceAdditionalItemStyle?.value ?? null}
                  onChange={(ev) => {
                    if (handleItemSelection) {
                      handleItemSelection(ev)
                    }
                  }}
                >
                  <FormControlLabel
                    label={`All Items`}
                    value={'allItems'}
                    disabled={!enabled}
                    control={<Radio color={'primary'} />}
                  />
                  <FormControlLabel
                    label={`Additional Items only`}
                    value={'additionalItems'}
                    disabled={!enabled}
                    control={<Radio color={'primary'} />}
                  />
                </RadioGroup>
                {!providerConfig?.config?.invoiceAdditionalItemStyle?.value && (
                  <Typography style={{ color: 'red' }} variant={'subtitle2'}>
                    Must select option.
                  </Typography>
                )}
              </Grid>
            </Collapse>
          </>
        }
      />
      <QBFormSection
        title={'Item Handling'}
        subTitle={`PaintScout can create a single "total item" on the invoice with the ${objectLabels.quote.value} total, or create an item for each Area/Line Item on the ${objectLabels.quote.value}.`}
        required={!providerConfig?.config.invoiceItemStyle?.value}
        rightContent={
          <>
            <Typography className={classes.itemSelection}>
              Invoice item style: <ItemSelection name={'invoiceItemStyle'} items={invoiceItemOptions} />
            </Typography>
            <Collapse show={providerConfig.config?.invoiceItemStyle?.value === 'total'}>
              <FormControlLabel
                label={"Don't create descriptions (just total line item)"}
                control={
                  <Checkbox
                    checked={!!providerConfig?.config?.dontCreateItems}
                    onChange={() => {
                      onChange(providerName, {
                        ...providerConfig,
                        config: { ...config, dontCreateItems: !providerConfig?.config?.dontCreateItems }
                      })
                    }}
                  />
                }
              />
            </Collapse>
          </>
        }
      />
      <Collapse show={providerConfig.config?.invoiceItemStyle?.value === 'total'}>
        <QBFormSection
          title={'Total Item Value'}
          subTitle={'Total item will use subtotal including discounts by default, but can use after tax total also.'}
          rightContent={
            <FormControlLabel
              label={'Use after tax total'}
              control={
                <Checkbox
                  checked={!!providerConfig?.config?.useAfterTaxTotal}
                  onChange={() => {
                    onChange(providerName, {
                      ...providerConfig,
                      config: { ...config, useAfterTaxTotal: !providerConfig?.config?.useAfterTaxTotal }
                    })
                  }}
                />
              }
            />
          }
        />
      </Collapse>
      <QBFormSection
        title={'Item Type'}
        subTitle={'You can select a type from Quickbooks for the invoice item(s) to be categorized as.'}
        required={
          !providerConfig?.config.totalServiceItem?.value ||
          !serviceItems.filter((item) => item.value === providerConfig.config?.totalServiceItem?.value)[0]
        }
        rightContent={
          <div className={classes.itemSelection}>
            <Typography className={classes.itemSelectText}>Use item type:</Typography>
            <DropdownItemSelection searchable name={'totalServiceItem'} items={serviceItems} />
          </div>
        }
      />
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormSectionTitle
            title={'Deposit Invoices'}
            subTitle={'PaintScout can create a deposit invoice with a portion of the total.'}
            variant={'h4'}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={12} md={7}>
          <InputField
            fullWidth
            label={'Deposit Percentage'}
            type="number"
            value={config.depositPercentage}
            onChange={(e) =>
              onChange(providerName, {
                ...providerConfig,
                config: { ...config, depositPercentage: e.target.value }
              })
            }
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Collapse show={options.options?.features?.surcharge}>
        <QBFormSection
          title={'Include Surcharge'}
          subTitle={'Toggle this on to include surcharge amounts in payment and desposit totals in Quickbooks.'}
          rightContent={
            <FormControlLabel
              label={'Include Surcharge'}
              control={
                <Checkbox
                  checked={!!providerConfig?.config?.includeSurcharge}
                  onChange={() => {
                    props.onChange(providerName, {
                      ...providerConfig,
                      config: { ...config, includeSurcharge: !providerConfig?.config?.includeSurcharge }
                    })
                  }}
                />
              }
            />
          }
        />
      </Collapse>
      <QBFormSection
        title={'Payment Setting Overrides'}
        subTitle={
          'By default Invoices created will use the default payment settings from QBO for CC and ACH payments. Can override to toggle these off here.'
        }
        rightContent={
          <>
            <FormControlLabel
              label={'Disable CC Payments on created invoices'}
              control={
                <Checkbox
                  checked={!!providerConfig?.config?.disableCCPayment}
                  onChange={() => {
                    onChange(providerName, {
                      ...providerConfig,
                      config: { ...config, disableCCPayment: !providerConfig?.config?.disableCCPayment }
                    })
                  }}
                />
              }
            />
            <FormControlLabel
              label={'Disable ACH Payments on created invoices'}
              control={
                <Checkbox
                  checked={!!providerConfig?.config?.disableACHPayment}
                  onChange={() => {
                    onChange(providerName, {
                      ...providerConfig,
                      config: { ...config, disableACHPayment: !providerConfig?.config?.disableACHPayment }
                    })
                  }}
                />
              }
            />
          </>
        }
      />
    </FormSection>
  )
}
