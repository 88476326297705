import React from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { Route } from 'react-router-dom'
import EditUser from './EditUser'
import SearchUsers from './SearchUsers'

export default function UsersIndex({ match }: RouteComponentProps) {
  return (
    <>
      <Route path={`${match.url}/`} exact component={SearchUsers} />
      <Route path={`${match.url}/:id/:section?`} component={EditUser} />
    </>
  )
}
