import React, { useEffect, useState } from 'react'

import type { DialogProps } from '@ui/paintscout'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputField,
  useDialogs
} from '@ui/paintscout'
import { Typography } from '@material-ui/core'
import type { Octokit } from '@octokit/core'
import { Spinner } from '@ui/core'
import { parseNumber } from '@paintscout/util/calculator'
import { useSnackbar } from 'notistack'

export interface LinkGithubIssueDialogProps extends DialogProps {
  octokit: Octokit
  debugInfo?: string
  onConfirm: (issue: any) => void
}

export default function LinkGithubIssueDialog({
  octokit,
  debugInfo,
  onConfirm,
  ...dialogProps
}: LinkGithubIssueDialogProps) {
  const { dismissDialog } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()

  const [issueNumber, setIssueNumber] = React.useState<number>(null)
  const [body, setBody] = React.useState<string>('')
  const [loading, setLoading] = useState(false)
  const [issue, setIssue] = useState(null)
  const [error, setError] = useState<boolean>(false)

  const handleIssueNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssueNumber(parseInt(event.target.value))
  }

  const handleBodyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBody(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (!issueNumber) {
        return
      }
      setLoading(true)

      await octokit
        .request('GET /repos/{owner}/{repo}/issues/{issue_number}', {
          owner: 'PaintScout',
          repo: 'PaintScout',
          issue_number: parseNumber(issueNumber),
          headers: {
            'X-GitHub-Api-Version': '2022-11-28'
          }
        })
        .catch((_err) => {
          setError(true)
          setIssue(null)
        })
        .then((response) => {
          if (!response) {
            return
          }
          setError(false)
          setIssue(response.data)
        })

      setLoading(false)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [issueNumber, 500])

  const handleSubmit = async () => {
    setLoading(true)
    await octokit
      .request('POST /repos/{owner}/{repo}/issues/{issue_number}/comments', {
        owner: 'PaintScout',
        repo: 'PaintScout',
        issue_number: parseNumber(issueNumber),
        body: "Bleep bloop! I'm a bot! 🤖\n\n" + (debugInfo ? body + '\n\n```\n' + debugInfo : body),
        headers: {
          'X-GitHub-Api-Version': '2022-11-28'
        }
      })
      .then((_response) => {
        enqueueSnackbar('Issue Linked', { variant: 'success' })
        onConfirm(issue)
      })
      .catch((e) => {
        console.error(e)
        enqueueSnackbar('Unable to link issue', { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog {...dialogProps} maxWidth={'md'} fullWidth={true}>
      <DialogTitle>Link Existing Issue</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              fullWidth
              label="Issue Number"
              onChange={handleIssueNumberChange}
              endAdornment={loading ? <Spinner size={20} /> : null}
            />
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="warning">No issue found</Alert>
            </Grid>
          )}
          {!error && issue && (
            <Grid item xs={12}>
              <Alert severity="success">
                <Typography>
                  {/* <a onClick={handleRedirect}>#{issue.number}</a> - {issue.title} */}
                  <a target="_blank" href={issue.html_url} rel="noreferrer">
                    #{issue.number}
                  </a>{' '}
                  - {issue.title}
                </Typography>
              </Alert>
              <InputField
                fullWidth
                label="Comment"
                onChange={handleBodyChange}
                value={body}
                multiline
                rows={4}
                helperText={debugInfo ? 'Note: the debug info will automatically append to the issue body.' : null}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button onClick={dismissDialog} variant="text">
            Cancel
          </Button>
        }
      >
        <Button type="submit" variant={'contained'} disabled={!issue || loading} onClick={handleSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
