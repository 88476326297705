import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, EventSubscriptionDetailsProps>(
  (theme) => ({
    root: {},
    details: {
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(2)
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.5
    },
    detailsRow: {
      display: 'flex',
      alignItems: 'center'
    }
  }),
  { name: 'EventSubscriptionDetails' }
)

export interface EventSubscriptionDetailsProps {
  classes?: StyleClasses<typeof useStyles>
  children?: React.ReactNode
  rows?: React.ReactNode[]
  disabled?: boolean
}

export function EventSubscriptionDetails(props: {
  children?: React.ReactNode
  rows?: React.ReactNode[]
  disabled?: boolean
}) {
  const { disabled, rows } = props
  const classes = useStyles(props)
  return (
    <div className={classnames({ [classes.disabled]: disabled, [classes.details]: true })}>
      {rows.map((row, index) => (
        <div key={index} className={classes.detailsRow}>
          {row}
        </div>
      ))}
    </div>
  )
}

export default EventSubscriptionDetails
