import React, { useState } from 'react'
import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/paintscout'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  EditableDialogTitle,
  Grid,
  Hidden,
  Tab,
  Tabs,
  useClientOptions
} from '@ui/paintscout'
import type { PresentationSection, QuoteDocument } from 'paintscout'
import { getObjectLabels } from '@paintscout/util/builder'

import { QuoteContextProvider } from '../../../context'
import PresentationSectionFormik from '../../PresentationSectionFormik'
import sections from '../../sections'
import AdvancedOptions from './AdvancedOptions'
import ImportExportOptions from './ImportExportOptions'
import BackgroundOptions from './BackgroundOptions'
import PresentationSectionPreview from './PresentationSectionPreview'
import EmptySectionAlerts from './EmptySectionAlerts'

import isEqual from 'lodash/isEqual'

export interface EditPresentationSectionDialogProps extends Omit<DialogProps, 'message' | 'title' | 'loading'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  isTemplate?: boolean
  isCustom?: boolean
  section: PresentationSection
  isSettings?: boolean
  showAdvanced?: boolean
  quote?: QuoteDocument
  onConfirm?: (section: PresentationSection, isDirty?: boolean) => void
  onCancel?: () => void
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    alert: {
      margin: theme.spacing(2)
    },
    paper: {
      // height: '80vh',
      maxWidth: '1250px'
    },
    dialogContent: {
      padding: 0,
      display: 'flex',
      '& #menu-bar': {
        top: 0
      }
    },
    formWrapper: {
      width: '50%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xl')]: {
        width: '100%'
      }
    },
    preview: {
      width: '50%',
      padding: theme.spacing(2),
      borderLeft: `1px solid #ddd`
    },
    tabs: {
      marginBottom: theme.spacing(3)
    },
    scaled: {
      display: 'flex',
      alignItems: 'center'
    },
    scalerWrapper: {
      paddingBottom: theme.spacing(3)
    },
    helpWrapper: {
      marginBottom: theme.spacing(2)
    }
  })

function EditPresentationSectionDialog({
  isCustom,
  isTemplate,
  open,
  classes,
  onConfirm,
  onCancel,
  showAdvanced,
  isSettings,
  quote,
  ...props
}: EditPresentationSectionDialogProps) {
  // const [section, setSection] = useState<PresentationSection>(props.section)

  const section = props.section
  const [active, setActive] = useState<boolean>(section.active ?? true)

  const [tabValue, setTabValue] = useState('Content')
  const [rightTabValue, setRightTabValue] = useState('Preview')

  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const FormComponent = sections?.[section.type]?.Form
  const HelpComponent = sections?.[section.type]?.Help

  const tabs = ['Content', 'Style', 'Advanced', 'Import/Export'].filter((tab) => showAdvanced || tab === 'Content')
  const rightTabs = ['Preview', HelpComponent ? 'Help' : null].filter(Boolean)

  return (
    <QuoteContextProvider quote={quote} tableView={'area'}>
      <PresentationSectionFormik
        section={section}
        onSubmit={(values, _actions) => {
          const updatedSection = { ...values.section, active }
          const isDirty = !isEqual(updatedSection, section)
          if (isDirty) {
            // Set presentation as custom if we have updated section.
            updatedSection.custom = true
          }
          onConfirm(updatedSection, isDirty)
        }}
      >
        {({ submitForm }) => (
          <Dialog open={open} fullWidth={true} maxWidth={'lg'} classes={{ paper: classes.paper }} {...props}>
            <EditableDialogTitle
              title={`Edit ${section.label} Section`}
              onToggle={handleActiveToggle}
              toggleValue={active}
              toggleLabel="Active"
            />
            {isTemplate && !isCustom && (
              <Grid container spacing={1}>
                <Grid item xs={12} classes={{ root: classes.alert }}>
                  <Alert severity={'warning'}>
                    Customizing Presentation sections will cause new {objectLabels.quote.plural.toLowerCase()} to use
                    this customized Presentation.
                  </Alert>
                </Grid>
              </Grid>
            )}
            {isTemplate && isCustom && (
              <Grid container spacing={1}>
                <Grid item xs={12} classes={{ root: classes.alert }}>
                  <Alert severity={'warning'}>
                    This Presentation has been customized from the selected Presentation template. New{' '}
                    {objectLabels.quote.plural.toLowerCase()} will use this customized Presentation.
                  </Alert>
                </Grid>
              </Grid>
            )}
            <DialogContent classes={{ root: classes.dialogContent }}>
              <Hidden lgDown={true}>
                <div className={classes.formWrapper}>
                  <Tabs
                    classes={{ root: classes.tabs }}
                    value={tabValue}
                    onChange={(ev, v) => {
                      setTabValue(v)
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab key={tab} value={tab} label={tab} />
                    ))}
                  </Tabs>
                  <SelectedTab selectedTab={tabValue} />
                </div>

                <div className={classes.preview}>
                  <Tabs
                    classes={{ root: classes.tabs }}
                    value={rightTabValue}
                    onChange={(ev, v) => {
                      setRightTabValue(v)
                    }}
                  >
                    {rightTabs.map((tab) => (
                      <Tab key={tab} value={tab} label={tab} />
                    ))}
                  </Tabs>
                  <SelectedTab selectedTab={rightTabValue} />
                </div>
              </Hidden>
              <Hidden xlUp={true}>
                <div className={classes.formWrapper}>
                  <Tabs
                    classes={{ root: classes.tabs }}
                    value={tabValue}
                    onChange={(ev, v) => {
                      setTabValue(v)
                    }}
                  >
                    {[...tabs, ...rightTabs].map((tab) => (
                      <Tab key={tab} value={tab} label={tab} />
                    ))}
                  </Tabs>
                  <SelectedTab selectedTab={tabValue} />
                </div>
              </Hidden>
            </DialogContent>
            <DialogActions
              leftButton={
                <Button variant={'text'} onClick={onCancel}>
                  Cancel
                </Button>
              }
            >
              <Button onClick={submitForm} variant={'contained'}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </PresentationSectionFormik>
    </QuoteContextProvider>
  )

  function handleActiveToggle(_event: React.ChangeEvent<HTMLElement>, _checked: boolean) {
    setActive(!active)
  }

  function SelectedTab({ selectedTab }) {
    switch (selectedTab) {
      case 'Content':
        return <FormComponent isSettings={isSettings} />

      case 'Style':
        return <BackgroundOptions />
      case 'Advanced':
        return <AdvancedOptions />
      case 'Import/Export':
        return <ImportExportOptions />
      case 'Preview':
        return (
          <>
            <EmptySectionAlerts />
            <div className={classes.scalerWrapper}>
              <PresentationSectionPreview editFromDialog={true} />
            </div>
          </>
        )
      case 'Help':
        return (
          <>
            <div className={classes.helpWrapper}>
              <HelpComponent section={section} />
            </div>
            <div className={classes.scalerWrapper}>
              <PresentationSectionPreview showHelp={true} isSettings={true} />
            </div>
          </>
        )
      default:
        return null
    }
  }
}

export default withStyles(styles)(EditPresentationSectionDialog)
