/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { TermsOption } from '../../index'
import { getQuoteType } from '../../quote/util'
import { template, createQuoteContext } from '../../../templater'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

export function getTermsOptions(args: {
  options: OptionsDocument
  compileForQuote?: QuoteDocument
  quoteType?: string
  inactive?: boolean
}): TermsOption[] {
  const { options, inactive, compileForQuote } = args
  const { terms } = options.options

  const quoteType = getQuoteType(args)

  const order =
    terms.quoteTypeOrder && terms.quoteTypeOrder[quoteType]
      ? uniq([...terms.quoteTypeOrder[quoteType], ...terms.order])
      : terms.order

  return order
    .map((orderItem) => {
      const item = terms.values[orderItem]
      if (!item) {
        return null
      }

      let quoteTypes
      if (item.quoteTypes) {
        quoteTypes = item.quoteTypes
      } else {
        quoteTypes = [get(item, 'int_ext.value')]
      }

      const option: TermsOption = {
        name: orderItem,
        quoteTypes,
        label: item.label,
        disabled: item.disabled,
        content: item.content,
        showToc: typeof item.showToc !== 'undefined' ? item.showToc : true
      }

      if (compileForQuote) {
        option.content = template({
          text: option.content,
          context: createQuoteContext({ quote: compileForQuote, options })
        })
      }

      if (!inactive && item.active !== true && typeof item.active !== 'undefined') {
        return null
      } else if (inactive) {
        option.active = typeof item.active === 'undefined' ? true : !!item.active
      }

      if (!quoteType || quoteType === 'all' || quoteTypes.indexOf('all') > -1 || quoteTypes.indexOf(quoteType) > -1) {
        return option
      }

      return null
    })
    .filter((item) => item)
}
