import type { Theme } from '@material-ui/core'
import { Grid, makeStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import type { FormikInputFieldProps } from '@ui/paintscout'
import { DropdownSelect, Switch, Tooltip } from '@ui/paintscout'
import {
  CheckboxField,
  FormikInputField,
  FormikRadioButtons,
  FormSectionTitle,
  PageHelpMessage,
  InputLabel,
  LearnMoreLink,
  NewFormSection as FormSection
} from '@ui/paintscout'
import type { LabelsOption } from '@paintscout/util/builder'
import get from 'lodash/get'
import { getFeatures, getObjectLabels } from '@paintscout/util/builder'
import { Form, FastField, Field, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useEffect, useMemo } from 'react'
import { Divider } from '@ui/core'
import SettingsPage from '@ui/paintscout/src/SettingsPage'
import TaxOptions from './TaxOptions'
import DiscountOptions from './DiscountOptions'
import type { DropdownSelectOption } from '@ui/paintscout/src/DropdownSelect'

const useStyles = makeStyles<Theme, { isDefault?: boolean }>((theme) => ({
  root: {},
  pdfSubtext: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  defaultRadioDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  visibleRadioDiv: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: `${-theme.spacing(1.5)}px !important`,
    marginTop: -theme.spacing(),
    padding: theme.spacing()
  }
}))

export interface QuoteOptionsProps {
  showAdvanced?: boolean
  showOnlyAdvanced?: boolean
}

function QuoteOptions({ showAdvanced, showOnlyAdvanced }: QuoteOptionsProps) {
  const classes = useStyles({})
  const { values, errors, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const isSuperadmin = showOnlyAdvanced || showAdvanced
  const { options } = values
  const objectLabels = getObjectLabels({ options })
  const features = getFeatures({ options })

  const roundingOptions = [
    { name: 'half', label: '0.5 hours' },
    { name: 'none', label: "Don't round hours" },
    { name: 'quarter', label: '0.25 hours' },
    { name: 'hour', label: '1 hour' }
  ]

  const productRoundingOptions = [
    { name: 'none', label: 'No rounding' },
    { name: 'round', label: 'Round to nearest unit' },
    { name: 'up', label: 'Always round upwards' }
  ]

  const priceRoundingOptions = [
    { name: 'none', label: 'No rounding' },
    { name: 'round', label: 'Round to nearest dollar' },
    { name: 'up', label: 'Always round upwards' }
  ]

  const initialSequenceValues = useMemo(
    () => ({
      quote: values.options.options.quotes?.sequence?.startAt?.quote ?? 0,
      invoice: values.options.options.quotes?.sequence?.startAt?.invoice ?? 0
    }),
    // eslint-disable-next-line
    [options._rev]
  )

  // Keep both expiry intervals in-sync
  useEffect(() => {
    const days = options.options?.quotes?.customerViewExpiry?.days || 0
    const months = options.options?.quotes?.customerViewExpiry?.months || 0
    const expiryInterval = days * (1000 * 60 * 60 * 24) + months * (1000 * 60 * 60 * 24 * 30)
    if (options.options?.quotes?.customerViewExpiry?.enabled) {
      options.options.quotes.customerViewExpiry.interval = expiryInterval
    }
  }, [options.options?.quotes?.customerViewExpiry?.days, options.options?.quotes?.customerViewExpiry?.months])
  useEffect(() => {
    const days = options.options?.quotes?.customerInvoiceExpiry?.days || 0
    const months = options.options?.quotes?.customerInvoiceExpiry?.months || 0
    const expiryInterval = days * (1000 * 60 * 60 * 24) + months * (1000 * 60 * 60 * 24 * 30)
    if (options.options?.quotes?.customerInvoiceExpiry?.enabled) {
      options.options.quotes.customerInvoiceExpiry.interval = expiryInterval
    }
  }, [options.options?.quotes?.customerInvoiceExpiry?.days, options.options?.quotes?.customerInvoiceExpiry?.months])

  if (showOnlyAdvanced) {
    return (
      <div className={classes.root}>
        <PageHelpMessage
          path={'admin-settings-quote-settings'}
          closedContent={<FormSectionTitle title={`${objectLabels.quote.value} Settings`} variant="h2" />}
        />
        <AdminQuoteOptions objectLabels={objectLabels} options={options} />
      </div>
    )
  }

  return (
    <SettingsPage
      title={`${objectLabels.quote.value} Settings`}
      callout={{
        path: 'settings-quote-settings',
        content: `Customize your ${objectLabels.quote.value.toLowerCase()} settings. Changes on this page will not affect
      or re-calculate existing ${objectLabels.quote.plural.toLowerCase()}.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/8276825-estimate-settings'
      }}
    >
      <Form className={classes.root}>
        <FormSection>
          <FormSectionTitle
            title={'Hourly Rate'}
            subTitle={`The rate you charge your customer per hour.`}
            variant={'h3'}
            interactiveToolTip
            toolTip={
              <>
                <Typography variant={'body2'}>
                  The hourly rate is comprised of your labor costs, overhead expenses, profit margin, and if applicable,
                  material costs.
                </Typography>
                <LearnMoreLink link="http://help.paintscout.com/en/articles/8137653-how-to-calculate-your-hourly-rate" />
              </>
            }
          />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <FastField
                component={FormikInputField}
                label={`Hourly Rate, ${objectLabels.currency.symbol}`}
                name="options.options.hourlyRate.value"
                fullWidth
                format={'rate'}
                required
                error={errors.options?.options?.hourlyRate?.value}
              />
            </Grid>
          </Grid>
        </FormSection>

        <FormSection>
          <FormSectionTitle
            title={'Tax Rates'}
            subTitle={`Add your list of tax rates and select one or more as a default to appear on new ${objectLabels.quote.plural.toLowerCase()} made.`}
            variant={'h3'}
          />
          <TaxOptions objectLabels={objectLabels} showAdvanced={showAdvanced} />
        </FormSection>
        <FormSection>
          <FormSectionTitle
            title={'Discounts'}
            subTitle={`Add your list of discount rates. You can select one as a default to appear on new ${objectLabels.quote.plural.toLowerCase()} made.`}
            variant={'h3'}
          />
          <DiscountOptions />
        </FormSection>
        <FormSection>
          <FormSectionTitle title={`Rounding Style/Accuracy`} variant={'h3'} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl>
                <Field
                  component={FormikRadioButtons}
                  labelVariant={'h4'}
                  name="options.options.quotes.roundHours"
                  options={roundingOptions}
                  label="Hours"
                  sublabel={`Adjust how hours are rounded on your ${objectLabels.quote.plural.toLowerCase()}.`}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Field
                  component={FormikRadioButtons}
                  labelVariant={'h4'}
                  badgeTop={'30%'}
                  badgeRight={'65px'}
                  name="options.options.quotes.roundProducts"
                  options={productRoundingOptions}
                  label="Products and Materials"
                  sublabel={`Adjust how products are rounded. This rounding is only applied to the final products calculated on the work order to avoid compound rounding errors.`}
                  interactiveToolTip
                  toolTip={
                    <>
                      <Typography variant={'body2'}>
                        Most users will have this option set to no rounding or to the nearest unit.
                      </Typography>
                      <LearnMoreLink
                        label={'Learn More'}
                        link="http://help.paintscout.com/en/articles/6063026-product-rounding#h_4edb291503"
                      />
                    </>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Field
                  component={FormikRadioButtons}
                  labelVariant={'h4'}
                  name="options.options.quotes.roundPrices"
                  options={priceRoundingOptions}
                  label={`Price`}
                  sublabel={`Adjust how the price of areas and line items are rounded on your ${objectLabels.quote.plural.toLowerCase()}.`}
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormSection>

        {/* <FormSection>
        <FormSectionTitle title={'Product Rounding'} variant={'h3'} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl>
              <Field
                component={FormikRadioButtons}
                name="options.options.quotes.roundProducts"
                options={productRoundingOptions}
                label="Rounding Style for products and materials"
                sublabel={`* Only applied to final product description on Work Order to avoid compound rounding errors. Only new ${objectLabels.quote.plural} will be affected.`}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FormSection> */}

        {/* <FormSection>
        <FormSectionTitle title={'Price Rounding'} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <Field
                component={FormikRadioButtons}
                name="options.options.quotes.roundPrices"
                options={priceRoundingOptions}
                label={`Rounding Style for ${objectLabels.substrate.value.toLowerCase()} and line items`}
                sublabel={`Only new ${objectLabels.quote.plural} will be affected.`}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FormSection> */}

        {/* <FormSection>
        <FormSectionTitle title={`${objectLabels.quote.value} Total Dimensions`} variant={'h3'} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.useAllAreasForTotalDimensions"
              label={`Use All Areas for Total Dimensions.`}
              sublabel={
                "By default, only areas that have work on a particular substrate will be included towards that substrate's total."
              }
            />
          </Grid>
        </Grid>
      </FormSection> */}
        <FormSection>
          <FormSectionTitle
            title={'Customer Accepted Options'}
            subTitle={
              <span>
                Changes made to this setting <strong>will</strong> affect existing{' '}
                {objectLabels.quote.plural.toLowerCase()}.
              </span>
            }
            variant={'h3'}
          />
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.visibleRadioDiv}>
              <Switch
                name="options.options.optionsApproval.enabled"
                checked={options.options.optionsApproval?.enabled}
                onChange={(_ev) => {
                  setFieldValue('options.options.optionsApproval.enabled', !options.options.optionsApproval?.enabled)
                }}
              />
              <Typography variant={'body1'}>
                Allow customers the ability to add and remove options from their{' '}
                {objectLabels.quote.value.toLowerCase()}.
              </Typography>
            </Grid>
          </Grid>
        </FormSection>

        <FormSection>
          <FormSectionTitle title={`Client Visible Expiry`} variant={'h3'} />
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.visibleRadioDiv}>
              <Switch
                name="options.options.quotes.customerViewExpiry.enabled"
                checked={options.options.quotes.customerViewExpiry?.enabled}
                // label={`${objectLabels.quote.plural} expire, becoming un-viewable by clients after a chosen time.`}
                onClick={(_ev) => {
                  // If not set yet it defaults so can be saved, as they are required fields
                  if (!options.options.quotes.customerViewExpiry?.months) {
                    setFieldValue('options.options.quotes.customerViewExpiry.months', 0)
                  }
                  if (!options.options.quotes.customerViewExpiry?.days) {
                    setFieldValue('options.options.quotes.customerViewExpiry.days', 1)
                  }
                  setFieldValue(
                    'options.options.quotes.customerViewExpiry.enabled',
                    !options.options.quotes.customerViewExpiry?.enabled
                  )
                }}
              />
              <Typography variant={'body1'}>
                {objectLabels.quote.plural} become inaccessible to clients after a set period, calculated from when they
                are sent.
              </Typography>
            </Grid>
            {options.options?.quotes?.customerViewExpiry?.enabled && (
              <>
                {/* <Grid item xs={8} className={`${classes.defaultRadioDiv}`} style={{justifyContent: 'space-between'}}> */}
                <Grid item xs={6} sm={4}>
                  <FastField
                    component={FormikInputField}
                    name="options.options.quotes.customerViewExpiry.months"
                    format={'number'}
                    label={'Months:'}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    component={FormikInputField}
                    name="options.options.quotes.customerViewExpiry.days"
                    format={'number'}
                    label={'Days:'}
                    fullWidth
                    required
                    error={errors.options?.options?.quotes?.customerViewExpiry?.days}
                  />
                </Grid>
                {/* </Grid> */}
              </>
            )}
          </Grid>
        </FormSection>

        {(isSuperadmin || get(features, 'quotes.customIDSequence', false)) && (
          <FormSection>
            <FormSectionTitle
              title={`${objectLabels.quote.value} IDs`}
              subTitle={`Adjust where new ${objectLabels.quote.value} IDs begin.`}
              variant={'h3'}
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Field
                  component={SequenceInputField}
                  defaultValue={initialSequenceValues.quote}
                  min={isSuperadmin ? 0 : initialSequenceValues.quote}
                  max={10000000}
                  type="number"
                  name="options.options.quotes.sequence.startAt.quote"
                  label={`Start ${objectLabels.quote.value} IDs at`}
                  fullWidth
                />
              </Grid>
              {features?.invoices?.enabled && (
                <Grid item xs={12}>
                  <Field
                    component={FormikInputField}
                    type="number"
                    defaultValue={initialSequenceValues.invoice}
                    inputProps={{}}
                    min={isSuperadmin ? 0 : initialSequenceValues.invoice}
                    max={10000000}
                    name="options.options.quotes.sequence.startAt.invoice"
                    label={`Start Invoice IDs at`}
                    fullWidth
                  />
                  <InputLabel shrink={true} variant="sublabel">
                    * Due to the possibility of duplicates you may only increase these numbers. If you need to decrease
                    them please contact support.
                  </InputLabel>
                </Grid>
              )}
            </Grid>
          </FormSection>
        )}
        {showAdvanced && <AdminQuoteOptions objectLabels={objectLabels} options={options} />}
      </Form>
    </SettingsPage>
  )
}

/**
 * Input used for sequences to enforce the min/max range on the inputs
 */
const SequenceInputField: React.FC<FormikInputFieldProps & { min: number; max: number }> = ({ min, max, ...props }) => {
  const onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    // clamp value on blur
    const sanitized = Math.min(Math.max(parseInt(ev.currentTarget.value, 10), min), max)

    props.form.setFieldValue(props.field.name, sanitized)

    if (props.field.onBlur) {
      props.field.onBlur(ev)
    }
  }
  return <FormikInputField {...props} onBlur={onBlur} inputProps={{ ...(props.inputProps ?? {}), min, max }} />
}

interface AdminQuoteOptionProps {
  objectLabels: LabelsOption
  options: OptionsDocument
}

const AdminQuoteOptions = ({ objectLabels, options }: AdminQuoteOptionProps) => {
  const classes = useStyles({})
  const features = getFeatures({ options })
  const { setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()

  const workOrderViewOptions = [
    { name: 'customer', label: 'Customer/Price' },
    { name: 'crew', label: 'Crew/Hours' }
  ]
  const pdfFormatLabel =
    'Allowable templating tags: {{fileType}}, {{clientFirstName}}, {{clientLastName}}, {{quoteDate}}, {{quoteNumber}}'

  const areaItemDisplayOptions = [
    {
      label: 'Default',
      value: 'default'
    },
    {
      label: `Show each area ${objectLabels.substrate.value.toLowerCase()} as a row with price`,
      value: 'showDetailedBreakdown',
      screenshot:
        'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/showDetailedBreakdown.png'
    },
    {
      label: `Show each area ${objectLabels.substrate.value.toLowerCase()} as a row without price`,
      value: 'stackSubstrates',
      screenshot:
        'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/stackSubstrates.png'
    },
    {
      label: 'Split area price into materials and labour',
      value: 'splitAreaPrice',
      screenshot:
        'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/splitAreaPrice.png'
    }
  ]

  const handleAreaItemDisplayChange = (option?: string) => {
    if (option && option !== 'default') {
      setFieldValue(`options.options.quotes.${option}`, true)
    }
    areaItemDisplayOptions
      .filter((opt) => opt.value !== option)
      .forEach((opt) => {
        setFieldValue(`options.options.quotes.${opt.value}`, false)
      })
  }

  // we're going to start with this being superadmin-only...
  // and as such, we probably don't need to worry much about when it should be shown.
  // in fact, we may want to allow our people to turn it on for non-payments people.
  // &&
  // (get(features, 'integrations.enabled', false) &&
  // (get(features, 'integrations.providers.stripe') || get(features, 'integrations.providers.wisetack')))

  return (
    <>
      <FormSection>
        <FormSectionTitle
          title={`${objectLabels.quote.value} Rate Customization`}
          subTitle={`Customization allowed on individual ${objectLabels.quote.plural.toLowerCase()}.`}
          variant={'h3'}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.hourlyRate.allow_custom"
              label={`Allow Hourly Rate to be customized on individual ${objectLabels.quote.plural.toLowerCase()}.`}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <CheckboxField
              name="options.options.taxRate.allow_custom"
              label={`Allow Tax Rates to be customized on individual ${objectLabels.quote.plural.toLowerCase()}.`}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.discountRate.allow_custom"
              label={`Allow Discount to be customized on individual ${objectLabels.quote.plural.toLowerCase()}.`}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle title={'Product Rounding'} variant={'h3'} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <CheckboxField
                name="options.options.quotes.individualProductRounding"
                label={'Round Area & Line Item products individually'}
                sublabel={`By default, the sum of all products are added then rounded, only affecting work orders. This setting will round the individual products first, then add them up. This will affect prices on the ${objectLabels.quote.value.toLowerCase()}, not just work orders.`}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle title={`${objectLabels.quote.value} Totals`} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.splitSubtotal"
              label={`Split ${objectLabels.quote.value} Sub Totals into labor and materials.`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            name="options.options.subTotal.allow_custom"
            label={`Allow ${objectLabels.quote.value} Sub Totals to be customized on individual ${objectLabels.quote.plural}.`}
          />
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle title={`Manual Payments`} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.allowQuotePayments"
              label={`Allow adding manual payments on ${objectLabels.quote.plural}.`}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle title={`Additional Work`} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.allowQuoteAdditionalWork"
              label={`Allow adding additional work on ${objectLabels.quote.plural}.`}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle title={`Use ${objectLabels.quote.value} Templates Only`} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.useTemplatesOnly"
              label={`New ${objectLabels.quote.plural} can only be created from templates.`}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle title={`Default Send Quote By Text`} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.sendQuoteByTextDefault"
              label={`When sending ${objectLabels.quote.plural} by email, default to always toggle on "Send by Text".`}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle title={`Default ${objectLabels.quote.value} Email From Estimator`} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.quoteEmailFromEstimator"
              label={`When sending ${objectLabels.quote.plural.toLowerCase()} by email, send the email from the owner's email address.`}
              sublabel={`By default, ${objectLabels.quote.plural.toLowerCase()} are sent from the email address of the currently logged in user.`}
            />
          </Grid>
        </Grid>
      </FormSection>

      {get(features, 'quotes.followUps.sendText', false) && (
        <FormSection>
          <FormSectionTitle title={`Default Send Follow-Up By Text`} variant={'h3'} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CheckboxField
                name="options.options.quotes.sendFollowUpTextDefault"
                label={`When sending ${objectLabels.quote.value} follow-ups, default to always toggle on "Send Text".`}
              />
            </Grid>
          </Grid>
        </FormSection>
      )}

      <FormSection>
        <FormSectionTitle title={'Work Order View Options'} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <Field
                component={FormikRadioButtons}
                name="options.options.quotes.workOrderOptionsConsumer"
                options={workOrderViewOptions}
                label="Choose to default view on work order"
                sublabel={`Only new ${objectLabels.quote.plural} will be affected.`}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <FormSectionTitle
          title={'Internal Service Fee'}
          subTitle={
            <>
              <p style={{ margin: 0 }}>
                Default internal service fee for {objectLabels.quote.plural.toLowerCase()} & invoices.
              </p>
              <p style={{ margin: 0 }}>
                This fee is included in the sub-total, so prices for each line item cannot be shown when using it.
              </p>
            </>
          }
          variant={'h3'}
        />
        <Grid container spacing={1}>
          <Grid item xs={8} sm={7} md={5} lg={4}>
            <Field
              component={FormikInputField}
              name="options.options.quotes.serviceFees.hiddenValue"
              format={'rate'}
              label={`Default Service Fee Rate (%)`}
              fullWidth
            />
          </Grid>
          {/* See #8247 <Hidden smDown> 
              <Grid item sm={1}>
                {}
              </Grid>
            </Hidden>
            <Grid item xs={8} sm={7} md={4} lg={3}>
              <Field
                component={FormikInputField}
                name="options.options.quotes.serviceFees.visibleValue"
                format={'rate'}
                label={`Default Visible Rate (%)`}
                fullWidth
              />
              <InputLabel shrink={true} variant="sublabel">
                * Calculated from {objectLabels.quote.value} after tax total.
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <CheckboxField
                name="options.options.quotes.serviceFees.allow_custom"
                label={`Allow Service Fees to be customized on individual ${objectLabels.quote.plural.toLowerCase()}.`}
                subtleLabel
              />
            </Grid> */}
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle title={'Internal Display Options'} variant={'h3'} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <CheckboxField
              name="options.options.quotes.dontCountAcceptedDate"
              label={`Win Rates - Count Accepted ${objectLabels.quote.plural} in the date they were created.`}
            />
            <CheckboxField
              name="options.options.quotes.afterDiscountSalesRate"
              label={`Sales Rate - Calculate sales rate using the after discount total.`}
              Help={<HelpComponent info="Default uses before discount total." />}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle
          title={'Customer Display Options'}
          variant={'h3'}
          subTitle={`Options on how ${objectLabels.quote.plural.toLowerCase()} are displayed to customers.`}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <CheckboxField
              name="options.options.quotes.groupHours"
              label={`Show Sub-Total Groups for ${objectLabels.quote.plural.toLowerCase()} with Multiple ${
                objectLabels.quote.value
              } Types`}
              Help={
                <HelpComponent
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/groupHours.png'
                  }
                />
              }
            />
            <CheckboxField
              name="options.options.quotes.separateThousands"
              label="Separate Thousands Values with commas"
            />

            <CheckboxField name="options.options.quotes.showPrices" label="Show prices for each item in value column" />

            <CheckboxField
              name="options.options.quotes.allowShowCustomizedTotalHours"
              label="Allow showing individual item hours when Total Hours has been customized"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckboxField
              name="options.options.quotes.noDefaultAreaDimensions"
              label="Don't include any default Area dimensions for new areas."
              Help={
                <HelpComponent
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/noDefaultAreaDimensions.png'
                  }
                />
              }
            />
            <CheckboxField
              name="options.options.quotes.showBalanceDue"
              label={`Show Balance Due at top of ${objectLabels.quote.plural}`}
            />
            <CheckboxField
              name="options.options.quotes.showEstimatorSignature"
              label={`Show Estimator Signature on ${objectLabels.quote.plural} sent to Customer`}
            />

            <CheckboxField
              name="options.options.quotes.showTopSignature"
              label={`Show Customer Signature at top of Quotes.`}
            />
            <CheckboxField
              name="options.options.quotes.alwaysShowJobAddress"
              label={`Always show Job Address (show Contact Address if no Job Address).`}
            />
            <CheckboxField
              defaultValue={false}
              name="options.options.hideSubstrateListDescription"
              label={`Hide ${objectLabels.substrate.value.toLowerCase()} labels in area descriptions`}
            />
          </Grid>
          <Grid item xs={12}>
            <DropdownSelect
              variant="single"
              label="Area Item Display"
              options={areaItemDisplayOptions}
              value={
                areaItemDisplayOptions.find((option) => get(options.options.quotes, option.value, false)) || {
                  label: 'Default',
                  value: 'default'
                }
              }
              onChange={(option?: DropdownSelectOption) => handleAreaItemDisplayChange(option?.value)}
              fullWidth
              formatOptionLabel={(option: DropdownSelectOption) => (
                <>
                  {option.label}{' '}
                  {(option as any)?.screenshot && (
                    <Tooltip
                      content={
                        <HelpComponent
                          screenshot={areaItemDisplayOptions.find((opt) => opt.value === option.value)?.screenshot}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <div>
          <FormSectionTitle
            title={'Crew Display Options'}
            variant={'h3'}
            subTitle={`Options on how ${objectLabels.workOrder.plural.toLowerCase()} are displayed to crew & staff.`}
          />
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <CheckboxField
              name="options.options.quotes.showWorkOrderItemTotals"
              label={`Show prices for ${objectLabels.currency.symbol}/${objectLabels.unit.abbreviation.square}, etc. rates on Work Order`}
              Help={
                <HelpComponent
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/showWorkOrderItemTotals.png'
                  }
                />
              }
            />
            <CheckboxField
              name="options.options.quotes.showWorkOrderViewDescriptions"
              label={`Show ${objectLabels.substrate.value} and Area View Descriptions on Work Order`}
              Help={
                <HelpComponent
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/showWorkOrderViewDescriptions.png'
                  }
                />
              }
            />
            <CheckboxField
              name="options.options.quotes.showWorkOrderClientNotes"
              label="Show Client Notes on Work Order"
              help={
                <HelpComponent
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/showWorkOrderClientNotes.png'
                  }
                />
              }
            />
            <CheckboxField
              name="options.options.quotes.hideWorkOrderClientContactInfo"
              label="Hide Client Contact Info on Work Order"
            />
            <CheckboxField
              name="options.options.quotes.useAllAreasForTotalDimensions"
              label={`Use All Areas for Total Dimensions.`}
              Help={
                <HelpComponent
                  info={`By default, only areas that have work on a particular ${objectLabels.substrate.value.toLowerCase()} will be included towards that ${objectLabels.substrate.value.toLowerCase()}'s total.`}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckboxField
              name="options.options.quotes.showWorkOrderFiles"
              label="Show Media section by default, on Work Orders"
            />
            <CheckboxField
              defaultValue={true}
              name="options.options.quotes.hideWorkOrderVolumes"
              label="Hide Work Order Volumes"
              Help={
                <HelpComponent
                  info="By default, items with volumes will look like this:"
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/hideWorkOrderVolumes.png'
                  }
                />
              }
            />
            <CheckboxField
              defaultValue={true}
              name="options.options.quotes.hideWorkOrderMaterialCost"
              label="Hide Work Order Material Cost"
              Help={
                <HelpComponent
                  info="By default, items with materials will look like this:"
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/hideWorkOrderMaterialCost.png'
                  }
                />
              }
            />
            <CheckboxField
              defaultValue={true}
              name="options.options.quotes.showWorkOrderAreaValues"
              label="Show Hours by default"
            />
            <CheckboxField
              defaultValue={true}
              name="options.options.quotes.showWorkOrderAreas"
              label="Show Areas by default"
            />
            <CheckboxField
              defaultValue={true}
              name="options.options.quotes.showWorkOrderSubstrates"
              label={`Show ${objectLabels.substrate.plural.toLowerCase()} by default`}
            />
            <CheckboxField
              defaultValue={true}
              name="options.options.quotes.showWorkOrderAreaOptions"
              label="Show Options by default"
            />
            <CheckboxField
              defaultValue={true}
              name="options.options.quotes.showWorkOrderCoats"
              label="Show Coats by default"
            />
            <CheckboxField
              defaultValue={false}
              name="options.options.quotes.singleColumnWorkOrder"
              label="Display Work Order Items in Single Column"
              Help={
                <HelpComponent
                  screenshot={
                    'https://res.cloudinary.com/taptapquote/image/upload/v1668617216/paintscout/settings-screenshots/singleColumnWorkOrder.png'
                  }
                />
              }
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle
          title={'Quote & Invoice PDF Settings'}
          variant={'h3'}
          subTitle={`Options on how ${objectLabels.quote.plural} & PDFs are displayed`}
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.pdf.letterhead.enabled"
              label="Enable Letterhead"
              Help={<HelpComponent info="Show header on every page." />}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FastField
              component={FormikInputField}
              name="options.options.pdf.filename.format"
              label={'PDF File Name Format'}
              type="text"
              margin="dense"
            />
            <div className={classes.pdfSubtext}>{pdfFormatLabel}</div>
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

const HelpComponent = ({ info, screenshot }: { info?: string; screenshot?: string }) => {
  const classes = useStyles({})
  return (
    <>
      {info && (
        <Grid item xs={12} className={screenshot ? classes.divider : null}>
          <Typography gutterBottom variant={'caption'}>
            {info}
          </Typography>
        </Grid>
      )}
      {screenshot && (
        <Grid item xs={12}>
          <img src={screenshot} width={325} />
        </Grid>
      )}
    </>
  )
}

export default QuoteOptions
