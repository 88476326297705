/**
 * @module builder
 */

import { uuid } from '../../util'
import type { TermsOption } from '../index'
export function createTermsOption(): TermsOption {
  return {
    name: uuid(),
    label: '',
    content: '',
    quoteTypes: ['all'],
    active: true,
    disabled: false,
    showToc: true
  }
}
