import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

function CalculatorIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M21,12.5H3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v2C22,12.1,21.6,12.5,21,12.5z" />
      <rect x="5.3" y="14.5" width="13.4" height="2" />
      <path d="M3.7,3.8c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l4.7,4.7h2.8L3.7,3.8z" />
      <path d="M21.7,3.8c-0.4-0.4-1-0.4-1.4,0l-6.1,6.1h2.8l4.7-4.7C22.1,4.8,22.1,4.2,21.7,3.8z" />
      <polygon points="21,11.3 19.1,10.7 16.5,18.5 7.5,18.5 4.9,10.7 3,11.3 6,20.5 18,20.5 	" />
    </SvgIcon>
  )
}

export default CalculatorIcon
