/**
 * @module builder
 */
import uniq from 'lodash/uniq'
import type { OptionsDocument } from 'paintscout'
import type { ProductOption } from '../../index'
import { filterByQuoteType } from '../../options'
import { getProductOption } from '.'
import { getQuoteType } from '../../quote/util'

export function getProductOptions(args: {
  options: OptionsDocument
  quoteType?: string
  inactive?: boolean
}): ProductOption[] {
  const { options, inactive } = args
  const { products } = options.options
  const quoteType = getQuoteType(args)

  const order =
    products.quoteTypeOrder && products.quoteTypeOrder[quoteType]
      ? uniq([...products.quoteTypeOrder[quoteType], ...products.order])
      : uniq(products.order)

  return order
    .map((orderItem) => {
      const option = getProductOption({ options, key: orderItem })
      if (!option || (!inactive && !option.active)) {
        return null
      }

      if (filterByQuoteType({ quoteType, quoteTypes: option.quoteTypes })) {
        return option
      }

      return null
    })
    .filter((item) => item)
}
