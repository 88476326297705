import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  ChatBox,
  NewFormSection as FormSection,
  FormikSwitch,
  FormikInputField,
  Grid,
  Typography,
  useClientOptions,
  useUser
} from '../'
import { Field, Form, useFormikContext } from 'formik'
import { getObjectLabels } from '@paintscout/util/builder'
import SettingsPage from '../SettingsPage'
import type { OptionsDocument } from 'paintscout'

const useStyles = makeStyles<Theme, ClientChatFormProps>(
  (theme) => ({
    root: {},
    chat: {
      maxWidth: 375,
      height: 400,
      boxShadow: theme.shadows[2],
      borderRadius: '5px',
      margin: '0 auto'
    },
    switchDiv: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: `${-theme.spacing()}px`
    },
    sublabel: {
      width: '500px',
      [theme.breakpoints.down('lg')]: {
        width: '480px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '500px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  }),
  { name: 'ClientChatForm' }
)

export interface ClientChatFormProps {
  classes?: StyleClasses<typeof useStyles>
  onDialogConfirm?: () => void
}

function ClientChatForm(props: ClientChatFormProps) {
  const classes = useStyles(props)
  const { values } = useFormikContext<{ options: OptionsDocument }>()
  const { options } = useClientOptions()
  const quoteLabel = getObjectLabels({ options }).quote
  const invoiceLabel = getObjectLabels({ options, invoice: true }).quote
  const { user } = useUser()
  const userName = `${user?.user_metadata?.firstName ?? 'John'} ${user?.user_metadata?.lastName ?? 'Doe'}` as string

  return (
    <SettingsPage
      title={'Chat'}
      callout={{
        path: 'profile-chat',
        content: `Messages that your customers send through the chat button on ${quoteLabel.plural.toLowerCase()} or ${invoiceLabel.plural.toLowerCase()} will be forwarded to the estimator's email. Set an away status that will display for all users when they are away from the chat.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/4486857-paintscout-chat'
      }}
    >
      <Form className={classes.root}>
        <FormSection topPadded>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Field
                component={FormikInputField}
                classes={{ sublabel: classes.sublabel }}
                label="Away Status"
                sublabel="Displays under your name when you are away from chat"
                name="options.options.chat.statusMessage"
                fullWidth
              />
              <Grid alignItems="center" container spacing={1}>
                <Grid className={classes.switchDiv} item xs={2}>
                  <Field
                    component={FormikSwitch}
                    label="Always show away status"
                    name="options.options.chat.alwaysShowStatusMessage"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1">Always show away status</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box pl={[0, 4]}>
                <ChatBox
                  classes={{
                    root: classes.chat
                  }}
                  name={userName}
                  status={values.options.options?.chat?.statusMessage}
                  disabled
                  messages={[
                    {
                      _id: '1',
                      type: 'activity',
                      docIds: [],
                      details: {
                        activityType: 'message',
                        docType: 'quote',
                        timestamp: Date.now(),

                        message: {
                          from: {
                            name: userName,
                            email: user?.user_metadata?.email ?? 'john@work.ca',
                            isCustomer: true
                          },
                          text: 'Hello!'
                        }
                      }
                    }
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
        </FormSection>
      </Form>
    </SettingsPage>
  )
}

export default ClientChatForm
