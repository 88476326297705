/**
 * @module builder
 */
import type { QuoteTypeOption } from '../index'
import { uuid } from '../../util'

export function createQuoteTypeOption(): QuoteTypeOption {
  const key = uuid()
  return {
    key,
    value: key,
    label: '',
    hourlyRate: null,
    defaultTerms: null,
    defaultPresentation: null,
    active: true,
    companyOptions: {
      logo: {
        cloudinaryId: null,
        value: null,
        fileType: null
      }
    }
  }
}
