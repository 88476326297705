/**
 * @module builder
 */
import type { OptionsDocument, PresentationOption } from 'paintscout'

export function deletePresentationOption(args: {
  options: OptionsDocument
  presentationOption: PresentationOption
}): OptionsDocument {
  const { options, presentationOption } = args

  const key = presentationOption.key

  const order = [...options.options.presentationOptions.order]
  const orderIndex = order.indexOf(key)
  if (orderIndex !== -1) {
    order.splice(orderIndex, 1)
  }

  const values = { ...options.options.presentationOptions.values }
  if (values[key]) {
    delete values[key]
  }

  return {
    ...options,
    options: {
      ...options.options,
      presentationOptions: {
        ...options.options.presentationOptions,
        order,
        values
      }
    }
  }
}
