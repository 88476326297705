import React from 'react'
import { Checkbox, Collapse, FormControlLabel, Grid, Typography, useClientOptions, useUserPermissions } from '../../'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { getObjectLabels } from '@paintscout/util/builder'
import type { EventType, EventTypeConfig, EventTriggeredBy } from 'paintscout'
import { makeStyles, Radio, RadioGroup } from '@material-ui/core'

const useStyles = makeStyles<Theme, EventSubscriptionTypeProps>(
  (theme) => ({
    root: {},
    left: {
      '& img': {
        height: 45,
        width: 'auto',
        marginRight: theme.spacing(2),
        marginTop: theme.typography.pxToRem(6)
      }
    },
    option: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 700,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%'
      }
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: -10
      }
    },
    optionLeft: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginBottom: theme.spacing(1)
    },
    label: {},
    disabled: {
      pointerEvents: 'none',
      opacity: 0.5
    },
    radioWrapper: {
      marginLeft: theme.spacing(1.4)
    }
  }),
  { name: 'EventSubscriptionType' }
)

export interface EventSubscriptionTypeProps {
  classes?: StyleClasses<typeof useStyles>
  name: EventType
  event: EventTypeConfig
  label: React.ReactNode
  selfLabel?: string
  allLabel?: string
  disableRadio?: boolean
  isAdminIntegrations?: boolean
  onChange: (name: EventType, event: EventTypeConfig) => void
}

const adminPermission = () => {
  return {
    permissionToViewAllQuotes: true
  }
}

export function EventSubscriptionType(props: EventSubscriptionTypeProps) {
  const {
    name,
    event = { enabled: false, triggeredBy: 'self' as EventTriggeredBy },
    onChange,
    selfLabel,
    allLabel,
    disableRadio,
    isAdminIntegrations = false
  } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()

  const userPermissions = useUserPermissions()
  const { permissionToViewAllQuotes } = isAdminIntegrations ? adminPermission() : userPermissions
  const objectLabels = getObjectLabels({ options })

  const handleEnableToggle = (_: React.MouseEvent) => {
    if (onChange) {
      onChange(name, { ...event, enabled: !event.enabled })
    }
  }

  const enabled = !!event.enabled

  return (
    <div className={classes.option}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item className={classes.optionLeft} onClick={handleEnableToggle}>
          <Checkbox checked={enabled} />
          <Typography className={classes.label}>{props.label}</Typography>
        </Grid>
        <Collapse show={permissionToViewAllQuotes && enabled && !disableRadio}>
          <Grid item classes={{ root: classes.radioWrapper }}>
            <RadioGroup
              className={classes.radioGroup}
              name={name}
              value={event.triggeredBy}
              onChange={(_, triggeredBy: EventTriggeredBy) => {
                if (onChange) {
                  onChange(name, { ...event, triggeredBy })
                }
              }}
            >
              <FormControlLabel
                label={selfLabel || `My ${objectLabels.quote.plural}`}
                value={'self'}
                disabled={!enabled}
                control={<Radio color={'primary'} />}
              />
              <FormControlLabel
                label={allLabel || `All ${objectLabels.quote.plural}`}
                value={'all'}
                disabled={!enabled}
                control={<Radio color={'primary'} />}
              />
            </RadioGroup>
          </Grid>
        </Collapse>
      </Grid>
    </div>
  )
}

export default EventSubscriptionType
