import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Typography, Button, FormSection, Grid, Tooltip, Checkbox } from '@ui/paintscout'
import type { DialogProps } from '@ui/paintscout'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@ui/paintscout'
import { useSnackbar } from 'notistack'
import { useFormikContext } from 'formik'
import type { ClientMetaDocument } from 'paintscout'
import { jsonToLucene } from 'json-to-lucene'
import CodeEditor from '../CodeEditor'
import { useSearchUsersQuery } from '@paintscout/api'
import type { DropdownSelectOption } from '@paintscout/ui/src/DropdownSelect'
import DropdownSelect from '@paintscout/ui/src/DropdownSelect'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  input: { width: '100%', height: '100%' },
  buttonContainer: { marginBottom: theme.spacing() }
}))

type DropdownSelectOptionWithEmail = DropdownSelectOption & { email: string }

export interface ImportCSVDialogProps extends DialogProps {
  classes?: StyleClasses<typeof useStyles>
  title: string
  open: boolean
  onClose?: () => void
  onConfirm?: (file: File, owner?: DropdownSelectOptionWithEmail, provider?: string) => void
  onConfirmJson?: (owner: DropdownSelectOptionWithEmail, jsonInput: string) => void
}

function ImportCSVDialog({ onConfirm, onConfirmJson, onClose, title, ...props }: ImportCSVDialogProps) {
  const {
    values: { meta }
  } = useFormikContext<{ meta: ClientMetaDocument }>()
  const classes = useStyles(props)
  const { enqueueSnackbar } = useSnackbar()
  const isQuoteUpload = title === 'quotes'
  const isProductUpload = title === 'products'
  const isRateUpload = title === 'rates'
  const [showJsonField, setShowJsonField] = useState<boolean>(false)
  const [jsonInput, setJsonInput] = useState<string>('')
  const [urlInput, setUrlInput] = useState<string>('')
  const [file, setFile] = useState<File>(null)
  const [owner, setOwner] = useState<DropdownSelectOptionWithEmail>(null)
  const [provider, setProvider] = useState<DropdownSelectOption>(null)
  const [safeImport, setSafeImport] = useState<boolean>(true)
  const owners = []
  const providers: DropdownSelectOption[] = [
    { value: 'default', label: 'Default' },
    { value: 'er-quotes', label: 'ER Quotes' }
  ]
  const rateImportDetail: DropdownSelectOption[] = [
    { value: 'basic', label: 'Basic' },
    { value: 'detailed', label: 'Detailed' }
  ]

  const handleImport = () => {
    onConfirm(file, owner, provider?.value)
  }
  const handleJsonImport = () => {
    onConfirmJson(owner, jsonInput)
  }

  const { data, error } = useSearchUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      query: jsonToLucene({
        filters: [
          { type: 'equal', id: 'app_metadata.companyId', value: meta._id },
          {
            type: 'equal',
            id: 'app_metadata.roles',
            not: true,
            value: 'superadmin'
          }
        ]
      }),
      limit: 100
    }
  })

  if (data) {
    data.searchUsers.rows.forEach((user) => {
      owners.push({
        value: user.user_id,
        label: user.name,
        email: user.email,
        companyId: meta._id
      })
    })
  }

  if (error) {
    enqueueSnackbar(error.message, { variant: 'error' })
  }

  const handleSelectedFile = (e: any) => {
    setShowJsonField(false)
    const file = e.target.files[0]
    if (file) {
      const fileType = file.name.split('.').pop()
      const fileSize = file.size

      if (!['csv'].includes(fileType)) {
        enqueueSnackbar('Invalid file type', { variant: 'error' })
      } else if (fileSize / (1024 * 1024) > 3.75) {
        // Will keep us under 5MB once encoded
        enqueueSnackbar('File size must be less than 3.75MB', { variant: 'error' })
      } else {
        setFile(e.target.files[0])
      }
    }
  }

  return (
    <Dialog {...props}>
      <DialogTitle>Import {title}</DialogTitle>
      <DialogContent>
        <Grid className={classes.buttonContainer} container spacing={3}>
          <Grid item xs={12}>
            <Button variant="contained" component="label" color="primary" onChange={handleSelectedFile}>
              Upload {title} File
              <input accept=".csv" type="file" hidden />
            </Button>
          </Grid>
          {isQuoteUpload && (
            <>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={() => {
                    setShowJsonField(true)
                    setUrlInput('')
                  }}
                >
                  Upload Single JSON Quote
                </Button>
              </Grid>
            </>
          )}
          {file && (
            <Grid item xs={12}>
              <Typography variant="h5">{file?.name}</Typography>
              <Typography variant="h6">{(file?.size / (1024 * 1024)).toFixed(2)} MB / 5 MB</Typography>
            </Grid>
          )}
        </Grid>
        {(file || jsonInput || urlInput) && !isProductUpload && !isRateUpload && (
          <FormSection>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <DropdownSelect
                  variant="single"
                  label="Select Owner"
                  options={owners.map((owner) => ({ value: owner.value, label: owner.label }))}
                  value={owner}
                  onChange={(owner: DropdownSelectOption) => {
                    const ownerWithEmail = owners.find((o) => o.value === owner.value)
                    setOwner(ownerWithEmail)
                  }}
                />
              </Grid>
              <Grid style={{ marginTop: '12px' }} item xs={4}>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body2">Safe values?</Typography>
                  <Tooltip content={<>Change contact details to prevent accidental sends</>} />
                </div>
                <Checkbox
                  value={safeImport}
                  defaultChecked={safeImport}
                  onChange={() => setSafeImport((safeImport) => !safeImport)}
                ></Checkbox>
              </Grid>
            </Grid>
          </FormSection>
        )}
        {showJsonField && (
          <FormSection>
            <Typography variant={'h6'}>Json:</Typography>
            <CodeEditor value={jsonInput} onChange={setJsonInput} />
          </FormSection>
        )}
        {file && owner && isQuoteUpload && (
          <FormSection>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DropdownSelect
                  variant="single"
                  label="Select Quote Provider"
                  options={providers}
                  value={provider}
                  onChange={setProvider}
                />
              </Grid>
            </Grid>
          </FormSection>
        )}
        {file && isRateUpload && (
          <FormSection>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DropdownSelect
                  variant="single"
                  label="Select Rate Import Detail"
                  options={rateImportDetail}
                  value={provider}
                  onChange={setProvider}
                />
              </Grid>
            </Grid>
          </FormSection>
        )}
      </DialogContent>
      <DialogActions leftButton={<Button onClick={onClose}>Cancel</Button>}>
        {showJsonField ? (
          // Json upload confirm
          <Button onClick={handleJsonImport} disabled={!owner || !jsonInput}>
            Confirm
          </Button>
        ) : (
          // File upload confirm
          <Button
            onClick={handleImport}
            disabled={
              (!owner && !isProductUpload && !isRateUpload) || !file || ((isQuoteUpload || isRateUpload) && !provider)
            }
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ImportCSVDialog
