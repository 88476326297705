import React from 'react'
import type { FieldProps } from 'formik'
import type { RadioButtonsProps } from '../RadioButtons'
import RadioButtons from '../RadioButtons'

export interface FormikRadioButtonsProps extends FieldProps<any>, Omit<RadioButtonsProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikRadioButtons = ({ field, ...props }: FormikRadioButtonsProps) => {
  const value = props.options.find((option) => option.name === field.value) || props.options[0]

  const onChange = (option) => {
    field.onChange({ target: { name: field.name, value: option } })
  }

  return <RadioButtons {...field} {...props} value={value.name} onChange={onChange} />
}

export default FormikRadioButtons
