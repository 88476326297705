import React from 'react'
import useAuthCallback from './useAuthCallback'
import type { Auth0Session } from './util/session'

export interface AuthCallbackRenderProps {
  loading: boolean
  error?: auth0.Auth0Error
  previousUrl: string
}

export interface AuthCallbackProps {
  children?: (props: AuthCallbackRenderProps) => JSX.Element
  onAuthenticated?: (err: auth0.Auth0Error, session: Auth0Session, previousUrl?: string) => any
}

function AuthCallback(props: AuthCallbackProps) {
  const [response, setResponse] = React.useState(null)

  useAuthCallback((err, session, previousUrl) => {
    setResponse({ error: err, previousUrl })
    if (props.onAuthenticated) {
      props.onAuthenticated(err, session, previousUrl)
    }
  })

  return props.children
    ? props.children({
        loading: !response,
        error: response ? response.error : null,
        previousUrl: response ? response.previousUrl : null
      })
    : null
}

export default AuthCallback
