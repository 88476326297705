import React, { useCallback, useEffect, useState } from 'react'
import type { OptionsDocument, OverridableValue } from 'paintscout'
import type { WithDialogStackContext } from '@ui/paintscout'
import { Typography } from '@ui/paintscout'
import { withDialogStackContext, FormSection, InputField, OverridableInputField, ActionButton } from '@ui/paintscout'
import type { Product, RenderableItem, UpdateableProduct } from '@paintscout/util/builder'
import ProductSelectDialog from '../../ProductSelectDialog'
import { roundPriceQuoteOptions } from '@paintscout/util/calculator'
import { useQuote } from '../../../context'
import ItemTable, { ItemTableCell } from '../../../ItemTable'
import { Grid, useIsMobile } from '@ui/core'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  itemCell: {
    padding: theme.spacing(1)
  }
}))

export interface MaterialsTableProps extends WithDialogStackContext {
  materials: UpdateableProduct[]
  options: OptionsDocument
  quoteTypes?: string[]
  disabled?: boolean
  allowCustomProduct?: boolean
  onChange?: (materials: UpdateableProduct[]) => void
}

function MaterialsTable(props: MaterialsTableProps) {
  const { options, quoteTypes, dialogStackContext, allowCustomProduct, disabled } = props
  const classes = useStyles()
  const [materials, setMaterials] = useState<UpdateableProduct[]>(props.materials ?? [])
  const mobile = useIsMobile({ xs: true })
  const { quote } = useQuote()

  useEffect(() => {
    setMaterials(props.materials ?? [])
  }, [props.materials])

  const convertToItem = (materials: UpdateableProduct[]): RenderableItem[] => {
    return materials.map((m) => {
      return {
        key: m.key,
        name: m.label,
        clientLabel: m.label,
        type: 'material',
        description: { default: '', custom: '' },
        value: m.totalPrice.useCustom ? m.totalPrice.custom : m.totalPrice.default
      }
    })
  }

  const renderHeadColumns = useCallback(() => {
    return mobile ? (
      <>
        <ItemTableCell>Materials</ItemTableCell>
        <ItemTableCell />
      </>
    ) : (
      <>
        <ItemTableCell style={{ width: '50%' }}>Name</ItemTableCell>
        <ItemTableCell>Qty/Vol</ItemTableCell>
        <ItemTableCell>Unit Price</ItemTableCell>
        <ItemTableCell>Cost</ItemTableCell>
        <ItemTableCell />
      </>
    )
  }, [materials, mobile])

  const renderMobileMaterial = useCallback(
    (index: number) => {
      const material = materials[index]
      return (
        <>
          <ItemTableCell className={classes.itemCell}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputField
                  value={material.label}
                  name={'label'}
                  type={'text'}
                  disabled={disabled}
                  onChange={handleChange(index)}
                  fullWidth
                  label="Name"
                  // label={<Typography variant="caption">Name</Typography>}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  value={material.quantity}
                  name={'quantity'}
                  autoSelect={true}
                  format={'quantity'}
                  disabled={disabled}
                  onChange={handleChange(index)}
                  fullWidth
                  label={<Typography variant="caption">Qty/Vol</Typography>}
                />
              </Grid>
              <Grid item xs={4}>
                <OverridableInputField
                  value={material.price}
                  onChange={handleOverridableChange(index, 'price')}
                  autoSelect={true}
                  resetBlank={true}
                  format={'price'}
                  disabled={disabled}
                  fullWidth
                  label={<Typography variant="caption">Unit Price</Typography>}
                />
              </Grid>
              <Grid item xs={4}>
                <OverridableInputField
                  value={material.totalPrice}
                  readOnly={true}
                  onChange={handleOverridableChange(index, 'totalPrice')}
                  resetBlank={true}
                  format={'price'}
                  disabled={disabled}
                  fullWidth
                  label={<Typography variant="caption">Cost</Typography>}
                  flipX
                />
              </Grid>
            </Grid>
          </ItemTableCell>
          <ItemTableCell className={classes.itemCell} valign="middle">
            <ActionButton
              actions={[
                {
                  key: 'change',
                  label: 'Change Product'
                },
                {
                  key: 'delete',
                  label: 'Remove'
                }
              ]}
              disabled={disabled}
              onActionClick={(ev, action) => handleActionItemClick(ev, action, index)}
            />
          </ItemTableCell>
        </>
      )
    },
    [materials, props.materials]
  )

  const renderMaterial = useCallback(
    (index: number) => {
      if (mobile) {
        return renderMobileMaterial(index)
      }
      const material = materials[index]
      return (
        <>
          <ItemTableCell className={classes.itemCell} style={{ width: '50%' }}>
            <InputField
              value={material.label}
              disabled={disabled}
              name={'label'}
              type={'text'}
              onChange={handleChange(index)}
              fullWidth
            />
          </ItemTableCell>
          <ItemTableCell className={classes.itemCell} style={{ width: '15%' }}>
            <InputField
              value={material.quantity}
              name={'quantity'}
              autoSelect={true}
              disabled={disabled}
              format={'quantity'}
              onChange={handleChange(index)}
            />
          </ItemTableCell>
          <ItemTableCell className={classes.itemCell} align="right" style={{ width: '15%' }}>
            <OverridableInputField
              value={material.price}
              onChange={handleOverridableChange(index, 'price')}
              autoSelect={true}
              resetBlank={true}
              disabled={disabled}
              format={'price'}
            />
          </ItemTableCell>
          <ItemTableCell className={classes.itemCell} align="right" style={{ width: '15%' }}>
            <OverridableInputField
              value={material.totalPrice}
              readOnly={true}
              disabled={disabled}
              onChange={handleOverridableChange(index, 'totalPrice')}
              resetBlank={true}
              format={'price'}
            />
          </ItemTableCell>
          <ItemTableCell className={classes.itemCell} align="right" style={{ width: '5%' }}>
            <ActionButton
              disabled={disabled}
              actions={[
                {
                  key: 'change',
                  label: 'Change Product'
                },
                {
                  key: 'delete',
                  label: 'Remove'
                }
              ]}
              onActionClick={(ev, action) => handleActionItemClick(ev, action, index)}
            />
          </ItemTableCell>
        </>
      )
    },
    [materials, props.materials, mobile]
  )

  return (
    <FormSection>
      <ItemTable
        items={convertToItem(materials)}
        showAddButton={true}
        addButtonLabel={'Add Materials'}
        onAddClick={handleAddMaterial}
        draggable={false}
        disabled={disabled}
        checkboxes={false}
        renderHeadColumns={renderHeadColumns}
        renderRowColumns={(item, index) => renderMaterial(index)}
      />
    </FormSection>
  )

  function handleEditMaterial(index?: number) {
    return function () {
      dialogStackContext.openDialog(ProductSelectDialog, {
        allowCustom: allowCustomProduct,
        onConfirm: (product: Product, custom?: boolean) => {
          const updatedMaterials = [...materials]
          const price = !custom && product.price ? product.price : 0

          const newProduct: UpdateableProduct = custom
            ? {
                key: 'custom-product', // this key is arbitrary
                label: '',
                color: '',
                quantity: 1,
                coverage: { default: 0, useCustom: false, custom: 0 },
                lnftCoverage: { default: 0, useCustom: false, custom: 0 },
                quantityCoverage: { default: 0, useCustom: false, custom: 0 },
                volume: { default: 0, useCustom: false, custom: 0 },
                price: { default: price, useCustom: false, custom: 0 },
                totalPrice: { default: 0, useCustom: false, custom: 0 },
                custom
              }
            : {
                ...product,
                color: '',
                quantity: 1,
                coverage: { default: product.coverage, useCustom: false, custom: 0 },
                lnftCoverage: { default: product.lnftCoverage, useCustom: false, custom: 0 },
                quantityCoverage: { default: product.quantityCoverage, useCustom: false, custom: 0 },
                volume: { default: 0, useCustom: false, custom: 0 },
                price: { default: price, useCustom: false, custom: 0 },
                totalPrice: { default: 0, useCustom: false, custom: 0 }
              }

          const calculatedProduct = calculateMaterial(newProduct)

          if (typeof index !== 'undefined') {
            updatedMaterials[index] = calculatedProduct
          } else {
            updatedMaterials.push(calculatedProduct)
          }

          setMaterials(updatedMaterials)
          if (props.onChange) {
            props.onChange(updatedMaterials)
          }
          dialogStackContext.dismissDialog()
        },
        onCancel: () => {
          dialogStackContext.dismissDialog()
        },
        quoteTypes,
        options
      })
    }
  }

  function calculateMaterial(product: UpdateableProduct) {
    const { quantity, price } = product

    const updatedPrice = roundPriceQuoteOptions(
      quantity * (price.useCustom ? price.custom : price.default),
      quote,
      options
    )

    return {
      ...product,
      totalPrice: {
        ...product.totalPrice,
        default: isNaN(updatedPrice) ? 0 : updatedPrice
      }
    }
  }

  function handleActionItemClick(event: React.MouseEvent<HTMLElement>, actionName: string, rowId: number) {
    if (actionName === 'change') {
      handleEditMaterial(rowId)()
    } else if (actionName === 'delete') {
      const updatedMaterials = [...materials]
      updatedMaterials.splice(rowId, 1)

      setMaterials(updatedMaterials)

      if (props.onChange) {
        props.onChange(updatedMaterials)
      }
    }
  }

  function handleOverridableChange(index: number, name: string) {
    return function (value: OverridableValue) {
      const updatedMaterials = [...materials]
      updatedMaterials[index] = calculateMaterial({
        ...updatedMaterials[index],
        [name]: value
      })

      setMaterials(updatedMaterials)

      if (props.onChange) {
        props.onChange(updatedMaterials)
      }
    }
  }

  function handleChange(index: number) {
    return function (ev: React.ChangeEvent<HTMLInputElement>) {
      const { name, value } = ev.target

      const updatedMaterials = [...materials]
      updatedMaterials[index] = calculateMaterial({
        ...updatedMaterials[index],
        [name]: value
      })

      setMaterials(updatedMaterials)

      if (props.onChange) {
        props.onChange(updatedMaterials)
      }
    }
  }

  function handleAddMaterial() {
    handleEditMaterial()()
  }
}

export default withDialogStackContext(MaterialsTable)
