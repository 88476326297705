import React, { useState } from 'react'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import Grid from '../Grid'
import FormSectionTitle from '../FormSectionTitle'
import { getObjectLabels, getQuoteTypeOptions } from '@paintscout/util/builder'
import type { QuoteTypeOption } from '@paintscout/util/builder'
import { useClientOptions } from '../ClientOptionsProvider'
import Button from '../Button'
import DropdownSelect from '../DropdownSelect'

const useStyles = makeStyles<Theme, FormSectionProps>((theme) => ({
  root: () => ({
    padding: theme.spacing(2, 3, 3, 3),
    backgroundColor: 'white ',
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[1],
    borderRadius: theme.typography.pxToRem(4),
    [theme.breakpoints.down('xs')]: {
      padding: (props: FormSectionProps) => `${theme.spacing()}px ${theme.spacing(props?.mobilePadding ? 0 : 3)}px`
    }
  }),
  divider: {},
  selectHeader: {
    display: 'flex',
    margin: theme.spacing(2, 0, 3, 0),
    justifyContent: 'space-between'
  },
  select: {
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '150px'
    }
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      padding: (props: FormSectionProps) => (props?.mobilePadding ? theme.spacing(0, 2) : 0)
    }
  }
}))

type DivProps = JSX.IntrinsicElements['div']
export interface FormSectionProps extends DivProps {
  classes?: StyleClasses<typeof useStyles>
  mobilePadding?: boolean
  hidePadding?: boolean
  topPadded?: boolean
  title?: string
  selectHeader?: boolean
  selectValue?: string
  changeSelect?: (value: string) => void
  buttonAction?: () => void
  buttonText?: string
}
/**
 * Renders a div with a Divider above it
 */
const NewFormSection = (props: FormSectionProps) => {
  const classes = useStyles(props)
  const {
    topPadded,
    title,
    hidePadding,
    selectHeader,
    selectValue,
    changeSelect,
    buttonAction,
    buttonText,
    ...otherProps
  } = props
  const [isExporting, setIsExporting] = useState(false)
  const { options } = useClientOptions()
  const quoteTypeOptions = getQuoteTypeOptions({ options, hideAll: true })

  const { quote: quoteLabel } = getObjectLabels({ options })

  const handleButtonClick = async () => {
    setIsExporting(true)
    buttonAction()
    setIsExporting(false)
  }

  const dropdownOptions = [
    {
      label: `All ${quoteLabel.value} Types`,
      value: 'all'
    },
    ...quoteTypeOptions.map((quoteType: QuoteTypeOption) => ({
      label: quoteType.label,
      value: quoteType.key
    }))
  ]

  return (
    <React.Fragment>
      <div {...otherProps} className={classes.root}>
        {topPadded && <div style={{ padding: '15px' }} />}
        {title && (
          <>
            <Grid className={classes.title} item xs={12} sm={10}>
              <FormSectionTitle variant={'subtitle1'} title={title} />
            </Grid>
            {!hidePadding && <div style={{ padding: '10px' }} />}
          </>
        )}
        {selectHeader && (
          <Grid item xs={12} className={classes.selectHeader}>
            <DropdownSelect
              className={classes.select}
              placeholder={{
                selectPlaceholder: `All ${quoteLabel.value} Types`
              }}
              variant={'single'}
              onChange={(option) => changeSelect(option.value as string)}
              options={dropdownOptions}
              value={dropdownOptions.find((option) => option.value === selectValue)}
            />

            {buttonAction && (
              <Button size={'medium'} variant="text" onClick={handleButtonClick} loading={isExporting}>
                {buttonText || 'Export'}
              </Button>
            )}
          </Grid>
        )}
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default NewFormSection
