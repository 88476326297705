import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { FormSection, FormSectionTitle, Grid, useClientOptions, EventSubscriptionType } from '../../'
import type { ProviderConfig } from 'paintscout'
import type { EventType } from 'paintscout'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'

const useStyles = makeStyles<Theme, HubspotProviderFormProps>(
  (_theme) => ({
    root: {}
  }),
  { name: 'HubspotProviderForm' }
)

export interface HubspotProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
}

export function HubspotProviderForm(props: HubspotProviderFormProps) {
  const { providerConfig, providerName, isSettingsIntegration = false, isAdminIntegrations = false, onChange } = props
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { handleEventChange } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  return (
    <>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle title={'Update Deals'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-sent' as EventType}
              event={providerConfig.events['quote-sent']}
              onChange={handleEventChange}
              label={`When ${objectLabels.quote.plural} are sent`}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}
