import React, { useCallback, useState } from 'react'
import { Radio, RadioGroup, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import {
  Button,
  FormControlLabel,
  KeypadInputField,
  OverridableInputField,
  Typography,
  useClientOptions
} from '@ui/paintscout'
import type { UpdateableLineItem } from '@paintscout/util/builder'
import AddIcon from '@material-ui/icons/Add'
import Chip from '@material-ui/core/Chip'
import { getObjectLabels, getDetails, getFeature } from '@paintscout/util/builder'
import { useIsMobile } from '@ui/core'
import { calculateUpdateableLineItem } from './util'
import { parseNumber } from '@paintscout/util/calculator'
import _set from 'lodash/set'

import type { OverridableValue, QuoteDocument } from 'paintscout'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, LineItemPricingProps>(
  (theme) => ({
    root: {},
    radioButtons: {
      marginBottom: theme.typography.pxToRem(16),
      [theme.breakpoints.down(370)]: {
        flexDirection: 'column'
      }
    },
    priceLine: {
      width: '300%',
      position: 'absolute',
      borderTop: `2px solid ${theme.palette.common.black}`,
      top: 'calc(50% - 1px)',
      zIndex: -1
    },
    calculationGrid: {
      display: 'grid',
      alignItems: 'flex-end',
      gridTemplateColumns: '0.25fr 0.05fr 0.29fr 0.05fr 0.26fr',
      gridRowGap: theme.typography.pxToRem(10),
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    defaultGrid: {
      display: 'grid',
      alignItems: 'flex-end',
      gridTemplateColumns: '0.25fr 0.25fr',
      gridGap: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    chip: {
      display: 'flex',
      justifyContent: 'center',
      '& span': {
        fontWeight: 500
      },
      zIndex: 5,
      position: 'relative',
      marginBottom: 8
    },
    priceInput: {
      width: '100%',
      '& textarea': {
        width: 580
      },
      '& > div': {
        background: theme.palette.common.white,
        position: 'relative',
        zIndex: 6
      }
    },
    left: {
      left: '50%'
    },
    disabled: {
      color: theme.palette.text.disabled
    },
    fontWeight: {
      fontWeight: 500
    },
    addButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      width: 'fit-content'
    }
  }),
  { name: 'LineItemPricing' }
)

export interface LineItemPricingProps {
  quote: QuoteDocument
  item: UpdateableLineItem
  initialItem: UpdateableLineItem
  setItem: (item: UpdateableLineItem) => void
  disabled?: boolean
}

export function LineItemPricing(props: LineItemPricingProps) {
  const classes = useStyles(props)
  const { item, initialItem, quote, disabled, setItem } = props
  const { options } = useClientOptions()
  const details = quote ? getDetails({ quote: quote, options }) : { hourlyRate: 0 }
  const hoursPerUnitLineItems = getFeature({ options, path: 'quotes.hoursPerUnitLineItems' })

  const { currency } = getObjectLabels({ options })

  const emptyStringFallback = (val: number) => (val ? val : '')
  const calculateByQuantity = item.calculateBy === 'quantity'
  const calculateByCustom = (item.calculateBy === 'none' || item.calculateBy === undefined) && !item.calculate
  const calculateByHourlyRate =
    (item.calculate && !calculateByCustom && !calculateByQuantity) || item.calculateBy === 'hourlyRate'

  const showCalculation = calculateByHourlyRate || calculateByQuantity

  const getHourlyRate = useCallback(() => {
    let hourlyRateValue: string | number = 0
    if (item.customHourlyRate) {
      hourlyRateValue = item.hourlyRate
    } else {
      hourlyRateValue = details.hourlyRate
    }

    if (hourlyRateValue === 0) {
      hourlyRateValue = 0
    }
    return hourlyRateValue
  }, [item.customHourlyRate, item.hourlyRate, calculateByHourlyRate, options, details.hourlyRate])

  const [calcByCustom, setCalcByCustom] = useState({
    hours: calculateByCustom ? initialItem.hours : undefined,
    price: calculateByCustom ? initialItem.price : undefined
  })

  const [calcByHourlyRate, setCalcByHourlyRate] = useState({
    hours: calculateByHourlyRate ? initialItem.price : undefined,
    hourlyRate: calculateByHourlyRate ? getHourlyRate() : undefined,
    customHourlyRate: calculateByHourlyRate ? initialItem.customHourlyRate : undefined,
    price: calculateByHourlyRate ? initialItem.price : undefined
  })

  const [calcByQuantity, setCalcByQuantity] = useState({
    quantity: calculateByQuantity ? initialItem.quantity : undefined,
    hours: calculateByQuantity ? initialItem.hours : undefined,
    pricePerUnit: calculateByQuantity ? initialItem.pricePerUnit : undefined
  })

  const handleRadioChange = useCallback(
    (event: any, value: any) => {
      const { name } = event.target
      const previous = item.calculateBy ?? 'none'
      const next = value

      let updatedItem = {
        ...item,
        customHourlyRate: false,
        hourlyRate: null,
        hours: 0,
        price: 0,
        pricePerUnit: 0,
        hoursPerUnit: 0,
        quantity: 0,
        calculate: false,
        [name]: value
      } as UpdateableLineItem

      if (previous === 'none') {
        setCalcByCustom({ hours: item.hours, price: item.price })
      } else if (previous === 'hourlyRate') {
        const { hours, customHourlyRate, price } = item
        setCalcByHourlyRate({ hours, customHourlyRate, price, hourlyRate: getHourlyRate() })
      } else if (previous === 'quantity') {
        const { quantity, hours, pricePerUnit } = item
        setCalcByQuantity({ quantity, hours, pricePerUnit })
      }

      if (next === 'none') {
        updatedItem = {
          ...updatedItem,
          hours: calcByCustom.hours,
          price: calcByCustom.price
        }
      } else if (next === 'hourlyRate') {
        const { hours, hourlyRate, customHourlyRate, price } = calcByHourlyRate
        updatedItem = {
          ...updatedItem,
          hours,
          hourlyRate,
          customHourlyRate,
          price
        }
      } else if (next === 'quantity') {
        updatedItem = {
          ...updatedItem,
          quantity: calcByQuantity.quantity,
          hours: calcByQuantity.hours,
          pricePerUnit: calcByQuantity.pricePerUnit
        }
      }

      const calculatedItem = calculateUpdateableLineItem({ item: updatedItem, options, quote })

      setItem(calculatedItem)
    },
    [item, props, calcByCustom, calcByHourlyRate, calcByQuantity]
  )

  const handleKeypadChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target
      const parsedValue = value ? parseFloat(value) : 0

      const updatedItem = { ...item }
      _set(updatedItem, name, parsedValue)

      const calculatedItem = calculateUpdateableLineItem({ item: updatedItem, options, quote })

      setItem(calculatedItem)
    },
    [item, options, quote, setItem]
  )

  const handleHourlyRateChange = useCallback(
    (val: OverridableValue) => {
      const defaultVal = parseNumber(val.default, 0)
      const customVal = parseNumber(val.custom, 0)

      const updatedItem = {
        ...item,
        hourlyRate: customVal || defaultVal,
        customHourlyRate: customVal > 0 && customVal !== defaultVal
      }

      const calculatedItem =
        updatedItem.calculate || ['hourlyRate', 'quantity'].includes(updatedItem.calculateBy)
          ? calculateUpdateableLineItem({ item: updatedItem, options, quote })
          : updatedItem

      setItem(calculatedItem)
    },
    [item, options, quote, setItem]
  )

  return (
    <>
      <Typography
        variant="overline"
        className={classnames({
          [classes.disabled]: disabled,
          [classes.fontWeight]: true
        })}
      >
        Calculate Price By
      </Typography>
      <RadioGroup
        row
        className={classes.radioButtons}
        name={'calculateBy'}
        value={item.calculateBy ? item.calculateBy : item.calculate ? 'hourlyRate' : 'none'}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          label={'Hourly Rate'}
          value={'hourlyRate'}
          control={<Radio disabled={disabled} color={'primary'} />}
        />
        <FormControlLabel
          label={'Quantity'}
          value={'quantity'}
          control={<Radio disabled={disabled} color={'primary'} />}
        />
        <FormControlLabel label={'Custom'} value={'none'} control={<Radio disabled={disabled} color={'primary'} />} />
      </RadioGroup>

      <div className={showCalculation ? classes.calculationGrid : classes.defaultGrid}>
        {calculateByCustom && (
          <>
            <KeypadInputField
              allowNegative={true}
              autoSelect={true}
              classes={{ root: classes.priceInput }}
              disabled={calculateByHourlyRate || calculateByQuantity}
              format={'price'}
              label={`Price (${currency.symbol})`}
              name={'price'}
              onChange={handleKeypadChange}
              value={emptyStringFallback(item.price)}
            />
            {item.showHoursForWorkOrder ? (
              <KeypadInputField
                allowNegative={true}
                autoSelect={true}
                disabled={disabled}
                classes={{ root: classes.priceInput }}
                format={'hours'}
                label={'Hrs for Work Order'}
                name={'hours'}
                onChange={handleKeypadChange}
                value={emptyStringFallback(item.hours)}
              />
            ) : (
              <Button
                onClick={() => {
                  const updatedItem = { ...item, showHoursForWorkOrder: true }
                  setItem(updatedItem)
                }}
                disabled={disabled}
                className={classes.addButton}
                icon={AddIcon}
                variant="text"
              >
                Hrs for Work Order
              </Button>
            )}
          </>
        )}
        {calculateByHourlyRate && (
          <>
            <KeypadInputField
              fullWidth
              disabled={disabled}
              allowNegative={true}
              autoSelect={true}
              classes={{ root: classes.priceInput }}
              format={'hours'}
              label={'Hours'}
              name={'hours'}
              onChange={handleKeypadChange}
              value={emptyStringFallback(item.hours)}
            />
            <OperatorChip chipClass={classes.chip} lineClass={classes.priceLine} label={'x'} />
            <OverridableInputField
              fullWidth
              autoSelect
              resetBlank
              hideHelp
              disabled={disabled}
              classes={{ root: classes.priceInput }}
              format={'price'}
              label={`Hourly Rate (${currency.symbol}/HR)`}
              name={'hourlyRate'}
              onChange={handleHourlyRateChange}
              value={{
                default: getHourlyRate(),
                custom: item.hourlyRate,
                useCustom: item.customHourlyRate
              }}
            />
            <OperatorChip chipClass={classes.chip} lineClass={classes.priceLine} label={'='} />
            <KeypadInputField
              fullWidth
              allowNegative={true}
              autoSelect={true}
              classes={{ root: classes.priceInput }}
              disabled={calculateByHourlyRate || calculateByQuantity || disabled}
              format={'price'}
              label={`Price (${currency.symbol})`}
              name={'price'}
              onChange={handleKeypadChange}
              value={emptyStringFallback(item.price)}
            />
          </>
        )}
        {calculateByQuantity &&
          (hoursPerUnitLineItems ? (
            <>
              <KeypadInputField
                fullWidth
                autoSelect={true}
                disabled={disabled}
                classes={{ root: classes.priceInput }}
                format={'quantity'}
                label={'Quantity'}
                name={'quantity'}
                onChange={handleKeypadChange}
                value={emptyStringFallback(item.quantity)}
              />
              <OperatorChip chipClass={classes.chip} lineClass={classes.priceLine} label={'x'} />
              <KeypadInputField
                fullWidth
                allowNegative={true}
                autoSelect={true}
                disabled={disabled}
                classes={{ root: classes.priceInput }}
                format={'hours'}
                label={'Hours/Unit'}
                name={'hoursPerUnit'}
                onChange={handleKeypadChange}
                value={emptyStringFallback(item.hoursPerUnit)}
              />
              <OperatorChip chipClass={classes.chip} lineClass={classes.priceLine} label={'&'} />
              <KeypadInputField
                fullWidth
                autoSelect={true}
                disabled={disabled}
                classes={{ root: classes.priceInput }}
                format={'price'}
                label={`${currency.symbol}/Unit`}
                name={'pricePerUnit'}
                onChange={handleKeypadChange}
                value={emptyStringFallback(item.pricePerUnit)}
              />
              <div />
              <OperatorChip chipClass={classes.chip} lineClass={`${classes.priceLine} ${classes.left}`} label={'='} />
              <KeypadInputField
                fullWidth
                allowNegative={true}
                autoSelect={true}
                classes={{ root: classes.priceInput }}
                disabled={true}
                format={'hours'}
                label={'Total Hours'}
                name={'hours'}
                value={emptyStringFallback(item.hours)}
              />
              <OperatorChip chipClass={classes.chip} lineClass={classes.priceLine} label={'&'} />
              <KeypadInputField
                fullWidth
                allowNegative={true}
                autoSelect={true}
                classes={{ root: classes.priceInput }}
                disabled={true}
                format={'price'}
                label={`Price (${currency.symbol})`}
                name={'price'}
                value={emptyStringFallback(item.price)}
              />
            </>
          ) : (
            <>
              <KeypadInputField
                fullWidth
                autoSelect={true}
                classes={{ root: classes.priceInput }}
                format={'quantity'}
                label={'Quantity'}
                name={'quantity'}
                disabled={disabled}
                onChange={handleKeypadChange}
                value={emptyStringFallback(item.quantity)}
              />
              <OperatorChip chipClass={classes.chip} lineClass={classes.priceLine} label={'x'} />
              <KeypadInputField
                fullWidth
                autoSelect={true}
                disabled={disabled}
                classes={{ root: classes.priceInput }}
                format={'price'}
                label={`${currency.symbol}/Unit`}
                name={'pricePerUnit'}
                onChange={handleKeypadChange}
                value={emptyStringFallback(item.pricePerUnit)}
              />
              <OperatorChip chipClass={classes.chip} lineClass={classes.priceLine} label={'='} />
              <KeypadInputField
                fullWidth
                allowNegative={true}
                autoSelect={true}
                classes={{ root: classes.priceInput }}
                disabled={true}
                format={'price'}
                label={`Price (${currency.symbol})`}
                name={'price'}
                value={emptyStringFallback(item.price)}
              />

              {item.showHoursForWorkOrder ? (
                <KeypadInputField
                  fullWidth
                  allowNegative={true}
                  autoSelect={true}
                  classes={{ root: classes.priceInput }}
                  format={'hours'}
                  disabled={disabled}
                  label="Hrs for Work Order"
                  name={'hours'}
                  onChange={handleKeypadChange}
                  value={emptyStringFallback(item.hours)}
                />
              ) : (
                <Button
                  onClick={() => {
                    const updatedItem = { ...item, showHoursForWorkOrder: true }
                    setItem(updatedItem)
                  }}
                  className={classes.addButton}
                  icon={AddIcon}
                  variant="text"
                  disabled={disabled}
                >
                  Hrs for Work Order
                </Button>
              )}
            </>
          ))}
      </div>
    </>
  )
}

export function OperatorChip(props: { chipClass: string; lineClass: string; label: string }) {
  const { chipClass, lineClass, label } = props
  const isMobile = useIsMobile({ xs: true })
  if (isMobile) return null
  return (
    <div className={chipClass}>
      <div className={lineClass} />
      <Chip label={label} />
    </div>
  )
}

export default LineItemPricing
