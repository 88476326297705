import React from 'react'
import type { QuoteTag } from '@paintscout/util/builder'
import { getObjectLabels } from '@paintscout/util/builder'
import {
  copyQuoteTag,
  createQuoteTag,
  deleteQuoteTag,
  deleteQuoteTags,
  getQuoteTags,
  reorderQuoteTags
} from '@paintscout/util/builder'
import { NewFormSection as FormSection, useDialogs, ConfirmationDialog } from '@ui/paintscout'
import { useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import { OptionsTileList } from '../..'
import { EditQuoteTagDialog } from '../../dialogs'
import type { ValueType } from '@ui/paintscout/src/TileList'
import SettingsPage from '@ui/paintscout/src/SettingsPage'

export interface QuoteTagsProps {
  onDialogConfirm?: () => void
}

const QuoteTags = ({ onDialogConfirm, ..._props }: QuoteTagsProps) => {
  const { openDialog, dismissDialog } = useDialogs()
  const [selected, setSelected] = React.useState<ValueType[]>([])
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })
  const items = getQuoteTags({ options })

  function handleReorder(quoteTags: QuoteTag[]) {
    const updatedOptions = reorderQuoteTags({
      quoteTags,
      options
    })
    setFieldValue('options', updatedOptions)
  }

  function handleCreateItem(_: React.MouseEvent) {
    const item = createQuoteTag({ options })
    openDialog(EditQuoteTagDialog, {
      options,
      item,
      isNew: true,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        setFieldValue('options', updatedOptions)
        dismissDialog()
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getQuoteTags({ options })
    const item = items.find((item) => item.name === key)

    openDialog(EditQuoteTagDialog, {
      options,
      item,
      isNew: false,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        if (onDialogConfirm && isDirty) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>This tag will no longer be available.</p>
          <p>
            <strong>{`Existing ${objectLabels.quote.plural} will be affected.`}</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
        const items = getQuoteTags({ options })
        const updatedOptions = deleteQuoteTag({
          quoteTag: items.find((item) => item.name === key),
          options
        })
        setFieldValue('options', updatedOptions)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These tags will no longer be available to add to {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
        const items = getQuoteTags({ options })
        const selectedTags = items.filter((i) => keys.includes(i.name))
        const updatedOptions = deleteQuoteTags({
          quoteTags: selectedTags,
          options
        })
        setFieldValue('options', updatedOptions)

        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyQuoteTag({ options, key })
    setFieldValue('options', updatedOptions)
  }

  return (
    <SettingsPage
      title={`${objectLabels.quote.value} Tags`}
      callout={{
        path: 'estimate-tags',
        content: `${
          objectLabels.quote.value
        } tags are designed to help you efficiently organize and categorize your ${objectLabels.quote.plural.toLowerCase()}. By applying custom tags to individual ${objectLabels.quote.plural.toLowerCase()}, you will be able to sort, filter, and search for specific ${objectLabels.quote.plural.toLowerCase()} with ease.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/6063120-estimate-tags'
      }}
    >
      <FormSection>
        <OptionsTileList
          title={''}
          items={items.map((item) => ({ ...item, key: item.name }))}
          createTitle={'Add New Tag'}
          showCreate={true}
          selected={selected}
          setSelected={setSelected}
          onReorder={handleReorder}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
          onCreateClick={handleCreateItem}
          onDeleteBulkItems={handleDeleteBulkItems}
          onCopyItem={handleCopyItem}
          noItemTitle={'No Tags'}
          noItemMessage={'Add tags to better organize your quotes.'}
        />
      </FormSection>
    </SettingsPage>
  )
}

export default QuoteTags
