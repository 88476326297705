import React from 'react'

export interface NavDrawerContextValue {
  open: boolean
}

const NavDrawerContext = React.createContext<NavDrawerContextValue>({
  open: false
})

export default NavDrawerContext
