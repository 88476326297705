import React from 'react'
import Button from '../Button'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import CoreSplitMenuButton from '@ui/core/SplitMenuButton'
import type { SplitMenuButtonProps as CoreSplitMenuButtonProps } from '@ui/core/SplitMenuButton'

export interface SplitMenuButtonProps extends Omit<CoreSplitMenuButtonProps, 'Button'> {}

const useStyles = makeStyles<Theme, SplitMenuButtonProps>(
  (theme: Theme) => ({
    root: {
      boxShadow: 'none'
      // boxShadow: (props) => (props.leftDisabled || props.rightDisabled || props.disabled ? 'none' : null)
    },
    groupedContainedPrimary: {
      '&:not(:last-child)': {
        borderRightColor: theme.palette.primary.light
      },
      '&$disabled': {
        borderRightColor: theme.palette.grey[400]
      }
    },
    disabled: {},
    rightButton: {
      '&$groupedContainedPrimary': {
        background: theme.palette.primary.main
      },
      fontSize: 'inherit',
      '&$disabled': {
        background: theme.palette.grey[300],
        boxShadow: 'none'
      }
    }
  }),
  {
    name: 'SplitMenuButton'
  }
)

const SplitMenuButton = React.forwardRef<HTMLDivElement, SplitMenuButtonProps>(function SplitMenuButton(
  props: SplitMenuButtonProps,
  ref
) {
  const classes = useStyles(props)
  return <CoreSplitMenuButton Button={Button} ref={ref} {...props} classes={classes} />
})

export default SplitMenuButton
