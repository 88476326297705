import React from 'react'
import { Scaler } from '@ui/paintscout'
import PresentationPreview from '../../../PresentationPreview'
import type { FormikPresentationSectionConnectProps, FormikPresentationSectionValues } from '../../../'
import { connect } from 'formik'

export interface PresentationSectionPreviewProps {
  showHelp?: boolean
  isSettings?: boolean
  editFromDialog?: boolean
}

const PresentationSectionPreview = ({
  formik,
  showHelp,
  isSettings,
  editFromDialog,
  ..._props
}: PresentationSectionPreviewProps & FormikPresentationSectionConnectProps) => {
  const section = formik.values?.section

  return (
    <Scaler fixedWidth={1002} noHeight={false}>
      <PresentationPreview
        presentation={{
          key: 'preview',
          label: 'Preview',
          pages: [{ key: 'preview', type: 'preview', label: 'Preview', title: 'Preview', sections: [section] }]
        }}
        showHelp={showHelp}
        isSettings={isSettings}
        editFromDialog={editFromDialog}
      />
    </Scaler>
  )
}

export default connect<PresentationSectionPreviewProps, FormikPresentationSectionValues>(PresentationSectionPreview)
