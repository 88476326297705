import { makeStyles } from '@material-ui/core'
import { getFeatures, getObjectLabels, getEmailTemplates, uuid } from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'
import get from 'lodash/get'
import type { EmailTemplate, OptionsDocument, RatesDocument } from 'paintscout'
import React, { useMemo, useState } from 'react'
import { Button, ConfirmationDialog, FormSectionTitle, InputField, useClientOptions } from '..'
import { useDialogs } from '../DialogStack'
import { NewFormSection as FormSection } from '../FormSection'
import TileList from '../TileList'
import type { ValueType } from '../TileList'
import EmailTemplateDialog from './UserEmailForm/EmailTemplateDialog'
import SettingsPage from '../SettingsPage'
import { TitleCallout } from '../TitleCallout'
import { getIndefiniteArticle } from '@paintscout/util/util'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  root: {},
  addressbookSection: {
    width: '100%',
    maxWidth: 600
  },
  templates: {
    marginTop: theme.typography.pxToRem(15)
  },
  input: {
    width: '100%'
  }
}))

export interface ClientEmailFormProps {
  onDialogConfirm?: () => void
}

function ClientEmailForm({ onDialogConfirm }: ClientEmailFormProps) {
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()

  console.log({ values })
  const { openDialog, dismissDialog } = useDialogs()
  const { options, isAdmin } = useClientOptions()
  const classes = useStyles()
  const [updateableEmailJson, setUpdateableEmailJson] = useState<string>(
    JSON.stringify(values.options.options.emails, null, 2)
  )
  const [jsonHidden, setJsonHidden] = useState<boolean>(true)
  const [jsonDirty, setJsonDirty] = useState<boolean>(false)
  const quoteLabel = getObjectLabels({ options }).quote
  const invoiceLabel = getObjectLabels({ options, invoice: true }).quote
  const features = getFeatures({ options })

  const toFieldHidden = !get(features, 'quotes.emails.allowToField')
  const templateItems = useMemo(() => {
    return [
      {
        label: quoteLabel.value,
        value: 'send-quote-client',
        key: 'send-quote-client',
        toHidden: toFieldHidden
      },
      get(features, 'quotes.emails.toStaff', false)
        ? {
            label: `${quoteLabel.value} to Staff`,
            value: 'send-quote-staff',
            key: 'send-quote-staff',
            toHidden: toFieldHidden,
            enabled: get(options, 'options.quotes.emails.values.[send-quote-staff].enabled', false)
          }
        : null,
      {
        label: 'Invoice',
        value: 'send-invoice-client',
        key: 'send-invoice-client',
        subLabel: 'Set default invoice template sent to client',
        toHidden: toFieldHidden
      },
      {
        label: 'Work Order',
        value: 'send-work-order-staff',
        key: 'send-work-order-staff',
        toHidden: toFieldHidden
      },
      {
        label: `Payment Request`,
        value: 'payment-request',
        key: 'payment-request',
        toHidden: toFieldHidden
      },
      get(features, 'quotes.productOrderForm', false)
        ? {
            label: 'Product Order Form',
            value: 'product-order-form',
            key: 'product-order-form',
            toHidden: toFieldHidden
          }
        : null
    ].filter((item) => {
      return item !== null
    })
  }, [options, values, toFieldHidden])

  const customTemplateItems = useMemo(() => {
    const customEmailTemplateValues = values?.options?.options?.emails?.customTemplates?.values
    const items = []
    if (customEmailTemplateValues)
      Object.keys(customEmailTemplateValues).forEach((key) => items.push(customEmailTemplateValues[key]))

    const mappedItems = items.map((item, index) => {
      return {
        enabled: item.enabled,
        key: index,
        label: item.label,
        subLabel: '',
        value: item.name,
        toHidden: toFieldHidden
      }
    })

    return mappedItems
  }, [values])

  const automationsItems = useMemo(() => {
    return [
      {
        label: `Thank You - Accepted`,
        value: 'accept-thank-you',
        key: 'accept-thank-you',
        toHidden: toFieldHidden
      },
      {
        label: 'Thank you - Declined',
        value: 'decline-thank-you',
        key: 'decline-thank-you',
        toHidden: toFieldHidden
      },
      {
        label: `Payment Received`,
        value: 'payment-received',
        key: 'payment-received',
        toHidden: toFieldHidden
      }
    ].filter((item) => {
      return item !== null
    })
  }, [options, values])

  function handleEditTemplateItem(event: any, selectedTemplate: ValueType[] | ValueType) {
    const templateItem = [...automationsItems, ...templateItems].find((item) => item.key === selectedTemplate)
    const template = values.options.options.emails?.values?.[templateItem.value]
      ? values?.options?.options?.emails?.values[templateItem.value]
      : getEmailTemplates({})[templateItem.value]

    if (templateItem.value === 'send-quote-staff' && typeof template.enabled == 'undefined') {
      template.enabled = get(values.options, 'options.quotes.emails.values.[send-quote-staff].enabled', false)
    }

    openDialog(EmailTemplateDialog, {
      item: template,
      options,
      toHidden: templateItem.toHidden,
      isAdmin,
      onConfirm: (updatedTemplate: EmailTemplate) => {
        setFieldValue('options.options.emails.values', {
          ...values?.options?.options?.emails?.values,
          [templateItem.value]: updatedTemplate
        })

        if (onDialogConfirm) {
          onDialogConfirm()
        }

        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCustomTemplate(event: any, key?: number) {
    const newId = key > -1 ? '' : uuid()
    const emptyTemplate = {
      additionalRecipients: [],
      body: '<p></p>',
      defaultFor: [],
      enabled: true,
      label: 'My Custom Template', // title in dialog
      name: newId,
      subject: '<p></p>'
    }

    const name = key > -1 ? customTemplateItems.find((item) => item.key === key).value : newId
    const template = key > -1 ? values.options.options?.emails?.customTemplates?.values[name] : emptyTemplate

    openDialog(EmailTemplateDialog, {
      item: template,
      options,
      customTemplate: true,
      isAdmin,
      toHidden: !get(features, 'quotes.emails.allowToField'),
      onConfirm: (updatedCustomTemplate: EmailTemplate) => {
        setFieldValue('options.options.emails.customTemplates.values', {
          ...values.options.options?.emails?.customTemplates?.values,
          [name]: updatedCustomTemplate
        })

        const order = values.options.options.emails.customTemplates?.order
        const updatedOrder = order ? [...order, name] : [name]
        if (!order || order.indexOf(name) === -1) {
          setFieldValue('options.options.emails.customTemplates.order', updatedOrder)
        }

        if (onDialogConfirm) {
          onDialogConfirm()
        }

        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteCustomItem(ev: any, actionName: string, rowId: number) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>Are you sure you want to delete this template?</p>
          <p>It will no longer be available when sending {quoteLabel.plural}.</p>
        </>
      ),
      onConfirm: () => {
        const name = customTemplateItems[rowId].value
        const updatedValues = { ...values.options.options?.emails?.customTemplates?.values }
        delete updatedValues[name]

        const order = values?.options?.options?.emails?.customTemplates?.order ?? []
        const updatedOrder = order.filter((item) => item !== name)

        setFieldValue('options.options.emails.customTemplates.values', updatedValues)
        setFieldValue('options.options.emails.customTemplates.order', updatedOrder)

        if (onDialogConfirm) {
          onDialogConfirm()
        }
        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const deleteAction = {
    key: 'delete',
    label: 'Delete',
    icon: DeleteIcon
  }

  const handleJsonUpdate = () => {
    const updatedValues = JSON.parse(updateableEmailJson)
    if (updatedValues) {
      setFieldValue('options.options.emails', updatedValues)
      setJsonHidden(true)
    }
  }

  return (
    <SettingsPage title={'Email Templates'}>
      <Form className={classes.root}>
        <FormSection>
          <TitleCallout
            title="Emails Sent by Your Team"
            variant="h3"
            path="communications-email-templates"
            openContent={`Create and customize your default email templates, which will serve as the preset option for all users when sending ${quoteLabel.plural.toLowerCase()}, ${invoiceLabel.plural.toLowerCase()}, or work orders.`}
            learnMoreLink="http://help.paintscout.com/en/articles/6063151-how-to-create-an-email-template"
          />
          <div className={classes.templates}>
            <TileList
              showActive={true}
              items={[...templateItems, ...customTemplateItems]}
              onSelect={(event: any, selected: ValueType[] | ValueType) => {
                if (typeof selected === 'number') {
                  handleCustomTemplate(event, selected as number)
                } else {
                  handleEditTemplateItem(event, selected)
                }
              }}
              createButton={'New Template'}
              onCreate={handleCustomTemplate}
              actions={(tile) => (typeof tile.key === 'number' ? [deleteAction] : null)}
              onActionClick={handleDeleteCustomItem}
            />
          </div>
        </FormSection>
        <FormSection>
          <TitleCallout
            title="Emails Sent Automatically"
            variant="h3"
            path="communications-email-automations"
            openContent={`Customize your automatic email template preferences, which impacts all users. These emails are automatically sent when ${quoteLabel.plural.toLowerCase()} are accepted or declined, and when a payment is made on ${getIndefiniteArticle(
              quoteLabel.value
            )} ${quoteLabel.value.toLowerCase()} or ${invoiceLabel.value.toLowerCase()}.`}
            learnMoreLink="http://help.paintscout.com/en/articles/8289201-automatic-emails"
          />
          <div className={classes.templates}>
            <TileList
              items={automationsItems}
              onSelect={(event: any, selected: ValueType[] | ValueType) => {
                handleEditTemplateItem(event, selected)
              }}
            />
          </div>
        </FormSection>
        {isAdmin && (
          <FormSection>
            <FormSectionTitle
              title={'JSON Editor'}
              variant={'h4'}
              subTitle={`Edit the JSON directly for the company email preferences.`}
            />
            <div style={{ display: 'flex' }}>
              <Button onClick={() => setJsonHidden((jsonHidden) => !jsonHidden)} variant={'text'}>
                {jsonHidden ? 'Show' : 'Hide'}
              </Button>
              {jsonDirty && (
                <Button onClick={() => handleJsonUpdate()} variant={'text'}>
                  Update
                </Button>
              )}
            </div>
            {jsonHidden && (
              <div>
                <InputField
                  className={classes.input}
                  multiline
                  value={updateableEmailJson}
                  onChange={(e) => {
                    setUpdateableEmailJson(e.target.value)
                    if (!jsonDirty) {
                      setJsonDirty(true)
                    }
                  }}
                  fullWidth={true}
                />
              </div>
            )}
          </FormSection>
        )}
      </Form>
    </SettingsPage>
  )
}

export default ClientEmailForm
