import type { OptionsDocument } from 'paintscout'
import type { LeadSourceResult, LeadSourceType } from '..'

/**
 * Get contact or quote lead sources based on type
 * @returns array of lead sources based on type passed in
 */
export function getLeadSources(args: {
  options: OptionsDocument
  type: LeadSourceType
}): LeadSourceResult<LeadSourceType>[] {
  const { options, type } = args
  const sources = options.options[type]

  if (!sources) {
    return []
  }

  return sources.order
    .map((orderItem) => {
      const item = sources.values[orderItem]
      if (!item) {
        return null
      }
      return {
        name: orderItem,
        label: item.label
      }
    })
    .filter((item) => item)
}
