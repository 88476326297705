import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const Check = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 15 14'} {...props}>
    <svg>
      <path d="M14.9056 0.702643L5.1717 13.4323L0.449837 7.92354L1.20909 7.27275L5.12737 11.844L14.1112 0.0952148L14.9056 0.702643Z" />
    </svg>
  </SvgIcon>
)
export default Check
