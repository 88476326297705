import React from 'react'

import { connect } from 'formik'

import sectionComponents from '../../../sections'

import { Alert, Grid, useClientOptions } from '@ui/paintscout'
import { useQuote } from '../../../../context'

import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../../'

const EmptySectionAlerts = connect<PresentationSectionFormProps, FormikPresentationSectionValues>(
  ({ formik, ..._props }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
    const { options } = useClientOptions()
    const { quote } = useQuote()

    const { values } = formik
    const { section } = values

    const isEmpty = sectionComponents?.[section.type]?.isEmpty

    if (!isEmpty) {
      return null
    }

    let isEmptyDefault = false
    if (section.type != 'about-estimator') {
      isEmptyDefault = isEmpty({ quote, options, section })
    }

    const alerts = []
    if (isEmptyDefault) {
      alerts.push(<Alert severity="info" content="This section is empty and will be hidden." />)
    }

    if (section.type === 'about-estimator') {
      alerts.push(<Alert severity="info" content="This will change based on the estimator who owns the quote." />)
    }

    if (!alerts.length) {
      return null
    }

    return (
      <Grid container spacing={1}>
        {alerts.map((alert, i) => {
          return (
            <Grid key={i} item xs={12}>
              {alert}
            </Grid>
          )
        })}
      </Grid>
    )
  }
)

export default EmptySectionAlerts
