import React from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { Route } from 'react-router-dom'
import EditClient from './EditClient'
import SearchClients from './SearchClients'

export default function ClientsIndex({ match }: RouteComponentProps) {
  return (
    <>
      <Route path={`${match.url}`} exact component={SearchClients} />
      <Route path={`${match.url}/:id/:tab?/:section?`} component={EditClient} />
    </>
  )
}
