import { useApolloClient } from '@apollo/react-hooks'
import type { User, UserQuery, UserQueryVariables } from '@paintscout/api'
import { UserDocument } from '@paintscout/api'
import type { DialogProps } from '../'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Spinner, UserTiles } from '../'
import { removeAuth0Prefix } from '@paintscout/util/users'
import { useSnackbar } from 'notistack'
import type { Auth0User } from 'paintscout'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { jsonToLucene } from 'json-to-lucene'
import { gql } from 'apollo-boost'

export interface SelectUserDialogProps extends DialogProps {
  companyId?: string
  currentUser?: User
  includeSuperadmin?: boolean
  onConfirm?: (user: User) => void
  onCancel?: () => void
}

/**
 * Admin facing User search/select dialog, give it an existing user to filter out
 * If companyid provided, will filter out users not in that company
 * if includeSuperadmin is true, will include superadmins in the list
 * Also includes the SEARCH_USERS query for admin user search, here and main page
 * @returns User if found, null if none selected
 */
function SelectUserDialog(props: SelectUserDialogProps) {
  const { onCancel, onConfirm, companyId, currentUser, includeSuperadmin = false, ...baseDialogProps } = props
  const [selectedUser, setSelectedEstimator] = useState(currentUser?.user_id ? currentUser?.user_id : '')
  const { enqueueSnackbar } = useSnackbar()
  const apolloClient = useApolloClient()

  const { loading, data, error } = useQuery<{
    searchUsers: {
      rows: Auth0User[]
      total_rows: number
    }
  }>(SEARCH_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: jsonToLucene({
        filters: [
          companyId ? { type: 'equal', id: 'app_metadata.companyId', value: companyId } : null,
          {
            type: 'equal',
            id: 'app_metadata.roles',
            not: !includeSuperadmin,
            value: 'superadmin'
          }
        ]
      }),
      limit: 100
    }
  })

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Unable to retrieve Users', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const users = data?.searchUsers?.rows ?? []
  const currentOwnerId = currentUser?.user_id
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialOwner = useMemo(() => users.find((user) => user.user_id === currentOwnerId), [users])

  return (
    <Dialog maxWidth="md" {...baseDialogProps}>
      <DialogTitle>Select Receiving Estimator</DialogTitle>
      <DialogContent>
        {loading && !users.length ? (
          <Spinner fullWidth fullHeight />
        ) : (
          <UserTiles
            disabled={loading}
            allowNone={false}
            users={[
              ...users.filter(
                (user) =>
                  removeAuth0Prefix(user.user_id) !== removeAuth0Prefix(initialOwner?.user_id ?? '') &&
                  !(
                    (user?.app_metadata?.roles ?? []).includes('viewer') &&
                    (user?.app_metadata?.roles ?? []).length === 1
                  )
              )
            ]}
            selectedUserIds={selectedUser ? [selectedUser] : []}
            onUserSelect={async (user) => {
              setSelectedEstimator(user?.user_id ?? null)
            }}
          />
        )}
      </DialogContent>
      <DialogActions
        leftButton={
          <Button onClick={onCancel} variant={'text'}>
            Cancel
          </Button>
        }
      >
        <Button
          type="submit"
          variant={'contained'}
          disabled={loading}
          onClick={async () => {
            if (selectedUser) {
              const { data } = await apolloClient.query<UserQuery, UserQueryVariables>({
                query: UserDocument,
                variables: {
                  id: selectedUser
                }
              })

              if (data.user) {
                //@ts-ignore
                onConfirm(data.user)
              } else {
                enqueueSnackbar('An error occurred while loading the selected User', { variant: 'error' })
              }
            } else {
              enqueueSnackbar('An error occurred while loading the User', { variant: 'error' })
            }
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectUserDialog

export const SEARCH_USERS = gql`
  query searchUsers($query: String!, $bookmark: String, $limit: Int) {
    searchUsers(query: $query, bookmark: $bookmark, limit: $limit) {
      bookmark
      total_rows
      rows {
        user_metadata {
          phoneNumber
          lastName
          firstName
        }
        user_id
        name
        email
        last_login
        created
        app_metadata {
          active
          companyId
          integrations
          roles
        }
      }
    }
  }
`

export interface SearchUsersResponse {
  searchUsers: {
    total_rows: number
    rows: Auth0User[]
    bookmark?: string
  }
}
