import type { RadioGroupProps, Theme } from '@material-ui/core'
import { FormControl, RadioGroup, makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import classnames from 'classnames'
import React from 'react'
import FormControlLabel from '../FormControlLabel'
import InputLabel from '../InputLabel'
import Radio from '../Radio'
import Tooltip from '../Tooltip'
import Typography from '../Typography'

const useStyles = makeStyles<Theme, RadioButtonsProps>((theme) => ({
  root: {},
  label: {},
  sublabel: {},
  radioGroup: {
    marginTop: theme.spacing(1)
  },
  radio: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  radioLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(),
    [theme.breakpoints.down('md')]: (props: RadioButtonsProps) => {
      if (!props?.labelVariant) {
        return {
          fontSize: '0.66rem',
          marginRight: 0
        }
      }
    }
  },
  selectedLabel: {
    color: '#292a2d'
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  labelDiv: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  toolTip: {
    marginLeft: -theme.spacing(0.5)
  }
}))

export interface RadioButtonsProps {
  classes?: RadioGroupProps['classes'] & StyleClasses<typeof useStyles>
  options: Array<{ label: string; name: string; sublabel?: string }>
  label?: string
  labelVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'overline'
  sublabel?: string
  value?: string
  name?: string
  disabled?: boolean
  color?: 'primary' | 'secondary' | 'default'
  row?: boolean
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end'
  toolTip?: string | React.ReactNode
  interactiveToolTip?: boolean
  onChange: (value: string) => void
  badgeTop?: string
  badgeRight?: string
}

function RadioButtons(props: RadioButtonsProps) {
  const classes = useStyles(props)
  const {
    labelPlacement,
    row,
    options,
    label,
    sublabel,
    name,
    value,
    disabled,
    color,
    onChange,
    toolTip,
    interactiveToolTip
  } = props

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value)
  }

  return (
    <FormControl classes={{ root: classes.root }}>
      {label && (
        <div className={classes.labelDiv}>
          {!toolTip && (
            <InputLabel className={classes.radioLabel} disabled={disabled}>
              {label}
            </InputLabel>
          )}
          {toolTip && (
            <Tooltip
              badgeRight={props.badgeRight}
              badgeTop={props.badgeTop}
              interactive={interactiveToolTip}
              content={toolTip}
            >
              <InputLabel className={classes.radioLabel} disabled={disabled}>
                {label}
              </InputLabel>
            </Tooltip>
          )}
        </div>
      )}
      {sublabel && <Typography variant={'subtitle1'}>{sublabel}</Typography>}
      <RadioGroup
        row={row}
        aria-label={label}
        name={name}
        value={value}
        onChange={handleChange}
        classes={{ root: classes.radioGroup }}
      >
        {options.map((option, i) => {
          const selectedOption = option.name === value
          return (
            <FormControlLabel
              key={i}
              value={option.name}
              disabled={disabled}
              labelPlacement={labelPlacement}
              control={<Radio className={classes.radio} color={color ?? 'primary'} />}
              label={
                <>
                  <Typography
                    variant={'body1'}
                    className={classnames({
                      [classes.selectedLabel]: selectedOption,
                      [classes.disabled]: disabled
                    })}
                  >
                    {option.label}
                  </Typography>
                  {option.sublabel && (
                    <Typography
                      variant={'subtitle2'}
                      className={classnames({
                        [classes.sublabel]: true,
                        [classes.selectedSublabel]: selectedOption,
                        [classes.disabled]: disabled
                      })}
                    >
                      {option.sublabel}
                    </Typography>
                  )}
                </>
              }
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButtons
