import { makeStyles } from '@material-ui/core/styles'
import type { DropdownSelectOption } from '@ui/paintscout'

import {
  Grid,
  FormSectionTitle,
  NewFormSection as FormSection,
  ConfirmationDialog,
  useDialogs,
  DropdownSelect,
  Editor,
  useClientOptions
} from '@ui/paintscout'
import type { ValueType } from '@ui/paintscout/src/TileList'
import {
  createTermsOption,
  deleteTermsOption,
  getObjectLabels,
  getTermsOptions,
  reorderTermsOptions,
  copyTermsOption,
  deleteTermsOptions,
  getFeature
} from '@paintscout/util/builder'
import type { TermsOption, LabelsOption } from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'

import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useState } from 'react'
import EditTermsOptionDialog from '../../dialogs/EditTermsOptionDialog'
import OptionsTileList from '../../OptionsTileList'
import SettingsPage from '@ui/paintscout/src/SettingsPage'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(5)}px ${theme.spacing()}px 0`
  },
  title: {
    marginLeft: theme.spacing(3)
  }
}))

export interface TermsOptionsProps {
  showOnlyAdvanced?: boolean
  onDialogConfirm?: () => void
}

function TermsOptions({ onDialogConfirm, showOnlyAdvanced }: TermsOptionsProps) {
  const classes = useStyles({})
  const { openDialog, dismissDialog } = useDialogs()
  const [quoteType, setQuoteType] = useState('all')
  const [selected, setSelected] = useState<ValueType[]>([])
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values

  const objectLabels = getObjectLabels({ options })
  const items = getTermsOptions({ options, quoteType: quoteType, inactive: true })
  const hasInvoices = getFeature({ options, path: 'invoices.enabled' })
  const hasInvoiceTerms = getFeature({ options, path: 'invoiceTerms.enabled' })

  if (showOnlyAdvanced) {
    return (
      <div className={classes.root}>
        <AdminTermsOptions objectLabels={objectLabels} options={options} />
      </div>
    )
  }

  return (
    <SettingsPage
      title={'Terms'}
      callout={{
        path: 'settings-terms',
        content: `Add your legal contract which will appear on ${objectLabels.quote.plural.toLowerCase()} and ${getObjectLabels(
          { options, invoice: true }
        ).quote.plural.toLowerCase()}.
        These terms outline the contractual obligations and other relevant conditions that govern the agreement between you and your customer.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/3207877-updating-estimate-terms'
      }}
    >
      <Form className={classes.root}>
        <FormSection hidePadding selectHeader selectValue={quoteType} changeSelect={setQuoteType}>
          <OptionsTileList
            title={''}
            items={items.map((item) => ({ ...item, key: item.name }))}
            createTitle={'Add New Terms Document'}
            showCreate={true}
            onReorder={handleReorder}
            selected={selected}
            setSelected={setSelected}
            onDeleteBulkItems={handleDeleteBulkItems}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            onCopyItem={handleCopyItem}
            onCreateClick={handleCreateItem}
            noItemTitle={'No Terms Documents'}
            noItemHelpText={`Use various terms documents for different types of ${objectLabels.quote.plural}`}
          />
        </FormSection>
        {hasInvoices && hasInvoiceTerms && <AdminTermsOptions objectLabels={objectLabels} options={options} />}
      </Form>
    </SettingsPage>
  )

  function handleReorder(termsOptions: TermsOption[]) {
    const updatedOptions = reorderTermsOptions({ termsOptions, options: options })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getTermsOptions({ options, inactive: true })
    const item = items.find((i) => i.name === key)

    openDialog(EditTermsOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        if (onDialogConfirm && isDirty) {
          setFieldValue('options', updatedOptions)
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCreateItem(_event: React.MouseEvent) {
    const item = createTermsOption()

    openDialog(EditTermsOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        setFieldValue('options', updatedOptions)
        dismissDialog()
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These Terms will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getTermsOptions({ options: options, inactive: true })
        const updatedOptions = deleteTermsOption({ termsOption: items.find((i) => i.name === key), options })

        setFieldValue('options', updatedOptions)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These Terms will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getTermsOptions({ options: options, inactive: true })
        const selectedTerms = items.filter((i) => keys.includes(i.name))
        const updatedOptions = deleteTermsOptions({
          termsOptions: selectedTerms,
          options
        })

        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyTermsOption({ options, key })
    setFieldValue('options', updatedOptions)
  }
}

interface AdminTermsOptionProps {
  objectLabels: LabelsOption
  options: OptionsDocument
}

const AdminTermsOptions = ({ objectLabels, options }: AdminTermsOptionProps) => {
  const classes = useStyles({})
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { isAdmin } = useClientOptions()

  const invoiceLabels = getObjectLabels({ options, invoice: true })

  const visibilityOptions: DropdownSelectOption[] = [
    {
      value: 'quote',
      label: `Use ${objectLabels.quote.value} terms on ${invoiceLabels.quote.plural}`
    },
    {
      value: 'both',
      label: `Use both ${invoiceLabels.quote.value} terms and ${objectLabels.quote.value} terms on ${invoiceLabels.quote.plural}`
    },
    {
      value: 'invoice',
      label: `Use ${invoiceLabels.quote.value} terms on ${invoiceLabels.quote.plural}`
    }
  ]

  return (
    <Form>
      <FormSectionTitle classes={{ root: classes.title }} title={'Invoice Terms'} variant="h2" />
      <FormSection>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              variant="single"
              label={'Visibility'}
              sublabel={
                isAdmin ? 'Note: InvoiceTerms feature must be enabled for customer to see these settings' : null
              }
              margin="dense"
              options={visibilityOptions}
              fullWidth
              onChange={(option: DropdownSelectOption) => {
                setFieldValue('options.options.terms.values.invoice.visibility', option.value)
              }}
              value={
                visibilityOptions.find(
                  (option) => option.value === options.options.terms?.values?.invoice?.visibility
                ) ?? visibilityOptions[0]
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Editor
              label="Content"
              content={values.options?.options?.terms?.values?.invoice?.content}
              fullWidth={true}
              onChange={(content: string) => {
                setFieldValue('options.options.terms.values.invoice.content', content)
              }}
              debounce
              toolbar={{
                headings: true,
                align: true
              }}
            />
          </Grid>
        </Grid>
      </FormSection>
    </Form>
  )
}

export default TermsOptions
