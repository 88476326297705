/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'

import cloneDeep from 'lodash/cloneDeep'
import { uuid } from '../../util'

export function copyTermsOption(args: { options: OptionsDocument; key: string }): OptionsDocument {
  const { options, key } = args

  const newItem = cloneDeep(options.options.terms.values[key])
  newItem.name = uuid()
  newItem.label = `${newItem.label} (copy)`

  const order = options.options.terms.order
  const orderIndex = order.indexOf(key)
  if (orderIndex !== -1) {
    order.splice(orderIndex + 1, 0, newItem.name)
  } else {
    order.push(newItem.name)
  }

  return {
    ...options,
    options: {
      ...options.options,
      terms: {
        ...options.options.terms,
        order,
        values: {
          ...options.options.terms.values,
          [newItem.name]: newItem
        }
      }
    }
  }
}
