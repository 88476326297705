import selectTemplate from './select-template'

/**
 * Check imported CSV headers for validity against csv template.
 * @param text csv text
 * @param type type of csv we are checking
 * @param provider if quote type, what provider
 * @returns boolean - is csv valid?
 */
const alpha = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
]
const checkImportedCSV = (text: string, type: string, provider?: string) => {
  const template = selectTemplate(type, provider)
  const headers = text
    .split('\n')[0]
    .split(',')
    .slice(0, template.length)
    .map((header) => header.replace(/\r|"/g, ''))

  let valid = true
  const errors = []
  template.forEach((header, i) => {
    if (headers[i] !== header) {
      console.log(`${header} does not match ${headers[i]}`)
      errors.push(`- Received: '${headers[i]}', Expected: '${header}' as header in column ${alpha[i].toUpperCase()}\n`)
      valid = false
    }
  })

  return { valid, errors }
}

export default checkImportedCSV
