import type { MutableRefObject } from 'react'
import React from 'react'
import Grid from '../Grid'
import InputField from '../InputField'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import type { Moment } from 'moment'
import type { DatePickerInputProps } from './DateRangePicker'

const useStyles = makeStyles((theme) => ({
  base: {
    ...theme.typography.body1,
    cursor: 'pointer !important',
    '& input': {
      cursor: 'pointer !important'
    },
    '& svg': {
      fontSize: '1rem'
    }
  },
  selected: {
    borderColor: theme.palette.primary.main,
    '& input': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main} !important`,
      '-webkit-box-shadow': `inset 0 0 0 2px ${theme.palette.primary.main}!important`,
      '-webkit-border-radius': '0px',
      '-webkit-appearance': 'none'
    },
    '&:hover': {
      backgroundColor: 'inherit',
      boxShadow: 'none'
    }
  },
  unselected: {
    borderColor: theme.palette.grey[500],
    '& input': {
      opacity: 0.6
    }
  }
}))

interface DatePickerInputsProps {
  focusedInput: 'startDate' | 'endDate'
  anchorRef?: MutableRefObject<any>
  startDate?: Moment
  endDate?: Moment
  disabled?: boolean
  anchorRefRight?: boolean
  inputProps?: DatePickerInputProps
  onStartClick: () => void
  onEndClick: () => void
}

export function DatePickerInputs(props: DatePickerInputsProps) {
  const {
    anchorRef,
    focusedInput,
    anchorRefRight,
    startDate,
    endDate,
    disabled,
    inputProps,
    onStartClick,
    onEndClick
  } = props
  const classes = useStyles()
  const dateFormat = 'MMM D, YYYY'
  return (
    <>
      <Grid item xs={6}>
        <InputField
          className={classnames(
            classes.base,
            focusedInput === 'startDate' && classes.selected,
            focusedInput === 'endDate' && classes.unselected
          )}
          placeholder={inputProps?.fromPlaceholder || 'Select Date'}
          inputRef={anchorRefRight ? null : anchorRef}
          readOnly
          fullWidth
          value={startDate ? startDate.format(dateFormat) : ''}
          label={inputProps?.fromLabel || 'From'}
          onClick={onStartClick}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          className={classnames(
            classes.base,
            focusedInput === 'endDate' && classes.selected,
            focusedInput === 'startDate' && classes.unselected
          )}
          readOnly
          placeholder={inputProps?.toPlaceholder || 'Select Date'}
          inputRef={anchorRefRight ? anchorRef : null}
          fullWidth
          value={endDate ? endDate.format(dateFormat) : ''}
          label={inputProps?.toLabel || 'To'}
          onClick={onEndClick}
          disabled={disabled}
        />
      </Grid>
    </>
  )
}
