import type { OptionsDocument, RateOption } from 'paintscout'
import { getObjectLabels } from '../../util'
import { convertCalculationType } from './rate-utils'

export function getRateCaption({ options, rate }: { options: OptionsDocument; rate: RateOption }): string {
  const { currency, unit } = getObjectLabels({ options })
  const { values, rate_type: rateType, default_applications: defaultApplications } = rate
  const calculationType = convertCalculationType(rate.calculation_type)
  const value = values && values[defaultApplications - 1] ? values[defaultApplications - 1] : ''
  if (['hoursPerAmount', 'amountPerHour'].includes(calculationType)) {
    if (rateType === 'quantity') {
      return `${value} hrs/item`
    } else if (rateType === 'lnft') {
      return `${value} ${unit.abbreviation.linear}/hr`
    } else if (rateType === 'cuft') {
      return `${value} ${unit.abbreviation.cubic}/hr`
    } else {
      return `${value} ${unit.abbreviation.square}/hr`
    }
  } else if (calculationType === 'pricePerAmount') {
    if (rateType === 'quantity') {
      return `${currency.symbol}${value}/item`
    } else if (rateType === 'lnft') {
      return `${currency.symbol}${value}/${unit.abbreviation.linear}`
    } else if (rateType === 'cuft') {
      return `${currency.symbol}${value}/${unit.abbreviation.cubic}`
    } else {
      return `${currency.symbol}${value}/${unit.abbreviation.square}`
    }
  } else if (calculationType === 'hours') {
    return `${value}hr`
  } else if (calculationType === 'price') {
    return `${currency.symbol}${value}`
  }
  return ''
}
