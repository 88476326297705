import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  FormSection,
  FormSectionTitle,
  Grid,
  useClientOptions,
  EventSubscriptionType,
  Typography,
  Checkbox,
  Select,
  MenuItem
} from '../../'
import { useProcessEvent, useAdminIntegrationsConfig } from '@ui/core'
import type { ProviderConfig, EventType } from 'paintscout'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'
import find from 'lodash/find'

const useStyles = makeStyles<Theme, SageProviderFormProps>(
  (theme) => ({
    root: {},
    configSection: {
      marginLeft: theme.spacing(4),
      cursor: 'pointer'
    },
    itemSelection: {
      marginLeft: theme.typography.pxToRem(5),
      marginRight: theme.typography.pxToRem(5)
    }
  }),
  { name: 'SageProviderForm' }
)

export interface SageProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
  companyId?: string
  userId?: string
}

export function SageProviderForm(props: SageProviderFormProps) {
  const {
    providerConfig,
    providerName,
    isSettingsIntegration = false,
    isAdminIntegrations = false,
    companyId = '',
    userId = '',
    onChange
  } = props
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { handleEventChange } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])
  const classes = useStyles(props)
  const { enabled, config } = providerConfig

  const [taxRates, setTaxRates] = useState([])
  const [ledgerAccounts, setLedgerAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const { adminIntegrationsConfig } = useAdminIntegrationsConfig()
  const { processEvent } = useProcessEvent()

  useEffect(() => {
    async function load() {
      if (enabled) {
        setLoading(true)
        const configItemsRes = isAdminIntegrations
          ? await adminIntegrationsConfig({
              provider: providerName,
              type: 'get-config-items',
              params: {
                companyId,
                userId
              }
            })
          : await processEvent({
              provider: providerName,
              type: 'get-config-items',
              params: {}
            })

        setLedgerAccounts(configItemsRes?.result?.ledgerAccounts ?? [])
        setTaxRates(configItemsRes?.result?.taxRates ?? [])

        setLoading(false)
      }
    }
    load()
  }, [enabled])

  const ItemSelection = (props: { name: string; items: Array<{ value: number; label: string }> }) => {
    const { name, items } = props
    return (
      <Select
        name={name}
        disabled={loading}
        classes={{ root: classes.itemSelection }}
        value={config[name]?.value}
        onChange={handleItemSelection}
        displayEmpty
        variant={'outlined'}
        renderValue={(selectedValue: string) => {
          const selected = find(items, { value: selectedValue }) as { value: number | string; label: string }
          return selected?.label ?? 'Select Item...'
        }}
      >
        {(items ?? []).map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    )
  }

  const handleItemSelection = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = ev.target
    let item = null

    if (name === 'customTaxRate') {
      item = find(taxRates, { value })
    } else if (name === 'ledgerAccounts') {
      item = find(ledgerAccounts, { value })
    }

    props.onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: item
      }
    })
  }

  return (
    <>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle title={'Create Quote'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-sent' as EventType}
              event={providerConfig.events['quote-sent']}
              onChange={handleEventChange}
              label={`When ${objectLabels.quote.plural} are sent`}
            />
          </Grid>
        </Grid>
        <FormSectionTitle title={'Create Invoice'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'invoice-sent' as EventType}
              event={providerConfig.events['invoice-sent']}
              onChange={handleEventChange}
              label={`When Invoices are sent`}
              selfLabel={'My Invoices'}
              allLabel={'All Invoices'}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSectionTitle
              title={'Line Item Ledger Account'}
              subTitle={`Paintscout will not show line item prices, but create a single "total item" on the ${objectLabels.quote.plural}/invoice.`}
              variant={'h4'}
            />
            <Grid item xs={12} md={12}>
              <Grid container spacing={1} alignItems={'center'} direction="row">
                <Grid item container alignItems="center" md={6}>
                  <Grid item>
                    <Typography>Choose Ledger Account:</Typography>
                  </Grid>
                  <Grid item>
                    <ItemSelection name={'ledgerAccounts'} items={ledgerAccounts} />
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" md={6}>
                  <Grid item>
                    <Checkbox
                      checked={!!providerConfig?.config?.dontCreateItems}
                      onChange={() => {
                        props.onChange(providerName, {
                          ...providerConfig,
                          config: {
                            ...providerConfig.config,
                            dontCreateItems: !providerConfig?.config?.dontCreateItems
                          }
                        })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>Don't create items (create a blank invoice)</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {' '}
                <FormSectionTitle
                  title={'Tax Rate'}
                  subTitle={'PaintScout must use a Tax Rate that has already been defined in Sage.'}
                  variant={'h4'}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems={'center'}>
                  <Grid item>
                    <Typography>Use tax rate:</Typography>
                  </Grid>
                  <Grid item>
                    <ItemSelection name={'customTaxRate'} items={taxRates} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}
