import React, { useState, useMemo } from 'react'
import { useFormikContext } from 'formik'
import InputField from '../../InputField'
import { makeStyles, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import AddIcon from '@material-ui/icons/Add'
import Button from '../../Button'
import type { UserPreferences } from 'paintscout'
import IconButton from '../../IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import Typography from '../../Typography'
import { isEmailValid } from '@paintscout/util/util'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 600
  },
  addressBookField: {
    width: '100%'
  },
  addressBookInput: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    border: '1px solid red'
  },
  addressBookList: {
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    height: 300,
    width: '100%',
    overflowY: 'scroll'
  }
}))

export default function AddressBook() {
  const { values, setFieldValue } = useFormikContext<{ preferences: UserPreferences }>()
  const [newAddress, setNewAddress] = useState('')
  const [error, setError] = useState('')
  const classes = useStyles()

  function addNewAddress() {
    const isInvalid = !isEmailValid(newAddress)
    const isDuplicate = values.preferences.addressBook.some((email) => email === newAddress)

    if (isDuplicate) {
      setNewAddress('')
      setError('')
    } else if (isInvalid) {
      setError('Email is invalid')
    } else {
      setFieldValue('preferences.addressBook', [...values.preferences.addressBook, newAddress])
      setNewAddress('')
      setError('')
    }
  }

  function deleteAddress(address: string) {
    setFieldValue('preferences.addressBook', [...values.preferences.addressBook.filter((a) => a !== address)])
  }

  const filteredAddressbook = useMemo(
    () =>
      values.preferences.addressBook.filter(
        (address) => !newAddress || new RegExp(newAddress.replace(/[.*+?^${}()|[\]\\]/g, ''), 'gi').test(address)
      ),
    [newAddress, values.preferences.addressBook]
  )

  return (
    <div className={classes.root}>
      <InputField
        fullWidth
        type="text"
        margin="dense"
        name={'email'}
        placeholder={'Search or add an email address'}
        value={newAddress}
        onChange={(ev) => setNewAddress(ev.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            addNewAddress()
          }
        }}
        startAdornment={<EmailIcon />}
        endAdornment={
          <Button variant={'text'} icon={AddIcon} onClick={addNewAddress}>
            Add
          </Button>
        }
      />
      <div className={classes.addressBookList}>
        <List dense={true}>
          {filteredAddressbook.map((address) => {
            return (
              <ListItem key={address}>
                <ListItemText primary={address} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => deleteAddress(address)}>
                    <ClearIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
          {filteredAddressbook.length === 0 && !error && (
            <Typography variant="subtitle2" align="center" style={{ marginTop: 16 }}>
              {!newAddress ? 'Add a new address by typing it in' : `Click Add or hit enter to add this address`}
            </Typography>
          )}
          {error && (
            <Typography variant="subtitle2" color="error" align="center" style={{ marginTop: 16 }}>
              {error}
            </Typography>
          )}
        </List>
      </div>
    </div>
  )
}
