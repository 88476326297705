import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {
  CheckboxField,
  FormikHtmlEditor,
  NewFormSection as FormSection,
  FormSectionTitle,
  Grid,
  PageHelpMessage,
  Switch,
  Tooltip,
  useClientOptions
} from '@ui/paintscout'
import SettingsPage from '@ui/paintscout/src/SettingsPage'
import { getObjectLabels } from '@paintscout/util/builder'
import { getIndefiniteArticle } from '@paintscout/util/util'
import { FastField, useFormikContext, Form } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {},
  switchDiv: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: `${-theme.spacing()}px`
  },
  spacer: {
    height: '6px'
  }
}))

export interface DisclaimerOptionsProps {
  showOnlyAdvanced?: boolean
}

function DisclaimerOptions(props: DisclaimerOptionsProps) {
  const classes = useStyles({})
  const { isAdmin } = useClientOptions()
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { showOnlyAdvanced } = props
  const { options } = values
  const objectLabels = getObjectLabels({ options })

  const showQuoteDisclaimer =
    options.options.quotes?.requireSignatureOnSpot || options.options.quotes?.requireSignatureSent

  if (showOnlyAdvanced) {
    return (
      <Form className={classes.root}>
        <HelpMessage />
        <AdminViewSettings />
      </Form>
    )
  }

  return (
    <SettingsPage
      title={'Disclaimers'}
      callout={{
        path: 'estimate-disclaimers',
        content: `${objectLabels.quote.plural} require an electronic signature from your customer upon acceptance. Use the disclaimers to personalize the message displayed below the signature box.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/6063029-customer-view-acceptance-overview'
      }}
    >
      <Form className={classes.root}>
        <FormSection>
          <Grid item container spacing={3} xs={12}>
            {showQuoteDisclaimer && (
              <Grid item xs={12}>
                <Tooltip
                  content={`This disclaimer will appear when a customer signs and accepts ${getIndefiniteArticle(
                    objectLabels.quote.value
                  )} ${objectLabels.quote.value.toLowerCase()}.`}
                >
                  <Typography variant={'h3'}>Acceptance Disclaimer</Typography>
                </Tooltip>
                <div className={classes.spacer} />
                <FastField
                  debounce
                  component={FormikHtmlEditor}
                  name="options.options.acceptDisclaimer"
                  multiline
                  fullWidth
                />
              </Grid>
            )}
            {options.options.quotes?.requireSignatureAdditionalWork && (
              <Grid item xs={12}>
                <Tooltip content={`This disclaimer will appear when a customer signs and accepts additional work.`}>
                  <Typography variant={'h3'}>Additional Work Disclaimer</Typography>
                </Tooltip>
                <div className={classes.spacer} />
                <FastField
                  debounce
                  component={FormikHtmlEditor}
                  name="options.options.acceptAdditionalDisclaimer"
                  multiline
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Tooltip
                content={` This disclaimer will appear when a customer declines ${getIndefiniteArticle(
                  objectLabels.quote.value
                )} ${objectLabels.quote.value.toLowerCase()}.`}
              >
                <Typography variant={'h3'}>Declined Disclaimer</Typography>
              </Tooltip>
              <Grid className={classes.switchDiv} item>
                <Switch
                  name="options.options.allowDecline"
                  checked={options.options?.allowDecline}
                  onChange={() => {
                    setFieldValue('options.options.allowDecline', !options.options?.allowDecline)
                  }}
                />
                <Typography variant={'body1'}>
                  Allow customers to decline {objectLabels.quote.plural.toLowerCase()}
                </Typography>
              </Grid>
              {options?.options?.allowDecline && (
                <Grid item xs={12}>
                  <div className={classes.spacer} />
                  <FastField
                    component={FormikHtmlEditor}
                    name="options.options.declineDisclaimer"
                    multiline
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </FormSection>
        {isAdmin && <AdminViewSettings />}
      </Form>
    </SettingsPage>
  )
}

const AdminViewSettings = () => {
  const { values } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })

  return (
    <>
      <FormSection>
        <FormSectionTitle title={`Accepting ${objectLabels.quote.plural}`} variant={'h3'} />
        <Grid item container spacing={3} direction="column">
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography variant={'h6'}>Require Customer Signature</Typography>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <CheckboxField
                  data-testid={'settings-require-on-spot'}
                  name="options.options.quotes.requireSignatureOnSpot"
                  label={`When Accepting ${objectLabels.quote.plural} on the spot`}
                />
              </Grid>
              <Grid item>
                <CheckboxField
                  data-testid={'settings-require-on-sent'}
                  name="options.options.quotes.requireSignatureSent"
                  label={`When Accepting Sent ${objectLabels.quote.plural}`}
                />
              </Grid>
              <Grid item>
                <CheckboxField
                  data-testid={'settings-require-on-additional'}
                  name="options.options.quotes.requireSignatureAdditionalWork"
                  label={`When Accepting ${objectLabels.additionalWork.plural}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>{' '}
      <FormSection>
        <FormSectionTitle title={`${objectLabels.quote.value} Top Bar`} variant={'h3'} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CheckboxField
              defaultValue={false}
              name="options.options.quotes.hideTotalOnPresentation"
              label="Hide total price"
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

const HelpMessage = () => {
  const { values } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })

  return (
    <PageHelpMessage
      path={'settings-quote-disclaimers'}
      openContent={
        <>
          <Typography>These options reflect how customers see & interact with {objectLabels.quote.plural}</Typography>
        </>
      }
      closedContent={
        <>
          <FormSectionTitle title={`Customer View & Acceptance Settings`} variant="h2" />
        </>
      }
    />
  )
}

export default DisclaimerOptions
