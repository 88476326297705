export function convertRateType(rateType: string): string {
  switch (rateType) {
    case 'sqft_walls':
      return 'sqftWalls'
    case 'sqft_ceiling':
      return 'sqftCeiling'
    case 'sqft_floor':
      return 'sqftFloor'
    default:
      return rateType
  }
}

export function convertCalculationType(calculationType: string): string {
  switch (calculationType) {
    case 'value_per_hour':
      return 'amountPerHour'
    case 'price_per_value':
      return 'pricePerAmount'
    case 'hours_per_value':
      return 'hoursPerAmount'
    case 'value_per_price':
      return 'amountPerPrice'
    default:
      return calculationType
  }
}
