import React from 'react'
import { Grid, FormControlLabel, Checkbox } from '@ui/paintscout'

import type { FormikPresentationSectionConnectProps, FormikPresentationSectionValues } from '../../../'
import { connect } from 'formik'

export interface AdvancedOptionsProps {}

const AdvancedOptions = ({ formik, ..._props }: AdvancedOptionsProps & FormikPresentationSectionConnectProps) => {
  const { section } = formik.values
  const advanced = section?.advanced ?? {}

  return (
    <Grid container alignItems={'flex-end'} spacing={1}>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={advanced?.allowCustom} onChange={handleCheckboxChange('allowCustom')} />}
          label={'Allow Estimators to Customize or Hide this section on each quote.'}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={advanced?.useForNav} onChange={handleCheckboxChange('useForNav')} />}
          label={'Include this section in navigation.'}
        />
      </Grid>
    </Grid>
  )

  function handleCheckboxChange(name: string) {
    return function () {
      formik.setFieldValue(`section.advanced.${name}` as any, !section?.advanced?.[name])
    }
  }
}

export default connect<AdvancedOptionsProps, FormikPresentationSectionValues>(AdvancedOptions)
