import type { LeadSourceResult, LeadSourceType } from '../..'
import { uuid } from '../..'

/**
 * Create a new source can be used for quote or contact sources
 * @returns a new source
 */
export function createLeadSource<T extends LeadSourceType>(): LeadSourceResult<T> {
  return {
    name: uuid(),
    label: ''
  }
}
