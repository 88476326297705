/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { LineItemOption } from '../../index'

export function updateLineItemOption(args: {
  lineItemOption: LineItemOption
  options: OptionsDocument
}): OptionsDocument {
  const { options, lineItemOption } = args

  const { quoteTypes } = lineItemOption

  const order = [...options.options.lineItems.order]
  const orderIndex = order.indexOf(lineItemOption.name)
  if (orderIndex === -1) {
    order.push(lineItemOption.name)
  }

  const quoteTypeOrder = { ...options.options.lineItems?.quoteTypeOrder }
  quoteTypes?.forEach((quoteType: string) => {
    if (quoteTypeOrder[quoteType]) {
      const quoteTypeOrderIndex = quoteTypeOrder[quoteType].indexOf(lineItemOption.name)
      if (quoteTypeOrderIndex === -1) {
        quoteTypeOrder[quoteType].push(lineItemOption.name)
      }
    }
  })

  let hourlyRate = lineItemOption.hourlyRate
  if (typeof hourlyRate !== 'number') {
    hourlyRate = parseFloat(hourlyRate)
  }
  if (isNaN(hourlyRate)) {
    hourlyRate = 0
  }

  return {
    ...options,
    options: {
      ...options.options,
      lineItems: {
        ...options.options.lineItems,
        values: {
          ...options.options.lineItems.values,
          [lineItemOption.name]: {
            active: true,
            ...options.options.lineItems.values[lineItemOption.name],
            ...lineItemOption,
            hourlyRate
          }
        },
        order,
        quoteTypeOrder
      }
    }
  }
}
