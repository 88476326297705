import { makeStyles } from '@material-ui/core'
import { NewFormSection as FormSection, ConfirmationDialog, useDialogs } from '@ui/paintscout'
import type { ValueType } from '@ui/paintscout/src/TileList'
import {
  createAreaLabelOption,
  deleteAreaLabelOption,
  getAreaLabelOptions,
  getObjectLabels,
  reorderAreaLabelOptions,
  copyAreaLabelOption,
  deleteAreaLabelOptions
} from '@paintscout/util/builder'
import type { AreaLabelOption } from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React, { useState } from 'react'
import EditAreaOptionDialog from '../../dialogs/EditAreaOptionDialog'
import OptionsTileList from '../../OptionsTileList'
import SettingsPage from '@ui/paintscout/src/SettingsPage'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(5)}px ${theme.spacing()}px 0`
  },
  title: {
    padding: 0
  }
}))

export interface AreaOptionsProps {
  onDialogConfirm?: () => void
}

function AreaOptions({ onDialogConfirm, ...props }: AreaOptionsProps) {
  const classes = useStyles(props)
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()

  const { options } = values

  const [quoteType, setQuoteType] = useState('all')
  const objectLabels = getObjectLabels({ options: options })
  const { openDialog, dismissDialog } = useDialogs()
  const items = getAreaLabelOptions({ options: options, quoteType, inactive: true })
  const [selected, setSelected] = useState<ValueType[]>([])

  function handleReorder(areaLabelOptions: AreaLabelOption[]) {
    const updatedOptions = reorderAreaLabelOptions({
      areaLabelOptions,
      options: options,
      quoteType
    })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getAreaLabelOptions({ options, quoteType, inactive: true })
    const item = items.find((item) => item.name === key)

    openDialog(EditAreaOptionDialog, {
      options,
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        dismissDialog()
        if (onDialogConfirm && isDirty) {
          setFieldValue('options', updatedOptions)
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCreateItem(_event: React.MouseEvent) {
    const item = createAreaLabelOption({ quoteType })

    openDialog(EditAreaOptionDialog, {
      options,
      item,
      isNew: true,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        dismissDialog()
        if (onDialogConfirm) {
          setFieldValue('options', updatedOptions)
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These items will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getAreaLabelOptions({ options, quoteType, inactive: true })
        const updatedOptions = deleteAreaLabelOptions({
          areaLabelOptions: items.filter((item) => keys.includes(item.name)),
          options
        })

        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>This item will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getAreaLabelOptions({ options, quoteType, inactive: true })
        const updatedOptions = deleteAreaLabelOption({
          areaLabelOption: items.find((item) => item.name === key),
          options
        })

        setFieldValue('options', updatedOptions)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyAreaLabelOption({ options, key })
    setFieldValue('options', updatedOptions)
  }

  return (
    <SettingsPage
      title={'Area Names'}
      callout={{
        path: 'area-names',
        content: `Create a list of the various interior and exterior spaces where work will be done. This list will be available for selection when adding production rates to ${objectLabels.quote.plural.toLowerCase()}.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/8289187-area-names'
      }}
    >
      <Form className={classes.root}>
        <FormSection selectValue={quoteType} changeSelect={setQuoteType} selectHeader hidePadding>
          <OptionsTileList
            title={''}
            items={items.map((item) => ({ ...item, key: item.name }))}
            createTitle={'Add New Area'}
            showCreate={true}
            selected={selected}
            setSelected={setSelected}
            onDeleteBulkItems={handleDeleteBulkItems}
            onReorder={handleReorder}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            onCreateClick={handleCreateItem}
            onCopyItem={handleCopyItem}
            noItemTitle={'No Areas'}
            noItemHelpText={'Organize Rates in Areas. Add an area to get started'}
          />
        </FormSection>
      </Form>
    </SettingsPage>
  )
}

export default AreaOptions
