import type { Theme } from '@material-ui/core'
import { CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'
import Button from '../Button'

const useStyles = makeStyles<Theme, FilterMenuFooterProps>((_theme: Theme) => ({
  filterButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: ({ padButtons }) => (padButtons ? '0 8px' : 0)
  },
  exportButton: {
    position: 'relative'
  },
  exportSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  clearButton: {
    marginLeft: ({ padButtons }) => (padButtons ? -8 : -14)
  }
}))

interface FilterMenuFooterProps {
  padButtons?: boolean
  onSearch: () => void
  onClear: () => void
  onExport: () => void
  showExport: boolean
  isExporting: boolean
}

export default function FilterMenuFooter(props: FilterMenuFooterProps) {
  const classes = useStyles(props)
  const { onSearch, onClear, onExport, showExport, isExporting } = props
  return (
    <div className={classes.filterButtons}>
      <Button className={classes.clearButton} variant="text" onClick={onClear}>
        Clear
      </Button>
      <div>
        {showExport && (
          <Button
            className={classes.exportButton}
            variant="text"
            style={{ marginRight: 8 }}
            disabled={isExporting}
            onClick={onExport}
          >
            Export
            {isExporting && <CircularProgress size={24} className={classes.exportSpinner} />}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            onSearch()
          }}
        >
          SEARCH
        </Button>
      </div>
    </div>
  )
}
