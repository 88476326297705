/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { ProductOption } from '../../index'
import get from 'lodash/get'

export function getProductOption(args: { options: OptionsDocument; key: string }): ProductOption {
  const { options, key } = args
  const { products } = options.options

  const item = products.values[key]
  if (!item) {
    return null
  }

  let quoteTypes
  if (item.quoteTypes) {
    quoteTypes = item.quoteTypes.filter(Boolean).length > 0 ? item.quoteTypes : ['all']
  } else {
    quoteTypes = [get(item, 'int_ext.value', 'all')]
  }

  return {
    ...item,
    key,
    quoteTypes,
    active: typeof item.active === 'undefined' ? true : !!item.active
  }
}
