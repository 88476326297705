export default function getRangePresets() {
  return [
    {
      value: 'thisWeek',
      label: `This Week`
    },
    {
      value: 'lastWeek',
      label: 'Last Week'
    },
    {
      value: 'lastMonth',
      label: 'Last Month'
    },
    {
      value: 'thisMonth',
      label: 'This Month'
    },
    {
      value: 'lastThirtyDays',
      label: 'Last 30 Days'
    },
    {
      value: 'lastFortyFiveDays',
      label: 'Last 45 Days'
    },
    {
      value: 'yearToDate',
      label: 'This Year'
    },
    {
      value: 'lastYear',
      label: 'Last Year'
    },
    {
      value: 'allTime',
      label: 'All Time'
    }
  ]
}
