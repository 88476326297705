import type { ProductOption, Product } from '..'

export function convertProductOption(args: { productOption: ProductOption }): Product {
  const { productOption } = args
  const { key, label, coverage_rate, lnft_coverage, quantity_coverage, quoteTypes, rateTypes, price, active } =
    productOption

  return {
    key,
    label,
    coverage: coverage_rate,
    lnftCoverage: lnft_coverage,
    quantityCoverage: quantity_coverage,
    quoteTypes,
    rateTypes,
    price,
    active
  }
}

export function convertProductOptions(args: { productOptions: ProductOption[] }): Product[] {
  const { productOptions } = args

  return productOptions.map((productOption) => {
    return convertProductOption({ productOption })
  })
}
