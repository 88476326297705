import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { makeStyles } from '@material-ui/core'
import { FormikInputField, Typography, Grid, Switch, FormSectionTitle, SwitchField } from '@ui/paintscout'
import { NewFormSection as FormSection } from '@ui/paintscout/src/FormSection'
import { Form, FastField, useFormikContext } from 'formik'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import SettingsPage from '@ui/paintscout/src/SettingsPage'
import { getObjectLabels } from '@paintscout/util/builder'
import { getIndefiniteArticle } from '@paintscout/util/util'

const useStyles = makeStyles<Theme, InvoiceOptionsProps>(
  (theme) => ({
    root: {},
    radioDiv: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: `${-theme.spacing()}px !important`,
      padding: theme.spacing()
    },
    helperText: {
      marginTop: theme.spacing(1)
    }
  }),
  { name: 'InvoiceOptions' }
)

export interface InvoiceOptionsProps {
  classes?: StyleClasses<typeof useStyles>
}

function InvoiceOptions(props: InvoiceOptionsProps) {
  const classes = useStyles(props)

  const { values, errors, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
  }>()

  const { options } = values
  const quoteLabel = getObjectLabels({ options }).quote
  const invoiceLabel = getObjectLabels({ options, invoice: true }).quote

  return (
    <SettingsPage
      title={`${invoiceLabel.value} Settings`}
      callout={{
        path: 'invoice-settings',
        content: `Add a default ${invoiceLabel.value.toLowerCase()} due date. This will help provide clear guidance to your customers about when their ${invoiceLabel.value.toLowerCase()} payments are due.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/8277125-invoice-settings'
      }}
    >
      <Form className={classes.root}>
        <FormSection>
          <FormSectionTitle title={`${invoiceLabel.value} Due Date`} variant="h3" />
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.radioDiv}>
              <Switch
                checked={options.options.quotes.customerInvoiceExpiry?.enabled}
                onClick={(_ev) => {
                  // If not set yet it defaults so can be saved, as they are required fields
                  if (!options.options.quotes.customerInvoiceExpiry?.months) {
                    setFieldValue('options.options.quotes.customerInvoiceExpiry.months', 0)
                  }
                  if (!options.options.quotes.customerInvoiceExpiry?.days) {
                    setFieldValue('options.options.quotes.customerInvoiceExpiry.days', 1)
                  }
                  setFieldValue(
                    'options.options.quotes.customerInvoiceExpiry.enabled',
                    !options.options.quotes.customerInvoiceExpiry?.enabled
                  )
                }}
              />
              <Typography variant={'body1'}>
                {invoiceLabel.plural} will include a due date, calculated from when they are sent.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {options.options?.quotes?.customerInvoiceExpiry?.enabled && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <FastField
                        component={FormikInputField}
                        name="options.options.quotes.customerInvoiceExpiry.months"
                        format={'number'}
                        label={'Months:'}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FastField
                        component={FormikInputField}
                        name="options.options.quotes.customerInvoiceExpiry.days"
                        format={'number'}
                        label={'Days:'}
                        required
                        error={errors.options?.options?.quotes?.customerInvoiceExpiry?.days}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormSectionTitle title={'Automatic Invoice Generation'} variant="h3" />
              <SwitchField
                name="options.options.automaticInvoiceOnAccept"
                label={`Automatically generate ${getIndefiniteArticle(
                  invoiceLabel.value
                )} ${invoiceLabel.value.toLowerCase()} when ${getIndefiniteArticle(
                  quoteLabel.value
                )} ${quoteLabel.value.toLowerCase()} is accepted.`}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Form>
    </SettingsPage>
  )
}

export default InvoiceOptions
