import { useAuth } from './AuthProvider'
import { useEffect } from 'react'
import type { Auth0Session } from './util/session'

export default function useAuthCallback(
  onAuthenticated: (err: auth0.Auth0Error, session: Auth0Session, previousUrl: string) => any
) {
  const { handleRedirectCallback } = useAuth()

  useEffect(() => {
    const previousUrl = localStorage.getItem('authRedirectUrl')

    handleRedirectCallback()
      .then(async (sessionResult) => {
        if (onAuthenticated) {
          onAuthenticated(null, sessionResult, previousUrl)
        }

        localStorage.removeItem('authRedirectUrl')
      })
      .catch((err) => {
        if (onAuthenticated) {
          onAuthenticated(err, null, null)
        }
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
