/**
 * @module builder
 */
import type { RatesDocument } from 'paintscout'
import cloneDeep from 'lodash/cloneDeep'
import { uuid } from '../../util'

export function copyRate(args: { rates: RatesDocument; key: string }): RatesDocument {
  const { key, rates } = args

  if (rates.rates[key]) {
    const newItem = cloneDeep(rates.rates[key])
    newItem.name = uuid()
    newItem.label = `${newItem.label} (copy)`

    return {
      ...rates,
      rates: {
        ...rates.rates,
        [newItem.name]: newItem
      }
    }
  }

  return rates
}
