import type { RateType } from 'paintscout'

export function maybeParseFloat(val: string | number) {
  return typeof val === 'string' ? parseFloat(val) : val
}

export function convertRateType(rateType: string): RateType {
  switch (rateType) {
    case 'sqftWalls':
      return 'sqft_walls' as RateType
    case 'sqftCeiling':
      return 'sqft_ceiling' as RateType
    case 'sqftFloor':
      return 'sqft_floor' as RateType
    default:
      return rateType as RateType
  }
}

export function convertCalculationType(calculationType: string): string {
  switch (calculationType) {
    case 'amountPerHour':
      return 'value_per_hour'
    case 'pricePerAmount':
      return 'price_per_value'
    case 'hoursPerAmount':
      return 'hours_per_value'
    case 'amountPerPrice':
      return 'value_per_price'
    default:
      return calculationType
  }
}
