import type { Moment } from 'moment'
import getRangePresets from './get-range-presets'
import getRangePresetValue from './get-range-preset-value'

/**
 * Returns the selected date range preset based on the start and end date.
 * @param startDate The start date.
 * @param endDate The end date.
 * @returns The selected date range preset.
 */
export default function getSelectedRangePreset(startDate: Moment, endDate: Moment) {
  if (!startDate && !endDate) {
    return null
  }

  const presets = getRangePresets()
  for (const preset of presets) {
    const { from, to } = getRangePresetValue(preset.value)
    if (startDate?.isSame(from, 'day') && endDate?.isSame(to, 'day')) {
      return preset
    }
  }

  return null
}
