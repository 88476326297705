import * as React from 'react'
import * as moment from 'moment-timezone'
import type { DropdownSelectOption, SingleSelectProps } from '../../../DropdownSelect'
import DropdownSelect from '../../../DropdownSelect'

export interface TimezoneSelectProps extends Omit<SingleSelectProps, 'classes' | 'value' | 'onChange' | 'options'> {
  value: string
  onChange?: (timezone: any) => void
}

const timeZone = moment.tz.names()
const tzList = timeZone.map((tz) => ({
  value: tz,
  label: tz
}))

const TimezoneSelect = (props: TimezoneSelectProps) => {
  const [state, setState] = React.useState({ value: props.value, label: props.value })

  const handleChange = (timezone: DropdownSelectOption) => {
    setState({ value: timezone.value, label: timezone.label })
    if (props.onChange) {
      props.onChange(timezone)
    }
  }

  return (
    <DropdownSelect
      {...props}
      value={state}
      options={tzList}
      fullWidth={true}
      onChange={handleChange}
      searchable={true}
    />
  )
}

export default TimezoneSelect
