import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import type { WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { FastField, Form, useFormikContext, Field } from 'formik'
import React from 'react'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import CheckboxField from '../../CheckboxField'
import IconButton from '../../IconButton'
import { FormikInputField, FormikDropdownSelect, FormikPhoneNumberInput } from '../../formik'
import { NewFormSection as FormSection } from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import { getObjectLabels } from '@paintscout/util/builder'
import ColorPicker from '../../ColorPicker'
import DateRadio from './DateFormat'
import { isEmailValid } from '@paintscout/util/util'
import SettingsPage from '../../SettingsPage'
import TimezoneSelect from './TimezoneSelect'
import UploadCompanyLogo from './UploadCompanyLogo'
import type { DropdownSelectOption } from '../../DropdownSelect'

const styles = createStyles({
  root: {}
})

export interface GeneralFormProps extends WithStyles<typeof styles> {
  /**
   * Called when the color has changed. Use this if you want to show a preview of the theme
   * to the user
   */
  onColorChange?: (colors: { primary: string; secondary: string }) => any

  showAdvanced?: boolean
  showOnlyAdvanced?: boolean
}

/**
 * A form for the General section of client options
 */
function GeneralForm({ classes, onColorChange, showAdvanced, showOnlyAdvanced }: GeneralFormProps) {
  const { values, errors } = useFormikContext<{ options: OptionsDocument; rates: RatesDocument }>()
  const objectLabels = getObjectLabels({ options: values.options })

  if (showOnlyAdvanced) {
    return (
      <Form className={classes.root}>
        <AdminGeneralSettings onColorChange={onColorChange} />
      </Form>
    )
  }

  return (
    <SettingsPage
      title="Company Information"
      callout={{
        path: 'company-settings',
        content: `Add your company information. This will appear on ${objectLabels.quote.plural.toLowerCase()} sent to your customers.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/6063006-company-overview'
      }}
    >
      <FormSection>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={10}>
            <FastField
              label="Name"
              name="options.options.companyName.value"
              margin="dense"
              component={FormikInputField}
              required
              fullWidth
              multiline
              multilineGrow
              error={!!errors.options && errors.options.options.companyName}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <FastField
              label="Address"
              name="options.options.companyAddress.value"
              margin="dense"
              component={FormikInputField}
              multiline
              multilineGrow
              error={!!errors.options && errors.options.options.companyAddress}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <FastField
              label="City"
              name="options.options.companyCity.value"
              margin="dense"
              component={FormikInputField}
              error={!!errors.options && errors.options.options.companyCity}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <FastField
              label="State / Prov"
              name="options.options.companyProv.value"
              margin="dense"
              error={!!errors.options && errors.options.options.companyProv}
              component={FormikInputField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <FastField
              label="Zip / Postal"
              name="options.options.companyPostal.value"
              margin="dense"
              component={FormikInputField}
              error={!!errors.options && errors.options.options.companyPostal}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <FastField
              label="Country"
              name="options.options.companyCountry.value"
              margin="dense"
              component={FormikDropdownSelect}
              options={[
                { label: 'United States', value: 'United States' },
                { label: 'Canada', value: 'Canada' },
                { label: 'United Kingdom', value: 'United Kingdom' },
                { label: 'Australia', value: 'Australia' }
              ]}
              error={!!errors.options && errors.options.options.companyCountry}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <FastField
              label="Phone"
              name="options.options.companyPhone.value"
              error={!!errors.options && errors.options.options.companyPhone}
              margin="dense"
              separator={values.options?.options?.phoneNumbers?.separator}
              component={FormikPhoneNumberInput}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <FastField
              label="Additional Details"
              name="options.options.companyAdditional.value"
              error={!!errors.options && errors.options.options.companyAdditional}
              margin="dense"
              component={FormikInputField}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle title="Timezone" variant={'h3'} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Field name="options.options.timezone" required>
              {({ field, ...rest }) => (
                <TimezoneSelect
                  {...field}
                  {...rest}
                  label="Timezone"
                  margin="dense"
                  onChange={(option: DropdownSelectOption) => {
                    field.onChange({ target: { name: field.name, value: option.value } })
                  }}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      {showAdvanced && <AdminGeneralSettings onColorChange={onColorChange} />}
    </SettingsPage>
  )
}

const AdminGeneralSettings = (args: {
  /**
   * Called when the color has changed. Use this if you want to show a preview of the theme
   * to the user
   */
  onColorChange?: (colors: { primary: string; secondary: string }) => any
}) => {
  const { onColorChange } = args
  const currenciesList: DropdownSelectOption[] = [
    { value: 'Dollar', label: 'Dollars' },
    { value: 'Euro', label: 'Euros' },
    { value: 'Pound', label: 'Pounds' }
  ]

  const unitsList: DropdownSelectOption[] = [
    { value: 'Foot', label: 'Feet' },
    { value: 'Metre', label: 'Metres' }
  ]

  const substrateLabelList: DropdownSelectOption[] = [
    { value: 'Substrate', label: 'Substrate' },
    { value: 'Surface', label: 'Surface' }
  ]

  const quoteLabelList: DropdownSelectOption[] = [
    {
      value: 'Quote',
      label: 'Quote'
    },
    {
      value: 'Estimate',
      label: 'Estimate'
    },
    {
      value: 'Proposal',
      label: 'Proposal'
    },
    {
      value: 'Bid',
      label: 'Bid'
    },
    {
      value: 'Agreement',
      label: 'Agreement'
    }
  ]

  const { values, errors, handleChange } = useFormikContext<{ options: OptionsDocument; rates: RatesDocument }>()
  const objectLabels = getObjectLabels({ options: values.options })

  return (
    <SettingsPage title={'Admin Company Settings'}>
      <FormSection>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <FastField
              name="options.options.customCss.value"
              margin="dense"
              multiline={true}
              label={'Custom CSS'}
              fullWidth
              rows={10}
              component={FormikInputField}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle title={'Branding'} variant={'h3'} subTitle={`Admin branding settings`} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <FastField name="options.options.logo">
              {({ field }) => (
                <UploadCompanyLogo
                  cloudinaryId={field?.value?.cloudinaryId}
                  s3PublicKey={field?.value?.s3PublicKey}
                  src={field.value.src}
                  onChange={(value) => {
                    field.onChange({ target: { name: field.name, value } })
                  }}
                />
              )}
            </FastField>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={5} sm={4} style={{ textAlign: 'center' }}>
                <Field name="options.options.primaryColor.value">
                  {({ field, form, ...other }) => (
                    <ColorPicker
                      label={'Primary Color'}
                      {...other}
                      {...field}
                      onChange={(value) => {
                        if (onColorChange) {
                          onColorChange({
                            primary: value as string,
                            secondary: form.values.options.options.secondaryColor.value as string
                          })
                        }
                        field.onChange({ target: { name: field.name, value } })
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={2} sm={2} container alignItems="center" justifyContent="center">
                <Grid item>
                  <IconButton
                    size="medium"
                    onClick={() => {
                      const { primaryColor, secondaryColor } = values.options.options

                      if (onColorChange) {
                        onColorChange({
                          primary: secondaryColor.value as string,
                          secondary: primaryColor.value as string
                        })
                      }

                      handleChange({
                        target: {
                          name: 'options.options.secondaryColor.value',
                          value: primaryColor.value
                        }
                      })
                      handleChange({
                        target: {
                          name: 'options.options.primaryColor.value',
                          value: secondaryColor.value
                        }
                      })
                    }}
                  >
                    <SwapHorizIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={5} sm={4} style={{ textAlign: 'center' }}>
                <Field name="options.options.secondaryColor.value">
                  {({ field, form, ...other }) => (
                    <ColorPicker
                      label={'Secondary Color'}
                      {...other}
                      {...field}
                      onChange={(value) => {
                        if (onColorChange) {
                          onColorChange({
                            primary: form.values.options.options.primaryColor.value as string,
                            secondary: value as string
                          })
                        }
                        field.onChange({ target: { name: field.name, value } })
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle
          title={'Display'}
          variant={'h3'}
          subTitle={`Change how ${objectLabels.quote.plural} are displayed to customers`}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FastField
              label={`Substrate Name/Label`}
              name={'options.options.labels.substrate'}
              margin="dense"
              options={substrateLabelList}
              required
              fullWidth
              component={FormikDropdownSelect}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <FastField
              label={`Quote Name/Label`}
              name={'options.options.labels.quote'}
              margin="dense"
              options={quoteLabelList}
              required
              fullWidth
              component={FormikDropdownSelect}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Field name="options.options.timezone" required>
              {({ field, ...rest }) => (
                <TimezoneSelect
                  {...field}
                  {...rest}
                  label="Timezone"
                  margin="dense"
                  onChange={(option: DropdownSelectOption) => {
                    field.onChange({ target: { name: field.name, value: option.value } })
                  }}
                />
              )}
            </Field>
          </Grid>
          {/*  We still need to decide on another option for production rates 
          
          <Grid item xs={12}>
            <FastField
              label={`Production Rate Name/Label`}
              name={'options.options.labels.productionRate'}
              margin="dense"
              options={productionRateLabelList}
              required
              fullWidth
              
              
              component={FormikDropdownSelect}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl margin="dense">
              <Typography variant={'overline'}>Date format</Typography>
              <Field name="options.options.dateFormat.momentValue">{({ field }) => <DateRadio {...field} />}</Field>
            </FormControl>
          </Grid>
          {!values.options.options.internationalMode && (
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  label="Phone Number Separator"
                  margin="dense"
                  fullWidth
                  options={[
                    { label: 'Dash (222-333-4444)', value: '-' },
                    { label: 'Dot (222.333.4444)', value: '.' }
                  ]}
                  component={FormikDropdownSelect}
                  name={'options.options.phoneNumbers.separator'}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle title={'Default Contact Details'} variant={'h3'} />
        <Grid item xs={12}>
          <CheckboxField
            name="options.options.defaultContactDetails.active"
            label="Pre-populate select fields when creating a new contact."
            defaultValue={values.options.options.defaultContactDetails?.active ?? false}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Field
              label="City"
              name={'options.options.defaultContactDetails.city'}
              margin="dense"
              component={FormikInputField}
              valueDefault={''}
              disabled={
                values.options.options.defaultContactDetails
                  ? !values.options.options.defaultContactDetails.active
                  : true
              }
              error={errors?.options?.options?.defaultContactDetails?.city}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Field
              label="State / Prov"
              name="options.options.defaultContactDetails.prov"
              margin="dense"
              component={FormikInputField}
              valueDefault={''}
              disabled={
                values.options.options.defaultContactDetails
                  ? !values.options.options.defaultContactDetails.active
                  : true
              }
              error={errors?.options?.options?.defaultContactDetails?.prov}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle
          title={'Integrations'}
          variant={'h3'}
          subTitle={'Import & export data with other applications and services.'}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <FastField
              name="options.options.crmBCCEmail"
              margin="dense"
              label={'Outgoing BCC Email Address:'}
              sublabel={
                'Forward sent emails and messages to your CRM BCC email to be attached to any matching contacts.'
              }
              placeholder={'Enter the CRM BCC Email address'}
              fullWidth
              component={FormikInputField}
            />
            {values.options.options?.crmBCCEmail && !isEmailValid(values.options.options?.crmBCCEmail) && (
              <Typography style={{ color: 'red ' }} variant={'subtitle2'}>
                Invalid Email Address
              </Typography>
            )}
            {values.options.options?.crmBCCEmail?.length > 0 && isEmailValid(values.options.options?.crmBCCEmail) && (
              <Typography variant={'subtitle2'}>
                You may also need to set up an alias email in your CRM to enable the Bcc email forwarding. If so use the
                following email: {<code>notifications@mail.paintscout.com</code>}
              </Typography>
            )}
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle
          title={'International'}
          subTitle="Settings for companies outside of North America."
          variant={'h3'}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FastField
              label={'Currency'}
              name={'options.options.labels.currency'}
              margin="dense"
              options={currenciesList}
              required
              fullWidth
              component={FormikDropdownSelect}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FastField
              label={'Units'}
              name={'options.options.labels.unit'}
              margin="dense"
              options={unitsList}
              required
              fullWidth
              component={FormikDropdownSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.internationalMode"
              label="International Mode"
              sublabel="Disables all formatting for phone numbers, states, etc..."
            />
          </Grid>
        </Grid>
      </FormSection>
    </SettingsPage>
  )
}

export default withStyles(styles)(GeneralForm)
