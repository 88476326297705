import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

function CalculatorIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5,3v18c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V3c0-0.6-0.4-1-1-1H6C5.4,2,5,2.4,5,3z M9,20H7v-2h2V20z M9,16H7v-2
      	h2V16z M9,12H7v-2h2V12z M13,20h-2v-2h2V20z M13,16h-2v-2h2V16z M13,12h-2v-2h2V12z M17,20h-2v-6h2V20z M17,12h-2v-2h2V12z M17,8H7
      	V4h10V8z"
      />
    </SvgIcon>
  )
}

export default CalculatorIcon
