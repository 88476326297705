/**
 * @module builder
 */
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { RateSectionOption } from '../../index'
import { filterByQuoteType } from '../../index'
import { getQuoteType } from '../../quote/util'

export function getRateSections(args: {
  options: OptionsDocument
  quoteType?: string
  inactive?: boolean
  quote?: QuoteDocument
}): RateSectionOption[] {
  const { options, inactive } = args
  const { rateSections } = options.options
  const quoteType = getQuoteType(args)

  const order =
    rateSections.quoteTypeOrder && rateSections.quoteTypeOrder[quoteType]
      ? uniq([...rateSections.quoteTypeOrder[quoteType], ...rateSections.order])
      : uniq(rateSections.order)

  return order
    .map((orderItem) => {
      const item = rateSections.values[orderItem]
      if (!item) {
        return null
      }

      let quoteTypes
      if (item.quoteTypes) {
        quoteTypes = item.quoteTypes.filter(Boolean).length > 0 ? item.quoteTypes : ['all']
      } else {
        quoteTypes = [get(item, 'int_ext.value', 'all')]
      }

      const option: RateSectionOption = {
        key: orderItem,
        quoteTypes,
        label: item.label,
        active: typeof item.active === 'undefined' ? true : !!item.active
      }

      if (!inactive && !option.active) {
        return null
      }

      if (filterByQuoteType({ quoteType, quoteTypes })) {
        return option
      }

      return null
    })
    .filter((item) => item)
}
