import React from 'react'
import type { DialogActionsProps as MuiDialogActionsProps } from '@material-ui/core/DialogActions'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Hidden from '../../Hidden'

import Menu from '@material-ui/core/Menu'
import MenuItem from '../../MenuItem'

import Button from '../../Button'
import AddIcon from '@material-ui/icons/Add'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import type { Theme, WithStyles } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = (theme: Theme) => {
  const { pxToRem } = theme.typography

  return createStyles({
    root: {
      height: pxToRem(55),
      borderTop: '1px #DDDDDD solid',
      margin: 0,
      paddingLeft: pxToRem(20), // the 10px from action margin will make total edge space 30px
      paddingRight: pxToRem(20),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      [theme.breakpoints.down('md')]: {
        height: pxToRem(50)
      }
    },
    noBorder: {
      borderTop: 'none'
    },
    action: {
      margin: `0 ${pxToRem(10)}`
    },
    button: {
      [theme.breakpoints.down('md')]: {
        '& svg': {
          marginRight: 0
        }
      }
    }
  })
}

export interface DialogToggleItem {
  key: string
  label: string
  component?: React.ReactNode
  icon?: React.ComponentType<SvgIconProps>
  value?: boolean
  disabled?: boolean
  subItems?: Array<{
    key: string
    label: string
    value?: boolean
    component?: React.ReactNode
    icon?: React.ComponentType<SvgIconProps>
  }>
}

export interface DialogTogglesProps extends WithStyles<typeof styles>, Omit<MuiDialogActionsProps, 'classes'> {
  className?: string
  toggles: DialogToggleItem[]
  scroll?: boolean
  onToggle?: (key: string, value: boolean) => void
  keepLabelsOnMobile?: boolean
}

class DialogToggles extends React.PureComponent<DialogTogglesProps, any> {
  public static defaultProps: Partial<DialogTogglesProps> = {}

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  public render() {
    const { className, classes, toggles, keepLabelsOnMobile, ...otherProps } = this.props

    const activeToggles = toggles

    if (!activeToggles.length) {
      return null
    }

    return (
      <MuiDialogActions
        className={className}
        {...otherProps}
        classes={{
          root: classes.root
        }}
        data-testid="DialogToggles"
      >
        {activeToggles.map((item) => {
          const { key, label, subItems } = item
          const icon = item.icon ? item.icon : AddIcon
          return (
            <>
              <Button
                key={key}
                variant={'text'}
                onClick={this.handleClick(key)}
                icon={icon}
                disabled={item.disabled}
                classes={{ root: classes.button }}
              >
                {keepLabelsOnMobile ? <span>{label}</span> : <Hidden mdDown>{label}</Hidden>}
              </Button>
              {subItems && (
                <Menu
                  id="date-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation()
                    this.setState({ anchorEl: null })
                  }}
                >
                  {subItems
                    .filter((item) => item)
                    .map((subItem) => {
                      return subItem.component ? (
                        subItem.component
                      ) : (
                        <MenuItem key={subItem.key} icon={subItem.icon} onClick={this.handleClick(key, subItem.key)}>
                          {subItem.label}
                        </MenuItem>
                      )
                    })}
                </Menu>
              )}
            </>
          )
        })}
      </MuiDialogActions>
    )
  }

  public handleClick = (key: string, subItemKey?: string) => (ev: React.MouseEvent<HTMLElement>) => {
    const { toggles } = this.props

    if (this.props.scroll) {
      setTimeout(() => {
        const scrollElement = document.querySelector(`.${key}`)
        if (scrollElement) {
          scrollElement.scrollIntoView({ behavior: 'smooth' })
        }
      }, 10)
    }

    if (this.props.onToggle) {
      const toggle = toggles.find((t) => t.key === key)
      if (subItemKey) {
        this.setState({ anchorEl: null })
        this.props.onToggle(subItemKey, null)
      } else if (toggle.subItems) {
        this.setState({ anchorEl: ev.currentTarget })
      } else {
        this.props.onToggle(key, !toggle.value)
      }
    }
  }
}

export default withStyles(styles)(DialogToggles)
