import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { FloatingMenu } from '@ui/paintscout'
import ClientFeaturesForm from './ClientFeaturesForm'
import ClientUsers from './ClientUsers'
import ClientGeneral from './ClientGeneral'
import ClientApiKeys from './ClientApiKeys'
import ClientBilling from './ClientBilling'
import ClientImportExport from './ClientImportExport'
import ClientGithubIssues from './ClientGithubIssues'

export interface ClientAdministrationFormProps {
  section?: string
  onSectionChange?: (section: string) => any
}

export default function ClientAdministrationForm({ section, onSectionChange }: ClientAdministrationFormProps) {
  const [floatingMenuValue, setFloatingMenuValue] = useState(section || 'general')

  const menuItems = [
    { key: 'general', label: 'General' },
    {
      key: 'features',
      label: 'Features'
    },
    {
      key: 'users',
      label: 'Users'
    },
    {
      key: 'billing',
      label: 'Billing/Trial'
    },
    {
      key: 'importExport',
      label: 'Import/Export'
    },
    {
      key: 'apiKeys',
      label: 'Api Keys'
    },
    {
      key: 'issues',
      label: 'Issues'
    }
  ]

  // update if url changes
  useEffect(() => {
    setFloatingMenuValue(section || 'general')
  }, [section])

  function changeMenuValue(newValue: string) {
    if (onSectionChange) {
      onSectionChange(newValue)
    }
    setFloatingMenuValue(newValue)
  }
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={2}>
        <FloatingMenu
          value={floatingMenuValue}
          defaultValue="general"
          onItemClick={(item) => changeMenuValue(item.key)}
          items={menuItems}
          collapseOn="lg"
        />
      </Grid>
      <Grid item xs={12} xl={10}>
        <Card>
          <CardContent>
            {floatingMenuValue === 'general' && <ClientGeneral />}
            {floatingMenuValue === 'features' && <ClientFeaturesForm />}
            {floatingMenuValue === 'users' && <ClientUsers />}
            {floatingMenuValue === 'billing' && <ClientBilling />}
            {floatingMenuValue === 'importExport' && <ClientImportExport />}
            {floatingMenuValue === 'apiKeys' && <ClientApiKeys />}
            {floatingMenuValue === 'issues' && <ClientGithubIssues />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
