import { useMemo } from 'react'
import { useUser } from '../UserProvider'
import { useClientOptions } from '../ClientOptionsProvider'

// User related permissions hook
export const useUserPermissions = () => {
  const { isAdmin } = useClientOptions()
  const { hasPermissions = () => isAdmin, user } = useUser()

  const permissionToViewAllQuotes = hasPermissions(['read:all-quotes'])
  const permissionToCreateQuote = hasPermissions(['create:quotes'])
  const permissionToCreateInvoice = hasPermissions(['create:invoices'])
  const permissionToCreateContact = hasPermissions(['create:contacts'])

  const permissionToSendAssignedQuote = hasPermissions(['send:assigned-quotes'])
  const permissionToEditAssignedQuote = hasPermissions(['write:assigned-quotes'])
  const permissionToEditAllQuote = hasPermissions(['write:all-quotes'])
  const permissionToSendAllQuote = hasPermissions(['send:all-quotes'])
  const permissionToSendAssignedInvoice = hasPermissions(['send:assigned-invoices'])
  const permissionToSendAllInvoice = hasPermissions(['send:all-invoices'])
  const permissionToEditAssignedInvoice = hasPermissions(['write:assigned-invoices'])
  const permissionToEditAllInvoice = hasPermissions(['write:all-invoices'])

  const permissionToEditAllContacts = hasPermissions(['write:all-contacts'])
  const permissionToEditAssignedContacts = hasPermissions(['write:assigned-contacts'])

  const permissionToEditAccountSettings = hasPermissions(['write:account-settings'])

  const permissionToViewEstimator = hasPermissions(['read:estimator-select'])

  return useMemo(
    () => ({
      permissionToViewAllQuotes,
      permissionToCreateQuote,
      permissionToCreateInvoice,
      permissionToSendAssignedQuote,
      permissionToEditAssignedQuote,
      permissionToEditAllQuote,
      permissionToSendAllQuote,
      permissionToSendAssignedInvoice,
      permissionToSendAllInvoice,
      permissionToEditAssignedInvoice,
      permissionToEditAllInvoice,

      permissionToCreateContact,
      permissionToEditAllContacts,
      permissionToEditAssignedContacts,

      permissionToEditAccountSettings,
      permissionToViewEstimator
    }),
    [user, hasPermissions]
  )
}
