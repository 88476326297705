/**
 * @module builder
 */
import type { AreaLabelOption } from '../index'
import { uuid } from '../../util'

export function createAreaLabelOption(args?: { quoteType?: string }): AreaLabelOption {
  const { quoteType } = args ?? {}

  const quoteTypes = quoteType ? [quoteType] : ['all']

  return {
    name: uuid(),
    label: '',
    quoteTypes,
    active: true
  }
}
