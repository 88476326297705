import React, { useEffect, useRef, useContext } from 'react'
import DialogStackRenderer from './DialogStackRenderer'
import DialogStackContext from './DialogStackContext'

/**
 * Changes where the specified dialog components are rendered in the React tree
 *
 * This is useful if you need to preserve Context that isn't available to the DialogStackProvider
 */
export default function DialogStackPortal() {
  const { setRenderPortal } = useContext(DialogStackContext)
  const renderRef = useRef(null)

  useEffect(() => {
    if (renderRef.current) {
      setRenderPortal(!!renderRef.current)
    }

    return () => {
      setRenderPortal(false)
    }
  }, [renderRef.current])

  return <DialogStackRenderer ref={renderRef} />
}
