import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Grid, makeStyles, Collapse } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { DropdownSelectOption } from '../../DropdownSelect'
import {
  Button,
  DropdownSelect,
  FormSection,
  FormSectionTitle,
  InputField,
  useClientOptions,
  useUser,
  Typography
} from '../../'
import { useProcessEvent, useAdminIntegrationsConfig } from '@ui/core'
import type { EventType, ProviderConfig } from 'paintscout'
import {
  getObjectLabels,
  getProductOptions,
  getQuoteTypeOptions,
  integrationEventHandlers
} from '@paintscout/util/builder'
import { useFormikContext } from 'formik'
import find from 'lodash/find'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import RefreshIcon from '@material-ui/icons/Refresh'
import { EventSubscriptionType } from '../Integrations'

const useStyles = makeStyles<Theme, WorkGlueProviderFormProps>(
  (_theme) => ({
    root: {},
    visIcon: {
      cursor: 'pointer'
    }
  }),
  { name: 'WorkGlueProviderForm' }
)

export interface WorkGlueProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
  companyId?: string
  userId?: string
}

export function WorkGlueProviderForm(props: WorkGlueProviderFormProps) {
  const {
    providerConfig,
    providerName,
    isSettingsIntegration = false,
    isAdminIntegrations = false,
    companyId = '',
    userId = '',
    onChange
  } = props
  const classes = useStyles(props)
  const { enabled, apiKey: providerConfigApiKey } = providerConfig

  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { preferences: userPreferences } = useUser()
  const preferences = isSettingsIntegration ? null : userPreferences

  const apiKey = useRef<string>(
    isSettingsIntegration ? providerConfigApiKey : preferences?.integrations?.[providerName]?.apiKey
  )
  const [costCodeOptions, setCostCodeOptions] = useState([])
  const [materialsOptions, setMaterialsOptions] = useState([])
  const [unitsOfMeasureOptions, setUnitsOfMeasureOptions] = useState([])

  const [editingCostCodes, setEditingCostCodes] = useState(false)
  const [editingMaterials, setEditingMaterials] = useState(false)
  const [editingUnits, setEditingUnits] = useState(false)
  const [visible, toggleVisible] = useState(false)
  const [invalidApiKey, setInvalidApiKey] = useState(false)
  const { dirty: isDirty } = useFormikContext()
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const { adminIntegrationsConfig } = useAdminIntegrationsConfig()
  const { processEvent } = useProcessEvent()

  const { handleEventChange } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  async function load(key?: string) {
    if (enabled && (apiKey.current || key)) {
      const usedKey = key ?? apiKey.current
      if (isDirty && !timeoutId.current) {
        onChange(
          providerName,
          {
            ...providerConfig,
            enabled: true,
            apiKey: usedKey
          },
          true
        )
        // Start a new timeout to block this from spamming
        const newTimeoutId = setTimeout(() => {
          clearTimeout(timeoutId.current)
          timeoutId.current = null
        }, 10000)

        timeoutId.current = newTimeoutId
      }
      const configItemsRes = isAdminIntegrations
        ? await adminIntegrationsConfig({
            provider: providerName,
            type: 'get-config-items',
            params: {
              apiKey: usedKey,
              companyId,
              userId
            }
          })
        : await processEvent({
            provider: providerName,
            type: 'get-config-items',
            params: {
              apiKey: usedKey
            }
          })

      if (configItemsRes) {
        const { result, error } = configItemsRes
        if (result) {
          setCostCodeOptions(configItemsRes?.result?.costCodes ?? [])
          setMaterialsOptions(configItemsRes?.result?.materials ?? [])
          setUnitsOfMeasureOptions(configItemsRes?.result?.unitsOfMeasure ?? [])
          setInvalidApiKey(false)
        }
        if (error?.includes('invalid request')) {
          setInvalidApiKey(true)
        }
      }
    } else if (!apiKey.current && invalidApiKey) {
      setInvalidApiKey(false)
    }
  }

  useEffect(() => {
    load()
  }, [enabled, preferences?._rev])

  const selectedUnitsOfMeasure = {
    'unit-gal': providerConfig.config?.unitsOfMeasure?.['unit-gal']
      ? find(unitsOfMeasureOptions, { value: providerConfig.config?.unitsOfMeasure?.['unit-gal'] })
      : null,
    'unit-item': providerConfig.config?.unitsOfMeasure?.['unit-item']
      ? find(unitsOfMeasureOptions, { value: providerConfig.config?.unitsOfMeasure?.['unit-item'] })
      : null
  }

  const quoteTypes = getQuoteTypeOptions({ options, inactive: true })
  const productOptions = getProductOptions({ options, inactive: true })

  const defaultProduct = providerConfig.config?.costCodes?.['default-product']
    ? find(materialsOptions, { value: providerConfig.config?.costCodes?.['default-product'] })
    : null

  const handleCostCodeChange = (name: string) => (option: DropdownSelectOption) => {
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...(providerConfig?.config ?? {}),
        costCodes: {
          ...(providerConfig?.config?.costCodes ?? {}),
          [name]: option.value
        }
      }
    })
  }

  const handleUnitOfMeasureChange = (name: string) => (option: DropdownSelectOption) => {
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...(providerConfig?.config ?? {}),
        unitsOfMeasure: {
          ...(providerConfig?.config?.unitsOfMeasure ?? {}),
          [name]: option.value
        }
      }
    })
  }

  function handleApiKeyChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const { value } = ev.target
    const trimmedValue = value.trim()
    props.onChange(
      providerName,
      {
        ...providerConfig,
        enabled: true,
        apiKey: trimmedValue
      },
      false
    )
    apiKey.current = trimmedValue

    // Clear the previous timeout, if any
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    }
  }

  function laborCostsMissing() {
    const res = quoteTypes.filter((quoteType) => {
      const selectedCostCode = providerConfig.config?.costCodes?.[quoteType.key]
        ? find(costCodeOptions, { value: providerConfig.config?.costCodes?.[quoteType.key] })
        : null
      return !!selectedCostCode
    })
    return res.length !== quoteTypes.length
  }

  function materialsMissing() {
    const res = productOptions.filter((productOption) => {
      const selectedCostCode = providerConfig.config?.costCodes?.[productOption.key]
        ? find(materialsOptions, { value: providerConfig.config?.costCodes?.[productOption.key] })
        : null
      return !!selectedCostCode
    })
    return res.length !== productOptions.length || !defaultProduct
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <InputField
            fullWidth={true}
            label={'Api Key'}
            type={visible ? 'text' : 'password'}
            value={apiKey.current}
            disabled={isAdminIntegrations}
            onChange={handleApiKeyChange}
            sublabel={`Found in your Workglue Account ${invalidApiKey ? '- (Invalid Api Key)' : ''}`}
            error={invalidApiKey || !apiKey.current}
            startAdornment={
              invalidApiKey || isDirty ? (
                <div onClick={() => load()}>
                  <RefreshIcon className={classes.visIcon} />
                </div>
              ) : null
            }
            endAdornment={
              <div onClick={() => toggleVisible(!visible)}>
                {visible ? (
                  <VisibilityOffIcon className={classes.visIcon} />
                ) : (
                  <VisibilityIcon className={classes.visIcon} />
                )}
              </div>
            }
          />
        </Grid>
      </Grid>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle title={'Export Work Orders'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-accepted' as EventType}
              event={providerConfig.events['quote-accepted']}
              onChange={handleEventChange}
              label={`When ${objectLabels.quote.plural} are accepted`}
            />
          </Grid>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-declined' as EventType}
              event={providerConfig.events['quote-declined']}
              onChange={handleEventChange}
              label={`When ${objectLabels.quote.plural} are declined`}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle
          title={'Labor Costs'}
          variant={'h4'}
          required={laborCostsMissing()}
          subTitle={
            <Grid container>
              <Grid item md={7}>
                The labor hours for each area in PaintScout will be exported to Workglue using a fixed{' '}
                <a href="https://pm2.workglue.com/edit-db-fields.html#alineitems" target="_blank" rel="noreferrer">
                  Workglue Cost Code
                </a>
                , which can be configured for each PaintScout {objectLabels.quote.value.toLowerCase()} type.
              </Grid>
              <Grid item md={5}>
                {!editingCostCodes && (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant={'text'}
                        onClick={() => {
                          setEditingCostCodes(!editingCostCodes)
                        }}
                      >
                        Edit Cost Codes
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
        />
        <Collapse in={editingCostCodes}>
          <Grid container spacing={1}>
            {quoteTypes.map((quoteType) => {
              const selectedCostCode = providerConfig.config?.costCodes?.[quoteType.key]
                ? find(costCodeOptions, { value: providerConfig.config?.costCodes?.[quoteType.key] })
                : null
              return (
                <Grid item xs={12} key={quoteType.key}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        label={`${objectLabels.quote.value} type`}
                        disabled={true}
                        value={quoteType.label}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropdownSelect
                        variant="single"
                        label={`Cost Code`}
                        name={quoteType.key}
                        fullWidth={true}
                        onChange={handleCostCodeChange(quoteType.key)}
                        value={selectedCostCode}
                        options={costCodeOptions}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Collapse>
      </FormSection>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle
          title={'Materials'}
          variant={'h4'}
          required={materialsMissing()}
          subTitle={
            <Grid container>
              <Grid item md={7}>
                Each material in PaintScout will be exported using a fixed{' '}
                <a href="https://pm2.workglue.com/edit-db-fields.html#alineitems" target="_blank" rel="noreferrer">
                  Workglue Cost Code
                </a>
                , which can be configured for each PaintScout product.
                <br />
                If a product has not been configured here, the default product cost code below will be used.
              </Grid>
              <Grid item md={5}>
                {!editingMaterials && (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant={'text'}
                        onClick={() => {
                          setEditingMaterials(!editingMaterials)
                        }}
                      >
                        Edit Materials
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
        />
        <Collapse in={editingMaterials}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <InputField label={'Product'} disabled={true} fullWidth={true} value={'Default Product'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropdownSelect
                    variant="single"
                    label={`Cost Code`}
                    fullWidth={true}
                    required={!defaultProduct}
                    onChange={handleCostCodeChange('default-product')}
                    value={defaultProduct}
                    options={materialsOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            {productOptions.map((productOption) => {
              const selectedCostCode = providerConfig.config?.costCodes?.[productOption.key]
                ? find(materialsOptions, { value: providerConfig.config?.costCodes?.[productOption.key] })
                : null
              return (
                <Grid item xs={12} key={productOption.key}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <InputField label={`Product`} disabled={true} value={productOption.label} fullWidth={true} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropdownSelect
                        variant="single"
                        label={`Cost Code`}
                        name={productOption.key}
                        fullWidth={true}
                        required={!selectedCostCode}
                        onChange={handleCostCodeChange(productOption.key)}
                        value={selectedCostCode}
                        options={materialsOptions}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Collapse>
      </FormSection>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle
          title={'Units of Measure'}
          variant={'h4'}
          subTitle={
            <Grid container>
              <Grid item md={7}>
                Materials in PaintScout will be exported using the following{' '}
                <a href="https://pm2.workglue.com/edit-db-fields.html#aunittypes" target="_blank" rel="noreferrer">
                  Workglue units of measure
                </a>
                , which can be configured for each PaintScout unit.
              </Grid>
              <Grid item md={5}>
                {!editingUnits && (
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant={'text'}
                        onClick={() => {
                          setEditingUnits(!editingUnits)
                        }}
                      >
                        Edit Units of Measure
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
        />
        <Collapse in={editingUnits}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <InputField label={`PaintScout Unit`} disabled={true} value={'gal'} fullWidth={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropdownSelect
                    variant="single"
                    label={`Workglue Unit`}
                    name={'unit-gal'}
                    fullWidth={true}
                    onChange={handleUnitOfMeasureChange('unit-gal')}
                    value={selectedUnitsOfMeasure['unit-gal']}
                    required={!selectedUnitsOfMeasure['unit-gal']}
                    options={unitsOfMeasureOptions}
                    formatOptionLabel={(option) => {
                      return (
                        <Typography>
                          {typeof option.label === 'string' ? option.label : (option.label as any).name}
                        </Typography>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <InputField label={`PaintScout Unit`} disabled={true} value={'item'} fullWidth={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropdownSelect
                    variant="single"
                    label={`Workglue Unit`}
                    name={'unit-item'}
                    fullWidth={true}
                    onChange={handleUnitOfMeasureChange('unit-item')}
                    value={selectedUnitsOfMeasure['unit-item']}
                    required={!selectedUnitsOfMeasure['unit-item']}
                    options={unitsOfMeasureOptions}
                    formatOptionLabel={(option) => {
                      return (
                        <Typography>
                          {typeof option.label === 'string' ? option.label : (option.label as any).name}
                        </Typography>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </FormSection>
    </>
  )
}
