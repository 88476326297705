import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  FloatingMenu,
  UserProfileForm,
  UserMiscForm,
  UserEmailForm,
  UserFollowUpsForm,
  UserNotificationsForm,
  UserAdminIntegrationsForm
} from '@ui/paintscout'

export interface UserProfileFormProps {
  section?: string

  onSectionChange?: (section: string) => any
}

export default function UserProfilePage({ section = 'account', onSectionChange }: UserProfileFormProps) {
  const [menuValue, setMenuValue] = useState(section)

  // update if url changes
  useEffect(() => {
    setMenuValue(section)
  }, [section])

  function handleMenuItemClick(item) {
    if (onSectionChange) {
      onSectionChange(item.key)
    }
    setMenuValue(item.key)
  }

  const menuItems = [
    {
      key: 'account',
      label: 'Account'
    },
    {
      key: 'email',
      label: 'Emails'
    },
    { key: 'follow-ups', label: 'Follow-Ups' },
    {
      key: 'notifications',
      label: 'Notifications'
    },
    {
      key: 'misc',
      label: 'Misc.'
    },
    {
      key: 'integrations',
      label: 'Integrations'
    }
  ]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={2}>
        <FloatingMenu
          value={menuValue}
          defaultValue="account"
          items={menuItems}
          onItemClick={handleMenuItemClick}
          collapseOn="lg"
        />
      </Grid>
      <Grid item xs={12} xl={10}>
        <Card>
          <CardContent>
            {menuValue === 'account' && <UserProfileForm allowEmailChange showChangePassword={false} />}
            {menuValue === 'misc' && <UserMiscForm />}
            {menuValue === 'email' && <UserEmailForm isAdmin />}
            {menuValue === 'follow-ups' && <UserFollowUpsForm isSuperadmin={true} />}
            {menuValue === 'notifications' && <UserNotificationsForm showAdmin={true} />}
            {menuValue === 'integrations' && <UserAdminIntegrationsForm />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
