import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import CoreColorPicker from '@ui/core/ColorPicker'
import type { ColorPickerProps as CoreColorPickerProps } from '@ui/core/ColorPicker'

interface ColorPickerProps extends CoreColorPickerProps {}

const useStyles = makeStyles<Theme, ColorPickerProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  swatch: {
    background: 'none',
    border: 'none',
    padding: theme.typography.pxToRem(5),
    paddingTop: 0,
    borderRadius: theme.typography.pxToRem(1),
    marginBottom: theme.typography.pxToRem(20),
    display: 'inline-block'
  },
  popover: {
    position: 'absolute',
    zIndex: 2
  },
  cover: {
    position: 'fixed',
    top: theme.typography.pxToRem(0),
    right: theme.typography.pxToRem(0),
    bottom: theme.typography.pxToRem(0),
    left: theme.typography.pxToRem(0)
  },
  colorCard: {
    marginTop: theme.typography.pxToRem(10),
    padding: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(2),
    '& .hex': {
      ...theme.typography.body1,
      textAlign: 'center',
      marginTop: theme.typography.pxToRem(8)
    }
  },
  label: {
    ...theme.typography.overline,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'left',
      paddingLeft: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1)
    }
  },
  colorStyle: {
    width: 120,
    height: 100,
    borderRadius: 2,
    cursor: 'pointer'
  }
}))

function ColorPicker(props: ColorPickerProps) {
  const classes = useStyles(props)

  return <CoreColorPicker {...props} classes={classes} />
}

export default ColorPicker
