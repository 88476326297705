import type { Theme, WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { useUser } from '../UserProvider'
import HtmlContent from '../HtmlContent'
import { getPresentationContentStyles } from '../styles'
import Alert from '../Alert'

import React, { useState, useLayoutEffect } from 'react'

import Typography from '../Typography'

import { LearnMoreLink } from './LearnMoreLink'
import AlertIcon from '../Alert/AlertIcon'
import classnames from 'classnames'

export interface PageHelpMessageProps extends WithStyles<typeof styles> {
  openContent?: React.ReactNode | string
  closedContent?: React.ReactNode | string
  title?: string
  path: string
  align?: 'left' | 'center'
  hideClose?: boolean
  learnMoreLink?: string
  newHelpMessage?: boolean
  severity?: 'success' | 'info' | 'warning' | 'error'
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    content: {
      ...getPresentationContentStyles(theme),
      '& a': {
        ...theme.typography.button,
        fontSize: '0.9em',
        color: theme.palette.grey[700],
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        margin: `0 ${theme.spacing(1)}px`,
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    heading: {
      alignItems: 'flex-end',
      '& p': {
        // maxWidth: '35em'
      }
    },
    icon: (props: PageHelpMessageProps) => {
      return {
        alignSelf: 'flex-start',
        marginLeft: 6,
        color: props?.severity ? theme.palette[props.severity].main : theme.palette.info.main
      }
    },
    closedIcon: {
      cursor: 'pointer',
      margin: theme.spacing(-2, 0, 1, 0),
      paddingLeft: theme.spacing(2)
    },
    contentWrapper: {
      padding: theme.spacing(0, 3, 0, 1)
    }
  })

const PageHelpMessage = ({
  closedContent,
  path,
  hideClose,
  learnMoreLink,
  newHelpMessage = false,
  severity = 'info',
  classes,
  ...props
}: PageHelpMessageProps) => {
  const { isTrial } = useUser()

  const localStoragePath = `hidePageMessage-${path}`

  useLayoutEffect(() => {
    setHeadingOpen(!JSON.parse(window.localStorage.getItem(localStoragePath)))
  }, [localStoragePath])

  const [headingOpen, setHeadingOpen] = useState(false)

  if (!isTrial && !newHelpMessage) {
    return <>{closedContent}</>
  }

  const title = props.title ? (
    <Typography variant="h4" color="inherit">
      {props.title}
    </Typography>
  ) : null

  let openContent = null

  if (typeof props.openContent === 'string') {
    const demoMessageLines = props.openContent.split('\n')

    openContent = (
      <>
        {demoMessageLines.map((line, index) => {
          return <HtmlContent content={line} key={index} />
        })}
      </>
    )
  } else {
    openContent = props.openContent
  }

  if (headingOpen) {
    if (newHelpMessage) {
      return (
        <Alert icon={null} severity={severity} className={classes.heading} onClose={hideClose ? null : handleClose}>
          <div className={classes.content}>
            {title}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={classes.icon}>
                <AlertIcon severity={severity} />
              </div>
              <div className={classes.contentWrapper}>{openContent}</div>

              {learnMoreLink && <LearnMoreLink link={learnMoreLink} />}
            </div>
          </div>
        </Alert>
      )
    } else {
      return (
        <Alert icon={null} severity={'info'} className={classes.heading} onClose={hideClose ? null : handleClose}>
          <div className={classes.content}>
            {title}
            {openContent}
            {learnMoreLink && <LearnMoreLink link={learnMoreLink} />}
          </div>
        </Alert>
      )
    }
  } else {
    if (newHelpMessage) {
      return (
        <div className={classnames(classes.icon, classes.closedIcon)} onClick={handleOpen}>
          <AlertIcon severity={severity} />
        </div>
      )
    } else {
      return <>{closedContent}</>
    }
  }

  function handleOpen() {
    setHeadingOpen(true)
    window.localStorage.setItem(localStoragePath, JSON.stringify(false))
  }

  function handleClose() {
    setHeadingOpen(false)
    window.localStorage.setItem(localStoragePath, JSON.stringify(true))
  }
}

export default withStyles(styles)(PageHelpMessage)
