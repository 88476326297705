import type { OptionsDocument } from 'paintscout'

/**
 * Sanitizes older $options and turns them into correct properties
 */
export function sanitizeOptions(options: OptionsDocument): OptionsDocument {
  return {
    ...options,
    options: {
      ...options.options,
      labels: options.options.labels ?? {
        quote: 'Quote',
        workOrder: 'Work Order',
        application: 'Coat',
        labour: 'Painting',
        prep: 'Prep'
      },
      discounts: {
        ...options.options.discounts,
        order: options.options.discounts.order,
        values: (options.options.discounts.order ?? []).reduce((acc, key) => {
          acc[key] = {
            ...options.options.discounts.values[key],
            value:
              options.options.discounts.values[key].value === '.' ? '0' : options.options.discounts.values[key].value
          }
          return acc
        }, {})
      },
      taxRates: (options.options.taxRates ?? []).map((taxRate) => ({
        ...taxRate,
        rate: (taxRate.rate as any) === '.' ? 0 : taxRate.rate
      }))
    }
  }
}
