import React from 'react'
import type { PresentationOption } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import Presentation from '../Presentation'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {}
}))

export interface PresentationPreviewProps {
  presentation: PresentationOption
  page?: string
  onPresentationClick?: (key: string) => void
  showHelp?: boolean
  isSettings?: boolean
  editFromDialog?: boolean
}

export default function PresentationPreview({
  presentation,
  onPresentationClick,
  showHelp,
  isSettings,
  editFromDialog,
  page,
  ...props
}: PresentationPreviewProps) {
  const classes = useStyles(props)

  return (
    <Presentation
      presentation={presentation}
      onPresentationClick={onPresentationClick}
      page={page}
      isPreview={true}
      isSettings={isSettings}
      editFromDialog={editFromDialog}
      classes={classes}
      showHelp={showHelp}
    />
  )
}
