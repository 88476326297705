import { auth } from '../util/auth0'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'

const authLink = setContext(async (_, { headers }) => {
  const token = await auth.getTokenSilently()

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }
})

const fetcher = (...args: any[]) => {
  // @ts-ignore
  return window.fetch(...args)
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    createHttpLink({
      uri: process.env.PS_GRAPHQL_URL,
      fetch: fetcher
    })
  ]),
  cache: new InMemoryCache()
})
