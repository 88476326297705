import React from 'react'
import { Formik } from 'formik'
import { Button, Dialog } from '@ui/paintscout'
import type { DialogProps } from '@ui/paintscout'
import { DialogActions, DialogContent, DialogTitle } from '@ui/paintscout'
import ResetPasswordForm from './ResetPasswordForm'
import * as Yup from 'yup'

export interface ResetPasswordDialogProps extends DialogProps {
  onConfirm: (clientId: string) => any
  onCancel: () => any
}
export default function ResetPasswordDialog({ onConfirm, onCancel, ...props }: ResetPasswordDialogProps) {
  const initialValues = { password: '', confirmPassword: '' }

  return (
    <Dialog {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnChange
        validationSchema={validationSchema}
        render={({ isSubmitting, errors, values, touched }) => (
          <>
            <DialogTitle loading={isSubmitting}>Reset Password</DialogTitle>
            <DialogContent>
              <ResetPasswordForm values={values} errors={errors} touched={touched} />
            </DialogContent>
            <DialogActions
              leftButton={
                <Button variant="text" disabled={isSubmitting} onClick={onCancel}>
                  Cancel
                </Button>
              }
            >
              <Button disabled={isSubmitting} onClick={() => onConfirm(values.password)}>
                Save
              </Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  )
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('At least 6 characters. Must contain each: a-z, A-Z, 0-9')
    .min(6, 'Password must be at least 6 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null])
    .required('Password confirm is required')
})
