import { makeStyles } from '@material-ui/core'
import type { FollowUp } from '@paintscout/api'
import { getObjectLabels, uuid } from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'
import type { UserPreferencesDocument } from 'paintscout'
import React from 'react'
import { ConfirmationDialog, Grid, MenuItem, useClientOptions } from '../'
import FollowUpDialog from '../dialogs/FollowUpDialog'
import { useDialogs } from '../DialogStack'
import FollowUpTable from '../FollowUpTable'
import { NewFormSection as FormSection } from '../FormSection'
import ExportJsonDialog from '../ExportJsonDialog'
import ImportJsonDialog from '../ImportJsonDialog'
import SettingsPage from '../SettingsPage'
import SplitMenuButton from '../SplitMenuButton'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles((theme) => ({
  root: {},
  addressbookSection: {
    width: '100%',
    maxWidth: 600
  },
  templates: {
    marginTop: theme.typography.pxToRem(15)
  },
  importButton: {
    marginBottom: theme.spacing(2)
  },
  formSection: {
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing()}px ${theme.spacing()}px`
    }
  }
}))

export interface UserFollowUpsFormProps {
  onDialogConfirm?: () => void
  isSuperadmin?: boolean
}

export default function UserFollowUpsForm({ onDialogConfirm, isSuperadmin }: UserFollowUpsFormProps) {
  const { values, setFieldValue } = useFormikContext<{ preferences: UserPreferencesDocument }>()
  const { openDialog, dismissDialog } = useDialogs()
  const classes = useStyles()
  const { options, save } = useClientOptions()
  const quotesLabel = getObjectLabels({ options }).quote.plural.toLowerCase()
  const invoicesLabel = getObjectLabels({ options, invoice: true }).quote.plural.toLowerCase()

  const order = values.preferences.emails.followUps?.order ?? []
  if (Object.keys(values.preferences.emails.followUps?.values || {}).length > order.length) {
    // We are missing some follow-up keys in order, these can still get triggered without being able to see/edit them, lets add them in
    const newOrder = Object.keys(values.preferences.emails.followUps?.values || {}).filter(
      (key) => !order.includes(key)
    )
    setFieldValue(`preferences.emails.followUps.order`, [...order, ...newOrder])
  }

  const followUps = order.map((key) => values.preferences.emails.followUps?.values[key]).filter(Boolean)

  function handleEdit(followUp: FollowUp) {
    openDialog(FollowUpDialog, {
      followUp,
      onConfirm: (followUp) => {
        setFieldValue(`preferences.emails.followUps.values.${followUp.key}`, followUp)
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  function handleToggle(followUp: FollowUp) {
    setFieldValue(`preferences.emails.followUps.values.${followUp.key}.active`, !followUp.active)
    setFieldValue(`preferences.emails.followUps.values.${followUp.key}.toggled`, Date.now())
  }

  function handleToggleText(followUp: FollowUp) {
    setFieldValue(`preferences.emails.followUps.values.${followUp.key}.sendText`, !followUp.sendText)
  }

  function handleCopy(followUp: FollowUp) {
    const newKey = uuid()
    const index = order.findIndex((o) => o === followUp.key)

    setFieldValue(`preferences.emails.followUps.order`, [...order.slice(0, index), newKey, ...order.slice(index)])
    setFieldValue(`preferences.emails.followUps.values.${newKey}`, {
      ...followUp,
      key: newKey
    })
  }

  function handleDelete(followUp: FollowUp) {
    openDialog(ConfirmationDialog, {
      title: 'Are you sure?',
      message: `This follow-up will no longer be available`,
      onConfirm: () => {
        const followUpsValues = {
          ...values.preferences.emails.followUps.values
        }

        delete followUpsValues[followUp.key]
        setFieldValue(`preferences.emails.followUps.values`, followUpsValues)
        setFieldValue(
          `preferences.emails.followUps.order`,
          values.preferences.emails.followUps.order.filter((o) => o !== followUp.key)
        )
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  function handleExport(followUp: FollowUp) {
    openDialog(ExportJsonDialog, {
      item: followUp,
      onConfirm: dismissDialog
    })
  }

  function handleImportJson() {
    openDialog(ImportJsonDialog, {
      onImport: (json: JSON) => {
        const newFollowUp = json as unknown as FollowUp
        const followUpsValues = {
          ...(values.preferences?.emails?.followUps?.values ?? {}),
          [newFollowUp.key]: newFollowUp
        }

        setFieldValue(`preferences.emails.followUps.values`, followUpsValues)
        setFieldValue(`preferences.emails.followUps.order`, [
          ...(values.preferences?.emails?.followUps?.order ?? []),
          newFollowUp.key
        ])
        dismissDialog()
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onClose: dismissDialog
    })
  }

  function handleMigrate() {
    openDialog(ConfirmationDialog, {
      title: 'Are you sure?',
      message: `This will migrate all users follow-ups to the company settings follow ups`,
      onConfirm: async () => {
        const updatedFollowUps = {
          order: [
            ...(options?.options?.emails?.followUps?.order ?? []),
            ...(values.preferences?.emails?.followUps?.order ?? [])
          ],
          values: {
            ...(options?.options?.emails?.followUps?.values ?? {}),
            ...(values.preferences?.emails?.followUps?.values ?? {})
          }
        }
        const updatedOptions = {
          ...options,
          options: {
            ...options?.options,
            emails: {
              ...options?.options?.emails,
              followUps: {
                ...options?.options?.emails?.followUps,
                values: {
                  ...updatedFollowUps.values
                },
                order: [...updatedFollowUps.order]
              }
            }
          }
        }
        await save({ options: updatedOptions })
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  const handleAdd = () => {
    openDialog(FollowUpDialog, {
      onConfirm: (followUp) => {
        const followUpsValues = {
          ...(values.preferences?.emails?.followUps?.values ?? {}),
          [followUp.key]: followUp
        }

        setFieldValue(`preferences.emails.followUps.values`, followUpsValues)
        setFieldValue(`preferences.emails.followUps.order`, [
          ...(values.preferences?.emails?.followUps?.order ?? []),
          followUp.key
        ])

        onDialogConfirm()
        dismissDialog()
      },
      onCancel: dismissDialog
    })
  }

  return (
    <SettingsPage
      title="Follow-Ups"
      callout={{
        path: 'profile-follow-ups',
        content: `Initiate automated follow-up communications with your customers based on their interactions with ${quotesLabel} or ${invoicesLabel} and a designated timeframe.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/8289229-auto-follow-ups'
      }}
    >
      <Form className={classes.root}>
        <FormSection className={classes.formSection} topPadded={!isSuperadmin}>
          {isSuperadmin && (
            <Grid container justifyContent="flex-end" className={classes.importButton}>
              <SplitMenuButton
                onClick={handleImportJson}
                variant={'contained'}
                color={'primary'}
                Icon={ArrowIcon}
                label={'Import'}
              >
                <MenuItem onClick={handleMigrate}>Send to Settings</MenuItem>
              </SplitMenuButton>
            </Grid>
          )}

          <FollowUpTable
            settingsView
            followUps={followUps}
            isSuperadmin={isSuperadmin}
            onAdd={handleAdd}
            onAction={(action, followUp) => {
              switch (action) {
                case 'edit':
                  handleEdit(followUp)
                  break
                case 'copy':
                  handleCopy(followUp)
                  break
                case 'delete':
                  handleDelete(followUp)
                  break
                case 'toggle':
                  handleToggle(followUp)
                  break
                case 'toggleSendText':
                  handleToggleText(followUp)
                  break
                case 'export':
                  handleExport(followUp)
                  break
              }
            }}
          />
        </FormSection>
      </Form>
    </SettingsPage>
  )
}
