import React from 'react'
import type { TypographyProps } from '@material-ui/core'
import { Typography } from '@material-ui/core'

export default function ErrorMessage(props: TypographyProps) {
  return (
    <Typography variant="body2" color="error" {...props}>
      {props.children}
    </Typography>
  )
}
