import type { ButtonProps } from '@ui/paintscout'
import { Button, DialogStackContext } from '@ui/paintscout'
import type { ClientMetaDocument } from 'paintscout'
import React, { useContext } from 'react'
import NewUserDialog from './NewUserDialog'
import SearchClientsDialog from './SearchClientsDialog'

export default function NewUserButton({
  client,
  ...buttonProps
}: { client?: Pick<ClientMetaDocument, '_id' | 'name'> } & Omit<ButtonProps, 'classes'>) {
  const { openDialog, dismissDialog } = useContext(DialogStackContext)

  function onConfirm() {
    dismissDialog()
  }

  return (
    <Button
      color="primary"
      variant="text"
      onClick={() => {
        if (!client) {
          openDialog(SearchClientsDialog, {
            title: 'Choose a client for the new user',
            onClose: dismissDialog,
            onConfirm: (newClient: Pick<ClientMetaDocument, '_id' | 'name'>) => {
              openDialog(
                NewUserDialog,
                {
                  client: newClient,
                  onClose: dismissDialog,
                  onConfirm
                },
                {
                  replace: true
                }
              )
            }
          })
        } else {
          openDialog(NewUserDialog, {
            client,
            onConfirm,
            onClose: dismissDialog
          })
        }
      }}
      {...buttonProps}
    >
      + New User
    </Button>
  )
}
