import * as React from 'react'
import type { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup'
import { default as MuiRadioGroup } from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

export interface RadioGroupProps extends MuiRadioGroupProps {
  children?: React.ReactNode
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

const useStyles = makeStyles((theme) => ({
  root: {},
  checkedText: {
    color: '#292a2d !important'
  },
  checked: {
    color: `${theme.palette.primary.main} !important`
  }
}))

export default function DateRadio(props: RadioGroupProps) {
  const classes = useStyles()

  const { value, onChange, ...other } = props
  const dateOptions = [{ dateValue: 'MM/DD/YYYY' }, { dateValue: 'DD/MM/YYYY' }, { dateValue: 'YYYY-MM-DD' }]

  const radioLabel = (value: string, checked?: boolean) => {
    return (
      <Typography className={checked ? classes.checkedText : null} variant={'subtitle2'}>
        {value}
      </Typography>
    )
  }

  return (
    <MuiRadioGroup onChange={onChange} value={value} {...other}>
      {dateOptions.map((option, index) => {
        const checked = option.dateValue === value
        return (
          <FormControlLabel
            key={index}
            value={option.dateValue}
            control={<Radio classes={{ root: checked ? classes.checked : null }} />}
            label={radioLabel(option.dateValue, checked)}
          />
        )
      })}
    </MuiRadioGroup>
  )
}
