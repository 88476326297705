/**
 * @module builder
 */

import type { ProviderConfig, OptionsDocument } from 'paintscout'
import get from 'lodash/get'

export function getOptionsProviderConfig({
  options,
  name
}: {
  options: OptionsDocument
  name: string
}): ProviderConfig {
  const existingConfig = get(options.options, `integrations[${name}]`, null)

  let translatedConfig = {}
  if (name === 'pld' && options?.options?.pipelineKey) {
    const existingPipelineKey = get(options.options, `pipelineKey`)
    // Translate the PipelineOptions to a providerConfig we use in PipelineDealsProviderSettings
    // and translate back from when changes are made
    translatedConfig = {
      enabled: (existingConfig?.enabled || existingPipelineKey?.enabled) ?? false,
      apiKey: existingPipelineKey.value,
      config: {
        peopleSearch: existingPipelineKey?.peopleSearch ?? false,
        sentCreateNote: existingPipelineKey?.features?.onSend?.createNote?.enabled ?? false,
        sentCreateDocument: existingPipelineKey?.features?.onSend?.createDocument?.enabled ?? false,
        sentUpdateValue: existingPipelineKey?.features?.onSend?.updateDeals?.value ?? false,
        sentUpdateStage: existingPipelineKey?.features?.onSend?.updateDeals?.enabled ?? false,
        sentDealStage: {
          value: existingPipelineKey?.features?.onSend?.updateDeals?.stage ?? null
        },
        sentDealStageFrom: (existingPipelineKey?.features?.onSend?.stages ?? [])
          .filter((stage) => stage && stage?.enabled && stage.id)
          .map((stage) => {
            return {
              value: stage.id,
              label: stage.name,
              enabled: true
            }
          }),
        // Accepted:
        acceptedCreateNote: existingPipelineKey?.features?.onAccept?.createNote?.enabled ?? false,
        acceptedCreateDocument: existingPipelineKey?.features?.onAccept?.createDocument?.enabled ?? false,
        acceptedUpdateValue: existingPipelineKey?.features?.onAccept?.updateDeals?.value ?? false,
        acceptedUpdateStage: existingPipelineKey?.features?.onAccept?.updateDeals?.enabled ?? false,
        acceptedDealStage: {
          value: existingPipelineKey?.features?.onAccept?.updateDeals?.stage ?? null
        },
        acceptedDealStageFrom: (existingPipelineKey?.features?.onAccept?.stages ?? [])
          .filter((stage) => stage && stage?.enabled && stage.id)
          .map((stage) => {
            return {
              value: stage.id,
              label: stage.name,
              enabled: true
            }
          }),
        // Declined
        declinedCreateNote: existingPipelineKey?.features?.onDecline?.createNote?.enabled ?? false,
        declinedCreateDocument: existingPipelineKey?.features?.onDecline?.createDocument?.enabled ?? false,
        declinedUpdateValue: existingPipelineKey?.features?.onDecline?.updateDeals?.value ?? false,
        declinedUpdateStage: existingPipelineKey?.features?.onDecline?.updateDeals?.enabled ?? false,
        declinedDealStage: {
          value: existingPipelineKey?.features?.onDecline?.updateDeals?.stage ?? null
        },
        declinedDealStageFrom: (existingPipelineKey?.features?.onDecline?.stages ?? [])
          .filter((stage) => stage && stage?.enabled && stage.id)
          .map((stage) => {
            return {
              value: stage.id,
              label: stage.name
            }
          })
      },
      events: {
        ...(existingConfig?.events ?? {})
      }
    } as ProviderConfig
  }

  const defaultConfig = { config: {}, enabled: false, events: {} } as ProviderConfig

  const config = {
    ...defaultConfig,
    ...existingConfig,
    ...translatedConfig
  }

  return config
}
