import ColorPicker from './ColorPicker'
export default ColorPicker
export * from './ColorPicker'

import type { InlineColorPickerProps } from './InlineColorPicker'
import InlineColorPicker from './InlineColorPicker'
export { InlineColorPicker }
export type { InlineColorPickerProps }
export * from './InlineColorPicker'

export interface ColorPickerProps {
  label?: string
  value: string
  onChange?: (hex: string) => void
}
