import type { ProviderConfig, OptionsDocument, EventTypeConfig } from 'paintscout'
import { translatePldConfig } from './translate-pld-config'

export function updatePldConfig(providerConfig: ProviderConfig, options: OptionsDocument): OptionsDocument {
  // Translate the changes into a PipelineOption
  const translatedConfig = translatePldConfig(providerConfig)

  const onSendValues = {
    ...options.options?.pipelineKey?.features?.onSend,
    ...translatedConfig.features?.onSend
  }
  const onAcceptValues = {
    ...options.options?.pipelineKey?.features?.onAccept,
    ...translatedConfig.features?.onAccept
  }
  const onDeclineValues = {
    ...options.options?.pipelineKey?.features?.onDecline,
    ...translatedConfig.features?.onDecline
  }

  const newOptionsValues = {
    ...options,
    options: {
      ...options.options,
      pipelineKey: {
        ...options?.options?.pipelineKey,
        value: translatedConfig.value,
        enabled: translatedConfig.enabled,
        peopleSearch: translatedConfig.peopleSearch,
        features: {
          ...options.options?.pipelineKey?.features,
          ...translatedConfig.features,
          onSend: onSendValues,
          onAccept: onAcceptValues,
          onDecline: onDeclineValues
        }
      },
      integrations: {
        ...options.options.integrations,
        ['pld']: {
          ...providerConfig,
          // Clean values from here that are private
          events: {
            ['quote-sent']: {
              enabled: Object.values(onSendValues).some(
                (value) => typeof value === 'object' && (value as EventTypeConfig)?.enabled
              ),
              triggeredBy: 'all'
            },
            ['quote-accepted']: {
              enabled:
                Object.values(onAcceptValues).some(
                  (value) => typeof value === 'object' && (value as EventTypeConfig)?.enabled
                ) || providerConfig.events['quote-accepted']?.enabled,
              triggeredBy: 'all'
            },
            ['quote-declined']: {
              enabled:
                Object.values(onDeclineValues).some(
                  (value) => typeof value === 'object' && (value as EventTypeConfig)?.enabled
                ) || providerConfig.events['quote-declined']?.enabled,
              triggeredBy: 'all'
            }
          },
          apiKey: ''
        }
      }
    }
  }

  return newOptionsValues
}
