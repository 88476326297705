/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { AreaLabelOption } from '../../index'

export function deleteAreaLabelOption(args: {
  options: OptionsDocument
  areaLabelOption: AreaLabelOption
}): OptionsDocument {
  const { options, areaLabelOption } = args

  const key = areaLabelOption.name

  const order = (options.options.areaTemplates.order ?? []).filter((item) => key !== item)
  const quoteTypeOrder = options.options.areaTemplates.quoteTypeOrder ?? {}

  Object.keys(quoteTypeOrder).forEach((type) => {
    quoteTypeOrder[type] = quoteTypeOrder[type].filter((item) => key !== item)
  })

  const values = { ...options.options.areaTemplates.values }
  if (values[key]) {
    delete values[key]
  }

  const updatedOptions = {
    ...options,
    options: {
      ...options.options,
      areaTemplates: {
        ...options.options.areaTemplates,
        order,
        quoteTypeOrder,
        values
      }
    }
  }

  return updatedOptions
}

export function deleteAreaLabelOptions(args: {
  options: OptionsDocument
  areaLabelOptions: AreaLabelOption[]
}): OptionsDocument {
  const { options, areaLabelOptions } = args
  return areaLabelOptions.reduce(
    (options, areaLabelOption) => deleteAreaLabelOption({ options, areaLabelOption }),
    options
  )
}
