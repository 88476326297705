import React from 'react'
import type { FieldProps } from 'formik'
import type { PhoneNumberInputProps } from '../PhoneNumberInput'
import PhoneNumberInput from '../PhoneNumberInput'
import InputField from '../InputField'

export interface FormikPhoneNumberInputProps extends FieldProps<any>, Omit<PhoneNumberInputProps, 'form' | 'classes'> {
  valueDefault?: any
}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikPhoneNumberInput = ({ field, ...props }: PhoneNumberInputProps) => {
  return (
    <InputField
      {...field}
      {...props}
      inputComponent={PhoneNumberInput}
      inputProps={{ ...(props.inputProps ?? {}), separator: props.separator }}
    />
  )
}

export default FormikPhoneNumberInput
