/**
 * @module builder
 */
import type { Product } from '../index'
import { uuid } from '../../util'

export function createProduct(args?: { quoteTypes?: string[]; key?: string; label?: string }): Product {
  const { quoteTypes = ['all'] } = args ?? {}
  const rateTypes = ['all']
  const label = args?.label ?? ''
  const key = args?.key ?? uuid()

  return {
    key,
    label,
    coverage: 0,
    lnftCoverage: 0,
    quantityCoverage: 0,
    quoteTypes,
    rateTypes,
    price: 0,
    active: true
  }
}
