import React from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '../Tab'
import { Tooltip } from '@material-ui/core'
import classnames from 'classnames'
import Add from '@material-ui/icons/Add'
import type { PresentationOption, QuoteDocument, OptionsDocument, QuoteViews, QuoteOptions } from 'paintscout'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import type { SortableContainerProps, SortableElementProps } from 'react-sortable-hoc'
import { getObjectLabels } from '@paintscout/util/builder'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      // display: 'grid'
      flexWrap: 'wrap',
      height: '100%',
      '& button': {
        ...theme.typography.overline
      }
    },
    tab: {
      // paddingTop: theme.spacing(3),
      // paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2)
      }
    },
    containerWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '3em',
      // marginRight: 30,
      // background: theme.paintscout.palette.primary,
      // padding: `0 15px`,
      paddingRight: theme.spacing(2),
      marginRight: 5,
      '& img': {
        maxHeight: '100%'
      }
    },
    item: {},
    itemLabel: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap'
    },
    selectedItem: {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      opacity: 1
    },
    dragHelper: {},
    addButton: {
      borderRadius: theme.shape.borderRadius,
      color: '#fff',
      background: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5),
      '&:hover': {
        color: '#fff',
        backgroundColor: theme.palette.primary.main
      }
    }
  }
})

export interface NavItem {
  value: string
  label: string
}

export interface NavTabsProps extends WithStyles {
  presentation?: PresentationOption
  options?: OptionsDocument
  quote?: QuoteDocument
  view?: QuoteViews
  value: string
  logo?: string
  disabled?: boolean
  onNavClick?: (ev: React.MouseEvent, value: string) => void

  onReorder?: (items: NavItem[]) => void
  allowAdd?: boolean
  onAddClick?: (ev: React.MouseEvent) => void
}

interface SortableNavContainerProps {
  children?: React.ReactNode
  classes?: any
}

const SortableNavContainer: React.ComponentClass<SortableContainerProps & SortableNavContainerProps, any> =
  SortableContainer(function NavWrapper({ children, ...props }: SortableNavContainerProps & WithStyles) {
    const classes = useStyles(props)

    return <div className={classes.root}>{children}</div>
  })

interface SortableNavItemProps {
  item: NavItem
  selected: boolean
  onNavClick?: (ev: React.MouseEvent, value: string) => void
  classes?: any
}
const SortableNavItem: React.ComponentClass<SortableElementProps & SortableNavItemProps, any> = SortableElement(
  ({
    item,
    selected,
    onNavClick,

    ...props
  }: SortableNavItemProps & WithStyles) => {
    const classes = useStyles(props)

    return (
      <div>
        <div
          onClick={(ev: React.MouseEvent) => {
            onNavClick(ev, item.value)
          }}
        >
          <Tab
            classes={{
              root: classnames({ [classes.item]: true, [classes.selectedItem]: selected, [classes.tab]: true })
            }}
            label={<div className={classes.itemLabel}>{item.label ? item.label : '(No Title)'}</div>}
          />
        </div>
      </div>
    )
  }
)

function NavTabs({
  value,
  logo,
  onNavClick,
  onAddClick,
  disabled,
  allowAdd,
  onReorder,
  presentation,
  options,
  quote,
  view,
  ...props
}: NavTabsProps) {
  const classes = useStyles(props)

  const items = getPresentationTabs({ presentation, options, quote, view })

  return (
    <div className={classes.containerWrapper}>
      {logo && (
        <div className={classes.logo}>
          <img src={logo} />
        </div>
      )}
      <SortableNavContainer
        axis={'xy'}
        pressDelay={150}
        helperClass={classes.dragHelper}
        classes={classes}
        onSortEnd={handleSortEnd}
        shouldCancelStart={() => disabled}
      >
        {items.map((item: NavItem, index: number) => (
          <SortableNavItem
            key={item.value}
            item={item}
            classes={classes}
            index={index}
            selected={(value && item.value === value) || (!value && index === 0)}
            onNavClick={onNavClick}
          />
        ))}
      </SortableNavContainer>
      {allowAdd && (
        <Tooltip title={'Add New Page'}>
          <div onClick={onAddClick}>
            <Tab
              label={
                <div className={classes.addButton}>
                  <Add />
                </div>
              }
            />
          </div>
        </Tooltip>
      )}
    </div>
  )

  function handleSortEnd({ oldIndex, newIndex }) {
    onReorder(arrayMove(items, oldIndex, newIndex))
  }
}

function getDefaultPresentationTabs(args: { quote: QuoteDocument; options: OptionsDocument; view: string }) {
  const { quote, options, view } = args
  const quoteOptions = quote.options ? quote.options : ({} as Partial<QuoteOptions>)
  const objectLabels = getObjectLabels({ options })

  let tabs: any[]
  if (view === 'work-order') {
    tabs = [
      { value: 'quote', label: objectLabels.workOrder.value },
      { value: 'pictures', label: 'PICTURES' }
    ]

    if (!quote.images || !quoteOptions.showWorkOrderFiles) {
      tabs = tabs.filter((item) => item.value !== 'pictures')
    }
  } else if (quote.is_invoice) {
    tabs = [
      { value: 'quote', label: 'INVOICE' },
      { value: 'terms', label: 'TERMS' }
    ]
  } else {
    tabs = [
      { value: 'quote', label: objectLabels.quote.value },
      { value: 'pictures', label: 'PICTURES' },
      { value: 'terms', label: 'TERMS' }
    ]

    if (!quote.images || !quoteOptions.showWorkOrderFiles) {
      tabs = tabs.filter((item) => item.name !== 'pictures')
    }
  }

  return tabs
}

function getPresentationTabs(args: {
  presentation: PresentationOption
  quote: QuoteDocument
  options: OptionsDocument
  view: string
}) {
  const { presentation, quote, options, view } = args
  const { pages } = presentation

  if (pages && pages.length > 1) {
    return pages
      .filter((page) => page?.advanced?.useForNav && (page?.active ?? true))
      .map((page) => {
        return {
          value: page.key,
          label: page.title,
          type: 'page'
        }
      })
  } else if (pages && pages.length === 1) {
    return pages[0].sections
      .filter((section) => section?.advanced?.useForNav && (section?.active ?? true) && !!section.title)
      .map((section) => {
        return {
          value: section.key,
          label: section.title,
          type: 'section'
        }
      })
  } else {
    return getDefaultPresentationTabs({ quote, options, view })
  }
}

export default NavTabs
