import { Card, CardContent, Grid } from '@material-ui/core'
import {
  AreaOptions,
  LineItemOptions,
  PaymentOptions,
  PresentationOptions,
  ProductOptions,
  QuoteOptions,
  QuoteTagOptions,
  ContactSources,
  QuoteTypeOptions,
  RateOptions,
  TermsOptions
} from '@paintscout/react-quote'
import {
  FloatingMenu,
  ClientGeneralForm,
  ClientEmailsForm,
  ClientFollowUpsForm,
  ClientChatForm,
  ClientAdminIntegrationsForm
} from '@paintscout/ui'
import { getObjectLabels } from '@paintscout/util/builder'
import { DisclaimerOptions, InvoiceOptions } from '@ui/react-quote/src/options'
import { useFormikContext } from 'formik'
import type { OptionsDocument } from 'paintscout'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getUrlQuery, setUrlQuery } from 'shared/util/routing'

interface UrlParams {
  section: string
  presentation: string
}

export interface ClientFormProps {
  section?: string
  isSuperAdmin?: boolean
  onSectionChange?: (section: string) => any
}

export default function ClientSettingsForm({ section, isSuperAdmin, onSectionChange }: ClientFormProps) {
  const location = useLocation()
  const urlQuery = getUrlQuery<UrlParams>({ location })

  const {
    values: { options }
  } = useFormikContext<{ options: OptionsDocument }>()
  const [floatingMenuValue, setFloatingMenuValue] = useState(section || 'company')

  // update if url changes
  useEffect(() => {
    setFloatingMenuValue(section || 'company')
  }, [section])

  function changeMenuValue(newValue: string) {
    if (onSectionChange) {
      onSectionChange(newValue)
    }
    setFloatingMenuValue(newValue)
  }

  const objectLabels = getObjectLabels({ options })
  const menuItems = [
    {
      key: 'company',
      label: 'Company'
    },
    {
      key: 'quotes',
      label: objectLabels.quote.plural
    },
    {
      key: 'quote-types',
      label: `${objectLabels.quote.value} Types`
    },
    {
      key: 'disclaimers',
      label: 'Disclaimers'
    },
    {
      key: 'tags',
      label: 'Tags'
    },
    {
      key: 'invoices',
      label: 'Invoices'
    },
    {
      key: 'rates',
      label: objectLabels.productionRate.plural
    },
    {
      key: 'areas',
      label: 'Areas'
    },
    {
      key: 'products',
      label: 'Products'
    },
    {
      key: 'line-items',
      label: 'Line Items'
    },
    {
      key: 'terms',
      label: 'Terms'
    },
    {
      key: 'presentation',
      label: 'Presentations'
    },
    {
      key: 'emails',
      label: 'Email Templates'
    },
    {
      key: 'follow-ups',
      label: 'Auto Follow-Ups'
    },
    {
      key: 'chat',
      label: 'Chat'
    },
    {
      key: 'sources',
      label: 'Contact Sources'
    },
    {
      key: 'integrations',
      label: 'Integrations'
    },
    {
      key: 'payments',
      label: 'Payments'
    }
  ]
    .filter(Boolean)
    .filter((item) =>
      isSuperAdmin ? ['company', 'quotes', 'payments', 'terms', 'sources', 'customer-view'].includes(item.key) : item
    )

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} xl={3}>
        <FloatingMenu
          value={floatingMenuValue}
          defaultValue="company"
          onItemClick={(item) => changeMenuValue(item.key)}
          items={menuItems}
          collapseOn="lg"
        />
      </Grid>
      <Grid item xs={12} xl={9}>
        <Card>
          <CardContent>
            {floatingMenuValue === 'company' && <ClientGeneralForm showAdvanced />}
            {floatingMenuValue === 'quotes' && <QuoteOptions showAdvanced />}
            {floatingMenuValue === 'disclaimers' && <DisclaimerOptions />}
            {floatingMenuValue === 'tags' && <QuoteTagOptions onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'quote-types' && <QuoteTypeOptions onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'rates' && <RateOptions />}
            {floatingMenuValue === 'areas' && <AreaOptions onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'line-items' && <LineItemOptions onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'products' && <ProductOptions onDialogConfirm={() => {}} />}
            {section === 'presentation' && (
              <PresentationOptions
                presentation={urlQuery.presentation}
                onPresentationChange={(presentation: string) => {
                  setUrlQuery({ location, params: { presentation } })
                }}
                onDialogConfirm={() => {}}
                isAdmin={true}
              />
            )}
            {floatingMenuValue === 'invoices' && <InvoiceOptions />}
            {floatingMenuValue === 'emails' && <ClientEmailsForm onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'follow-ups' && <ClientFollowUpsForm onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'chat' && <ClientChatForm onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'integrations' && <ClientAdminIntegrationsForm />}
            {floatingMenuValue === 'terms' && (
              <TermsOptions showOnlyAdvanced={isSuperAdmin} onDialogConfirm={() => {}} />
            )}
            {floatingMenuValue === 'sources' && <ContactSources onDialogConfirm={() => {}} />}
            {floatingMenuValue === 'payments' && <PaymentOptions />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
