import type { OptionsDocument } from 'paintscout'
import type { LeadSourceResult, LeadSourceType } from '../..'

/**
 * Update lead sources based on type
 * @returns updated options
 */
export function updateLeadSource(args: {
  options: OptionsDocument
  type: LeadSourceType
  source: LeadSourceResult<LeadSourceType>
}): OptionsDocument {
  const { options, type, source } = args
  const sources = options.options[type]

  const order = sources ? [...sources.order] : [source.name]
  const orderIndex = order.indexOf(source.name)
  if (orderIndex === -1) {
    order.push(source.name)
  }

  return {
    ...options,
    options: {
      ...options.options,
      [type]: {
        ...sources,
        values: sources
          ? {
              ...sources.values,
              [source.name]: {
                ...sources.values[source.name],
                ...source
              }
            }
          : {
              [source.name]: {
                ...source
              }
            },
        order
      }
    }
  }
}
