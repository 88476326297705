/**
 * Check if given quoteType is valid for given quoteTypes
 * @returns boolean - true if valid quoteType
 */
export function filterByQuoteType({ quoteTypes, quoteType }: { quoteTypes: string[]; quoteType?: string }): boolean {
  return (
    !quoteType || // if not filtered by any quote type
    quoteType === 'all' || // if filtering by all quote type
    quoteTypes.indexOf('all') > -1 || // If item has all quote types
    quoteTypes.indexOf(quoteType) > -1 || // if item has given quote type
    quoteTypes.filter(Boolean).length === 0 // if we have 'all' quote types that is [] or 'all' quote types that is actually [undefined]
  )
}
