import type { WithStyles } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { FormikContextType } from 'formik'
import { connect } from 'formik'
import get from 'lodash/get'
import type { WithSnackbarProps } from 'notistack'
import { withSnackbar } from 'notistack'
import * as React from 'react'
import { getObjectLabels } from '@paintscout/util/builder'
import type { WithClientOptions } from '../../ClientOptionsProvider'
import { withClientOptions } from '../../ClientOptionsProvider'
import type { WithDialogStackContext } from '../../DialogStack'
import { withDialogStackContext } from '../../DialogStack'
import FormSection from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import RadioButtons from '../../RadioButtons'
import type { WithUser } from '../../UserProvider/withUser'
import withUser from '../../UserProvider/withUser'
import type { ProfileFormikValues } from '../ProfileFormik'
import { Editor } from '../../Editor'

const styles = createStyles({
  root: {},
  screenRadio: {
    marginTop: 0
  }
})

export interface MiscFormProps
  extends WithStyles<typeof styles>,
    WithDialogStackContext,
    WithSnackbarProps,
    WithUser,
    WithClientOptions {}

interface FormikConnectProps {
  formik: FormikContextType<ProfileFormikValues>
}

class MiscForm extends React.Component<FormikConnectProps & MiscFormProps> {
  public render() {
    const { classes, user } = this.props
    // Default true so works in admin
    const { hasPermissions = () => true } = user

    const numPadOptions = [
      { name: 'always', label: 'Always' },
      { name: 'never', label: 'Never' },
      { name: 'touch', label: 'Only show on Touch Devices/iPad' }
    ]
    return (
      <div className={classes.root}>
        <FormSectionTitle title={'Miscellaneous'} variant="h2" />
        <FormSection>
          <FormSectionTitle
            title={'On-Screen Number Pad'}
            variant={'h3'}
            subTitle={'Allow showing number pads throughout app.'}
          />
          <RadioButtons
            options={numPadOptions}
            classes={{ radioGroup: classes.screenRadio }}
            onChange={this.handlePreferenceChange('keypad')}
            value={this.props.formik.values.preferences.keypad ?? 'touch'}
          />
        </FormSection>

        {this.renderFontScale()}
        {this.renderHomePageInput()}
        {hasPermissions(['create:quotes']) && this.renderAddContact()}
        {hasPermissions(['create:quotes']) && this.renderDefaultEstimatorNotes()}
      </div>
    )
  }

  public renderFontScale = () => {
    const { classes, clientOptions } = this.props
    const { options } = clientOptions

    const hasFontScaling = get(options, 'options.features.fontScaling.enabled', false)
    if (!hasFontScaling) {
      return null
    }

    const fontScaleOptions = [
      { name: '0.9', label: 'Smaller' },
      { name: '1', label: 'Normal' },
      { name: '1.1', label: 'Larger' }
    ]

    return (
      <FormSection>
        <FormSectionTitle
          title={'Font Scale'}
          variant={'h3'}
          subTitle={'Smaller or Larger Default Fonts. Only affects Estimator view.'}
        />
        <RadioButtons
          options={fontScaleOptions}
          classes={{ radioGroup: classes.screenRadio }}
          onChange={this.handleFontScaleChange}
          value={String(this.props.formik.values.preferences.fontScale ?? '1')}
        />
      </FormSection>
    )
  }

  public renderHomePageInput = () => {
    const { classes, clientOptions, formik } = this.props
    const { options } = clientOptions

    const hasDashboard = get(options, 'options.features.dashboard.enabled', false)
    if (!hasDashboard) {
      return null
    }

    const objectLabels = getObjectLabels({ options })

    const homePageOptions = [
      { name: 'dashboard', label: 'Dashboard' },
      { name: 'quotes', label: objectLabels.quote.plural }
    ]

    const homePageValue = get(formik.values.preferences, 'homePage', 'dashboard')

    return (
      <FormSection>
        <FormSectionTitle
          title={'Home Page'}
          variant={'h3'}
          subTitle={'Page shown by default when starting the app.'}
        />
        <RadioButtons
          options={homePageOptions}
          classes={{ radioGroup: classes.screenRadio }}
          onChange={this.handlePreferenceChange('homePage')}
          value={homePageValue}
        />
      </FormSection>
    )
  }

  public renderAddContact = () => {
    const { classes, clientOptions, formik } = this.props
    const { options } = clientOptions

    const objectLabels = getObjectLabels({ options })

    const addContactOptions = [
      { name: 'searchFirst', label: 'Search' },
      { name: 'addFirst', label: 'Create New' }
    ]

    const addContactValue = get(formik.values.preferences, 'addContact', 'searchFirst')

    return (
      <FormSection>
        <FormSectionTitle
          title={'Add Contact'}
          variant={'h3'}
          subTitle={`Default option when adding a contact to a new ${objectLabels.quote.value}.`}
        />
        <RadioButtons
          options={addContactOptions}
          classes={{ radioGroup: classes.screenRadio }}
          onChange={this.handlePreferenceChange('addContact')}
          value={addContactValue}
        />
      </FormSection>
    )
  }

  public renderDefaultEstimatorNotes = () => {
    const { clientOptions } = this.props
    const { options } = clientOptions

    const objectLabels = getObjectLabels({ options })

    return (
      <FormSection>
        <FormSectionTitle
          title={'Default Estimator Notes'}
          subTitle={`Default estimator notes to appear on all new ${objectLabels.quote.plural}.`}
          variant={'h3'}
        />
        <Editor
          classes={{}}
          content={this.props.formik.values.preferences.defaultEstimatorNotes ?? '<p></p>'}
          fullWidth={true}
          debounce={true}
          onChange={this.handleDefaultEstimatorNotesChange}
        />
      </FormSection>
    )
  }

  public handlePreferenceChange = (name: string) => (value: any) => {
    this.props.formik.setFieldValue(`preferences.${name}` as any, value)
  }

  public handleFontScaleChange = (value: any) => {
    this.props.formik.setFieldValue(`preferences.fontScale` as any, Number(value))
  }

  public handleDefaultEstimatorNotesChange = (value: string) => {
    this.props.formik.setFieldValue(`preferences.defaultEstimatorNotes` as any, value)
  }
}

export default withStyles(styles)(
  withDialogStackContext(
    withSnackbar(withUser(withClientOptions(connect<MiscFormProps, ProfileFormikValues>(MiscForm))))
  )
)
