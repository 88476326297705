import type { OptionsDocument, TaxOption } from 'paintscout'

export function generateTaxRateDetails({ options }: { options: OptionsDocument }): {
  taxRate: number
  taxDescription: string
  taxDetails: TaxOption[]
} {
  const { taxRate, taxDescription, taxDetails } = (options?.options?.taxRates ?? []).reduce(
    (acc, rate) => {
      if (rate.isDefault) {
        acc.taxRate += parseFloat(rate.rate as unknown as string)
        acc.taxDescription += `${acc.taxDescription.length == 0 ? '' : ', '}${rate.description}`
        acc.taxDetails.push({
          key: rate.key,
          rate: rate.rate,
          description: rate.description,
          isDefault: rate.isDefault
        })
      }
      return acc
    },
    { taxRate: 0, taxDescription: '', taxDetails: [] as TaxOption[] }
  )
  return { taxRate, taxDescription, taxDetails }
}
