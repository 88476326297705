import React, { useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { useClientOptions, FormSectionTitle, FormSection, EventSubscriptionType } from '../../'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'
import type { EventType, ProviderConfig } from 'paintscout'

const useStyles = makeStyles<Theme, XeroProviderFormProps>(
  (_theme) => ({
    root: {}
  }),
  { name: 'XeroProviderForm' }
)

export interface XeroProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
}

export function XeroProviderForm(props: XeroProviderFormProps) {
  const { providerConfig, providerName, isSettingsIntegration = false, isAdminIntegrations = false, onChange } = props
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const { handleEventChange } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  return (
    <>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle title={'Create Quote'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-sent' as EventType}
              event={providerConfig.events['quote-sent']}
              onChange={handleEventChange}
              label={`When ${objectLabels.quote.plural} are sent`}
            />
          </Grid>
        </Grid>
        <FormSectionTitle title={'Create Invoice'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'invoice-sent' as EventType}
              event={providerConfig.events['invoice-sent']}
              onChange={handleEventChange}
              label={`When Invoices are sent`}
              selfLabel={'My Invoices'}
              allLabel={'All Invoices'}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}
