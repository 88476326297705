/**
 * @module builder
 */

import type { UserPreferencesDocument, ProviderConfig } from 'paintscout'

import get from 'lodash/get'

export function getProfileProviderConfig(args: { preferences: UserPreferencesDocument; name: string }): ProviderConfig {
  const { preferences, name } = args
  const existingConfig = get(preferences, `integrations[${name}]`, {})

  const defaultConfig = { config: {}, enabled: false, events: {} } as ProviderConfig

  const config = {
    ...defaultConfig,
    ...existingConfig
  }

  return config
}
