import createCSVTemplate from './create-csv-template'

/**
 * Create template and setup download for csv file.
 * @param type type of template we are creating
 * @param provider if quote type, what provider
 * @returns initiates download of chosen csv template
 */
const downloadCSVTemplate = async (type: string, provider?: string) => {
  const csvData = createCSVTemplate(type, provider)
  downloadCSV(csvData, `${provider ? `${provider}-${type}` : `${type}`}.csv`)
}

const downloadCSV = (csvData: string, filename: string) => {
  const csvBlob = new Blob([csvData], { type: 'text/csv' })
  const csvUrl = URL.createObjectURL(csvBlob)
  const link = document.createElement('a')
  link.href = csvUrl
  link.download = filename
  link.click()
}

export default downloadCSVTemplate
