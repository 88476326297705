import { Fab, Grow, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import {
  Button,
  ClientOptionsProvider,
  ConfirmationDialog,
  DialogStackContext,
  DialogStackPortal,
  Hidden,
  PopupMenuItem,
  Spinner,
  Tab,
  Tabs
} from '@ui/paintscout'
import { useSnackbar } from 'notistack'
import type { Auth0User } from 'paintscout'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { matchPath, useHistory, useRouteMatch } from 'react-router-dom'
import * as Yup from 'yup'
import ClientAdministrationForm from '../../components/ClientAdministrationForm'
import ClientSettingsForm from '../../components/ClientSettingsForm'
import ErrorMessage from '../../components/ErrorMessage'

import { useClientQuery, useUpdateClientMutation } from '@paintscout/api'
import { getDeepObjectDiff } from '@paintscout/util/activity'
import { calculateTaxRate, sanitizeOptions } from '@paintscout/util/builder'
import { removeTypenames } from '@paintscout/util/util'
import { Formik } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import { NavHeader } from '../../components/NavDrawer'
import NewUserDialog from '../../components/NewUserDialog'
import RoutePrompt from '../../components/RoutePrompt'

const useStyles = makeStyles({
  tabsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mobileSaveButton: {
    position: 'fixed',
    bottom: 16,
    right: 16
  }
})

const validationSchema = Yup.object().shape({
  options: Yup.object().shape({
    options: Yup.object().shape({
      // companyName: Yup.object().shape({
      //   value: Yup.string().required('Required')
      // }),
      hourlyRate: Yup.object().shape({
        value: Yup.string().min(1).required()
      }),
      quotes: Yup.object().shape({
        customerViewExpiry: Yup.object().shape({
          enabled: Yup.boolean(),
          days: Yup.number().when('enabled', {
            is: true,
            then: Yup.number().required()
          }),
          months: Yup.number().when('days', {
            is: 0,
            then: Yup.number().moreThan(0).required()
          })
        }),
        customerInvoiceExpiry: Yup.object().shape({
          enabled: Yup.boolean(),
          days: Yup.number().when('enabled', {
            is: true,
            then: Yup.number().required()
          }),
          months: Yup.number().when('days', {
            is: 0,
            then: Yup.number().moreThan(0).required()
          })
        })
      })
    })
  }),
  internalOptions: Yup.object().shape({
    options: Yup.object().shape({
      integrations: Yup.object().shape({
        stripe: Yup.object().shape({
          feeRate: Yup.number().min(0).max(0.01),
          currency: Yup.string().oneOf(['USD', 'CAD'])
        })
      })
    })
  })
})

export default function EditClient() {
  const match = useRouteMatch<{ id: string; tab: string; section: string }>()
  const history = useHistory()
  const { id } = match.params
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState(match.params.tab || 'administration')
  const [section, setSection] = useState(match.params.section)
  const classes = useStyles({})

  useEffect(() => {
    history.replace({
      pathname: `/clients/${id}/${tab}/${section || ''}`
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, section])

  const [updateClient, { loading: saving }] = useUpdateClientMutation({
    variables: {
      id
    }
  })
  const { data, loading, error } = useClientQuery({
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })

  const clientInfo = data?.client
  const options = clientInfo?.options ? sanitizeOptions(data.client.options) : null
  const internalOptions = clientInfo?.internalOptions
  const meta = clientInfo?.meta
  const rates = clientInfo?.rates
  const initialValues = useRef(null)
  useEffect(() => {
    initialValues.current = { options, internalOptions, rates, meta }
  }, [options, internalOptions, rates, meta, data, clientInfo, loading])

  function handleNewUser() {
    openDialog(NewUserDialog, {
      client: {
        _id: meta._id,
        name: options.options.companyName.value as string
      },
      onConfirm: (user: Auth0User) => {
        history.push({
          pathname: `/users/${user.user_id}`
        })
        dismissDialog()
      }
    })
  }

  function changeTab(newTab: string) {
    setTab(newTab)
    setSection(undefined)
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }
  if (loading || !initialValues?.current?.options) {
    if (!initialValues?.current?.options && data?.client) {
      initialValues.current = { options, internalOptions, rates, meta }
    } else {
      return <Spinner fullWidth fullHeight />
    }
  }

  return (
    <Formik
      initialValues={initialValues.current}
      onSubmit={async (values, actions) => {
        const { options, internalOptions, rates, meta } = values
        try {
          const internalOptionsChanges = getDeepObjectDiff(
            initialValues.current.internalOptions.options,
            internalOptions.options
          )

          const handleSave = async () => {
            const updatedOptions = calculateTaxRate(options)
            const response = await updateClient({
              variables: {
                id,
                ...removeTypenames({ options: updatedOptions, internalOptions, rates, meta })
              }
            })
            const newValues = {
              options: response.data.updateClient.options ?? options,
              internalOptions: response.data.updateClient.internalOptions ?? internalOptions,
              rates: response.data.updateClient.rates ?? rates,
              meta: {
                ...meta,
                ...response?.data?.updateClient?.meta
              }
            }

            initialValues.current = cloneDeep(newValues)
            actions.resetForm({
              values: newValues
            })
            dismissDialog()
            enqueueSnackbar('Saved', { variant: 'success' })
          }

          if (internalOptionsChanges.length > 0) {
            openDialog(ConfirmationDialog, {
              title: `Warning - Updating Internal Options`,
              message: `You are about to update important Internal Options values, are you sure you wish to proceed?\n
              The values are ${Object.values(internalOptionsChanges).join(`, `)}`,
              scary: true,
              onConfirm: async () => {
                await handleSave()
              },
              onCancel: () => {
                dismissDialog()
                return
              }
            })
          } else {
            await handleSave()
          }
        } catch (e) {
          console.error(e)
          enqueueSnackbar('Unable to save client', { variant: 'error' })
        }
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, values, dirty }) => {
        return (
          <ClientOptionsProvider options={values.options} rates={values.rates} isAdmin={true} clientId={meta._id}>
            <DialogStackPortal />
            <RoutePrompt
              when={dirty}
              message={(next) => {
                if (
                  !matchPath(next.pathname, {
                    path: `/clients/${match.params.id}`
                  })
                ) {
                  return 'You have unsaved changes, are you sure?'
                }

                return true
              }}
            />
            <NavHeader
              title={options.options.companyName.value as string}
              showBackButton
              menuItems={
                <>
                  <PopupMenuItem icon={AddIcon} onClick={handleNewUser}>
                    New User
                  </PopupMenuItem>
                </>
              }
            />
            <div className={classes.tabsWrapper}>
              <Tabs value={tab} style={{ marginBottom: 20 }} onChange={(ev, value) => changeTab(value)}>
                <Tab value="administration" label="Administration" />
                <Tab value="settings" label="Settings" />
                <Tab value="admin-settings" label="Admin Settings" />
              </Tabs>
              <Hidden mdDown>
                <Grow in={dirty}>
                  <Button loading={isSubmitting} icon={SaveIcon} onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </Grow>
              </Hidden>
            </div>
            {tab === 'administration' && <ClientAdministrationForm section={section} onSectionChange={setSection} />}
            {tab === 'settings' && <ClientSettingsForm section={section} onSectionChange={setSection} />}
            {tab === 'admin-settings' && (
              <ClientSettingsForm section={section} onSectionChange={setSection} isSuperAdmin />
            )}
            <Hidden lgUp>
              <div className={classes.mobileSaveButton}>
                <Grow in={dirty}>
                  <Fab disabled={!dirty || saving} color="primary" onClick={() => handleSubmit()}>
                    <SaveIcon />
                  </Fab>
                </Grow>
              </div>
            </Hidden>
          </ClientOptionsProvider>
        )
      }}
    </Formik>
  )
}
