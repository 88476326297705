/**
 * @module builder
 */
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import type { LineItemOption } from '../../index'
import { uuid } from '../../index'
import { getQuoteType } from '../../quote/util'

export function getLineItemOptions(args: {
  options: OptionsDocument
  quoteType?: string
  inactive?: boolean
  quote?: QuoteDocument
  rekeyMedia?: boolean
}): LineItemOption[] {
  const { options, inactive, rekeyMedia } = args
  const { lineItems } = options.options

  const quoteType = getQuoteType(args)

  const order =
    lineItems.quoteTypeOrder && lineItems.quoteTypeOrder[quoteType]
      ? uniq([...lineItems.quoteTypeOrder[quoteType], ...lineItems.order])
      : uniq(lineItems.order)

  return order
    .map((orderItem) => {
      const item = lineItems.values[orderItem]
      if (!item) {
        return null
      }

      let quoteTypes
      if (item.quoteTypes) {
        quoteTypes = item.quoteTypes
      } else {
        quoteTypes = [get(item, 'int_ext.value')]
      }

      const rekeyedMedia = {}
      const rekeyedFiles = []
      if (rekeyMedia && Object.keys(item.quoteFiles ?? []).length > 0) {
        Object.keys(item.quoteFiles).forEach((key) => {
          const newKey = uuid()
          rekeyedMedia[newKey] = { ...item.quoteFiles[key], key: newKey }
          rekeyedFiles.push(newKey)
        })
      }

      const option: LineItemOption = {
        name: orderItem,
        label: item.label,
        description: item.description,
        hours: item.hours,
        materials: item.materials ?? [],
        price: item.price,
        files: rekeyMedia ? rekeyedFiles : item.files ?? [],
        quoteFiles: rekeyMedia ? rekeyedMedia : item.quoteFiles ?? {},
        calculate: item.calculate,
        calculateBy: item.calculateBy,
        quantity: item.quantity,
        pricePerUnit: item.pricePerUnit,
        hoursPerUnit: item.hoursPerUnit,
        crewNote: item.crewNote,
        clientNote: item.clientNote,
        customHourlyRate: item.customHourlyRate,
        hideOnWorkOrder: item.hideOnWorkOrder,
        hourlyRate: item.hourlyRate,
        showPrice: item.showPrice,
        hideQuantity: item.hideQuantity,
        thumbnailId: item.quoteFiles?.[item.files?.[0]]?.cloudinaryPublicId,
        quoteTypes,
        source: item.source ?? { id: '', provider: '', value: '', label: '' },
        showHoursForWorkOrder: item.showHoursForWorkOrder
      }

      if (!inactive && item.active !== true && typeof item.active !== 'undefined') {
        return null
      } else if (inactive) {
        option.active = typeof item.active === 'undefined' ? true : !!item.active
      }

      if (
        !quoteType ||
        quoteType === 'all' ||
        quoteTypes.indexOf('all') > -1 ||
        quoteTypes.length === 0 ||
        quoteTypes.indexOf(quoteType) > -1
      ) {
        return option
      }

      return null
    })
    .filter((item) => item)
}
