import {
  BASIC_RATES_TEMPLATE,
  DETAILED_RATES_TEMPLATE,
  PRODUCTS_TEMPLATE,
  ER_QUOTES_TEMPLATE,
  DEFAULT_QUOTES_TEMPLATE,
  CONTACTS_TEMPLATE
} from '@paintscout/util'

/**
 * Get csv headers for specified template type and provider.
 * @param type type of template
 * @param provider if quote type, what provider
 * @returns template headers
 */
const selectTemplate = (type: string, provider?: string): string[] => {
  let template = []
  if (type === 'contacts') {
    template = CONTACTS_TEMPLATE
  } else if (type === 'quotes') {
    switch (provider) {
      case 'default':
        template = DEFAULT_QUOTES_TEMPLATE
        break
      case 'er-quotes':
        template = ER_QUOTES_TEMPLATE
        break
    }
  } else if (type === 'products') {
    template = PRODUCTS_TEMPLATE
  } else if (type === 'rates') {
    switch (provider) {
      case 'basic':
        template = BASIC_RATES_TEMPLATE
        break
      case 'detailed':
        template = DETAILED_RATES_TEMPLATE
        break
    }
  }

  return template
}

export default selectTemplate
