import type { QuoteDocument, OrderItem, LineItem, QuoteFileVisibility } from 'paintscout'
import { uuid } from '../../../util'
import { getQuoteOptions } from '../../../quote'

export function getPresentationPreviewQuote(quote?: Partial<QuoteDocument>): QuoteDocument {
  const lineItems = [
    {
      key: uuid(),
      name: 'First Line Item',
      description: 'This is the line item description.',
      price: 1234.5,
      show: true,
      quantity: 1,
      showPrice: true,
      show_price: true,
      use_total: true,
      images: ['house-1', 'house-2']
    }
  ] as LineItem[]
  const lineItemOptions = [
    {
      key: uuid(),
      name: 'Second Line Item',
      description: 'This is the line item description.',
      price: 1234.5,
      show: true,
      show_price: true,
      use_total: false,
      images: ['house-3']
    }
  ]
  const defaultQuote = {
    _id: 'preview',
    type: 'quote' as const,
    name: '',
    number: 12345,
    archived: false,

    contact: {
      id: uuid(),
      rev: '1',
      type: 'contact' as const,
      created_by: '',
      contact_type: 'contact' as const,
      created_date: Date.now(),
      updated_date: Date.now(),
      first_name: 'Bob',
      last_name: 'Johnson',
      phone_number: '222.333.4444',
      email: 'bob.johnson@example.com'
    },
    owner: {
      email: 'example@example.com',
      firstName: 'Example',
      lastName: 'Example'
    },
    int_ext: {
      value: 'int'
    },
    files: {
      'house-1': {
        key: 'house-1',
        type: 'image',
        cloudinaryPublicId: 'samples/houses/house-1',
        visibility: 'visible' as QuoteFileVisibility
      },
      'house-2': {
        key: 'house-2',
        type: 'image',
        cloudinaryPublicId: 'samples/houses/house-2',
        visibility: 'visible' as QuoteFileVisibility
      },
      'house-3': {
        key: 'house-3',
        type: 'image',
        cloudinaryPublicId: 'samples/houses/house-3',
        visibility: 'visible' as QuoteFileVisibility
      }
    },
    order: {
      area: [...lineItems, ...lineItemOptions].map((item) => ({ type: 'line_item', key: item.key })) as OrderItem[],
      substrate: [] as OrderItem[]
    },
    areas: {},
    lineItems: [...lineItems, ...lineItemOptions].reduce((item: LineItem, acc: any) => {
      acc[item.key] = item
      return acc
    }, {}),
    quote_date: Date.now(),
    hourly_rate: 50,
    created_by: '',
    status: {
      value: 'accepted' as const
    },
    options: getQuoteOptions({}),
    totals: {
      hours: 0,
      prep: 0,
      price: 1234.5,
      total_discount: 123.45,
      after_discount: 1111.05,
      after_tax: 1111.05
    },
    discount_type: { value: 'percent' },
    discount: 10,
    terms: {
      name: '',
      label: '',
      content: '<h2>Terms</h2><p>This is a sample little terms document.</p>'
    },
    substrates: {},
    dates: {}
  }

  return {
    ...defaultQuote,
    ...(quote ? quote : {})
  }
}
