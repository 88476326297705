import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { FieldAttributes } from 'formik'
import { Field } from 'formik'
import type { FormikSwitchProps } from '../formik'
import { FormikSwitch } from '../formik'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Tooltip from '../Tooltip'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  switchRoot: {
    margin: 0,
    marginRight: theme.spacing(1)
  },
  sublabel: {
    marginLeft: theme.typography.pxToRem(50),
    fontSize: '1rem'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
}))

const SwitchField: React.FC<FieldAttributes<any> & FormikSwitchProps & { sublabel?: string; Help?: JSX.Element }> = ({
  sublabel,
  label,
  Help,
  ...props
}) => {
  const classes = useStyles({})
  const ref = useRef(null)
  return (
    <>
      <FormControl className={classes.root}>
        <Field {...props} innerRef={ref} classes={{ root: classes.switchRoot }} component={FormikSwitch} />
        {label && (
          <div className={classes.labelContainer}>
            {typeof label === 'string' ? <Typography>{label}</Typography> : label}
            {Help && <Tooltip content={Help} />}
          </div>
        )}
      </FormControl>
      {sublabel && (
        <Typography className={classes.sublabel} variant="subtitle2">
          {sublabel}
        </Typography>
      )}
    </>
  )
}

export default SwitchField
