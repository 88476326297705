import React from 'react'
import type { FieldProps } from 'formik'
import type { FormSectionTitleProps } from '../FormSectionTitle'
import FormSectionTitle from '../FormSectionTitle'

export interface FormikFormSectionTitleProps extends FieldProps<any>, Omit<FormSectionTitleProps, 'form' | 'classes'> {}

/**
 * Helper component to use with a Formik <Field />
 */
const FormikFormSectionTitle = ({ field, form, ...props }: FormikFormSectionTitleProps) => {
  return (
    <FormSectionTitle
      showToggle={true}
      toggleValue={field.value}
      onToggle={(ev, value) => {
        form.setFieldValue(field.name, value)
      }}
      {...field}
      {...props}
    />
  )
}

export default FormikFormSectionTitle
