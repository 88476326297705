import { ApolloProvider } from '@apollo/react-hooks'
import { CssBaseline } from '@material-ui/core'
import { CloudinaryProvider, createTheme, DialogStackProvider, SnackbarProvider, ThemeProvider } from '@ui/paintscout'
import React from 'react'
import { Authorizer, AuthProvider } from 'react-auth0'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { Redirect, Route, Router, Switch } from 'react-router-dom'
import history from 'shared/util/routing/history'
import AppLoading from './components/AppLoading'
import LogRocket from './components/LogRocket'
import NavDrawer from './components/NavDrawer'
import { client } from './graphql/client'
import Clients from './pages/Clients/Index'
import Emergency from './pages/Emergency/Index'
import LoginCallback from './pages/LoginCallback'
import Logout from './pages/Logout'
import BMRewards from './pages/Partners/BMRewards'
import Partners from './pages/Partners/Index'
import Referrals from './pages/Partners/Referrals'
import Users from './pages/Users/Index'
import { audience, auth, scope } from './util'

const theme = createTheme({
  palette: {
    primary: { main: '#396E90' },
    secondary: { main: '#BDE2EA' }
  }
})

function App() {
  return (
    <CloudinaryProvider
      cloudName={process.env.CLOUDINARY_CLOUD_NAME}
      uploadPreset={process.env.CLOUDINARY_UPLOAD_PRESET}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AuthProvider auth0Client={auth} scope={scope} audience={audience} appUrl={process.env.PS_ADMIN_URL}>
            <ApolloProvider client={client}>
              <CssBaseline />
              <Router history={history}>
                <DialogStackProvider>
                  <LogRocket />
                  <Switch>
                    {/* Unauthenticated Routes */}
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/login-callback" exact component={LoginCallback} />

                    {/* Authenticated Routes */}
                    <Route
                      path="/"
                      render={() => (
                        <Authorizer>
                          {({ isAuthenticating }) => {
                            return isAuthenticating ? (
                              <AppLoading />
                            ) : (
                              <NavDrawer>
                                <Route path="/" exact>
                                  <Redirect to="/clients" />
                                </Route>
                                <Route path={'/clients'} component={Clients} />
                                <Route path="/users" component={Users} />
                                <Route path="/emergency" component={Emergency} />
                                <Route path="/partners/referrals" component={Referrals} />
                                <Route path="/partners/bmrewards" component={BMRewards} />
                                <Route path="/partners" exact component={Partners} />
                              </NavDrawer>
                            )
                          }}
                        </Authorizer>
                      )}
                    />
                  </Switch>
                </DialogStackProvider>
              </Router>
            </ApolloProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </CloudinaryProvider>
  )
}
export default App
