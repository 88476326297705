import React from 'react'
import type { ButtonProps } from '../../Button'
import Button from '../../Button'

function DialogActionButton(props: Omit<ButtonProps, 'classes'> & { classes?: Partial<ButtonProps['classes']> }) {
  return <Button {...props} />
}

DialogActionButton.defaultProps = {}

export default DialogActionButton
