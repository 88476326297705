/**
 * @module builder
 */
import type { OptionsDocument, PresentationOption, QuoteDocument } from 'paintscout'
import { getQuoteType } from '../../../quote/util'

import get from 'lodash/get'
import uniq from 'lodash/uniq'
import { getDefaultPresentation } from '../get-default-presentation'

export function getPresentationOptions(args: {
  options: OptionsDocument
  quoteType?: string
  inactive?: boolean
  quote?: QuoteDocument
}): PresentationOption[] {
  const { options, inactive } = args
  const presentationOptions = get(options.options, 'presentationOptions', {
    order: [],
    values: {} as { [key: string]: PresentationOption },
    quoteTypeOrder: {} as { [key: string]: string[] }
  })

  const quoteType = getQuoteType(args)

  let order =
    presentationOptions.quoteTypeOrder && presentationOptions.quoteTypeOrder[quoteType]
      ? uniq([...presentationOptions.quoteTypeOrder[quoteType], ...presentationOptions.order])
      : uniq(presentationOptions.order)

  if (!order) {
    order = []
  }

  const r = order
    .map((orderItem: string) => {
      const item = presentationOptions.values[orderItem] as PresentationOption
      if (!item) {
        return null
      }

      let quoteTypes
      if (item.quoteTypes) {
        quoteTypes = item.quoteTypes
      } else {
        quoteTypes = [get(item, 'int_ext.value')]
      }

      const option: PresentationOption = {
        ...item,
        key: orderItem,
        quoteTypes
      }

      if (!inactive && item.active !== true && typeof item.active !== 'undefined') {
        return null
      } else if (inactive) {
        option.active = typeof item.active === 'undefined' ? true : !!item.active
      }

      if (
        !quoteType ||
        quoteType === 'all' ||
        quoteTypes.indexOf('all') > -1 ||
        quoteTypes.indexOf(quoteType) > -1 ||
        !quoteTypes.length //If no quoteTypes are selected, show this option.
      ) {
        return option
      }

      return null
    })
    .filter((item) => item)

  // Only include default if its not saved as a presentation already
  if (r.find((item) => item.key === 'default')) {
    return [...r]
  } else {
    return [getDefaultPresentation({ options }), ...r]
  }
}
