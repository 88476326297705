/**
 * @module builder
 */

import type { PresentationPageOption, PresentationPage } from 'paintscout'
import { uuid } from '../../../util'

export function createPresentationPage(args?: { presentationPageOption: PresentationPageOption }): PresentationPage {
  const { presentationPageOption } = args ?? {}
  return {
    key: uuid(),
    title: 'New Page',
    type: '',
    active: true,
    advanced: {
      useForNav: true,
      allowCustom: true
    },
    sections: [],
    ...presentationPageOption
  }
}
