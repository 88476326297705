import selectTemplate from './select-template'

/**
 * Create csv headers for specified template type.
 * @param type type of text we are checking
 * @param provider if quote type, what provider
 * @returns header values for csv template
 */
const createCSVTemplate = (type: string, provider?: string) => {
  const headerValues = []
  const template = selectTemplate(type, provider)

  headerValues.push(template.join(','))
  return headerValues.join('\n')
}

export default createCSVTemplate
