import type { LineItemOption } from '@paintscout/util/builder'
import { getFeature } from '@paintscout/util/builder'
import type { OptionsDocument } from 'paintscout'

export const calculateLineItemOption = ({
  item,
  options
}: {
  item: LineItemOption
  options: OptionsDocument
}): LineItemOption => {
  if (item.calculate || item.calculateBy === 'hourlyRate') {
    const hourlyRate = item.customHourlyRate ? item.hourlyRate : Number(options.options?.hourlyRate?.value ?? 0)
    return {
      ...item,
      price: item.hours * hourlyRate
    }
  } else if (item.calculateBy === 'quantity') {
    const hoursPerUnitLineItems = getFeature({ options, path: 'quotes.hoursPerUnitLineItems' })
    return hoursPerUnitLineItems
      ? {
          ...item,
          price: item.pricePerUnit * item.quantity,
          hours: item.hoursPerUnit * item.quantity
        }
      : {
          ...item,
          price: item.pricePerUnit * item.quantity
        }
  } else {
    return item
  }
}
