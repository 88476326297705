import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Spinner } from '@ui/paintscout'

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default function AppLoading() {
  const classes = useStyles({})

  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, 200)

    return () => clearTimeout(timer)
  }, [])

  if (!showLoading) {
    return null
  }

  return (
    <div className={classes.root}>
      <Spinner />
    </div>
  )
}
