import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { DialogProps } from '../Dialog'
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../Dialog'
import Button from '../Button'
import Grid from '../Grid'
import InputField from '../InputField'

import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

export interface ImportJsonDialogProps extends Omit<DialogProps, 'classes'>, WithStyles<typeof styles> {
  onClose?: () => void
  queueDocUpdates?: (json: any) => void
  onImport?: (json: JSON) => void
}

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  input: { width: '100%', height: '100%' },
  content: { maxHeight: '100%' }
})

function ImportJsonDialog({ classes, onClose, queueDocUpdates, onImport, ...otherProps }: ImportJsonDialogProps) {
  const { enqueueSnackbar } = useSnackbar()

  const [item, setItem] = useState<string>('')

  async function handleImport() {
    let json = {} as any
    try {
      json = JSON.parse(item)
    } catch (err) {
      enqueueSnackbar(`Unable to parse JSON!`, { variant: 'error' })
      return
    }

    if (queueDocUpdates) {
      queueDocUpdates(json)
      enqueueSnackbar('Documents ready to save!', { variant: 'success' })
      onClose()
      return
    }

    onImport(json)
  }

  return (
    <Dialog {...otherProps} fullScreen={true}>
      <DialogTitle>{'Import JSON'}</DialogTitle>
      <DialogContent className={classes.content}>
        <InputField
          className={classes.input}
          multiline
          value={item}
          onChange={(e) => {
            setItem(e.target.value)
          }}
          fullWidth={true}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Grid item>
            <Button onClick={onClose} variant={'text'}>
              Cancel
            </Button>
            <Button onClick={handleImport} variant="contained">
              Import
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ImportJsonDialog)
