import React from 'react'
import UploadImage from '../../UploadImage'

export interface UploadCompanyLogoProps {
  /**
   * Fallback if clodinaryId/s3PublicKey is unavailable
   */
  src?: string

  /**
   * Provide these from the image if you can
   */
  cloudinaryId?: string
  s3PublicKey?: string

  disableClear?: boolean
  classes?: any
  label?: string
  onChange?: (value: {
    value: string
    cdnValue: string
    cloudinaryId?: string
    s3PublicKey?: string
    width?: number
    height?: number
    fileType: string
  }) => void
}

function UploadCompanyLogo({
  src,
  cloudinaryId,
  s3PublicKey,
  classes,
  label,
  disableClear,
  onChange
}: UploadCompanyLogoProps) {
  function handleUpload({
    src: newSrc,
    format,
    cloudinaryPublicId,
    s3PublicKey,
    width,
    height
  }: {
    src: string
    format: string
    cloudinaryPublicId?: string
    s3PublicKey?: string
    width: number
    height: number
    type: string
  }) {
    if (onChange) {
      onChange({
        value: newSrc,
        cdnValue: newSrc,
        cloudinaryId: cloudinaryPublicId,
        s3PublicKey,
        width,
        height,
        fileType: format
      })
    }
  }

  function handleClear() {
    if (onChange) {
      onChange({
        value: null,
        cdnValue: null,
        cloudinaryId: null,
        s3PublicKey: null,
        fileType: null
      })
    }
  }

  return (
    <UploadImage
      classes={classes}
      label={label}
      s3PublicKey={s3PublicKey}
      cloudinaryPublicId={cloudinaryId}
      src={src}
      onUpload={handleUpload}
      onClear={handleClear}
      maxWidth={300}
      disableClear={disableClear}
    />
  )
}

export default UploadCompanyLogo
