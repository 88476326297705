import type { ValueFilter } from 'json-to-lucene'
import React from 'react'
import moment from 'moment'
import { PLAN_OPTIONS } from './ClientSearchFilters'
import isEqual from 'lodash/isEqual'
import { FilterChip } from '@paintscout/ui'
import type { FilterChipProps } from '@paintscout/ui'

export interface ChipProps extends Omit<FilterChipProps, 'title' | 'label' | 'onDelete'> {
  filter: ValueFilter
  clients: any[]

  /**
   * Called when a user deletes the chip. The "valueIndex" parameter will be provided
   * if the filter is an InFilter
   */
  onDelete: (filter: ValueFilter, valueIndex?: number) => any
}

export default function ClientFilterChip({ filter, onDelete, ...props }: ChipProps) {
  const dateFormat = 'MM/DD/YYYY'

  if (filter.value === null || (Array.isArray(filter.value) && !filter.value[0] && !filter.value[1])) {
    return null
  }

  function getTitle() {
    switch (filter.id) {
      case 'trialExpiry':
        return 'Trial Expiry'
      case 'plan':
        return 'Plan Type'
      case 'delete':
        return 'Delete'
      case 'active':
        return 'Active'
      default:
        return ''
    }
  }

  function getLabel() {
    switch (filter.id) {
      case 'trialExpiry': {
        const value = filter.value as string[]
        const start = value[0]
        const end = value[1]

        if (start && end) {
          return `${moment(start).format(dateFormat)} to ${moment(end).format(dateFormat)}`
        }

        if (start) {
          return `From ${moment(start).format(dateFormat)}`
        }
        if (end) {
          return `Before ${moment(end).format(dateFormat)}`
        }

        return ''
      }
      case 'active':
        if (isEqual(['true', 'false'], filter.value)) {
          return 'Include Active'
        } else if (isEqual(['true'], filter.value)) {
          return 'Only Active'
        }

        return 'No Active'
      case 'delete':
        if (isEqual(['true', 'false'], filter.value)) {
          return 'Include Delete'
        } else if (isEqual(['true'], filter.value)) {
          return 'Only Delete'
        }

        return 'No Delete'
      default:
        return ''
    }
  }

  switch (filter.id) {
    case 'plan': {
      const values = filter.value as string[]

      if (!values) {
        return null
      }
      return (
        <>
          {values.map((value, index) => (
            <FilterChip
              {...props}
              key={index}
              title="Plan Type"
              label={PLAN_OPTIONS.find((plan) => plan.value === value)?.label}
              onDelete={() => onDelete(filter, index)}
            />
          ))}
        </>
      )
    }
    case 'company': {
      const values = filter.value as string[]
      return (
        <>
          {values.map((value, index) => (
            <FilterChip
              {...props}
              key={index}
              title="Company"
              label={values[index]}
              onDelete={() => onDelete(filter, index)}
            />
          ))}
        </>
      )
    }

    default: {
      const title = getTitle()
      const label = getLabel()

      if (!title || !label) {
        return null
      }

      return <FilterChip {...props} title={title} label={label} onDelete={() => onDelete(filter)} />
    }
  }
}
