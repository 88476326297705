import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Grid, Typography, Spinner } from '@ui/paintscout'
import { useSubscriptionQuery } from '@paintscout/api'

const useStyles = makeStyles<Theme>({
  root: {
    minHeight: '200px'
  }
})

export interface ClientSubscriptionCardProps {
  classes?: StyleClasses<typeof useStyles>
  clientBillingId?: string
}

function ClientSubscriptionCard({ clientBillingId, ...props }: ClientSubscriptionCardProps) {
  const classes = useStyles(props)
  const { data, loading, error } = useSubscriptionQuery({
    fetchPolicy: 'cache-and-network',
    skip: !clientBillingId,
    variables: {
      clientBillingId
    }
  })
  const { subscription } = data ?? {}
  const { billingContact, addons, plan } = subscription ?? {}
  const startDate = subscription?.current_term_start
    ? new Date(subscription.current_term_start * 1000).toDateString()
    : ''
  const endDate = subscription?.current_term_end ? new Date(subscription?.current_term_end * 1000).toDateString() : ''

  if (loading) {
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Spinner />
          </Grid>
        </Grid>
      </div>
    )
  }

  if (error) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Error retrieving client subscription details.</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <Typography variant={'h4'}>Billing Contact</Typography>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Typography variant={'subtitle1'}>First: </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'body1'}>{billingContact?.first_name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant={'subtitle1'}>Last: </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'body1'}>{billingContact?.last_name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant={'subtitle1'}>Email: </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'body1'}>{billingContact?.email}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant={'subtitle1'}>Phone: </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'body1'}>{billingContact?.phone}</Typography>
        </Grid>
      </Grid>
      <br />
      <Typography variant={'h4'}>Subscription Info</Typography>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Typography variant={'subtitle1'}>Plan: </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'body1'}>{plan.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'subtitle1'}>Plan Amount: </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={'body1'}>
            {subscription.currency_code} {(subscription.plan_unit_price / 100).toFixed(2)}/
            {subscription.billing_period_unit} x {subscription.plan_quantity}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'subtitle1'}>Plan Start: </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={'body1'}>
            {subscription?.subscription_start ? new Date(subscription.subscription_start * 1000).toDateString() : ''}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'subtitle1'}>Plan Activated: </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={'body1'}>
            {subscription?.subscription_activated
              ? new Date(subscription.subscription_activated * 1000).toDateString()
              : ''}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Typography variant={'h4'}>Addons</Typography>
      {addons.map((addon) => {
        return (
          <Typography key={addon.name} variant={'body1'}>
            - {addon.name}
          </Typography>
        )
      })}
      {addons.length === 0 && <Typography variant={'body1'}>- No addons -</Typography>}
      <br />
      <Typography variant={'h4'}>Current Billing Term</Typography>
      <Typography variant={'subtitle1'}>
        {startDate} - {endDate}
      </Typography>
    </div>
  )
}

export default ClientSubscriptionCard
