import type { TypographyProps } from '@material-ui/core'
import { Box, Typography, useTheme } from '@material-ui/core'
import type { FollowUp } from '@paintscout/api'
import { getObjectLabels, getQuoteTypeOptions } from '@paintscout/util/builder'
import moment from 'moment'
import React from 'react'
import { useClientOptions } from '../ClientOptionsProvider'
import Highlight from '../Highlight'
import type { FlexProps } from '../Flex'
import Flex from '../Flex'
import TimeIcon from '@material-ui/icons/Timer'
import QuoteIcon from '@material-ui/icons/Description'
import WarningIcon from '@material-ui/icons/Warning'
import SendIcon from '@material-ui/icons/Send'
import type { QuoteDocument } from 'paintscout'
import Tooltip from '../Tooltip'

interface FollowUpDetails {
  hasBeenSent: boolean
  notStatusHappened: boolean
  fromStatusHasHappened: boolean
  overdueFollowUp: boolean
  dueDate: number
}

/**
 * Summarizes the conditions of a Follow Up into a sentence
 */
export default function FollowUpSummary({
  followUp,
  disclaimer,
  followUpDetails = {
    hasBeenSent: false,
    notStatusHappened: false,
    fromStatusHasHappened: false,
    overdueFollowUp: false,
    dueDate: 0
  },
  quote = null,
  ...props
}: { followUp: FollowUp; disclaimer?: string; followUpDetails?: FollowUpDetails; quote?: QuoteDocument } & FlexProps) {
  const theme = useTheme()
  const { options } = useClientOptions()

  const { fromStatus, interval, days = moment.duration(interval).asDays(), months, notStatus } = followUp.conditions

  const objectLabels = getObjectLabels({ options })
  const docType = followUp.isInvoice ? 'Invoice' : objectLabels.quote.value
  const { hasBeenSent, notStatusHappened, fromStatusHasHappened, overdueFollowUp, dueDate } = followUpDetails

  return (
    <Flex direction="column" {...props}>
      <SubLabel icon={QuoteIcon}>
        <TypeLabel followUp={followUp} />
      </SubLabel>
      <SubLabel icon={TimeIcon}>
        <Chain
          elements={[
            // quote is
            docType && (
              <>
                <span>
                  {docType} {fromStatus === 'overdue' ? 'has been' : 'is'}{' '}
                </span>
              </>
            ),
            // <status>
            fromStatus && (
              <Highlight capitalize color={theme.palette.quote.status[fromStatus]}>
                {fromStatus === 'partial' ? 'Partially Paid' : fromStatus}{' '}
              </Highlight>
            ),
            // and not <status>
            notStatus?.length > 0 && (
              <>
                and {notStatus === 'responded' ? 'not' : 'has not been'}{' '}
                <Highlight
                  capitalize
                  color={theme.palette.quote.status[notStatus === 'responded' ? 'accepted' : notStatus]}
                >
                  {notStatus === 'partial' ? 'Partially Paid' : notStatus}
                </Highlight>{' '}
              </>
            ),
            // after <days>
            (months || days) && (
              <>
                {fromStatus === 'overdue' ? 'for' : 'after'} {getIntervalDescription({ months, days })}
              </>
            )
          ]}
          ellipses={fromStatus === 'overdue' ? !(months || days) : true}
        />
      </SubLabel>
      {!!disclaimer && (
        <SubLabel icon={WarningIcon}>
          <DisclaimerLabel disclaimer={disclaimer} />
        </SubLabel>
      )}
      {Boolean(quote && quote?.status?.history && quote?.status?.history['sent']) && (
        <SubLabel icon={SendIcon}>
          {!hasBeenSent && !notStatusHappened && fromStatusHasHappened && !overdueFollowUp && (
            <Typography component="span">Scheduled - {new Date(dueDate).toLocaleDateString()}</Typography>
          )}
          {hasBeenSent && (
            <Typography component="span">
              Sent - {new Date(quote?.followUps[followUp.key]?.sent).toLocaleDateString()}
            </Typography>
          )}
          {(notStatusHappened || !fromStatusHasHappened || overdueFollowUp) && !hasBeenSent && (
            <div style={{ display: 'flex', justifyContent: 'center', color: '#AAAAAA' }}>
              <Tooltip
                interactive
                content={
                  <>
                    This status indicates that the scheduled follow-up will not be sent because the specified criteria
                    have not been met.{' '}
                    <a
                      href="http://help.paintscout.com/en/articles/9298877-why-is-my-auto-follow-up-not-applicable-to-send"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>{' '}
                  </>
                }
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography component={'span'}>Not applicable to send</Typography>
                </div>
              </Tooltip>
            </div>
          )}
        </SubLabel>
      )}
    </Flex>
  )
}

/**
 * Renders each element if truthy. when falsey, truncates and doesn't render the rest.
 */
function Chain({ elements, ellipses }: { elements: Array<JSX.Element | React.ReactNode>; ellipses: boolean }) {
  const filtered = elements.filter(Boolean)
  if (filtered.length !== elements.length && ellipses) {
    filtered.push(<React.Fragment key="...">...</React.Fragment>)
  }

  return filtered.map((el, index) => React.cloneElement(el as any, { key: index })) as any
}

function TypeLabel({ followUp }: { followUp: FollowUp }) {
  const { options } = useClientOptions()

  const objectLabels = getObjectLabels({ options })
  const quoteTypes = getQuoteTypeOptions({ options, inactive: false, hideAll: false })
  const docType = followUp.isInvoice ? 'Invoice' : objectLabels.quote.value

  if (followUp.quoteTypes?.length === 0 || followUp.quoteTypes.includes('__all__') || followUp.isInvoice) {
    return <span>{docType}</span>
  }

  const quoteTypeLabels = followUp.quoteTypes
    .map((type) => quoteTypes.find((t) => t.key === type)?.label)
    .filter(Boolean)

  return (
    <span>
      {docType} - {quoteTypeLabels.join(', ')}
    </span>
  )
}

function DisclaimerLabel({ disclaimer }: { disclaimer: string }) {
  const theme = useTheme()
  return (
    <Highlight color={theme.palette.error.main}>
      <span>{disclaimer}</span>
    </Highlight>
  )
}

function SubLabel({ icon, children, ...props }: TypographyProps & { icon: React.ComponentType }) {
  const Icon = icon
  return (
    <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'flex-start' }} gutterBottom {...props}>
      <Box component={Icon} fontSize="inherit" marginRight={1} marginTop="2px" />
      <Typography component="span">{children}</Typography>
    </Typography>
  )
}

function getIntervalDescription({ days, months }: { days: number; months: number }) {
  let txt = ''

  if (months) {
    txt += months === 1 ? '1 month' : `${months} months`
  }

  if (days) {
    if (months) {
      txt += ' and '
    }

    txt += days === 1 ? '1 day' : `${days} days`
  }

  return txt
}
