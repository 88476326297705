import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import React from 'react'

import type { Theme } from '@material-ui/core'
import { Card, makeStyles } from '@material-ui/core'
import CouponCodeTable from '../../components/CouponCodeTable'
import ErrorMessage from '../../components/ErrorMessage'

import { NavHeader } from '../../components/NavDrawer'

import type { ValueFilter } from 'json-to-lucene'

import { Button, useDialogs } from '@paintscout/ui'

import NewReferralCodeDialog from 'src/components/NewReferralCodeDialog'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  tableWrapper: {
    marginTop: theme.spacing(2)
  },
  searchBar: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      gridColumn: '1 / 3'
    }
  },
  searchContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    },
    '& input': {
      minWidth: 400,
      [theme.breakpoints.down('md')]: {
        minWidth: 260
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset'
      }
    }
  },
  filterChips: {
    '&$filterChips': {
      gridRow: 3,
      gridColumn: '1 / -1',
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(-1)
    }
  }
}))

export interface SearchProps {
  text: string
  filters: ValueFilter[]
  isInitialSearch?: boolean
}

export interface UrlParams {
  sort: string
  page: number
  search: string
}

export default function ListCouponCodes() {
  const classes = useStyles({})

  const { loading, data, error, updateQuery } = useQuery(
    gql`
      query coupons($prefix: String, $status: String) {
        coupons(prefix: $prefix, status: $status) {
          id
          status
          name
          email
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: false,
      variables: {
        prefix: 'Referral - '
      }
    }
  )

  const rows = data?.coupons ?? []

  const { openDialog, dismissDialog } = useDialogs()

  return (
    <div className={classes.root}>
      <NavHeader title="Referral Partners" />
      <Button
        color="primary"
        variant="text"
        onClick={() => {
          openDialog(NewReferralCodeDialog, {
            title: 'New Referral Coupon',
            prefix: 'Referral - ',
            onClose: dismissDialog,
            onConfirm: (newCoupon) => {
              console.log({ newCoupon, updateQuery })
              updateQuery((prevResults) => {
                console.log({ prevResults, newCoupon })
                return {
                  coupons: [newCoupon, ...prevResults.coupons]
                }
              })
              dismissDialog()
            }
          })
        }}
      >
        + New Referral Coupon
      </Button>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <Card className={classes.tableWrapper}>
        <CouponCodeTable
          rows={
            loading
              ? []
              : rows.map((row) => {
                  return {
                    ...row,
                    code: row.id
                  }
                })
          }
          loading={loading}
        />
      </Card>
    </div>
  )
}
