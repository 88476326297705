import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles<Theme, AdornmentBoxProps>((theme) => ({
  root: {
    borderLeft: '1px solid black !important',
    width: (props) => props.width ?? theme.typography.pxToRem(70),
    height: (props) => props.height ?? theme.typography.pxToRem(42),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(-0.5),
    color: theme.palette.grey[500]
  }
}))

export interface AdornmentBoxProps {
  children: JSX.Element
  height?: number
  width?: number
}

const AdornmentBox = (props: AdornmentBoxProps) => {
  const { children } = props
  const classes = useStyles(props)
  return <div className={classes.root}>{children}</div>
}

export default AdornmentBox
