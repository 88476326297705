import React, { useState } from 'react'

import type { DialogProps, DropdownSelectOption } from '@ui/paintscout'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormikDropdownSelect,
  FormikInputField,
  Grid,
  useDialogs
} from '@ui/paintscout'
import { Field, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import type { Octokit } from '@octokit/core'

export interface GithubIssueDialogProps extends DialogProps {
  octokit: Octokit
  debugInfo?: string
  onConfirm: (issue: any) => void
}

export default function GithubIssueDialog({ octokit, debugInfo, onConfirm, ...dialogProps }: GithubIssueDialogProps) {
  const { dismissDialog } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const labelOptons: DropdownSelectOption[] = [
    {
      label: 'Type: Bug',
      value: 'Type: Bug'
    },
    {
      label: 'Type: Feature/Enhancement',
      value: 'Type: Feature/Enhancement'
    },
    {
      label: 'Type: Internal/Task',
      value: 'Type: Internal/Task'
    }
  ]

  const handleSubmit = async (values) => {
    setLoading(true)
    await octokit
      .request('POST /repos/{owner}/{repo}/issues', {
        owner: 'PaintScout',
        repo: 'PaintScout',
        title: values.title,
        body: "Bleep bloop! I'm a bot! 🤖\n\n" + (debugInfo ? values.body + '\n\n```' + debugInfo : values.body),
        labels: [values.label],
        milestone: 55, // "Triage" Milestone
        headers: {
          'X-GitHub-Api-Version': '2022-11-28'
        }
      })
      .then((response) => {
        console.log(response)
        enqueueSnackbar('Issue Created', { variant: 'success' })
        onConfirm(response.data)
      })
      .catch((e) => {
        console.error(e)
        enqueueSnackbar('Unable to create issue', { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog {...dialogProps} maxWidth={'md'} fullWidth={true}>
      <Formik
        initialValues={{
          title: '',
          body: '',
          label: 'Type: Bug'
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => (
          <>
            <DialogTitle>Create New Issue</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field name="title" label="Title" required component={FormikInputField} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="body"
                    label="Body"
                    required
                    component={FormikInputField}
                    multiline
                    rows={4}
                    fullWidth
                    helperText={debugInfo ? 'Note: the debug info will automatically append to the issue body.' : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    onChange={(e) => {
                      setFieldValue('label', e.target.value)
                    }}
                    name="label"
                    label="Label"
                    required
                    component={FormikDropdownSelect}
                    options={labelOptons}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              leftButton={
                <Button onClick={dismissDialog} variant="text">
                  Cancel
                </Button>
              }
            >
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit(values)
                }}
                loading={loading}
              >
                Confirm
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}
