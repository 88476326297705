/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { AreaLabelOption } from '../../index'
import { getQuoteType } from '../../quote/util'

export function reorderAreaLabelOptions(args: {
  options: OptionsDocument
  areaLabelOptions: AreaLabelOption[]
  quoteType: string
}): OptionsDocument {
  const { options, areaLabelOptions } = args

  const quoteType = getQuoteType(args)

  let order = [...options.options.areaTemplates.order]
  const quoteTypeOrder = { ...options.options.areaTemplates.quoteTypeOrder }

  if (quoteType && quoteType !== 'all') {
    quoteTypeOrder[quoteType] = areaLabelOptions.map((item: AreaLabelOption) => {
      return item.name
    })
  } else {
    order = areaLabelOptions.map((item: AreaLabelOption) => {
      return item.name
    })
  }

  return {
    ...options,
    options: {
      ...options.options,
      areaTemplates: {
        ...options.options.areaTemplates,
        order,
        quoteTypeOrder
      }
    }
  }
}
