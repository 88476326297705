import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import type { OptionsDocument } from 'paintscout'
import { TileList } from '@ui/paintscout'
import type { ProductOption } from '@paintscout/util/builder'
import { getProductOptions } from '@paintscout/util/builder'
import React from 'react'
import uniqBy from 'lodash/uniqBy'

export interface ProductSelectorProps extends WithStyles<typeof styles> {
  options?: OptionsDocument
  quoteTypes?: string[]
  query?: string

  allowCustom?: boolean

  onChange?: (event: any, product: ProductOption, custom?: boolean) => void
}

export const styles = (_theme: Theme) => {
  return createStyles({
    root: {},
    formSection: {
      padding: '1.5rem 0'
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  })
}

class ProductSelector extends React.PureComponent<ProductSelectorProps> {
  public render() {
    const { classes, options, query, quoteTypes = [] } = this.props
    const productOptions = uniqBy(
      quoteTypes.reduce((acc, quoteType) => {
        const newOptions = getProductOptions({ options, quoteType })
        return [...acc, ...newOptions]
      }, []),
      'key'
    )

    const filteredOptions = productOptions.filter(
      (option: ProductOption) => option.label.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    )

    return (
      <div className={classes.root}>
        <TileList
          variant="select"
          title="Product"
          items={filteredOptions}
          onSelect={(ev, key: string) => this.handleChange(ev, key, false, productOptions)}
          onCustom={(ev) => this.handleChange(ev, null, true, productOptions)}
          subLabelField={'sublabel'}
        />
      </div>
    )
  }

  public handleChange = (event: any, key: string, custom: boolean, productOptions: ProductOption[]) => {
    this.props.onChange(
      event,
      productOptions.find((i) => i.key === key),
      custom
    )
  }
}

export default withStyles(styles)(ProductSelector)
