import type { FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import type { OptionsDocument } from 'paintscout'
import { Button, Dialog } from '@ui/paintscout'
import type { DialogProps } from '@ui/paintscout'
import { DialogActions, DialogContent, DialogTitle } from '@ui/paintscout'
import { getOptionsFixture } from '@paintscout/util/database/fixtures'
import { changePlan } from '@paintscout/util/builder'
import NewClientForm from './NewClientForm'
import { useCreateClientMutation } from '@paintscout/api'

export interface NewClientDialogProps extends DialogProps {
  onConfirm: (clientId: string) => any
}
export default function NewClientDialog({ onConfirm, ...props }: NewClientDialogProps) {
  const { enqueueSnackbar } = useSnackbar()

  const [createClient] = useCreateClientMutation()

  const template = 'trial'
  const plan = 'trial'
  const product = 'ps'

  const options = getOptionsFixture({ template })
  const initialValues = changePlan({ options, plan })

  async function handleSubmit(values: OptionsDocument, actions: FormikHelpers<OptionsDocument>) {
    actions.setStatus({ error: null })

    try {
      const { data } = await createClient({
        variables: {
          input: {
            options: values,
            meta: {
              signupMethod: 'admin',
              product
            },
            template
          }
        },
        refetchQueries: ['searchClients']
      })

      onConfirm(data.createClient._id)
      enqueueSnackbar('Client Created', { variant: 'success' })
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Unable to create client', { variant: 'error' })
    }
    actions.setSubmitting(false)
  }

  return (
    <Dialog {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ isSubmitting }) => (
          <Form>
            <DialogTitle loading={isSubmitting}>New Client</DialogTitle>
            <DialogContent>
              <NewClientForm />
            </DialogContent>
            <DialogActions
              leftButton={
                <Button variant="text" disabled={isSubmitting} onClick={() => props.onClose(null, null)}>
                  Cancel
                </Button>
              }
            >
              <Button disabled={isSubmitting} type="submit">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      />
    </Dialog>
  )
}
