/**
 * @module builder
 */
import type { OptionsDocument, PresentationOption } from 'paintscout'

import { getQuoteType } from '../../../quote/util'

export function reorderPresentationOptions(args: {
  options: OptionsDocument
  presentationOptions: PresentationOption[]
  quoteType: string
}): OptionsDocument {
  const { options, presentationOptions } = args

  const quoteType = getQuoteType(args)

  let order = [...options.options.presentationOptions.order]
  const quoteTypeOrder = { ...options.options.presentationOptions.quoteTypeOrder }

  if (quoteType && quoteType !== 'all') {
    quoteTypeOrder[quoteType] = presentationOptions.map((item: PresentationOption) => {
      return item.key
    })
  } else {
    order = presentationOptions.map((item: PresentationOption) => {
      return item.key
    })
  }

  return {
    ...options,
    options: {
      ...options.options,
      presentationOptions: {
        ...options.options.presentationOptions,
        order,
        quoteTypeOrder
      }
    }
  }
}
