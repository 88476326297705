import {
  CheckboxField,
  Collapse,
  FormikDropdownSelect,
  FormikHtmlEditor,
  FormikInputField,
  FormSection,
  FormSectionTitle,
  Grid,
  useUser,
  useClientOptions,
  Tooltip,
  FormikCheckbox,
  AdornmentBox
} from '@ui/paintscout'
import { getEmailTemplates, getFeature, getFeatures, getObjectLabels } from '@paintscout/util/builder'
import { getTemplateContext, getTemplateTags, makeHtml } from '@paintscout/util/util'
import { FastField, Field, useFormikContext } from 'formik'
import type { InternalOptionsDocument, OptionsDocument } from 'paintscout'
import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import { parseNumber } from '@paintscout/util/calculator'

function PaymentOptions() {
  const { values, errors, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    internalOptions?: InternalOptionsDocument
  }>()
  const { options } = values

  const hasSettingsCommunications = getFeature({ options, path: 'settingsCommunications.enabled' })
  const surchargeFeature = getFeatures({ options })?.surcharge
  const { preferences } = useUser()
  const { isAdmin } = useClientOptions()
  const { quote: quotesLabel, currency } = getObjectLabels({ options, invoice: false })
  const invoicesLabel = getObjectLabels({ options, invoice: true }).quote

  const emailTemplates = getEmailTemplates({ options })
  const emailTemplate = {
    key: 'payment-received',
    ...emailTemplates['payment-received']
  }

  options.options.emails.values[emailTemplate.name] = options.options.emails.values[emailTemplate.name] ?? emailTemplate

  const editorPreference = preferences?.editor ?? 'always'
  const templateContext = getTemplateContext(editorPreference)
  const templateTags = getTemplateTags({ options, invoice: true })

  const internationalMode = options.options.internationalMode

  return (
    <>
      <FormSection hideDivider>
        <FormSectionTitle title={`${quotesLabel.plural}`} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CheckboxField
              name="options.options.quotes.allowPaymentsOnQuotes"
              label={
                <Tooltip
                  content={`If this box is enabled, customers will be prompted to pay a deposit after accepting their ${quotesLabel.value.toLowerCase()}.`}
                >
                  <>{`Collect a deposit payment when ${quotesLabel.plural.toLowerCase()} are accepted.`}</>
                </Tooltip>
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} alignItems="flex-end">
            <Field
              component={FormikInputField}
              label="Default Deposit Amount"
              name="options.options.quotes.depositAmount.value"
              fullWidth
              format="percent"
              error={errors.options?.options?.quotes?.depositAmount}
              disabled={!values.options.options.quotes.allowPaymentsOnQuotes}
              endAdornment={
                <AdornmentBox>
                  <Field
                    component={FormikDropdownSelect}
                    name="options.options.quotes.depositAmount.type"
                    fullWidth
                    placeholder="%"
                    nestedDropdown
                    options={[
                      { label: '%', value: 'percentage' },
                      { label: currency.symbol, value: 'amount' }
                    ]}
                    disabled={!values.options.options.quotes.allowPaymentsOnQuotes}
                  />
                </AdornmentBox>
              }
            />
          </Grid>
          {isAdmin && (
            <Grid item xs={12}>
              <Collapse show={values?.options?.options?.quotes?.depositAmount?.type !== 'amount'}>
                <CheckboxField
                  name="options.options.quotes.depositAmountBeforeTax"
                  label={'Use balance due before tax when calculating the deposit amount'}
                  sublabel={'By default, the deposit amount is a percentage of the balance due after tax'}
                  disabled={!values.options.options.quotes.allowPaymentsOnQuotes}
                />
              </Collapse>
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection>
        <FormSectionTitle title={`${invoicesLabel.plural}`} variant={'h4'} />
        <Grid item xs={12}>
          <CheckboxField
            name="options.options.quotes.allowPaymentsOnInvoices"
            label={`Allow payments on ${invoicesLabel.plural.toLowerCase()}.`}
          />
        </Grid>
      </FormSection>
      {surchargeFeature && (
        <FormSection>
          <FormSectionTitle title="Surcharging" variant="h4" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CheckboxField
                name="options.options.quotes.surcharge.enabled"
                label={`Request a surcharge when payments are made by credit card.`}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField
                name="options.options.quotes.surcharge.applyToDeposit"
                label={`Request a surcharge on deposits.`}
                disabled={!values.options?.options?.quotes?.surcharge?.enabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                component={FormikInputField}
                label="Surcharge Amount (%)"
                name="options.options.quotes.surcharge.value"
                fullWidth
                type="number"
                disabled={!values?.options?.options?.quotes?.surcharge?.enabled}
              />
            </Grid>
          </Grid>
        </FormSection>
      )}

      {!hasSettingsCommunications && (
        <FormSection>
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <FormSectionTitle
                title={'Payment Received Email'}
                variant={'h4'}
                subTitle={`This email is sent whenever a payment is received or manually added.`}
              />
            </Grid>
            <Grid item xs={12}>
              <FastField
                label={'Subject'}
                content={makeHtml(emailTemplate.subject, { replaceBreaks: true })}
                component={FormikHtmlEditor}
                debounce={true}
                name={`options.options.emails.values.${emailTemplate.name}.subject`}
                templateContext={templateContext}
                toolbar={{
                  template: {
                    tags: templateTags
                  }
                }}
                singleLine
              />
            </Grid>
            <Grid item xs={12}>
              <FastField
                label={'Body'}
                component={FormikHtmlEditor}
                debounce={true}
                content={makeHtml(emailTemplate.body, { replaceBreaks: true })}
                name={`options.options.emails.values.${emailTemplate.name}.body`}
                templateContext={templateContext}
                toolbar={{
                  template: {
                    tags: templateTags
                  }
                }}
              />
            </Grid>
          </Grid>
        </FormSection>
      )}
      <FormSection>
        <FormSectionTitle title="Currency" subTitle={'Set your stripe fee currency.'} variant="h4" />
        <Grid container>
          <Grid item xs={4}>
            <Field
              component={FormikDropdownSelect}
              fullWidth
              name="options.options.currency"
              placeholder="USD"
              options={[
                { label: 'USD', value: 'USD' },
                { label: 'CAD', value: 'CAD' },
                internationalMode && { label: 'GBP', value: 'GBP' },
                internationalMode && { label: 'EUR', value: 'EUR' },
                internationalMode && { label: 'AUD', value: 'AUD' }
              ].filter(Boolean)}
            />
          </Grid>
        </Grid>
      </FormSection>
      {/* This should only ever be present from Admin as it isnt tied into saveOptions from App */}
      {isAdmin && (
        <FormSection>
          <FormSectionTitle
            title={`Advanced`}
            subTitle={`Advanced payment settings, careful changing these values!`}
            variant={'h3'}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Tooltip
                icon={<WarningIcon fontSize="small" style={{ color: '#F5A623' }} />}
                interactive
                title="Be careful!"
                content={
                  <ul>
                    <li>
                      This rate will be <strong>added</strong> to the client's base Stripe rate.
                    </li>
                    <li>
                      <strong>Don't input a percentage!</strong> a value of 0.005 means a rate of 0.5%.
                    </li>
                  </ul>
                }
              >
                <Field
                  label="Use a custom Stripe fee rate"
                  name="internalOptions.options.integrations.stripe.useCustomFeeRate"
                  component={FormikCheckbox}
                />
              </Tooltip>
              <Collapse show={values.internalOptions?.options?.integrations?.stripe?.useCustomFeeRate}>
                <Field
                  component={FormikInputField}
                  label="Stripe Fee Rate"
                  name="internalOptions.options.integrations.stripe.feeRate"
                  onChange={(e) => {
                    setFieldValue(
                      'internalOptions.options.integrations.stripe.feeRate',
                      Math.min(Math.max(parseNumber(e.target.value), 0), 0.01)
                    )
                  }}
                  type="number"
                  placeholder={0}
                  default={0}
                  inputProps={{ min: 0, max: 0.01, step: 0.001 }}
                  error={errors?.internalOptions?.options?.integrations?.stripe?.feeRate}
                />
              </Collapse>
            </Grid>
          </Grid>
        </FormSection>
      )}
    </>
  )
}

export default PaymentOptions
