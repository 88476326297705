import React, { useMemo, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  InputField,
  Checkbox,
  Typography,
  FormSection,
  FormSectionTitle,
  Grid,
  useClientOptions,
  EventSubscriptionDetails,
  DropdownSelect,
  FormControlLabel
} from '../../'
import { useProcessEvent, useAdminIntegrationsConfig } from '@ui/core'
import type { DropdownSelectOption } from '../../'
import type { ProviderConfig } from 'paintscout'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'

import { useFormikContext } from 'formik'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import RefreshIcon from '@material-ui/icons/Refresh'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, PipelineDealsProviderFormProps>(
  (theme) => ({
    root: {},
    stageSelection: {
      paddingLeft: theme.spacing(6),
      marginBottom: theme.spacing(1)
    },
    secondStageSelection: {
      marginBottom: theme.spacing(2)
    },
    visIcon: {
      cursor: 'pointer'
    },
    selected: {
      backgroundColor: 'lightGrey'
    },
    formSection: {
      padding: theme.spacing(5, 0)
    },
    noPadding: {
      paddingBottom: 0
    },
    formControlLabel: {
      marginLeft: theme.spacing(2)
    },
    fromText: {
      paddingLeft: theme.spacing(6),
      marginBottom: theme.spacing(0.5)
    },
    required: {
      color: theme.palette.error.main
    }
  }),
  { name: 'PipelineDealsProviderForm' }
)

export interface PipelineDealsProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isAdminIntegrations?: boolean
  companyId?: string
  userId?: string
}

export function PipelineDealsProviderForm(props: PipelineDealsProviderFormProps) {
  const { providerConfig, providerName, isAdminIntegrations = false, companyId = '', userId = '', onChange } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { enabled, config, apiKey: providerConfigApiKey } = providerConfig
  const apiKey = useRef<string>(providerConfigApiKey)
  const declinedToRequired = !config.declinedDealStage.value && config.declinedDealStageFrom.length
  const declinedFromRequired = config.declinedUpdateStage && !config.declinedDealStageFrom.length
  const acceptedToRequired = !config.acceptedDealStage.value && config.acceptedDealStageFrom.length
  const acceptedFromRequired = config.acceptedUpdateStage && !config.acceptedDealStageFrom.length
  const sentToRequired = config.sentUpdateStage && !config.sentDealStage.value && config.sentDealStageFrom.length
  const sentFromRequired = config.sentUpdateStage && !config.sentDealStageFrom.length

  const [dealStages, setDealStages] = useState([])
  const [loadingStages, setLoadingStages] = useState(false)
  const [visible, toggleVisible] = useState(false)
  const [invalidApiKey, setInvalidApiKey] = useState(false)
  const { dirty: isDirty } = useFormikContext()
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const { handleStageSelection, handleUpdateConfigCheckbox } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  const { adminIntegrationsConfig } = useAdminIntegrationsConfig()
  const { processEvent } = useProcessEvent()

  async function processEffect(key?: string) {
    if (enabled && (apiKey.current || key)) {
      setInvalidApiKey(false)
      setLoadingStages(true)
      const usedKey = key ?? apiKey.current

      if (isDirty && !timeoutId.current) {
        props.onChange(
          providerName,
          {
            ...providerConfig,
            enabled: true,
            apiKey: usedKey
          },
          true
        )
        // Start a new timeout to block this from spamming
        const newTimeoutId = setTimeout(() => {
          clearTimeout(timeoutId.current)
          timeoutId.current = null
        }, 10000)

        timeoutId.current = newTimeoutId
      }

      const res = isAdminIntegrations
        ? await adminIntegrationsConfig({
            provider: providerName,
            type: 'get-config-items',
            params: {
              apiKey: usedKey,
              companyId,
              userId
            }
          })
        : await processEvent({
            provider: providerName,
            type: 'get-config-items',
            params: {
              apiKey: usedKey
            }
          })

      if (res) {
        const { result: stages, error } = res
        if (stages?.stages) {
          setDealStages(stages.stages)
          setInvalidApiKey(false)
        }
        if (error?.includes('Unknown api_key')) {
          setInvalidApiKey(true)
        }
      }

      setLoadingStages(false)
    } else if (!apiKey.current && invalidApiKey) {
      setInvalidApiKey(false)
    }
  }

  useEffect(() => {
    processEffect(apiKey.current)
  }, [enabled])

  const StageSelection = (props: { name: string }) => {
    const { name } = props
    const dropdownOptions = dealStages as DropdownSelectOption[]
    const value = dropdownOptions.find((option) => option.value === config[name]?.value)

    return (
      <div className={classes.stageSelection}>
        <DropdownSelect
          fullWidth
          name={name}
          disabled={loadingStages}
          variant="single"
          placeholder={{
            selectPlaceholder: 'Select Deal Stage...'
          }}
          value={value}
          options={dropdownOptions}
          onChange={(option) => handleStageSelection(name, option)}
        />
      </div>
    )
  }
  const StageSelectionFrom = (props: { name: string }) => {
    const { name } = props
    const dropdownOptions = dealStages as DropdownSelectOption[]
    const value = (
      config[name] ? config[name].map((item) => dropdownOptions.find((option) => option.value === item.value)) : []
    ).filter(Boolean)

    return (
      <div className={classnames(classes.stageSelection, classes.secondStageSelection)}>
        <DropdownSelect
          fullWidth
          name={name}
          variant="multi"
          disabled={loadingStages}
          value={value}
          options={dropdownOptions}
          placeholder={{ selectPlaceholder: 'Select Deal Stages...' }}
          onChange={(options) => handleStageSelection(name, options)}
        />
      </div>
    )
  }

  const handleKeyChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    const trimmedValue = value.trim()
    props.onChange(
      providerName,
      {
        ...providerConfig,
        enabled: true,
        apiKey: trimmedValue
      },
      false
    )
    apiKey.current = trimmedValue

    // Clear the previous timeout, if any
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    }
  }

  return (
    <div>
      <FormSection hideDivider className={classes.formSection}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormSectionTitle title={'Access'} variant={'h4'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              fullWidth
              label={'Api Key'}
              type={visible ? 'text' : 'password'}
              value={apiKey.current}
              onChange={handleKeyChange}
              showErrorBeforeValidation
              required
              error={!apiKey.current || invalidApiKey}
              sublabel={`Found in your Pipeline CRM Account ${invalidApiKey ? '- (Invalid Api Key)' : ''}`}
              // error={invalidApiKey}
              startAdornment={
                invalidApiKey || isDirty ? (
                  <div onClick={() => processEffect(apiKey.current)}>
                    <RefreshIcon className={classes.visIcon} />
                  </div>
                ) : null
              }
              endAdornment={
                <div onClick={() => toggleVisible(!visible)}>
                  {visible ? (
                    <VisibilityOffIcon className={classes.visIcon} />
                  ) : (
                    <VisibilityIcon className={classes.visIcon} />
                  )}
                </div>
              }
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection className={classnames(classes.formSection, classes.noPadding)}>
        <FormSectionTitle title={'Update Deals'} variant={'h4'} />
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            When updating Deals (as specified below), we'll search for Deals associated with the contact you chose.
          </Typography>
          <Typography variant="subtitle2">
            We'll use the most recent deal for that contact which is considered "open".
          </Typography>
          <Typography variant="subtitle2">
            You can specify which deal stages are considered open to move from for each action below.
          </Typography>
        </Grid>
      </FormSection>
      <FormSection className={classes.formSection} hideDivider>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <Typography variant={'body1'}>
              When {objectLabels.quote.plural} are <strong>sent:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <EventSubscriptionDetails
              rows={[
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key={'sentUpdateValue'}
                  control={
                    <Checkbox
                      checked={config.sentUpdateValue}
                      name={'sentUpdateValue'}
                      onChange={handleUpdateConfigCheckbox('sentUpdateValue')}
                    />
                  }
                  label={`Update deal value to ${objectLabels.quote.value} total`}
                />,
                <Grid key={'sentUpdateStage'} container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      className={classnames({
                        [classes.required]: sentFromRequired
                      })}
                      control={
                        <Checkbox
                          checked={config.sentUpdateStage}
                          name={'sentUpdateStage'}
                          onChange={handleUpdateConfigCheckbox('sentUpdateStage')}
                        />
                      }
                      label={`Update deal stage From: ${sentFromRequired ? '*' : ''}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StageSelectionFrom name={'sentDealStageFrom'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classnames({
                        [classes.required]: sentToRequired,
                        [classes.fromText]: true
                      })}
                    >{`To: ${sentToRequired ? '*' : ''}`}</Typography>
                    <StageSelection name={'sentDealStage'} />
                  </Grid>
                </Grid>,
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key="sentCreateNote"
                  control={
                    <Checkbox
                      checked={config.sentCreateNote}
                      name={'sentCreateNote'}
                      onChange={handleUpdateConfigCheckbox('sentCreateNote')}
                    />
                  }
                  label={'Create a deal note'}
                />,
                // <FormControlLabel
                //   classes={{ label: classes.formControlLabel }}
                //   key="sentCreateContact"
                //   control={
                //     <Checkbox
                //       checked={config.sentCreateContact}
                //       name={'sentCreateContact'}
                //       onChange={handleUpdateConfigCheckbox('sentCreateContact')}
                //     />
                //   }
                //   label={`Create a new contact ${objectLabels.quote.value} to deal`}
                // />,
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key="sentCreateDocument"
                  control={
                    <Checkbox
                      checked={config.sentCreateDocument}
                      name={'sentCreateDocument'}
                      onChange={handleUpdateConfigCheckbox('sentCreateDocument')}
                    />
                  }
                  label={`Attach a PDF of the ${objectLabels.quote.value} to deal`}
                />
              ]}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection className={classes.formSection}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <Typography variant={'body1'}>
              When {objectLabels.quote.plural} are <strong>accepted:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <EventSubscriptionDetails
              rows={[
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key="acceptedUpdateValue"
                  control={
                    <Checkbox
                      checked={config.acceptedUpdateValue}
                      name={'acceptedUpdateValue'}
                      onChange={handleUpdateConfigCheckbox('acceptedUpdateValue')}
                    />
                  }
                  label={`Update deal value to ${objectLabels.quote.value} total`}
                />,
                <Grid key={'acceptedUpdateStage'} container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      className={classnames({
                        [classes.required]: acceptedFromRequired
                      })}
                      control={
                        <Checkbox
                          checked={config.acceptedUpdateStage}
                          name={'acceptedUpdateStage'}
                          onChange={handleUpdateConfigCheckbox('acceptedUpdateStage')}
                        />
                      }
                      label={`Update deal stage from: ${acceptedFromRequired ? '*' : ''}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StageSelectionFrom name={'acceptedDealStageFrom'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classnames({
                        [classes.required]: acceptedToRequired,
                        [classes.fromText]: true
                      })}
                    >{`To: ${acceptedToRequired ? '*' : ''}`}</Typography>
                    <StageSelection name={'acceptedDealStage'} />
                  </Grid>
                </Grid>,

                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key="acceptedCreateNote"
                  control={
                    <Checkbox
                      checked={config.acceptedCreateNote}
                      name={'acceptedCreateNote'}
                      onChange={handleUpdateConfigCheckbox('acceptedCreateNote')}
                    />
                  }
                  label={`Create a deal note`}
                />,
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key="acceptedCreateDocument"
                  control={
                    <Checkbox
                      checked={config.acceptedCreateDocument}
                      name={'acceptedCreateDocument'}
                      onChange={handleUpdateConfigCheckbox('acceptedCreateDocument')}
                    />
                  }
                  label={`Attach a PDF of the ${objectLabels.quote.value} to deal`}
                />
              ]}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection className={classes.formSection}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <Typography variant={'body1'}>
              When {objectLabels.quote.plural} are <strong>declined:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <EventSubscriptionDetails
              rows={[
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key="declinedUpdateValue"
                  control={
                    <Checkbox
                      checked={config.declinedUpdateValue}
                      name={'declinedUpdateValue'}
                      onChange={handleUpdateConfigCheckbox('declinedUpdateValue')}
                    />
                  }
                  label={`Update deal value to ${objectLabels.quote.value} total`}
                />,
                <Grid key={'declinedUpdateStage'} container spacing={0}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      className={classnames({
                        [classes.required]: declinedFromRequired
                      })}
                      control={
                        <Checkbox
                          checked={config.declinedUpdateStage}
                          name={'declinedUpdateStage'}
                          onChange={handleUpdateConfigCheckbox('declinedUpdateStage')}
                        />
                      }
                      label={`Update deal stage from: ${declinedFromRequired ? '*' : ''}`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StageSelectionFrom name={'declinedDealStageFrom'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classnames({
                        [classes.required]: declinedToRequired,
                        [classes.fromText]: true
                      })}
                    >{`To: ${declinedToRequired ? '*' : ''}`}</Typography>
                    <StageSelection name={'declinedDealStage'} />
                  </Grid>
                </Grid>,
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key="declinedCreateNote"
                  control={
                    <Checkbox
                      checked={config.declinedCreateNote}
                      name={'declinedCreateNote'}
                      onChange={handleUpdateConfigCheckbox('declinedCreateNote')}
                    />
                  }
                  label={'Create a deal note'}
                />,
                <FormControlLabel
                  classes={{ label: classes.formControlLabel }}
                  key={'declinedCreateDocument'}
                  control={
                    <Checkbox
                      checked={config.declinedCreateDocument}
                      name={'declinedCreateDocument'}
                      onChange={handleUpdateConfigCheckbox('declinedCreateDocument')}
                    />
                  }
                  label={`Attach a PDF of the ${objectLabels.quote.value} to deal`}
                />
              ]}
            />
          </Grid>
        </Grid>
      </FormSection>
    </div>
  )
}
