import { Portal } from '@material-ui/core'
import React, { useLayoutEffect, useState } from 'react'

export interface NavHeaderPortalProps {
  children: JSX.Element
}

export default function NavHeaderPortal({ children }: NavHeaderPortalProps) {
  const [container, setContainer] = useState(document.getElementById('nav-header-portal'))

  // if container was null the first time it rendered, we'll update it after page has rendered
  // (this can happen when this component renders when the nav isn't mounted)
  useLayoutEffect(() => {
    if (!container) {
      setContainer(document.getElementById('nav-header-portal'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Portal container={container}>{children}</Portal>
}
