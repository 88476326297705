import type { ProviderConfig, PipelineOption } from 'paintscout'

export function translatePldConfig(providerConfig: ProviderConfig): PipelineOption {
  const translation = {
    value: providerConfig.apiKey,
    peopleSearch: providerConfig?.config?.peopleSearch ?? false,
    enabled: providerConfig.enabled,
    features: {
      onSend: {
        createNote: {
          enabled: providerConfig?.config?.sentCreateNote ?? false
        },
        createDocument: {
          enabled: providerConfig?.config?.sentCreateDocument ?? false
        },
        updateDeals: {
          value: providerConfig?.config?.sentUpdateValue ?? false,
          enabled: providerConfig?.config?.sentUpdateStage ?? false,
          stage: providerConfig?.config?.sentDealStage?.value ?? null
        },
        stages: (providerConfig?.config?.sentDealStageFrom ?? []).map((stage) => {
          return {
            enabled: true,
            name: stage.label,
            id: stage.value
          }
        })
      },
      onAccept: {
        createNote: {
          enabled: providerConfig?.config?.acceptedCreateNote ?? false
        },
        createDocument: {
          enabled: providerConfig?.config?.acceptedCreateDocument ?? false
        },
        updateDeals: {
          value: providerConfig?.config?.acceptedUpdateValue ?? false,
          enabled: providerConfig?.config?.acceptedUpdateStage ?? false,
          stage: providerConfig?.config?.acceptedDealStage?.value ?? null
        },
        stages: (providerConfig?.config?.acceptedDealStageFrom ?? []).map((stage) => {
          return {
            enabled: true,
            name: stage.label,
            id: stage.value
          }
        })
      },
      onDecline: {
        createNote: {
          enabled: providerConfig?.config?.declinedCreateNote ?? false
        },
        createDocument: {
          enabled: providerConfig?.config?.declinedCreateDocument ?? false
        },
        updateDeals: {
          value: providerConfig?.config?.declinedUpdateValue ?? false,
          enabled: providerConfig?.config?.declinedUpdateStage ?? false,
          stage: providerConfig?.config?.declinedDealStage?.value ?? null
        },
        stages: (providerConfig?.config?.declinedDealStageFrom ?? []).map((stage) => {
          return {
            enabled: true,
            name: stage.label,
            id: stage.value
          }
        })
      }
    }
  } as PipelineOption

  return translation
}
