import type { ValueFilter } from 'json-to-lucene'
import React from 'react'
import { ROLE_OPTIONS } from './UserSearchFilters'
import isEqual from 'lodash/isEqual'
import { FilterChip } from '@paintscout/ui'
import type { FilterChipProps } from '@paintscout/ui'

export interface ChipProps extends Omit<FilterChipProps, 'title' | 'label' | 'onDelete'> {
  filter: ValueFilter

  /**
   * Called when a user deletes the chip. The "valueIndex" parameter will be provided
   * if the filter is an InFilter
   */
  onDelete: (filter: ValueFilter, valueIndex?: number) => any
}

export default function UserFilterChip({ filter, onDelete, ...props }: ChipProps) {
  if (filter.value === null || (Array.isArray(filter.value) && !filter.value[0] && !filter.value[1])) {
    return null
  }

  function getTitle() {
    switch (filter.id) {
      case 'app_metadata.roles':
        return 'Role'
      case 'app_metadata.active':
        return 'Active'
      default:
        return ''
    }
  }

  function getLabel() {
    switch (filter.id) {
      case 'app_metadata.active':
        if (isEqual(['true', 'false'], filter.value)) {
          return 'Include Active'
        } else if (isEqual(['true'], filter.value)) {
          return 'Only Active'
        }

        return 'No Active'
      default:
        return ''
    }
  }

  switch (filter.id) {
    case 'app_metadata.roles': {
      const values = filter.value as string[]

      if (!values) {
        return null
      }
      return (
        <>
          {values.map((value, index) => (
            <FilterChip
              {...props}
              key={index}
              title="Role"
              label={ROLE_OPTIONS.find((role) => role.value === value)?.label}
              onDelete={() => onDelete(filter, index)}
            />
          ))}
        </>
      )
    }

    default: {
      const title = getTitle()
      const label = getLabel()

      if (!title || !label) {
        return null
      }

      return <FilterChip {...props} title={title} label={label} onDelete={() => onDelete(filter)} />
    }
  }
}
