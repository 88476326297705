import React, { useState } from 'react'
import { useUser, useDialogs, NewFormSection as FormSection, ConfirmationDialog } from '@ui/paintscout'
import ExportJsonDialog from '@ui/paintscout/src/ExportJsonDialog'
import ImportJsonDialog from '@ui/paintscout/src/ImportJsonDialog'
import {
  getPresentationOptions,
  getObjectLabels,
  copyPresentationOption,
  deletePresentationOption,
  updatePresentationOption,
  deletePresentationOptions
} from '@paintscout/util/builder'
import type { OptionsDocument, PresentationOption } from 'paintscout'
import OptionsTileList from '../../../OptionsTileList'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { Form, useFormikContext } from 'formik'
import type { ValueType } from '@ui/paintscout/src/TileList/TileList'

export interface PresentationIndexProps {
  options: OptionsDocument
  isAdminPresentation?: boolean
  onSelectItem?: (presentation: PresentationOption) => void
  onCopyItem?: (presentation: PresentationOption) => void
  onDeleteItem?: (presentation: PresentationOption) => void
  onDeleteBulkItems?: (presentations: PresentationOption[]) => void
  onNewItem?: () => void
  onImportItem?: (presentation: PresentationOption) => void
  onReorderItems?: (presentationOptions: PresentationOption[], quoteType?: string) => void
}

const exportAction = {
  key: 'export',
  label: 'Export',
  icon: ImportExportIcon
}

function PresentationIndex(props: PresentationIndexProps) {
  const {
    options,
    onSelectItem,
    onCopyItem,
    onNewItem,
    onDeleteItem,
    onDeleteBulkItems,
    onReorderItems,
    onImportItem,
    isAdminPresentation
  } = props
  const [quoteType, setQuoteType] = useState<string>('all')
  const { openDialog, dismissDialog } = useDialogs()
  const user = useUser()
  const { isSuperadmin } = user
  const { setFieldValue } = useFormikContext<{ options: OptionsDocument }>()
  const [selected, setSelected] = useState<ValueType[]>([])

  const canAddPresentations = isSuperadmin || options?.options?.features?.presentation?.enabled || isAdminPresentation
  const canImport = isSuperadmin || isAdminPresentation
  const objectLabels = getObjectLabels({ options })
  const items = getPresentationOptions({ options, quoteType, inactive: true })

  return (
    <Form>
      <FormSection
        hidePadding
        selectHeader
        selectValue={quoteType}
        changeSelect={setQuoteType}
        buttonAction={canImport && handleImportJson}
        buttonText="Import"
      >
        <OptionsTileList
          addButtonColor="primary"
          title={''}
          items={items}
          createTitle={canAddPresentations ? 'Add New Presentation' : null}
          selected={selected}
          setSelected={setSelected}
          onDeleteBulkItems={handleDeleteBulkItems}
          showCreate={canAddPresentations}
          onReorder={handleReorder}
          onEditItem={handleEditItem}
          onCopyItem={handleCopyItem}
          onDeleteItem={handleDeleteItem}
          onCreateClick={handleCreateItem}
          onItemAction={handleItemAction}
          actions={isSuperadmin || isAdminPresentation ? [exportAction] : null}
        />
      </FormSection>
    </Form>
  )

  function handleReorder(presentationOptions: PresentationOption[]) {
    if (onReorderItems) {
      onReorderItems(presentationOptions, quoteType)
    }
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getPresentationOptions({ options, inactive: true })
    const item = items.find((i) => i.key === key)
    if (item && onSelectItem) {
      onSelectItem(item)
    }
  }

  function handleCreateItem(_event: React.MouseEvent) {
    onNewItem()
  }

  function handleCopyItem(event: React.MouseEvent, key: string) {
    const items = getPresentationOptions({ options, inactive: true })
    const item = items.find((i) => i.key === key)
    if (item && onCopyItem) {
      onCopyItem(item)
    }
    if (isAdminPresentation) {
      setFieldValue(
        'options',
        copyPresentationOption({
          options,
          presentation: item
        })
      )
    }
  }

  function handleDeleteItem(event: any, key: string) {
    if (onDeleteItem || isAdminPresentation) {
      openDialog(ConfirmationDialog, {
        message: (
          <>
            <p>This item will no longer be available to add to new {objectLabels.quote.plural}.</p>
            <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          </>
        ),
        onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
          dismissDialog()

          const items = getPresentationOptions({ options, inactive: true })
          const item = items.find((i) => i.key === key)
          if (item && onDeleteItem) {
            onDeleteItem(item)
          } else if (isAdminPresentation) {
            setFieldValue(
              'options',
              deletePresentationOption({
                presentationOption: item,
                options
              })
            )
          }
        },
        onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
          dismissDialog()
        }
      })
    }
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These presentations will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getPresentationOptions({ options, inactive: true })
        const selectedOptions = items.filter((i) => keys.includes(i.key))

        if (keys && onDeleteBulkItems) {
          onDeleteBulkItems(selectedOptions)
        } else if (isAdminPresentation) {
          setFieldValue(
            'options',
            deletePresentationOptions({
              presentationOptions: selectedOptions,
              options
            })
          )
        }

        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleImportJson() {
    openDialog(ImportJsonDialog, {
      onImport: (json: JSON) => {
        dismissDialog()
        if (onImportItem) {
          onImportItem(json as unknown as PresentationOption)
        } else if (isAdminPresentation) {
          setFieldValue(
            'options',
            updatePresentationOption({
              options,
              presentationOption: json as unknown as PresentationOption
            })
          )
        }
      },
      onClose: dismissDialog
    })
  }

  function handleItemAction(event: React.MouseEvent, key: string, action: string) {
    if (action === 'export') {
      handleExportJson(key)
    }
  }

  function handleExportJson(key: string) {
    const item = items.filter((item) => item.key === key)[0]
    openDialog(ExportJsonDialog, {
      item,
      onConfirm: dismissDialog
    })
  }
}

export default PresentationIndex
