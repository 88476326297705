import type { Theme } from '@material-ui/core/styles'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { getPresentationContentStyles } from '../styles'
import Alert from '../Alert'

import React, { useState, useLayoutEffect } from 'react'

import Typography from '../Typography'

import AlertIcon from '../Alert/AlertIcon'
import classnames from 'classnames'
import { LearnMoreLink } from '../PageHelpMessage'
import FormSectionTitle from '../FormSectionTitle'
import IconButton from '@ui/core/IconButton'
import Grid from '../Grid'

export interface TitleCalloutProps {
  openContent?: string | React.ReactNode
  title?: string
  subTitle?: string
  path: string
  hideClose?: boolean
  learnMoreLink?: string
  severity?: 'success' | 'info' | 'warning' | 'error'
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const useStyles = makeStyles<Theme, TitleCalloutProps>((theme) => ({
  alert: {
    ...getPresentationContentStyles(theme),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 7
  },
  learnMoreWrapper: {
    marginLeft: theme.spacing(5),
    marginTop: '1.0em'
  },
  icon: (props: TitleCalloutProps) => {
    return {
      alignSelf: 'flex-start',
      color: props?.severity ? theme.palette[props.severity].main : theme.palette.info.main
    }
  },
  closedIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    marginBottom: 2
  },
  contentWrapper: {
    padding: theme.spacing(0, 3, 0.5, 1)
  },
  title: ({ variant }: TitleCalloutProps) => ({
    paddingLeft: !variant || variant === 'h2' ? theme.spacing(3) : 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: !variant || variant === 'h2' ? theme.spacing(8) : 0
    }
  })
}))

const TitleCallout = (props: TitleCalloutProps) => {
  const { path, hideClose, learnMoreLink, title, subTitle, openContent, severity = 'info', variant = 'h2' } = props
  const classes = useStyles(props)
  const theme = useTheme()
  const localStoragePath = `hidePageMessage-${path}`

  useLayoutEffect(() => {
    setOpen(!JSON.parse(window.localStorage.getItem(localStoragePath)))
  }, [localStoragePath])

  const [open, setOpen] = useState(false)

  function handleOpen() {
    setOpen(true)
    window.localStorage.setItem(localStoragePath, JSON.stringify(false))
  }

  function handleClose() {
    setOpen(false)
    window.localStorage.setItem(localStoragePath, JSON.stringify(true))
  }

  return (
    <>
      <FormSectionTitle
        classes={{ root: classes.title }}
        title={
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant={variant}>{title}</Typography>
            </Grid>
            <Grid item>
              {!open && (
                <IconButton size="small" className={classnames(classes.icon, classes.closedIcon)} onClick={handleOpen}>
                  <AlertIcon severity={severity} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
        subTitle={subTitle}
      />
      {open && (
        <Alert icon={null} severity={severity} onClose={hideClose ? null : handleClose}>
          <div className={classes.alert}>
            <div className={classes.icon}>
              <AlertIcon severity={severity} />
            </div>
            <Typography className={classes.contentWrapper}>{openContent}</Typography>
          </div>
          {learnMoreLink && (
            <div className={classes.learnMoreWrapper}>
              <LearnMoreLink link={learnMoreLink} linkColor={theme.palette.text.secondary} />
            </div>
          )}
        </Alert>
      )}
    </>
  )
}

export default TitleCallout
