/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { TermsOption } from '../../index'

export function reorderTermsOptions(args: { options: OptionsDocument; termsOptions: TermsOption[] }): OptionsDocument {
  const { options, termsOptions } = args

  return {
    ...options,
    options: {
      ...options.options,
      terms: {
        ...options.options.terms,
        order: termsOptions.map((item: TermsOption) => {
          return item.name
        })
      }
    }
  }
}
