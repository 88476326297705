import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { LeadSourceResult, LeadSourceType } from '@paintscout/util/builder'
import type { OptionsDocument } from 'paintscout'
import { Button, Dialog, DialogActions, DialogContent, EditableDialogTitle, Grid, InputField } from '@ui/paintscout'
import type { DialogProps } from '@ui/paintscout'
import Done from '@material-ui/icons/Done'
import { updateLeadSource } from '@paintscout/util/builder'

const useStyles = makeStyles<Theme, EditLeadSourceDialogProps>(
  () => ({
    root: {}
  }),
  { name: 'EditLeadSourceDialog' }
)

export interface EditLeadSourceDialogProps extends DialogProps {
  classes?: StyleClasses<typeof useStyles>
  type: LeadSourceType
  item: LeadSourceResult<LeadSourceType>
  options: OptionsDocument
  isNew?: boolean
  onConfirm: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel: () => void
}

function EditLeadSourceDialog(props: EditLeadSourceDialogProps) {
  const classes = useStyles(props)
  const { item, type, options, loading, isNew, onConfirm, ...otherProps } = props
  const [source, setSource] = useState<LeadSourceResult<LeadSourceType>>(item)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const label = type === 'quoteSources' ? 'Quote' : 'Contact'

  const handleConfirm = (_: any) => {
    const updatedOptions = updateLeadSource({
      options,
      type,
      source: {
        ...source,
        label: source.label.trim()
      }
    })
    onConfirm(updatedOptions, isDirty)
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setIsDirty(true)
    setSource((source) => {
      return {
        ...source,
        [name]: value
      }
    })
  }

  const leftButton = (
    <Button disabled={loading} onClick={props.onCancel} variant={'text'}>
      Cancel
    </Button>
  )
  return (
    <Dialog fullWidth={true} maxWidth={'md'} {...otherProps}>
      <EditableDialogTitle title={`${isNew ? 'Add' : 'Edit'} ${label} Lead Source`} loading={loading} />
      <DialogContent style={{ padding: '24px 16px 32px 16px' }}>
        <form
          id="area-option"
          onSubmit={(ev) => {
            ev.preventDefault()
            handleConfirm(ev)
          }}
        >
          <Grid item xs={12}>
            <InputField
              classes={{ root: classes.input }}
              label={'Label or Name'}
              name={'label'}
              value={source.label}
              fullWidth={true}
              onChange={handleChange}
              required
              error={!source.label}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions showBorder={true} leftButton={leftButton}>
        <Button type="submit" form="area-option" disabled={loading} variant={'contained'} icon={Done}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditLeadSourceDialog
