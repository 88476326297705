import type { Theme } from '@material-ui/core'
import { TableRow, makeStyles } from '@material-ui/core'
import { CloseButton } from '@ui/react-quote/src/common'
import {
  AlertDialog,
  Button,
  FormSection,
  FormSectionTitle,
  ResultsTable,
  TableCell,
  Typography,
  useDialogs
} from '@ui/paintscout'
import AddIcon from '@material-ui/icons/Add'
import React, { useCallback, useEffect, useState } from 'react'
import { GithubIssueDialog, LinkGithubIssueDialog, TileListDialog } from '@ui/react-quote/src/dialogs'
import { useFormikContext } from 'formik'
import type { OptionsDocument } from 'paintscout'
import { Octokit } from '@octokit/core'
import DotIcon from '@material-ui/icons/Adjust'

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    cursor: 'pointer',
    border: '1px solid #e0e0e0',
    borderTop: 'none'
  },
  caption: {
    color: theme.palette.text.secondary
  },
  addButton: {
    padding: 0
  }
}))

export default function ClientGithubIssues() {
  const classes = useStyles()
  const [issues, setIssues] = useState([])
  const [loading, setLoading] = useState(true)

  const { openDialog, dismissDialog, dismissAllDialogs } = useDialogs()
  const { values, setFieldValue } = useFormikContext<{ options: OptionsDocument }>()
  const options = values.options
  const linkedIssues = (options.options.linkedIssues || []).reverse()

  const octokit = new Octokit({
    auth: process.env.GH_PAT
  })

  useEffect(() => {
    async function getIssues() {
      setLoading(true)
      linkedIssues.forEach(async (issueNumber) => {
        if (!issueNumber) {
          return
        }
        await octokit
          .request('GET /repos/{owner}/{repo}/issues/{issue_number}', {
            owner: 'PaintScout',
            repo: 'PaintScout',
            issue_number: issueNumber,
            headers: {
              'X-GitHub-Api-Version': '2022-11-28'
            }
          })
          .then((response: any) => {
            setIssues((issues) => [...issues, response.data])
          })
      })
      setLoading(false)
    }
    getIssues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRedirect = (issue) => {
    window.open(issue.url, '_blank')
  }

  const handleNew = () => {
    openDialog(TileListDialog, {
      title: 'Link Issue',
      items: [
        {
          key: 'create',
          label: 'Create New Issue'
        },
        {
          key: 'link',
          label: 'Link Existing Issue'
        }
      ],
      onItemSelect: (key) => {
        if (key === 'create') {
          openDialog(GithubIssueDialog, {
            octokit,
            debugInfo: JSON.stringify({
              company: (values as any).meta.name,
              companyId: (values as any).meta._id
            }),
            onConfirm: async (issue) => {
              setIssues((issues) => [...issues, issue])
              setFieldValue('options.options.linkedIssues', [
                ...(options.options.linkedIssues || []).filter((linkedIssue) => linkedIssue !== issue.number),
                issue.number
              ])
              dismissAllDialogs()
            }
          })
        } else if (key === 'link') {
          openDialog(LinkGithubIssueDialog, {
            octokit,
            debugInfo: JSON.stringify({
              company: (values as any).meta.name,
              companyId: (values as any).meta._id
            }),
            onConfirm: async (issue) => {
              setIssues((issues) => [...issues, issue])
              setFieldValue('options.options.linkedIssues', [
                ...(options.options.linkedIssues || []).filter((linkedIssue) => linkedIssue !== issue.number),
                issue.number
              ])
              dismissAllDialogs()
            }
          })
        }
      }
    })
  }

  const handleUnlink = (issue) => {
    openDialog(AlertDialog, {
      title: 'Unlink Issue',
      message: 'Are you sure you want to unlink this issue?',
      actions: [
        {
          label: 'Cancel',
          onClick: dismissDialog,
          variant: 'text',
          left: true
        },
        {
          label: 'Unlink',
          onClick: async () => {
            setIssues((issues) => issues.filter((i) => i.number !== issue.number))
            setFieldValue(
              'options.options.linkedIssues',
              (options.options.linkedIssues || []).filter((linkedIssue) => linkedIssue !== issue.number)
            )
            dismissAllDialogs()
          }
        }
      ]
    })
  }

  const renderRow = useCallback((row) => {
    return (
      <TableRow onClick={() => handleRedirect(row)} className={classes.row}>
        <TableCell>{row.number}</TableCell>
        <TableCell style={{ width: 40 }}>
          <DotIcon
            style={{
              color: row.state === 'open' ? 'green' : 'purple'
            }}
          />
        </TableCell>
        <TableCell>
          <Typography>{row.title}</Typography>
        </TableCell>
        <TableCell align="right">
          <CloseButton
            onCancel={(ev) => {
              ev.stopPropagation()
              handleUnlink(row)
            }}
          />
        </TableCell>
      </TableRow>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const parseBody = (body: string) => {
    if (!body) return ''
    const parsed = body.split('```')[0]
    return parsed.length > 400 ? parsed.substring(0, 400) + '...' : parsed
  }

  const mapIssueToRow = (issue) => {
    return {
      number: issue.number,
      title: issue.title,
      state: issue.state,
      body: parseBody(issue.body),
      url: issue.html_url
    }
  }

  return (
    <>
      <FormSectionTitle
        title="GitHub Issues"
        rightContent={
          <Button className={classes.addButton} onClick={handleNew} variant="text" icon={AddIcon}>
            New
          </Button>
        }
      />
      <FormSection>
        <ResultsTable
          columns={
            <TableRow>
              <TableCell>Issues</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          }
          renderRow={renderRow}
          rows={issues.map(mapIssueToRow)}
          rowsPerPage={10}
          loading={loading}
          noResultsMessage="No linked issues"
        />
      </FormSection>
    </>
  )
}
