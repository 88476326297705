import moment from 'moment'
import type { Moment } from 'moment'

/**
 * Returns the date range for the given preset option.
 * @param option The dropdown option.
 * @returns The date range.
 */
export default function getRangePresetValue(key: string): { from: Moment; to: Moment } {
  switch (key) {
    case 'thisWeek':
      return { from: moment().startOf('week'), to: moment().endOf('week') }
    case 'lastWeek':
      return {
        from: moment().startOf('week').subtract(1, 'week'),
        to: moment().startOf('week').subtract(1, 'week').endOf('week')
      }
    case 'lastMonth':
      return {
        from: moment().startOf('month').subtract(1, 'month'),
        to: moment().startOf('month').subtract(1, 'month').endOf('month')
      }
    case 'thisMonth':
      return { from: moment().startOf('month'), to: moment().endOf('month') }
    case 'lastThirtyDays':
      return { from: moment().endOf('day').subtract(30, 'day'), to: moment().endOf('day') }
    case 'lastFortyFiveDays':
      return { from: moment().endOf('day').subtract(45, 'day'), to: moment().endOf('day') }
    case 'yearToDate':
      return { from: moment().endOf('day').startOf('year'), to: moment().endOf('year') }
    case 'lastYear':
      return {
        from: moment().endOf('day').startOf('year').subtract(1, 'year'),
        to: moment().endOf('year').subtract(1, 'year')
      }
    default: // All Time
      return { from: moment(0), to: moment().endOf('day') }
  }
}
