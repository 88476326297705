import {
  Button,
  Checkbox,
  Collapse,
  // Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  type DialogProps
} from '@ui/paintscout'
import { Typography, createStyles, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import type { PartnerContentFeatures, PartnerPresentationOptions, PresentationOption } from 'paintscout'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'

export interface PresentationPartnerDialogProps
  extends Omit<DialogProps, 'message' | 'title' | 'loading' | 'onChange'> {
  onConfirm?: (updatedPresentation: PresentationOption) => void
  onCancel?: () => void
  presentation: PresentationOption
  partnerContentFeatures: PartnerContentFeatures
}

export const styles = () =>
  createStyles({
    root: {},
    pageLabel: {
      paddingTop: '12px'
    },
    pagesContainer: {
      padding: '20px'
    },
    sectionsContainer: {
      padding: '0 10px'
    }
  })

type Partners = 'sherwinWilliams'

function PresentationPartnerDialog({
  open,
  onConfirm,
  onCancel,
  partnerContentFeatures,
  presentation,
  ...props
}: PresentationPartnerDialogProps) {
  const { classes } = props

  const [partnerContent, setPartnerContent] = useState<PartnerPresentationOptions>(
    presentation?.advanced?.partnerContent ?? {
      sherwinWilliams: {}
    }
  )

  const handlePartnerEnableOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    partner: Partners
  ) => {
    const newPartnerContentEnabled = cloneDeep(partnerContent)
    set(newPartnerContentEnabled, [partner, ...event.target.name.split('.')], checked)
    setPartnerContent(newPartnerContentEnabled)
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm({
        ...presentation,
        advanced: {
          ...presentation.advanced,
          partnerContent: partnerContent
        }
      })
    }
  }

  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Partner Presentation Pages</DialogTitle>
      <DialogContent>
        {partnerContentFeatures?.sherwinWilliams?.presentationPages?.enabled && (
          <>
            <FormControlLabel
              label={'Sherwin Williams'}
              name={'enabled'}
              control={
                <Switch
                  onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                  checked={partnerContent?.sherwinWilliams?.enabled}
                />
              }
            />
            <Collapse show={partnerContent?.sherwinWilliams?.enabled}>
              <Grid container direction="column" className={classes.pagesContainer}>
                <Grid item>
                  <Typography variant="h4">Pages</Typography>
                </Grid>

                <FormControlLabel
                  label={'Most Popular Colors'}
                  name={'pages.popularColors.enabled'}
                  className={classes.pageLabel}
                  control={
                    <Switch
                      onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                      checked={partnerContent?.sherwinWilliams?.pages?.popularColors?.enabled}
                    />
                  }
                />
                <Collapse show={partnerContent?.sherwinWilliams?.pages?.popularColors?.enabled}>
                  <Grid item container direction="column">
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Color of the Year'}
                        control={
                          <Checkbox
                            name={'pages.popularColors.colorOfTheYear'}
                            checked={partnerContent?.sherwinWilliams?.pages?.popularColors?.colorOfTheYear}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    {/* <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Colors of the Month'}
                        control={
                          <Checkbox
                            name={'pages.popularColors.colorsOfTheMonth'}
                            checked={
                              partnerContentEnabled?.sherwinWilliams?.pages?.popularColors
                                ?.colorsOfTheMonth
                            }
                            onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                          />
                        }
                      />
                    </Grid> */}
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Top Colors'}
                        control={
                          <Checkbox
                            name={'pages.popularColors.topColors'}
                            checked={partnerContent?.sherwinWilliams?.pages?.popularColors?.topColors}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Finest Whites & Neutrals'}
                        control={
                          <Checkbox
                            name={'pages.popularColors.finestWhitesNeutrals'}
                            checked={partnerContent?.sherwinWilliams?.pages?.popularColors?.finestWhitesNeutrals}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Expert Collections'}
                        control={
                          <Checkbox
                            name={'pages.popularColors.expertCollections'}
                            checked={partnerContent?.sherwinWilliams?.pages?.popularColors?.expertCollections}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Explore Exterior Paint'}
                        control={
                          <Checkbox
                            name={'pages.popularColors.exploreExteriorPaint'}
                            checked={partnerContent?.sherwinWilliams?.pages?.popularColors?.exploreExteriorPaint}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Collapse>

                <FormControlLabel
                  label={'Free Color Samples'}
                  name={'pages.colorSamples.enabled'}
                  className={classes.pageLabel}
                  control={
                    <Switch
                      onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                      checked={partnerContent?.sherwinWilliams?.pages?.colorSamples?.enabled}
                    />
                  }
                />
                <Collapse show={partnerContent?.sherwinWilliams?.pages?.colorSamples?.enabled}>
                  <Grid item container direction="column">
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Right to Your Door'}
                        control={
                          <Checkbox
                            name={'pages.colorSamples.rightToYourDoor'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorSamples?.rightToYourDoor}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Hottest Colors'}
                        control={
                          <Checkbox
                            name={'pages.colorSamples.topHottestColors'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorSamples?.topHottestColors}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Finest Whites'}
                        control={
                          <Checkbox
                            name={'pages.colorSamples.finestWhites'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorSamples?.finestWhites}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Peel and Stick'}
                        control={
                          <Checkbox
                            name={'pages.colorSamples.peelAndStick'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorSamples?.peelAndStick}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Collapse>

                <FormControlLabel
                  label={'Help With Color'}
                  name={'pages.colorHelp.enabled'}
                  className={classes.pageLabel}
                  control={
                    <Switch
                      onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                      checked={partnerContent?.sherwinWilliams?.pages?.colorHelp?.enabled}
                    />
                  }
                />
                <Collapse show={partnerContent?.sherwinWilliams?.pages?.colorHelp?.enabled}>
                  <Grid item container direction="column">
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Get Color Help'}
                        control={
                          <Checkbox
                            name={'pages.colorHelp.getColorHelp'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorHelp?.getColorHelp}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Upload Your Photos'}
                        control={
                          <Checkbox
                            name={'pages.colorHelp.uploadPhotos'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorHelp?.uploadPhotos}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Schedule Free Consultation'}
                        control={
                          <Checkbox
                            name={'pages.colorHelp.scheduleFreeConsultation'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorHelp?.scheduleFreeConsultation}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Match A Photo'}
                        control={
                          <Checkbox
                            name={'pages.colorHelp.matchAPhoto'}
                            checked={partnerContent?.sherwinWilliams?.pages?.colorHelp?.matchAPhoto}
                            onChange={(event, checked) =>
                              handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Collapse>
          </>
        )}
      </DialogContent>
      <DialogActions
        leftButton={
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        }
      >
        <Button onClick={handleConfirm}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(PresentationPartnerDialog)
