/**
 * @module builder
 */
import type { OptionsDocument } from 'paintscout'
import type { QuoteTypeOption } from '../../index'

export function reorderQuoteTypeOptions(args: {
  options: OptionsDocument
  quoteTypeOptions: QuoteTypeOption[]
}): OptionsDocument {
  const { options, quoteTypeOptions } = args

  return {
    ...options,
    options: {
      ...options.options,
      intExtOptions: {
        ...options.options.intExtOptions,
        order: quoteTypeOptions.map((item: QuoteTypeOption) => {
          return item.key
        })
      }
    }
  }
}
