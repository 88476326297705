import isString from 'lodash/isString'
import type { OptionsDocument } from 'paintscout'
import type { QuoteTag } from '../..'
import { uuid } from '../..'

export function createQuoteTag(args: { options: OptionsDocument }): QuoteTag {
  const { options } = args
  const color = options.options.primaryColor.value
  return {
    name: uuid(),
    label: '',
    color: isString(color) ? color : '#000000'
  }
}
