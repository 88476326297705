import type { Theme } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TileList, useClientOptions } from '@ui/paintscout'
import { createPresentationPage, getPresentationPageOptions } from '@paintscout/util/builder'
import type { PresentationPage } from 'paintscout'
import React from 'react'

export interface PresentationPageSelectDialogProps extends Omit<DialogProps, 'message' | 'title' | 'loading'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  onConfirm?: (page: PresentationPage) => void
  onCancel?: () => void
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {},
    tileList: {}
  })

function PresentationPageSelectDialog({
  open,
  classes,
  onConfirm,
  onCancel,
  ...props
}: PresentationPageSelectDialogProps) {
  const { options } = useClientOptions()
  const pageOptions = getPresentationPageOptions({ options })

  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Add Page</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <TileList
            items={pageOptions.map((opt, index) => ({ ...opt, key: index }))}
            onSelect={onTileClick}
            variant="select"
          />
        </div>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button variant={'text'} onClick={onCancel}>
            Cancel
          </Button>
        }
      ></DialogActions>
    </Dialog>
  )

  function onTileClick(_event: any, index: number) {
    const presentationPageOption = pageOptions[index]
    if (onConfirm) {
      onConfirm(createPresentationPage({ presentationPageOption }))
    }
  }
}

export default withStyles(styles)(PresentationPageSelectDialog)
