import React, { useState } from 'react'
import type { ButtonProps } from '../Button'
import type { Theme } from '@material-ui/core'
import { ButtonGroup, makeStyles, Menu } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/KeyboardArrowDown'
import type { ButtonGroupProps } from '@material-ui/core/ButtonGroup'
import classnames from 'classnames'

export interface SplitMenuButtonProps extends Omit<ButtonGroupProps, 'onClick'> {
  Button: React.ComponentType<any>
  Icon?: React.ComponentType<any>
  label: React.ReactNode
  leftDisabled?: boolean
  leftHidden?: boolean
  loading?: boolean
  onClick?: ButtonProps['onClick']
  onRightClick?: ButtonProps['onClick']
  rightDisabled?: boolean
  prominence?: 1 | 2
  anchorToGroup?: boolean
  leftWidth?: number
  menu?: string
  /**
   * Should be <MenuItem /> components
   */
  children?: React.ReactNode
}

const useStyles = makeStyles<Theme, SplitMenuButtonProps>(
  (_theme: Theme) => ({
    root: {},
    icon: {
      height: 20,
      width: 'auto'
    },
    groupedContainedPrimary: {},
    disabled: {},
    rightButton: {},
    leftButton: (props) => ({
      width: '100%',
      ...(props.leftWidth && {
        width: `${props.leftWidth}px`
      })
    }),
    menu: {}
  }),
  {
    name: 'SplitMenuButton'
  }
)

const SplitMenuButton = React.forwardRef<HTMLDivElement, SplitMenuButtonProps>(function SplitMenuButton(
  props: SplitMenuButtonProps,
  ref: any
) {
  const {
    onClick,
    onRightClick,
    label,
    Button,
    Icon,
    anchorToGroup: _anchorToGroup,
    menu: _menu,
    leftDisabled,
    leftHidden,
    rightDisabled,
    disabled,
    children,
    loading,
    ...other
  } = props
  const size = props?.size ?? 'medium'
  const classes = useStyles(props)
  const [anchorEl, setAnchorEl] = useState(null)
  // const Icon = props.Icon

  return (
    <>
      <ButtonGroup
        classes={{
          root: classnames(classes.root),
          groupedContainedPrimary: classes.groupedContainedPrimary
        }}
        ref={ref}
        {...other}
      >
        {!leftHidden && (
          <Button
            prominence={props.prominence}
            size={size}
            className={classnames(classes.leftButton, {
              [classes.disabled]: !!leftDisabled || disabled,
              [classes.root]: true
            })}
            disabled={leftDisabled || disabled}
            loading={loading}
            onClick={onClick}
          >
            {label}
          </Button>
        )}
        {(children || onRightClick) && (
          <Button
            prominence={props.prominence}
            data-testid={props['data-testid'] ? `${props['data-testid']}-dropdown` : undefined}
            size={size}
            className={classnames(classes.rightButton, {
              [classes.disabled]: !!rightDisabled || disabled,
              [classes.root]: true
            })}
            disabled={rightDisabled || disabled}
            onClick={
              onRightClick ??
              ((ev) => {
                const target = props.anchorToGroup ? ev.currentTarget.parentElement : ev.currentTarget
                setAnchorEl(target)
              })
            }
          >
            {props.Icon ? (
              <Icon style={{ display: 'flex' }} className={classes.icon} />
            ) : (
              <ArrowDropDownIcon className={classes.icon} />
            )}
          </Button>
        )}
      </ButtonGroup>
      {children && (
        <Menu
          open={!!anchorEl}
          classes={{ paper: classes.menu }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: props.anchorToGroup ? 'right' : 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: props.anchorToGroup ? 'right' : 'left' }}
          getContentAnchorEl={undefined}
          onClose={() => setAnchorEl(null)}
        >
          {React.Children.map(
            children,
            (child: any) =>
              child &&
              React.cloneElement(child, {
                onClick: (ev: any) => {
                  setAnchorEl(null)
                  if (child.props.onClick) {
                    child.props.onClick(ev)
                  }
                }
              })
          )}
        </Menu>
      )}
    </>
  )
})

SplitMenuButton.defaultProps = {
  variant: 'contained',
  color: 'primary'
}

export default SplitMenuButton
