import type { WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'

import type { DialogProps } from '../Dialog'
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../Dialog'
import Button from '../Button'
import Grid from '../Grid'
import InputField from '../InputField'

import { useSnackbar } from 'notistack'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

export interface ExportJsonDialogProps extends Omit<DialogProps, 'classes'>, WithStyles<typeof styles> {
  item: any
  onConfirm?: () => void
}

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  input: { width: '100%', height: '100%' },
  content: { maxHeight: '100%' }
})

function ExportJsonDialog({ classes, item, onConfirm, ...otherProps }: ExportJsonDialogProps) {
  const { enqueueSnackbar } = useSnackbar()

  const sanitizedItem = { ...item }
  delete sanitizedItem.__typename

  return (
    <Dialog {...otherProps} fullScreen={true}>
      <DialogTitle>{'Export JSON'}</DialogTitle>
      <DialogContent className={classes.content}>
        <CopyToClipboard
          text={JSON.stringify(sanitizedItem, null, 2)}
          onCopy={() => enqueueSnackbar(`Copied to clipboard!`, { variant: 'success' })}
        >
          <InputField
            className={classes.input}
            multiline
            value={JSON.stringify(sanitizedItem, null, 2)}
            fullWidth={true}
          />
        </CopyToClipboard>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Grid item>
            <Button onClick={onConfirm} variant={'contained'}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ExportJsonDialog)
