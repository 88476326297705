import { useEffect } from 'react'
import { useAuth } from 'react-auth0'
import LogRocketImport from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

let isInitialized = false

export default function LogRocket(): JSX.Element {
  const { session } = useAuth()

  useEffect(() => {
    if (process.env.LOGROCKET_ADMIN_ID && session) {
      // we can only run LogRocket.init once
      if (!isInitialized) {
        LogRocketImport.init(process.env.LOGROCKET_ADMIN_ID, {
          release: `${process.env.VERSION}-${process.env.COMMIT}`,
          shouldCaptureIP: true
        })
        setupLogRocketReact(LogRocketImport)
        isInitialized = true
      }

      LogRocketImport.identify(session.user_id, {
        name: session.name ?? `${session.user_metadata.firstName} ${session.user_metadata.lastName}`,
        email: session.email ?? session.user_metadata.email,
        company: 'admin'
      })
    }
  }, [session])

  return null
}
